import { ConfigProvider, message, Skeleton } from "antd";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Auth0Config } from "./config";
import actions from "./QianKunActions";

import { Suspense } from "react";
import "./public-path";
import reportWebVitals from "./reportWebVitals";

import Auth0Provider from "@thepiquelab/web-auth0";
import "./index.less";

let root: ReactDOM.Root;

function render(props: any) {
  root = ReactDOM.createRoot(
    props?.container
      ? props?.container.querySelector("#archus-administration-root")
      : document.querySelector("#archus-administration-root"),
  );

  ConfigProvider.config({
    prefixCls: "ce-ant",
  });

  message.config({
    getContainer: () =>
      document.getElementById("archus-administration-root") as HTMLElement,
    prefixCls: "ce-ant-message",
  });

  root.render(
    <Suspense fallback={<Skeleton />}>
      <BrowserRouter
        basename={
          // @ts-ignore
          window.__POWERED_BY_QIANKUN__ ? "/system-user" : "/"
        }
      >
        <ConfigProvider
          prefixCls="ce-ant"
          getTargetContainer={() =>
            document.getElementById("archus-administration-root") as HTMLElement
          }
          getPopupContainer={() =>
            document.getElementById("archus-administration-root") as HTMLElement
          }
        >
          <Auth0Provider
            {...Auth0Config}
            loadingNode={<Skeleton />}
            message={message}
          >
            <App />
          </Auth0Provider>
        </ConfigProvider>
      </BrowserRouter>
    </Suspense>,
  );
}

//@ts-ignore
if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap() {}

export async function mount(props: any) {
  actions.setActions(props);
  render(props);
}

export async function unmount(props: any) {
  root.unmount();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
