import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Decimal` scalar type to represent currency values */
  Decimal: any;
  /** Json object scalar type */
  Object: any;
  /** Mongo object id scalar type */
  ObjectId: any;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcademicLevel = {
  __typename?: 'AcademicLevel';
  _id: Scalars['ObjectId'];
  alias: Scalars['String'];
  classes?: Maybe<Array<Class>>;
  courses?: Maybe<Array<Course>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  educationStage: EducationStage;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  orderNo?: Maybe<Scalars['Float']>;
  seatsAnalyticsByAcademicLevel?: Maybe<SeatAnalytics>;
  systemId?: Maybe<Scalars['String']>;
};


export type AcademicLevelClassesArgs = {
  filterInput?: InputMaybe<ClassFilterInput>;
};


export type AcademicLevelCoursesArgs = {
  statuses?: InputMaybe<Array<CourseStatus>>;
  types?: InputMaybe<Array<CourseType>>;
};


export type AcademicLevelSeatsAnalyticsByAcademicLevelArgs = {
  filterInput?: InputMaybe<ClassFilterInput>;
};

export type AcademicWeek = {
  __typename?: 'AcademicWeek';
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  /** The week number in an academic year */
  weekNumber: Scalars['Int'];
};

export type AcademicWeekData = {
  __typename?: 'AcademicWeekData';
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  selectionData?: Maybe<Array<SelectionData>>;
};

export type AcademicWeekInput = {
  weekNumber: Scalars['Int'];
};

/** A single instance of an academic week within an academic year. */
export type AcademicWeekWithId = {
  __typename?: 'AcademicWeekWithId';
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'];
  /** The end date of the academic week. */
  endDate: Scalars['DateTime'];
  /** The start date of the academic week. */
  startDate: Scalars['DateTime'];
  /** The week number within the academic year. */
  weekNumber: Scalars['Float'];
};

/** A calendar year pertaining to the academic year. */
export type AcademicYear = {
  __typename?: 'AcademicYear';
  /** Unique identifier of the academic year record. */
  _id: Scalars['ObjectId'];
  /** The day the week end date falls on. e.g. Monday. */
  academicWeekEnd: WeekDay;
  /** The day the week start date falls on. e.g. Monday. */
  academicWeekStart: WeekDay;
  /** Academic weeks within the academic year. */
  academicWeeks: Array<AcademicWeekWithId>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  groupAssignment?: Maybe<GroupAssignment>;
  holidays?: Maybe<Array<Holiday>>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /** The start and end date of the academic year. */
  period: DateTimeRange;
  taxRate: Scalars['Float'];
  term?: Maybe<Term>;
  /** Term billing due dates within the academic year. */
  termBillingDueDate?: Maybe<TermBillingDueDate>;
  terms?: Maybe<Array<Term>>;
  /** The calendar year that the academic year is for. There could only be one record per year. */
  year: Scalars['Int'];
};


/** A calendar year pertaining to the academic year. */
export type AcademicYearTermArgs = {
  academicLevelId: Scalars['ObjectId'];
};

export type AccessDevice = {
  __typename?: 'AccessDevice';
  id?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type Account = {
  __typename?: 'Account';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type AccountInput = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export enum AccountType {
  Bank = 'BANK',
  Current = 'CURRENT',
  Currliab = 'CURRLIAB',
  Depreciatn = 'DEPRECIATN',
  Directcosts = 'DIRECTCOSTS',
  Equity = 'EQUITY',
  Expense = 'EXPENSE',
  Fixed = 'FIXED',
  Inventory = 'INVENTORY',
  Liability = 'LIABILITY',
  Noncurrent = 'NONCURRENT',
  Otherincome = 'OTHERINCOME',
  Overheads = 'OVERHEADS',
  Payg = 'PAYG',
  Paygliability = 'PAYGLIABILITY',
  Prepayment = 'PREPAYMENT',
  Revenue = 'REVENUE',
  Sales = 'SALES',
  Superannuationexpense = 'SUPERANNUATIONEXPENSE',
  Superannuationliability = 'SUPERANNUATIONLIABILITY',
  Termliab = 'TERMLIAB',
  Wagesexpense = 'WAGESEXPENSE'
}

export type Activities = {
  isExercise?: InputMaybe<Scalars['Boolean']>;
  isHomework?: InputMaybe<Scalars['Boolean']>;
};

/** Activity Log. */
export type ActivityLog = {
  __typename?: 'ActivityLog';
  _id: Scalars['ObjectId'];
  addIds?: Maybe<Array<Scalars['ObjectId']>>;
  adds?: Maybe<Array<ActivityLogEntity>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  keywords?: Maybe<Array<Scalars['String']>>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  previousAndUpdateValue?: Maybe<ActivityLogValue>;
  removeIds?: Maybe<Array<Scalars['ObjectId']>>;
  removes?: Maybe<Array<ActivityLogEntity>>;
  sourceId: Scalars['ObjectId'];
  subType: ActivityLogSubType;
  type: ActivityLogType;
};

export type ActivityLogEntity = SystemUser;

export type ActivityLogListQuery = {
  sourceIds: Array<Scalars['ObjectId']>;
};

export type ActivityLogPage = {
  __typename?: 'ActivityLogPage';
  items: Array<ActivityLog>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ActivityLogSubType {
  Created = 'Created',
  TaskAddedChecklist = 'TaskAddedChecklist',
  TaskAddedItem = 'TaskAddedItem',
  TaskAssigned = 'TaskAssigned',
  TaskAssignedChecklist = 'TaskAssignedChecklist',
  TaskAssignedItem = 'TaskAssignedItem',
  TaskCompleted = 'TaskCompleted',
  TaskCompletedItem = 'TaskCompletedItem',
  TaskDeletedChecklist = 'TaskDeletedChecklist',
  TaskDeletedItem = 'TaskDeletedItem',
  TaskReopen = 'TaskReopen',
  TaskUnassigned = 'TaskUnassigned',
  TaskUnassignedChecklist = 'TaskUnassignedChecklist',
  TaskUnassignedItem = 'TaskUnassignedItem',
  TaskUncompletedItem = 'TaskUncompletedItem',
  TaskUpdatedDueDate = 'TaskUpdatedDueDate',
  TaskVoided = 'TaskVoided'
}

export enum ActivityLogType {
  Task = 'Task'
}

export type ActivityLogValue = TaskActivityLogDateValue;

export type AddInvoiceLineItemCommand = {
  invoiceId: Scalars['ObjectId'];
  lineItem: AddLineItemCommand;
};

/**
 * Inputs for the Add LessonFlow Schedule mutation.
 *   Add either a worksheet, test, break, and other to the schedule. Passing 2 or
 *   more item types at the same time will be invalid.
 */
export type AddLessonFlowScheduleInput = {
  /** Required for updating existing lesson plan item of type BREAK. */
  break?: InputMaybe<LessonFlowBreakItemInput>;
  /** Id of the lesson flow under the lesson plan you want the schedule to be added to. */
  lessonFlowId: Scalars['ObjectId'];
  /** Id of the lesson plan you want the schedule to be added to. */
  lessonPlanId: Scalars['ObjectId'];
  /** Required for updating existing lesson plan item of type OTHER. */
  other?: InputMaybe<LessonFlowOtherItemInput>;
  /** Required for updating existing lesson plan item of type TEST. */
  test?: InputMaybe<LessonFlowTestItemInput>;
  /** Required for updating existing lesson plan item of type WORKSHEET. */
  worksheet?: InputMaybe<LessonFlowWorkSheetItemInput>;
};

/** Indicates the effect of the add lesson flow schedule operation. */
export type AddLessonFlowScheduleResponse = {
  __typename?: 'AddLessonFlowScheduleResponse';
  /** The updated lesson plan - added schedule to a lesson flow. */
  updatedLessonPlan: LessonPlan;
  /** The number of lessons updated with the schedule of the lesson flow. */
  updatedLessonsCount: Scalars['Int'];
};

/** Adds a lesson plan to a course. */
export type AddLessonPlanInput = {
  /** The year to assign the Lesson Plan. */
  academicYearId: Scalars['ObjectId'];
  /** The course to assign the Lesson Plan. */
  courseId: Scalars['ObjectId'];
  /** The name to assign to the Lesson Plan. */
  name: Scalars['String'];
};

export type AddLineItemCommand = {
  description: Scalars['String'];
  quantity: Scalars['Int'];
  unitAmount: Scalars['Decimal'];
};

export type AddRewardCatalogueInput = {
  avatar: Scalars['String'];
  category: Scalars['String'];
  description: Scalars['String'];
  galleries?: InputMaybe<Array<Scalars['String']>>;
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
  points: Scalars['Int'];
  stock: Scalars['Int'];
};

/** Reward points input to add for one student item */
export type AddRewardPointsInput = {
  /** Linked gradable (Exercise or Homework) ID if points are from award */
  gradableId?: InputMaybe<Scalars['ObjectId']>;
  /** Amount of reward points */
  points: Scalars['Float'];
  /** Other notes associated with the reward transaction */
  remarks?: InputMaybe<Scalars['String']>;
  /** Student ID on where reward points will be added */
  studentId: Scalars['ObjectId'];
  /** Type of reward history */
  type?: InputMaybe<RewardHistoryType>;
};

export type AddXeroAccountInput = {
  code: Scalars['String'];
  name: Scalars['String'];
  type: AccountType;
};

export type Address = {
  __typename?: 'Address';
  country?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  country?: InputMaybe<Scalars['String']>;
  line1: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
};

export type AdjustBalanceInput = {
  amount: Scalars['Decimal'];
  description: Scalars['String'];
  parentId: Scalars['ObjectId'];
};

export type Admin = {
  __typename?: 'Admin';
  /** Unique identifier for the record */
  _id: Scalars['ObjectId'];
  alias?: Maybe<Scalars['String']>;
  alternateUserId?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  branches?: Maybe<Array<Branch>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Department>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  linkUser?: Maybe<User>;
  mobile?: Maybe<Telephone>;
  officePhone?: Maybe<Telephone>;
  salutation?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type AdminFilterInput = {
  branchIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  departmentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  mobile?: InputMaybe<TelephoneInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type AdminPage = {
  __typename?: 'AdminPage';
  items: Array<Admin>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type AlternativeLessonsForChangeAttendingClassInput = {
  lessonId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};

export type AmountAudit = {
  __typename?: 'AmountAudit';
  onHold: Scalars['Decimal'];
  overdue: Scalars['Decimal'];
  paid: Scalars['Decimal'];
  pending: Scalars['Decimal'];
  unpaid: Scalars['Decimal'];
};

export type Applicable = {
  isApplicable: Scalars['Boolean'];
};

export type ApplyInvoiceDiscountCommand = {
  discountId: Scalars['ObjectId'];
  lineItemId: Scalars['ObjectId'];
  remarks?: InputMaybe<Scalars['String']>;
};

/** Contains the lesson plan id and the class id where the lesson plan should be applied. */
export type ApplyLessonPlanToClassInput = {
  /** The class to apply the lesson plan. */
  classId: Scalars['ObjectId'];
  /** The lesson plan containing the lesson flows to apply to a class. */
  lessonPlanId: Scalars['ObjectId'];
};

/** Indicates the effect of the apply lesson plan to class operation. */
export type ApplyLessonPlanToClassResponse = {
  __typename?: 'ApplyLessonPlanToClassResponse';
  /** The number of lessons the lesson flow was applied to. */
  lessonsAppliedWithFlowsCount: Scalars['Int'];
  /** The updated class that the lesson plan was applied to. */
  updatedClass: Class;
  /** The updated lesson plan that was applied to the class. */
  updatedLessonPlan: LessonPlan;
};

export type ApproveRegistrationInput = {
  dueDate?: InputMaybe<Scalars['DateTime']>;
  group?: InputMaybe<EnrollmentGroupType>;
  id: Scalars['ObjectId'];
  isChangeToWaitingList?: InputMaybe<Scalars['Boolean']>;
  preferredLearningArrangement?: InputMaybe<LearningArrangementType>;
  remarks?: InputMaybe<Scalars['String']>;
  selectedLessons?: InputMaybe<Array<SelectedLessonInput>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type Archivable = {
  isArchived: Scalars['Boolean'];
};

/** Input for archiving the worksheet. */
export type ArchiveWorkSheetsInput = {
  /** The ids of the worksheets to be archived. */
  worksheetIds: Array<Scalars['ObjectId']>;
};

export type Arrangement = {
  __typename?: 'Arrangement';
  _id: Scalars['ObjectId'];
  arrangementBillingCheck?: Maybe<ArrangementBillingCheckResult>;
  cancelInfo?: Maybe<TransferCancelInfo>;
  cancellation?: Maybe<Array<Arrangement>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  currentStep?: Maybe<Scalars['Int']>;
  currentTermNumber?: Maybe<Scalars['Int']>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailStatus?: Maybe<ReminderStatus>;
  enrollment?: Maybe<Enrollment>;
  enrollmentId?: Maybe<Scalars['ObjectId']>;
  fromClass?: Maybe<Class>;
  fromLesson?: Maybe<Lesson>;
  incompleteDeadline: Scalars['DateTime'];
  invoice?: Maybe<Invoice>;
  isAdhocAdditionalLesson?: Maybe<Scalars['Boolean']>;
  isCancelHasFullLesson?: Maybe<Scalars['Boolean']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  newEnrollment?: Maybe<Enrollment>;
  newEnrollmentId?: Maybe<Scalars['ObjectId']>;
  originInvoice?: Maybe<Invoice>;
  processedBy?: Maybe<SystemUser>;
  processedDateTime?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  reminders?: Maybe<Array<Reminder>>;
  selectedLessons?: Maybe<Array<SelectedLesson>>;
  session?: Maybe<Session>;
  status?: Maybe<ArrangementStatus>;
  student: Student;
  toClass?: Maybe<Class>;
  toLesson?: Maybe<Lesson>;
  type: ArrangementType;
  useCredits?: Maybe<Scalars['Boolean']>;
  waStatus?: Maybe<ReminderStatus>;
  willAttendLessons?: Maybe<Array<Lesson>>;
};

export type ArrangementAcademicYearIndex = {
  __typename?: 'ArrangementAcademicYearIndex';
  _id: Scalars['ObjectId'];
  year: Scalars['Int'];
};

export type ArrangementBillingCheckResult = {
  __typename?: 'ArrangementBillingCheckResult';
  amountUsed: Scalars['Decimal'];
  feeDifference?: Maybe<Scalars['Decimal']>;
  lessonsNeedNotToPay: Array<Lesson>;
  lessonsNeedToPay: Array<Lesson>;
  remainingFee: Scalars['Decimal'];
  updatedLessonFee?: Maybe<Scalars['Decimal']>;
};

export type ArrangementClassIndex = {
  __typename?: 'ArrangementClassIndex';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type ArrangementCourseIndex = {
  __typename?: 'ArrangementCourseIndex';
  _id: Scalars['ObjectId'];
  nameShort: Scalars['String'];
};

export type ArrangementFilterInput = {
  academicYearIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  createdBy?: InputMaybe<Scalars['ObjectId']>;
  dateRange?: InputMaybe<DateTimeRangeInput>;
  fromClassIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  fromLessonIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  name?: InputMaybe<Scalars['String']>;
  paymentStatuses?: InputMaybe<Array<InvoiceStatus>>;
  status?: InputMaybe<Array<ArrangementStatus>>;
  studentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  toClassIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  toLessonIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  types?: InputMaybe<Array<ArrangementType>>;
};

export type ArrangementHolidayIndex = {
  __typename?: 'ArrangementHolidayIndex';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type ArrangementIndex = {
  __typename?: 'ArrangementIndex';
  _id: Scalars['ObjectId'];
  academicYear?: Maybe<ArrangementAcademicYearIndex>;
  course?: Maybe<ArrangementCourseIndex>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  currentStep?: Maybe<Scalars['Int']>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  effectedHolidays?: Maybe<Array<ArrangementHolidayIndex>>;
  emailStatus?: Maybe<ReminderStatus>;
  enrollmentId?: Maybe<Scalars['ObjectId']>;
  fromClass?: Maybe<ArrangementClassIndex>;
  fromClassTeacher?: Maybe<ArrangementTeacherIndex>;
  fromLesson?: Maybe<ArrangementLessonIndex>;
  invoice?: Maybe<ArrangementInvoiceIndex>;
  isAdhocAdditionalLesson?: Maybe<Scalars['Boolean']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  newEnrollmentId?: Maybe<Scalars['ObjectId']>;
  processedByFullName?: Maybe<Scalars['String']>;
  processedDateTime?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  status?: Maybe<ArrangementStatus>;
  student?: Maybe<ArrangementStudentIndex>;
  toClass?: Maybe<ArrangementClassIndex>;
  toClassTeacher?: Maybe<ArrangementTeacherIndex>;
  toLesson?: Maybe<ArrangementLessonIndex>;
  type: ArrangementType;
  useCredits?: Maybe<Scalars['Boolean']>;
  waStatus?: Maybe<ReminderStatus>;
};

export type ArrangementIndexPage = {
  __typename?: 'ArrangementIndexPage';
  items: Array<ArrangementIndex>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type ArrangementInvoiceIndex = {
  __typename?: 'ArrangementInvoiceIndex';
  _id: Scalars['ObjectId'];
  status: InvoiceStatus;
};

export type ArrangementLessonIndex = {
  __typename?: 'ArrangementLessonIndex';
  _id: Scalars['ObjectId'];
  lessonEnd: Scalars['DateTime'];
  lessonStart: Scalars['DateTime'];
};

export type ArrangementPage = {
  __typename?: 'ArrangementPage';
  items: Array<Arrangement>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type ArrangementSession = {
  __typename?: 'ArrangementSession';
  arrangementId: Scalars['ObjectId'];
};

export enum ArrangementStatus {
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Draft = 'Draft'
}

export type ArrangementStudentIndex = {
  __typename?: 'ArrangementStudentIndex';
  _id: Scalars['ObjectId'];
  avatarUrl?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ArrangementTeacherIndex = {
  __typename?: 'ArrangementTeacherIndex';
  _id: Scalars['ObjectId'];
  avatarUrl?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
};

export enum ArrangementType {
  Additional = 'Additional',
  Makeup = 'Makeup',
  Transfer = 'Transfer',
  Withdrawals = 'Withdrawals'
}

export type Attendance = {
  __typename?: 'Attendance';
  _id: Scalars['ObjectId'];
  class: Class;
  classId: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  endDateTime: Scalars['DateTime'];
  enrollment?: Maybe<Enrollment>;
  group?: Maybe<EnrollmentGroupType>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  learningArrangement?: Maybe<LearningArrangementType>;
  lesson: Lesson;
  lessonId: Scalars['ObjectId'];
  lessonMode?: Maybe<LearningArrangementType>;
  linkArrangement?: Maybe<Arrangement>;
  linkInvoice?: Maybe<Invoice>;
  linkedAttendance?: Maybe<Attendance>;
  /**
   * In the CRM, when the 'Manual Delivery Option' is selected for
   *     this attendance. This will have a value containing the student's attendance.
   */
  materialsForHandOver?: Maybe<Material>;
  previousLessonAttendance?: Maybe<Attendance>;
  /** The remarks associated with this attendance are 'Lesson Remarks'. */
  remarks?: Maybe<Array<Remark>>;
  startDateTime: Scalars['DateTime'];
  status?: Maybe<AttendanceStatus>;
  statusTag?: Maybe<AttendanceStatusTag>;
  student?: Maybe<Student>;
  type: AttendanceType;
};


export type AttendanceRemarksArgs = {
  includesStudentRemarks?: InputMaybe<Scalars['Boolean']>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
};

export type AttendanceAcademicLevelIndex = {
  __typename?: 'AttendanceAcademicLevelIndex';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type AttendanceArrangementIndex = {
  __typename?: 'AttendanceArrangementIndex';
  _id: Scalars['ObjectId'];
  fromClassId?: Maybe<Scalars['ObjectId']>;
  fromClassShorthand?: Maybe<Scalars['String']>;
  status?: Maybe<ArrangementStatus>;
  toClassId?: Maybe<Scalars['ObjectId']>;
  toClassShorthand?: Maybe<Scalars['String']>;
  type: ArrangementType;
};

export type AttendanceClassIndex = {
  __typename?: 'AttendanceClassIndex';
  _id: Scalars['ObjectId'];
  academicYearId: Scalars['ObjectId'];
  courseId: Scalars['ObjectId'];
  learningArrangement: LearningArrangementType;
  shorthand?: Maybe<Scalars['String']>;
  type?: Maybe<ClassType>;
};

export type AttendanceEnrollmentIndex = {
  __typename?: 'AttendanceEnrollmentIndex';
  _id: Scalars['ObjectId'];
  followUpStatus?: Maybe<FollowUpStatus>;
  followUpStatusLastModifiedByFullName?: Maybe<Scalars['String']>;
  followUpStatusLastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  group?: Maybe<EnrollmentGroupType>;
  preferredLearningArrangement?: Maybe<LearningArrangementType>;
  status: EnrollmentStatus;
  /** Enrollment suitability information that tells if student is suitable for registration */
  suitability?: Maybe<EnrollmentSuitability>;
  type: EnrollmentType;
};

export type AttendanceIndex = {
  __typename?: 'AttendanceIndex';
  _id: Scalars['ObjectId'];
  academicLevels?: Maybe<Array<AttendanceAcademicLevelIndex>>;
  class?: Maybe<AttendanceClassIndex>;
  classId: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  endDateTime: Scalars['DateTime'];
  enrollment?: Maybe<AttendanceEnrollmentIndex>;
  flagRemark?: Maybe<Scalars['String']>;
  group?: Maybe<EnrollmentGroupType>;
  invoice?: Maybe<AttendanceInvoiceIndex>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  latestLessonRemark?: Maybe<Scalars['String']>;
  learningArrangement?: Maybe<LearningArrangementType>;
  lesson?: Maybe<AttendanceLessonIndex>;
  lessonId: Scalars['ObjectId'];
  lessonMode?: Maybe<LearningArrangementType>;
  linkArrangements?: Maybe<Array<AttendanceArrangementIndex>>;
  pinnedRemark?: Maybe<Scalars['String']>;
  previousAttendance?: Maybe<PreviousAttendanceIndex>;
  primaryParent?: Maybe<AttendanceParentIndex>;
  primaryParentWhatsappAccount?: Maybe<AttendanceWhatsappAccountIndex>;
  secondaryParentWhatsappAccounts?: Maybe<Array<AttendanceWhatsappAccountIndex>>;
  secondaryParents?: Maybe<Array<AttendanceParentIndex>>;
  startDateTime: Scalars['DateTime'];
  status?: Maybe<AttendanceStatus>;
  statusTag?: Maybe<AttendanceStatusTag>;
  student?: Maybe<AttendanceStudentIndex>;
  teacher?: Maybe<AttendanceTeacherIndex>;
  trialAttendances?: Maybe<Array<AttendanceTrialIndex>>;
  type: AttendanceType;
};

export type AttendanceIndexPage = {
  __typename?: 'AttendanceIndexPage';
  items: Array<AttendanceIndex>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type AttendanceInvoiceIndex = {
  __typename?: 'AttendanceInvoiceIndex';
  _id: Scalars['ObjectId'];
  dueDate?: Maybe<Scalars['DateTime']>;
  status: InvoiceStatus;
};

export type AttendanceLessonIndex = {
  __typename?: 'AttendanceLessonIndex';
  _id: Scalars['ObjectId'];
  lessonEnd: Scalars['DateTime'];
  lessonNumber: Scalars['Float'];
  lessonStart: Scalars['DateTime'];
};

export type AttendancePage = {
  __typename?: 'AttendancePage';
  items: Array<Attendance>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type AttendanceParentIndex = {
  __typename?: 'AttendanceParentIndex';
  _id: Scalars['ObjectId'];
  avatarUrl?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  mobile: Telephone;
  salutation?: Maybe<Scalars['String']>;
};

export type AttendanceSnapshot = {
  __typename?: 'AttendanceSnapshot';
  _id: Scalars['ObjectId'];
  class: Class;
  classId: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  endDateTime: Scalars['DateTime'];
  group?: Maybe<EnrollmentGroupType>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lesson: Lesson;
  lessonId: Scalars['ObjectId'];
  lessonMode?: Maybe<LearningArrangementType>;
  /** The remarks for this attendance. This should be used over the `remarks` prop. */
  remarks?: Maybe<Array<Remark>>;
  startDateTime: Scalars['DateTime'];
  status?: Maybe<AttendanceStatus>;
  student?: Maybe<Student>;
  type: AttendanceType;
};


export type AttendanceSnapshotRemarksArgs = {
  includesStudentRemarks?: InputMaybe<Scalars['Boolean']>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
};

export enum AttendanceStatus {
  Absent = 'Absent',
  Cancelled = 'Cancelled',
  Holding = 'Holding',
  NotAttending = 'NotAttending',
  Present = 'Present',
  Skipped = 'Skipped',
  Unmarked = 'Unmarked'
}

export type AttendanceStatusTag = {
  __typename?: 'AttendanceStatusTag';
  description: Scalars['String'];
  tags: Array<AttendanceTag>;
};

export type AttendanceStudentIndex = {
  __typename?: 'AttendanceStudentIndex';
  _id: Scalars['ObjectId'];
  alias: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export enum AttendanceTag {
  AdditionalLesson = 'AdditionalLesson',
  Paid = 'Paid',
  ReplacementIn = 'ReplacementIn',
  TransferredOut = 'TransferredOut',
  TransferringIn = 'TransferringIn',
  TransferringOut = 'TransferringOut',
  Trial = 'Trial',
  Unpaid = 'Unpaid',
  Withdrawing = 'Withdrawing',
  Withdrawn = 'Withdrawn'
}

export type AttendanceTeacherIndex = {
  __typename?: 'AttendanceTeacherIndex';
  _id: Scalars['ObjectId'];
  avatarUrl?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
};

export type AttendanceTrialIndex = {
  __typename?: 'AttendanceTrialIndex';
  _id: Scalars['ObjectId'];
  startDateTime: Scalars['DateTime'];
  status?: Maybe<AttendanceStatus>;
};

export enum AttendanceType {
  Additional = 'Additional',
  MakeupIn = 'MakeupIn',
  Regular = 'Regular',
  Trial = 'Trial'
}

export type AttendanceWhatsappAccountIndex = {
  __typename?: 'AttendanceWhatsappAccountIndex';
  _id: Scalars['ObjectId'];
  number?: Maybe<Telephone>;
  parentId: Scalars['ObjectId'];
};

export type AuditLog = {
  __typename?: 'AuditLog';
  _id: Scalars['ObjectId'];
  accessDevice?: Maybe<AccessDevice>;
  changes?: Maybe<Array<Changes>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<AuditLogDescriptionType>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  operationType: AuditLogOperationType;
  principal?: Maybe<SystemUser>;
  principalId: Scalars['ObjectId'];
  resourceId?: Maybe<Scalars['ObjectId']>;
  resourceName: Scalars['String'];
  resourceType?: Maybe<AuditLogResourceType>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export enum AuditLogDescriptionType {
  Approved = 'Approved',
  Archive = 'Archive',
  Cancelled = 'Cancelled',
  ChangeToRegistration = 'ChangeToRegistration',
  Link = 'Link',
  LinkRemove = 'LinkRemove',
  SetAttendance = 'SetAttendance',
  Unarchive = 'Unarchive',
  UpdateRecord = 'UpdateRecord',
  Void = 'Void'
}

export type AuditLogFilterInput = {
  dateTimeRange?: InputMaybe<DateRangeInput>;
  descriptions?: InputMaybe<Array<AuditLogDescriptionType>>;
  fieldNames?: InputMaybe<Array<Scalars['String']>>;
  operationTypes?: InputMaybe<Array<AuditLogOperationType>>;
  principalId?: InputMaybe<Scalars['ObjectId']>;
  principalType?: InputMaybe<Array<UserRole>>;
  resourceTypes?: InputMaybe<Array<AuditLogResourceType>>;
};

export enum AuditLogOperationType {
  Create = 'Create',
  Delete = 'Delete',
  Fetch = 'Fetch',
  Unknown = 'Unknown',
  Update = 'Update'
}

export type AuditLogPage = {
  __typename?: 'AuditLogPage';
  items: Array<AuditLog>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AuditLogResourceType {
  Admin = 'Admin',
  Attendance = 'Attendance',
  Classes = 'Classes',
  Course = 'Course',
  Invoice = 'Invoice',
  Lessons = 'Lessons',
  Makeup = 'Makeup',
  Parameter = 'Parameter',
  Parent = 'Parent',
  Receipt = 'Receipt',
  Refund = 'Refund',
  Registration = 'Registration',
  Student = 'Student',
  Submission = 'Submission',
  Teacher = 'Teacher',
  Template = 'Template',
  Transfer = 'Transfer',
  Withdrawal = 'Withdrawal'
}

export type Balance = {
  __typename?: 'Balance';
  _id: Scalars['ObjectId'];
  balance: Scalars['Decimal'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  parent?: Maybe<Parent>;
  parentId: Scalars['ObjectId'];
};

export enum BalanceActionType {
  AddToBalance = 'AddToBalance',
  ApplyBalance = 'ApplyBalance',
  DeductBalance = 'DeductBalance',
  ManualAdjustBalance = 'ManualAdjustBalance',
  ReversalOfApplyBalance = 'ReversalOfApplyBalance',
  VoidReceipt = 'VoidReceipt'
}

export type BalanceHistory = {
  __typename?: 'BalanceHistory';
  _id: Scalars['ObjectId'];
  actionType?: Maybe<BalanceActionType>;
  amount: Scalars['Decimal'];
  amountPaid?: Maybe<Scalars['Decimal']>;
  amountPayable?: Maybe<Scalars['Decimal']>;
  balance: Scalars['Decimal'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  invoiceIds?: Maybe<Array<Scalars['ObjectId']>>;
  invoiceNumbers?: Maybe<Array<Scalars['String']>>;
  invoices?: Maybe<Array<Invoice>>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isMerged?: Maybe<Scalars['Boolean']>;
  isVoidedInvoice?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  originalInvoiceAmount?: Maybe<Scalars['Decimal']>;
  outstandingPayable?: Maybe<Scalars['Decimal']>;
  parent: Parent;
  parentId: Scalars['ObjectId'];
  /** The date when the payment was made */
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentIds?: Maybe<Array<Scalars['ObjectId']>>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentNumbers?: Maybe<Array<Scalars['String']>>;
  payments?: Maybe<Array<Payment>>;
  receiptIds?: Maybe<Array<Scalars['ObjectId']>>;
  receiptNumbers?: Maybe<Array<Scalars['String']>>;
  refund?: Maybe<Refund>;
  refundId?: Maybe<Scalars['ObjectId']>;
  type: BalanceHistoryType;
};

export type BalanceHistoryFilterInput = {
  actionTypes?: InputMaybe<Array<BalanceActionType>>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ObjectId']>;
  paymentDate?: InputMaybe<DateTimeRangeInput>;
  paymentMethods?: InputMaybe<Array<PaymentMode>>;
  types?: InputMaybe<Array<BalanceHistoryType>>;
};

export type BalanceHistoryPage = {
  __typename?: 'BalanceHistoryPage';
  items: Array<BalanceHistory>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum BalanceHistoryType {
  BalanceRefund = 'BalanceRefund',
  FeePayment = 'FeePayment',
  ManualAdjustment = 'ManualAdjustment',
  MergeAdjustment = 'MergeAdjustment',
  Receipt = 'Receipt',
  VoidBalanceRefund = 'VoidBalanceRefund',
  VoidFeePayment = 'VoidFeePayment',
  VoidReceipt = 'VoidReceipt'
}

export enum BillingFrequency {
  Perclass = 'Perclass',
  Permonth = 'Permonth',
  Perterm = 'Perterm'
}

export type Branch = {
  __typename?: 'Branch';
  _id: Scalars['ObjectId'];
  address: Address;
  alias: Scalars['String'];
  centre: Centre;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  systemId?: Maybe<Scalars['String']>;
  venues?: Maybe<Array<Venue>>;
};


export type BranchVenuesArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};

/** Reward points input to add for multiple student items */
export type BulkAddRewardPointsInput = {
  /** Linked gradable (Exercise or Homework) ID if points are from award */
  gradableId?: InputMaybe<Scalars['ObjectId']>;
  /** Multiple student items along with points to be added */
  items: Array<RewardPointsItemInput>;
  /** Other notes associated with the reward transaction */
  remarks?: InputMaybe<Scalars['String']>;
  /** Type of reward history */
  type?: InputMaybe<RewardHistoryType>;
};

export type BulkCreateClassCommand = {
  adhocClasses?: InputMaybe<Array<CreateAdhocClassCommand>>;
  regularClasses?: InputMaybe<Array<CreateRegularClassCommand>>;
  workshopClasses?: InputMaybe<Array<CreateWorkShopClassCommand>>;
};

export type BulkCreateDeliveryInput = {
  createDeliveries?: InputMaybe<Array<CreateDeliveryInput>>;
};

export type BulkCreateHistory = {
  __typename?: 'BulkCreateHistory';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  failed?: Maybe<Scalars['Int']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isExportFailedDocuments?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  processed?: Maybe<Scalars['Int']>;
  status: BulkCreateHistoryStatus;
  total?: Maybe<Scalars['Int']>;
  type: BulkCreateHistoryType;
};

export enum BulkCreateHistoryStatus {
  Complete = 'Complete',
  Processing = 'Processing',
  ToBeConfirmed = 'ToBeConfirmed'
}

export enum BulkCreateHistoryType {
  Class = 'Class',
  Registration = 'Registration'
}

export type BulkCreateRegistrationCommand = {
  details: Array<BulkCreateRegistrationDetailCommand>;
};

export type BulkCreateRegistrationDetailCommand = {
  classId: Scalars['ObjectId'];
  lessonIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  stage: RegistrationStage;
  startDate: Scalars['DateTime'];
  studentId: Scalars['ObjectId'];
};

/** Reward points input to deduct for multiple student items */
export type BulkDeductRewardPointsInput = {
  /** Linked gradable (Exercise or Homework) ID if points are from award */
  gradableId?: InputMaybe<Scalars['ObjectId']>;
  /** Multiple student items along with points to be deducted */
  items: Array<RewardPointsItemInput>;
  /** Reward order to redeem */
  orderDetail?: InputMaybe<RewardDetailInput>;
  /** Other notes associated with the reward transaction */
  remarks?: InputMaybe<Scalars['String']>;
  /** Type of reward history */
  type?: InputMaybe<RewardHistoryType>;
};

/** Bulk Handover Onsite Material. */
export type BulkHandoverOnsiteCommand = {
  materials?: InputMaybe<Array<HandoverOnsiteMaterialCommand>>;
  weekNumber?: InputMaybe<Scalars['Int']>;
};

export type BulkSendReminderInput = {
  attendanceIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  emailInput?: InputMaybe<EmailReminderInput>;
  enrollmentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  generalRecipients?: InputMaybe<Array<CampaignRecipientInput>>;
  invoiceIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  materialIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  parentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  receiptIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  registrationIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  taskIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  types: Array<ReminderType>;
  whatsappInput?: InputMaybe<WhatsappReminderInput>;
};

export type Campaign = {
  __typename?: 'Campaign';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailSubject?: Maybe<Scalars['String']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isIncludeAttachment?: Maybe<Scalars['Boolean']>;
  isRealSended: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  realSendAt?: Maybe<Scalars['DateTime']>;
  recallAt?: Maybe<Scalars['DateTime']>;
  recipientTypes?: Maybe<Array<CampaignRecipientType>>;
  recipients?: Maybe<Array<CampaignRecipient>>;
  scheduleSendAt?: Maybe<Scalars['DateTime']>;
  sendMethod: ReminderType;
  sentAt?: Maybe<Scalars['DateTime']>;
  status: CampaignStatus;
  template?: Maybe<Template>;
  templateId?: Maybe<Scalars['ObjectId']>;
  usageType?: Maybe<TemplateUsageType>;
};

export type CampaignExportHistory = {
  __typename?: 'CampaignExportHistory';
  _id: Scalars['ObjectId'];
  campaign: Campaign;
  campaignId: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type CampaignExportHistoryPage = {
  __typename?: 'CampaignExportHistoryPage';
  items: Array<CampaignExportHistory>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type CampaignFilterInput = {
  type?: InputMaybe<TemplateType>;
};

export type CampaignPage = {
  __typename?: 'CampaignPage';
  items: Array<Campaign>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type CampaignRecipient = {
  __typename?: 'CampaignRecipient';
  message?: Maybe<Scalars['String']>;
  parent: Parent;
  parentId: Scalars['ObjectId'];
  receiptId?: Maybe<Scalars['ObjectId']>;
  studentIds: Array<Scalars['ObjectId']>;
  students: Array<Student>;
};

export type CampaignRecipientInput = {
  message?: InputMaybe<Scalars['String']>;
  parentId: Scalars['ObjectId'];
  receiptId?: InputMaybe<Scalars['ObjectId']>;
  studentIds: Array<Scalars['ObjectId']>;
};

export enum CampaignRecipientType {
  PrimaryParent = 'PrimaryParent',
  SecondaryParents = 'SecondaryParents'
}

export enum CampaignStatus {
  Draft = 'Draft',
  Failed = 'Failed',
  Recalled = 'Recalled',
  Scheduling = 'Scheduling',
  Sent = 'Sent'
}

export type CancellationEffects = {
  __typename?: 'CancellationEffects';
  invoicesToVoid?: Maybe<Array<Invoice>>;
  isCreateNewCreditNote?: Maybe<Scalars['Boolean']>;
  receiptsToVoid?: Maybe<Array<Receipt>>;
};

export enum CapacitySimulationType {
  Additional = 'Additional',
  Makeup = 'Makeup',
  Regular = 'Regular',
  Reservation = 'Reservation',
  Trial = 'Trial'
}

export type Centre = {
  __typename?: 'Centre';
  _id: Scalars['ObjectId'];
  branches?: Maybe<Array<Branch>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  departments?: Maybe<Array<Department>>;
  isArchived: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
};


export type CentreBranchesArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type CentreDepartmentsArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};

export type CentreAssignments = {
  __typename?: 'CentreAssignments';
  classScores?: Maybe<ScoreStats>;
  gradable?: Maybe<GradableUnion>;
  levelScores?: Maybe<ScoreStats>;
  submission?: Maybe<Submission>;
};

export type ChangeAcademicLevelArchive = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};

export type ChangeAcademicLevelVisibility = {
  id: Scalars['ObjectId'];
  isVisible: Scalars['Boolean'];
};

export type ChangeAdminArchiveInput = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};

export type ChangeAttendingClassForRegistrationInput = {
  fromLessonId: Scalars['ObjectId'];
  registrationId: Scalars['ObjectId'];
  toLessonId: Scalars['ObjectId'];
};

export type ChangeAttendingClassForTransferInput = {
  arrangementId: Scalars['ObjectId'];
  fromLessonId: Scalars['ObjectId'];
  toLessonId: Scalars['ObjectId'];
};

export type ChangeBranchArchiveInput = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};

/** An input that is used to update the assigned LessonFlow of one or many classes. The "ids" field must contain one or more IDs. */
export type ChangeClassLessonFlowInput = {
  /** Class ID to update. */
  id: Scalars['ObjectId'];
  /** The ID of the new LessonFlow to assign to the classes specified. */
  lessonFlowId?: InputMaybe<Scalars['ObjectId']>;
};

export type ChangeClassVisibilityInput = {
  id: Scalars['ObjectId'];
  isVisible: Scalars['Boolean'];
};

export type ChangeCourierPickupAddressArchiveInput = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};

export type ChangeDepartmentArchiveInput = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};

export type ChangeSchoolArchiveInput = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};

export type ChangeSchoolVisibilityInput = {
  id: Scalars['ObjectId'];
  isVisible: Scalars['Boolean'];
};

export type ChangeUserArchiveInput = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};

export type ChangeVenueArchiveInput = {
  /** Id of the venue */
  id: Scalars['ObjectId'];
  /** Indicator that the venue is archived, archiving the venue means it cannot be associated to a class */
  isArchived: Scalars['Boolean'];
};

export type Changes = {
  __typename?: 'Changes';
  fieldName?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
};

export type Class = {
  __typename?: 'Class';
  _id: Scalars['ObjectId'];
  academicLevelIds: Array<Scalars['ObjectId']>;
  academicLevels: Array<AcademicLevel>;
  academicWeeks: Array<AcademicWeek>;
  academicYear: AcademicYear;
  academicYearId: Scalars['ObjectId'];
  activeEnrollments?: Maybe<Array<Enrollment>>;
  capacity?: Maybe<ClassCapacity>;
  capacityUsed?: Maybe<Scalars['Int']>;
  classCode?: Maybe<Scalars['String']>;
  classFee?: Maybe<StandardCourseFee>;
  course: Course;
  courseId: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  dailySessionTimeSlots?: Maybe<Array<TimeRange>>;
  defaultLessonDays?: Maybe<Array<WeekDay>>;
  defaultLessonEnd?: Maybe<Scalars['DateTime']>;
  defaultLessonStart?: Maybe<Scalars['DateTime']>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  digitalClassAccessCredentials?: Maybe<DigitalClassAccessCredentials>;
  eligibility?: Maybe<Array<ClassEligibility>>;
  enrollments?: Maybe<Array<Enrollment>>;
  firstLessonDate?: Maybe<Scalars['DateTime']>;
  isAdHoc?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isReplacementLesson?: Maybe<Scalars['Boolean']>;
  isSimulation?: Maybe<Scalars['Boolean']>;
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastLessonDate?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  learningArrangement: LearningArrangementType;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  lessonFlow?: Maybe<LessonFlow>;
  lessonNumber?: Maybe<Scalars['Int']>;
  /** The lesson plan applied to the class. */
  lessonPlan?: Maybe<ClassLessonPlan>;
  lessons?: Maybe<Array<Lesson>>;
  maximumClassCapacity?: Maybe<Scalars['Int']>;
  maximumMakeUpCapacity?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  numberOfLessons?: Maybe<Scalars['Int']>;
  registrations?: Maybe<Array<Registration>>;
  repeat?: Maybe<Scalars['Int']>;
  runNumber?: Maybe<Scalars['Int']>;
  seats?: Maybe<Array<Seat>>;
  shorthand?: Maybe<Scalars['String']>;
  status?: Maybe<ClassStatus>;
  teacher?: Maybe<SystemUser>;
  teacherId?: Maybe<Scalars['ObjectId']>;
  term?: Maybe<Term>;
  type?: Maybe<ClassType>;
  venue?: Maybe<Venue>;
  venueId?: Maybe<Scalars['ObjectId']>;
  voidRemarks?: Maybe<Scalars['String']>;
};


export type ClassEligibilityArgs = {
  linkRegistrationId?: InputMaybe<Scalars['ObjectId']>;
  studentId?: InputMaybe<Scalars['ObjectId']>;
};


export type ClassEnrollmentsArgs = {
  status?: InputMaybe<Array<EnrollmentStatus>>;
  types?: InputMaybe<Array<EnrollmentType>>;
};


export type ClassLessonsArgs = {
  skipHoliday?: InputMaybe<Scalars['Boolean']>;
};


export type ClassRegistrationsArgs = {
  status?: InputMaybe<Array<RegistrationsStatus>>;
  types?: InputMaybe<Array<RegistrationType>>;
};

export type ClassAcademicLevelIndex = {
  __typename?: 'ClassAcademicLevelIndex';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type ClassAcademicYearIndex = {
  __typename?: 'ClassAcademicYearIndex';
  _id: Scalars['ObjectId'];
  year: Scalars['Int'];
};

export type ClassBranchIndex = {
  __typename?: 'ClassBranchIndex';
  _id: Scalars['ObjectId'];
  alias: Scalars['String'];
  name: Scalars['String'];
};

export type ClassCapacity = {
  __typename?: 'ClassCapacity';
  class?: Maybe<Scalars['Int']>;
  enrolled?: Maybe<Scalars['Int']>;
  makeUp?: Maybe<Scalars['Int']>;
  seats?: Maybe<Array<Seat>>;
  seatsLeft?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  waiting?: Maybe<Scalars['Int']>;
};

export enum ClassCapacitySeatType {
  Regular = 'Regular',
  Reserved = 'Reserved',
  TransferIn = 'TransferIn',
  TransferOut = 'TransferOut',
  Withdrawing = 'Withdrawing'
}

export type ClassChangeLog = {
  __typename?: 'ClassChangeLog';
  _id: Scalars['ObjectId'];
  affectAt: Scalars['DateTime'];
  classId?: Maybe<Scalars['ObjectId']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  digitalCredentials?: Maybe<DigitalClassAccessCredentialsDo>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  learningArrangement?: Maybe<LearningArrangementType>;
  lessonId?: Maybe<Scalars['ObjectId']>;
  previousDigitalCredentials?: Maybe<DigitalClassAccessCredentialsDo>;
  previousLearningArrangement?: Maybe<LearningArrangementType>;
  previousTeacher?: Maybe<SystemUser>;
  previousTeacherId?: Maybe<Scalars['ObjectId']>;
  previousVenue?: Maybe<Venue>;
  previousVenueId?: Maybe<Scalars['ObjectId']>;
  teacher?: Maybe<SystemUser>;
  teacherId?: Maybe<Scalars['ObjectId']>;
  type: ClassChangeLogType;
  venue?: Maybe<Venue>;
  venueId?: Maybe<Scalars['ObjectId']>;
};

export enum ClassChangeLogType {
  ClassDate = 'ClassDate',
  ClassLearningArrangement = 'ClassLearningArrangement',
  ClassTeacher = 'ClassTeacher',
  ClassVenue = 'ClassVenue',
  ClassZoomDetails = 'ClassZoomDetails',
  LessonTeacher = 'LessonTeacher',
  LessonVenue = 'LessonVenue'
}

export type ClassCourseIndex = {
  __typename?: 'ClassCourseIndex';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  nameShort: Scalars['String'];
};

export type ClassEligibility = {
  __typename?: 'ClassEligibility';
  lessonDates?: Maybe<Array<Scalars['DateTime']>>;
  stage: RegistrationStage;
};

export type ClassEnrollmentOverview = {
  __typename?: 'ClassEnrollmentOverview';
  capacity?: Maybe<ClassCapacity>;
  classId: Scalars['ObjectId'];
  serializedData: Scalars['String'];
};

export type ClassFilterInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  academicYearIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  academicYears?: InputMaybe<Array<Scalars['Int']>>;
  classCodes?: InputMaybe<Array<Scalars['String']>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  dateRange?: InputMaybe<DateTimeRangeInput>;
  isAdHoc?: InputMaybe<Scalars['Boolean']>;
  isFull?: InputMaybe<Scalars['Boolean']>;
  isReplacementLesson?: InputMaybe<Scalars['Boolean']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  isWaitlisted?: InputMaybe<Scalars['Boolean']>;
  learningArrangements?: InputMaybe<Array<LearningArrangementType>>;
  lessonDays?: InputMaybe<Array<WeekDay>>;
  /** Filters classes with applied lesson plans. */
  lessonPlanIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<ClassStatus>>;
  teacherIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  types?: InputMaybe<Array<ClassType>>;
  venueIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type ClassIndex = {
  __typename?: 'ClassIndex';
  _id: Scalars['ObjectId'];
  academicLevels?: Maybe<Array<ClassAcademicLevelIndex>>;
  academicYear?: Maybe<ClassAcademicYearIndex>;
  academicYearId: Scalars['ObjectId'];
  branch?: Maybe<ClassBranchIndex>;
  capacity?: Maybe<ClassCapacity>;
  classCode?: Maybe<Scalars['String']>;
  course?: Maybe<ClassCourseIndex>;
  courseId: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  defaultLessonDays?: Maybe<Array<WeekDay>>;
  defaultLessonEnd?: Maybe<Scalars['DateTime']>;
  defaultLessonStart?: Maybe<Scalars['DateTime']>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  digitalClassAccessCredentials?: Maybe<DigitalClassAccessCredentialsDo>;
  isAdHoc?: Maybe<Scalars['Boolean']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedFullName?: Maybe<Scalars['String']>;
  learningArrangement: LearningArrangementType;
  maximumClassCapacity?: Maybe<Scalars['Int']>;
  maximumMakeUpCapacity?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  regularStudents?: Maybe<Scalars['Int']>;
  reservedStudents?: Maybe<Scalars['Int']>;
  shorthand?: Maybe<Scalars['String']>;
  status?: Maybe<ClassStatus>;
  teacher?: Maybe<ClassTeacherIndex>;
  transferredStudents?: Maybe<Scalars['Int']>;
  trialStudents?: Maybe<Scalars['Int']>;
  type?: Maybe<ClassType>;
  venue?: Maybe<ClassVenueIndex>;
  withdrawnStudents?: Maybe<Scalars['Int']>;
};

export type ClassIndexPage = {
  __typename?: 'ClassIndexPage';
  items: Array<ClassIndex>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/** The lesson plan applied to the class. */
export type ClassLessonPlan = {
  __typename?: 'ClassLessonPlan';
  /** The timestamp when the lesson plan was applied. */
  appliedDate: Scalars['DateTime'];
  /** Reference to the lesson plan applied. */
  id: Scalars['ObjectId'];
  /** The name of the lesson plan applied. */
  name: Scalars['String'];
};

export type ClassPage = {
  __typename?: 'ClassPage';
  items: Array<Class>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type ClassSeat = {
  __typename?: 'ClassSeat';
  checkFirstLesson?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['DateTime']>;
  hasPaid?: Maybe<Scalars['Boolean']>;
  isSimulation?: Maybe<Scalars['Boolean']>;
  seatType?: Maybe<SeatType>;
  status?: Maybe<EnrollmentStatus>;
  student?: Maybe<Student>;
  type?: Maybe<ClassCapacitySeatType>;
};

export enum ClassStatus {
  Completed = 'Completed',
  Created = 'Created',
  Incomplete = 'Incomplete',
  Ongoing = 'Ongoing',
  Void = 'Void'
}

/** Object defining submission statistics by Class */
export type ClassSubmissionStats = {
  __typename?: 'ClassSubmissionStats';
  /** Object ID of Class */
  _id: Scalars['ObjectId'];
  /** Abbreviated Class name */
  abbreviation: Scalars['String'];
  /** Class name */
  className: Scalars['String'];
  /** Score statistics object containing min, max, and avg scores of the Class */
  score: ScoreStats;
};

/** Input parameters in getting score statistics by class */
export type ClassSubmissionStatsInput = {
  /** Filter class score statistics by Academic Level ID */
  academicLevelId?: InputMaybe<Scalars['ObjectId']>;
  /** Filter class score statistics by Academic Year ID */
  academicYearId?: InputMaybe<Scalars['ObjectId']>;
  /** Only filter the class score statistisc of these teachers */
  teacherIds?: InputMaybe<Scalars['ObjectId']>;
  /** Filter class score statistics by Worksheet ID */
  worksheetId?: InputMaybe<Scalars['ObjectId']>;
};

export type ClassTeacherIndex = {
  __typename?: 'ClassTeacherIndex';
  _id: Scalars['ObjectId'];
  avatarUrl?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
};

export enum ClassType {
  Recurring = 'Recurring',
  Workshop = 'Workshop'
}

export type ClassVenueIndex = {
  __typename?: 'ClassVenueIndex';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
};

/** The collect object that lists the description of a collect item in the LessonFlowItem. */
export type Collect = {
  __typename?: 'Collect';
  /** The ID of the Collect. */
  _id: Scalars['ObjectId'];
  /**
   * The description of the collect formatted in markdown.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  description: Scalars['String'];
};

export type CompleteMailMergeInput = {
  id: Scalars['ObjectId'];
  senderId: Scalars['ObjectId'];
};

export type ConnectXeroAccountForCourseInput = {
  courseId: Scalars['ObjectId'];
  liabilityAccount: AccountInput;
  revenueAccount: AccountInput;
};

export type ConnectXeroAccountInput = {
  code: Scalars['String'];
  id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type Consent = {
  __typename?: 'Consent';
  PhotographyOrVideography?: Maybe<Scalars['Boolean']>;
  accountCondition?: Maybe<Scalars['Boolean']>;
  statements?: Maybe<Scalars['Boolean']>;
  termCondition?: Maybe<Scalars['Boolean']>;
};

export type ConsentInput = {
  PhotographyOrVideography?: InputMaybe<Scalars['Boolean']>;
  accountCondition?: InputMaybe<Scalars['Boolean']>;
  statements?: InputMaybe<Scalars['Boolean']>;
  termCondition?: InputMaybe<Scalars['Boolean']>;
};

export type ConsentOutput = {
  __typename?: 'ConsentOutput';
  PhotographyOrVideography?: Maybe<Scalars['Boolean']>;
  accountCondition?: Maybe<Scalars['Boolean']>;
  statements?: Maybe<Scalars['Boolean']>;
  termCondition?: Maybe<Scalars['Boolean']>;
};

export type Content = {
  __typename?: 'Content';
  academicWeek?: Maybe<AcademicWeek>;
  gradable?: Maybe<GradableUnion>;
  submissions: Array<Submission>;
};

export type CopyTaskListInput = {
  endAt?: InputMaybe<Scalars['DateTime']>;
  generateAt?: InputMaybe<Scalars['DateTime']>;
  isAutoGenerate: Scalars['Boolean'];
  listId: Scalars['ObjectId'];
  name: Scalars['String'];
  startAt?: InputMaybe<Scalars['DateTime']>;
  weekDay?: InputMaybe<WeekDay>;
};

export type CountryCode = {
  __typename?: 'CountryCode';
  _id: Scalars['ObjectId'];
  areaCode: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Int']>;
};

export type CourierPickupAddress = {
  __typename?: 'CourierPickupAddress';
  _id: Scalars['ObjectId'];
  address: Scalars['String'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  postalCode: Scalars['String'];
};

export type Course = {
  __typename?: 'Course';
  _id: Scalars['ObjectId'];
  academicLevels: Array<AcademicLevel>;
  academicStreams?: Maybe<Array<Scalars['String']>>;
  avatarUrl?: Maybe<Scalars['String']>;
  classes?: Maybe<Array<Class>>;
  colorCode?: Maybe<Scalars['String']>;
  courseFee?: Maybe<CourseFee>;
  courseMaterial?: Maybe<Scalars['String']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['Float']>;
  enrollments: Array<Enrollment>;
  gamePlanLink?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isRequireFeedback?: Maybe<Scalars['Boolean']>;
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  learningResourceUrl?: Maybe<Scalars['String']>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  lessonFlow?: Maybe<LessonFlow>;
  liabilityAccount?: Maybe<Account>;
  makeUpCredits: MakeUpCredits;
  name: Scalars['String'];
  nameShort: Scalars['String'];
  nextRunNumber?: Maybe<Scalars['Int']>;
  numberOfLessons?: Maybe<Scalars['Int']>;
  onGoingClassNumber: Scalars['Int'];
  revenueAccount?: Maybe<Account>;
  status?: Maybe<CourseStatus>;
  subject?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  /** List of topics for the course */
  topics?: Maybe<Array<Topic>>;
  type: CourseType;
  voidRemarks?: Maybe<Scalars['String']>;
  xeroAccountsSyncStatus?: Maybe<Scalars['Boolean']>;
};


export type CourseClassesArgs = {
  academicYearId?: InputMaybe<Scalars['ObjectId']>;
  status?: InputMaybe<Array<ClassStatus>>;
};

export type CourseAcademicLevelIndex = {
  __typename?: 'CourseAcademicLevelIndex';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type CourseFee = {
  __typename?: 'CourseFee';
  billingFrequency?: Maybe<BillingFrequency>;
  isApplicable: Scalars['Boolean'];
  otherFees?: Maybe<Array<Fee>>;
  standardCourseFees: Array<StandardCourseFeeDo>;
};

export type CourseFeeInput = {
  billingFrequency: BillingFrequency;
  isApplicable: Scalars['Boolean'];
  otherFees?: InputMaybe<Array<OtherFeeInput>>;
  standardCourseFees: Array<StandardCourseFeeInput>;
};

export type CourseFeeOutput = {
  __typename?: 'CourseFeeOutput';
  billingFrequency: BillingFrequency;
  isApplicable: Scalars['Boolean'];
  otherFees?: Maybe<Array<OtherFeeOutput>>;
  standardCourseFees: Array<StandardCourseFee>;
};

export enum CourseFeeType {
  Perclass = 'Perclass',
  Perlesson = 'Perlesson',
  Permonth = 'Permonth',
  Perterm = 'Perterm'
}

export type CourseFilterInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  academicStreams?: InputMaybe<Array<Scalars['String']>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  dateRange?: InputMaybe<DateTimeRangeInput>;
  eligibleStudentId?: InputMaybe<Scalars['ObjectId']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<CourseStatus>>;
  teacherId?: InputMaybe<Scalars['ObjectId']>;
  type?: InputMaybe<Array<CourseType>>;
};

export type CourseIndex = {
  __typename?: 'CourseIndex';
  _id: Scalars['ObjectId'];
  academicLevels?: Maybe<Array<CourseAcademicLevelIndex>>;
  academicStreams?: Maybe<Array<Scalars['String']>>;
  additionalLessonFee?: Maybe<Scalars['Decimal']>;
  avatarUrl?: Maybe<Scalars['String']>;
  colorCode?: Maybe<Scalars['String']>;
  courseCompleted?: Maybe<Scalars['Int']>;
  courseFee?: Maybe<CourseFee>;
  courseFeeWithSort?: Maybe<Scalars['Decimal']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  depositFee?: Maybe<Scalars['Decimal']>;
  description?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['Float']>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedFullName?: Maybe<Scalars['String']>;
  learningResourceUrl?: Maybe<Scalars['String']>;
  makeUpCredits: MakeUpCredits;
  materialsFee?: Maybe<Scalars['Decimal']>;
  name: Scalars['String'];
  nameShort: Scalars['String'];
  numberOfLessons?: Maybe<Scalars['Int']>;
  ongoingClasses?: Maybe<Scalars['Int']>;
  registrationFee?: Maybe<Scalars['Decimal']>;
  registrations?: Maybe<Scalars['Int']>;
  reservations?: Maybe<Scalars['Int']>;
  status?: Maybe<CourseStatus>;
  subject?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  totalRevenue?: Maybe<Scalars['Float']>;
  trialFee?: Maybe<Scalars['Decimal']>;
  trials?: Maybe<Scalars['Int']>;
  type: CourseType;
  voidRemarks?: Maybe<Scalars['String']>;
  withdrawals?: Maybe<Scalars['Int']>;
  withdrawing?: Maybe<Scalars['Int']>;
};


export type CourseIndexTotalRevenueArgs = {
  dateRange: DateTimeRangeInput;
};

export type CourseIndexPage = {
  __typename?: 'CourseIndexPage';
  items: Array<CourseIndex>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type CourseNotification = {
  __typename?: 'CourseNotification';
  _id: Scalars['ObjectId'];
  academicYear?: Maybe<AcademicYear>;
  academicYearId: Scalars['ObjectId'];
  course?: Maybe<Course>;
  courseId: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  enrollments?: Maybe<Array<Enrollment>>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  newClasses?: Maybe<Array<Class>>;
  student?: Maybe<Student>;
  studentId: Scalars['ObjectId'];
};

export type CourseNotificationFilterInput = {
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type CourseNotificationPage = {
  __typename?: 'CourseNotificationPage';
  items: Array<CourseNotification>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type CoursePage = {
  __typename?: 'CoursePage';
  items: Array<Course>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CourseStatus {
  Completed = 'Completed',
  Created = 'Created',
  Ongoing = 'Ongoing',
  Terminated = 'Terminated',
  Void = 'Void'
}

export enum CourseType {
  Regular = 'Regular',
  Workshop = 'Workshop'
}

export type CreateAcademicLevelInput = {
  alias: Scalars['String'];
  educationStage: EducationStage;
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateAcademicYearInput = {
  academicWeekEnd: WeekDay;
  academicWeekStart: WeekDay;
  period: DateTimeRangeInput;
  taxRate: Scalars['Int'];
  termBillingDueDate: TermBillingDueDateInput;
  terms: Array<TermInput>;
  year: Scalars['Int'];
};

export type CreateAdHocInvoiceCommand = {
  billedForStudentId?: InputMaybe<Scalars['ObjectId']>;
  billedToParentId: Scalars['ObjectId'];
  classId?: InputMaybe<Scalars['ObjectId']>;
  lineItems: Array<CreateAdHocInvoiceItemCommand>;
};

export type CreateAdHocInvoiceItemCommand = {
  description: Scalars['String'];
  quantity: Scalars['Int'];
  remarks?: InputMaybe<Scalars['String']>;
  unitAmount: Scalars['Decimal'];
};

/** Create Ad Hoc Material Envelope Booklet Input */
export type CreateAdHocMaterialEnvelopeBookletInput = {
  bookletId: Scalars['ObjectId'];
  worksheetIds: Array<Scalars['ObjectId']>;
};

/** Create Ad Hoc Material Envelopes Input */
export type CreateAdHocMaterialEnvelopeInput = {
  booklets: Array<CreateAdHocMaterialEnvelopeBookletInput>;
  envelopeId: Scalars['ObjectId'];
};

/** Create Ad Hoc Material Input */
export type CreateAdHocMaterialInput = {
  adHocMaterials?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['ObjectId']>;
  envelope?: InputMaybe<CreateAdHocMaterialEnvelopeInput>;
  studentId: Scalars['ObjectId'];
};

export type CreateAdhocClassCommand = {
  academicWeekNumber: Scalars['Int'];
  academicYearId: Scalars['ObjectId'];
  classCode: Scalars['String'];
  courseId: Scalars['ObjectId'];
  /** Used to record the original CSV document index for import class creation records. */
  csvIndex?: InputMaybe<Scalars['Float']>;
  defaultLessonEnd: Scalars['DateTime'];
  defaultLessonStart: Scalars['DateTime'];
  digitalClassAccessCredentials?: InputMaybe<DigitalClassAccessCredentialsInput>;
  firstLessonDate: Scalars['DateTime'];
  isSkipScheduledHoliday?: InputMaybe<Scalars['Boolean']>;
  learningArrangement: LearningArrangementType;
  maximumClassCapacity?: InputMaybe<Scalars['Int']>;
  maximumMakeUpCapacity?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  shorthand: Scalars['String'];
  teacherId?: InputMaybe<Scalars['ObjectId']>;
  venueId?: InputMaybe<Scalars['ObjectId']>;
};

export type CreateAdminInput = {
  alias: Scalars['String'];
  alternateUserId?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  branchIds: Array<Scalars['ObjectId']>;
  departmentId: Scalars['ObjectId'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender: Scalars['String'];
  initials: Scalars['String'];
  mobile?: InputMaybe<TelephoneInput>;
  officePhone?: InputMaybe<TelephoneInput>;
  salutation?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userRole?: InputMaybe<UserRole>;
};

export type CreateAdminOutput = {
  __typename?: 'CreateAdminOutput';
  alias: Scalars['String'];
  alternateUserId?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  branchIds: Array<Scalars['ObjectId']>;
  departmentId: Scalars['ObjectId'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender: Scalars['String'];
  initials: Scalars['String'];
  mobile?: Maybe<TelephoneOutput>;
  officePhone?: Maybe<TelephoneOutput>;
  salutation?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRole?: Maybe<UserRole>;
};

export type CreateArrangementInput = {
  fromClassId: Scalars['ObjectId'];
  fromLessonId: Scalars['ObjectId'];
  group?: InputMaybe<EnrollmentGroupType>;
  isAdhocAdditionalLesson?: InputMaybe<Scalars['Boolean']>;
  preferredLearningArrangement?: InputMaybe<LearningArrangementType>;
  remarks?: InputMaybe<Scalars['String']>;
  studentId: Scalars['ObjectId'];
  toClassId?: InputMaybe<Scalars['ObjectId']>;
  toLessonId?: InputMaybe<Scalars['ObjectId']>;
  type: ArrangementType;
  useCredits?: InputMaybe<Scalars['Boolean']>;
  withdrawReasons?: InputMaybe<WithdrawReasonsInput>;
};

export type CreateBranchInput = {
  address: AddressInput;
  alias: Scalars['String'];
  centreId: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type CreateCampaignInput = {
  emailSubject?: InputMaybe<Scalars['String']>;
  isIncludeAttachment: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  recipientTypes?: InputMaybe<Array<CampaignRecipientType>>;
  recipients?: InputMaybe<Array<CampaignRecipientInput>>;
  scheduleSendAt?: InputMaybe<Scalars['DateTime']>;
  sendMethod: ReminderType;
  status: CampaignStatus;
  templateId?: InputMaybe<Scalars['ObjectId']>;
  usageType: TemplateUsageType;
};

export type CreateClassOutput = {
  __typename?: 'CreateClassOutput';
  academicWeekNumber?: Maybe<Scalars['Int']>;
  academicYearId: Scalars['ObjectId'];
  classCode: Scalars['String'];
  classFee?: Maybe<StandardCourseFee>;
  courseId: Scalars['ObjectId'];
  dailySessionTimeSlots?: Maybe<Array<TimeRangeOutput>>;
  defaultLessonDays?: Maybe<Array<WeekDay>>;
  defaultLessonEnd?: Maybe<Scalars['DateTime']>;
  defaultLessonStart?: Maybe<Scalars['DateTime']>;
  digitalClassAccessCredentials?: Maybe<DigitalClassAccessCredentialsOutput>;
  firstLessonDate?: Maybe<Scalars['DateTime']>;
  isAdHoc?: Maybe<Scalars['Boolean']>;
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>;
  lastLessonDate?: Maybe<Scalars['DateTime']>;
  learningArrangement: LearningArrangementType;
  maximumClassCapacity?: Maybe<Scalars['Int']>;
  maximumMakeUpCapacity?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  numberOfLessons?: Maybe<Scalars['Int']>;
  repeat?: Maybe<Scalars['Int']>;
  runNumber?: Maybe<Scalars['Int']>;
  shorthand?: Maybe<Scalars['String']>;
  teacherId?: Maybe<Scalars['ObjectId']>;
  venueId?: Maybe<Scalars['ObjectId']>;
};

/** Input used to Create collect details of a LessonFlowItem. */
export type CreateCollectInput = {
  /** The text of the collect generated from a rich text editor. */
  description: Scalars['String'];
  /** The ID of the LessonFlowItem to insert the collect to. */
  lessonFlowItemId: Scalars['ObjectId'];
};

export type CreateCourierPickupAddressInput = {
  address: Scalars['String'];
  postalCode: Scalars['String'];
};

export type CreateCourseNotificationInput = {
  academicYearId: Scalars['ObjectId'];
  courseId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};

export type CreateCourseOutput = {
  __typename?: 'CreateCourseOutput';
  academicLevelIds: Array<Scalars['ObjectId']>;
  academicStreams: Array<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  courseFee: CourseFeeOutput;
  description?: Maybe<Scalars['String']>;
  disclaimer: Array<Scalars['String']>;
  duration: Scalars['Float'];
  isRequireFeedback?: Maybe<Scalars['Boolean']>;
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>;
  learningResourceUrl?: Maybe<Scalars['String']>;
  makeUpCredits: MakeUpCreditOutput;
  name: Scalars['String'];
  nameShort: Scalars['String'];
  numberOfLessons?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  termsAndConditions: Scalars['String'];
  type: CourseType;
};

export type CreateDeliveryInput = {
  address: Scalars['String'];
  dropOffAddress: Scalars['String'];
  dropOffMobile: TelephoneInput;
  dropOffPostalCode: Scalars['String'];
  dropOffRemarks?: InputMaybe<Scalars['String']>;
  materialIds: Array<Scalars['ObjectId']>;
  parcelSize: DeliveryParcelSize;
  pickupEndAt: Scalars['DateTime'];
  pickupStartAt: Scalars['DateTime'];
  remarks?: InputMaybe<Scalars['String']>;
  status: DeliveryStatus;
  trackingNo?: InputMaybe<Scalars['String']>;
  type: DeliveryType;
};

export type CreateDepartmentInput = {
  centreId: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type CreateDiscountInput = {
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountName: Scalars['String'];
  discountRate?: InputMaybe<Scalars['Decimal']>;
  discountType: DiscountType;
};

export type CreateEligibilityFlowInput = {
  courseId: Scalars['ObjectId'];
  startNode: FlowNodeInput;
};

export type CreateExerciseInput = {
  content: Scalars['String'];
  duration?: InputMaybe<Scalars['Int']>;
  lessonNumber: Scalars['Int'];
  needSubmit?: InputMaybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  totalBreakdown?: InputMaybe<MarkBreakDownInput>;
  totalGrade?: InputMaybe<Scalars['Int']>;
  workSheetId: Scalars['ObjectId'];
};

export type CreateFeedbackInput = {
  lessonFlowId?: InputMaybe<Scalars['ObjectId']>;
  lessonFlowItemId?: InputMaybe<Scalars['ObjectId']>;
  quoted: Scalars['String'];
  text: Scalars['String'];
  type: FeedbackType;
};

/** Input in creating a single Feedback Seen Receipt */
export type CreateFeedbackSeenReceiptInput = {
  /** Linked feedback ID from `feedback` collection */
  feedbackId: Scalars['ObjectId'];
  /** Linked Lesson Flow ID */
  lessonFlowId?: InputMaybe<Scalars['ObjectId']>;
  /** Linked Lesson Flow Item ID */
  lessonFlowItemId?: InputMaybe<Scalars['ObjectId']>;
  /** Feedback type (e.g. NOTE or ERROR) */
  type: FeedbackType;
};

/** Input in creating multiple Feedback Seen Receipts */
export type CreateFeedbackSeenReceiptInputs = {
  /** Array of Feedback seen receipt records */
  items: Array<CreateFeedbackSeenReceiptInput>;
};

export type CreateGradingTemplateInput = {
  name: Scalars['String'];
  sections: Array<GradingTemplateSectionInput>;
};

export type CreateGroupAssignmentInput = {
  academicYearId: Scalars['ObjectId'];
  items?: InputMaybe<Array<GroupAssignmentItemInput>>;
};

/** Arguments to be used when creating a new handout. */
export type CreateHandoutInput = {
  /** The Academic Week this handout is for. */
  academicWeek: LessonFlowAcademicWeekInput;
  /** Used to determine where the Handout is intended to be sent. */
  channel: HandoutChannel;
  /** The ID of the course this handout is for. */
  courseId: Scalars['ObjectId'];
  /** The enrollment type this handout is for. */
  enrollmentType: EnrollmentType;
  /**  A file to be attached to the course. This is used for handouts that are of type `HandoutType.Answers` */
  file?: InputMaybe<HandoutFileInput>;
  /** The instructions for the handout. This is formatted in Markdown. */
  instructions?: InputMaybe<Scalars['String']>;
  /**  This determines who the handout is intended for. */
  type: HandoutType;
  /** The id of the worksheet linked to this handout. */
  worksheetId?: InputMaybe<Scalars['ObjectId']>;
};

export type CreateHolidayInput = {
  name: Scalars['String'];
  period: DateRangeInput;
  type: HolidayType;
};

export type CreateHomeworkInput = {
  content: Scalars['String'];
  /** The duration of the `homework` in minutes. */
  duration?: InputMaybe<Scalars['Int']>;
  lessonNumber: Scalars['Int'];
  needSubmit?: InputMaybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  totalBreakdown?: InputMaybe<MarkBreakDownInput>;
  totalGrade?: InputMaybe<Scalars['Int']>;
  workSheetId?: InputMaybe<Scalars['ObjectId']>;
};

export type CreateInvoiceItemOptionInput = {
  description: Scalars['String'];
  unitAmount: Scalars['Decimal'];
};

export type CreateLessonFlowInput = {
  academicWeek?: InputMaybe<LessonFlowAcademicWeekInput>;
  academicYearId: Scalars['ObjectId'];
  /** List of Course IDs to apply the LessonFlow to. */
  applyToCourses?: InputMaybe<Array<Scalars['ObjectId']>>;
  courseId?: InputMaybe<Scalars['ObjectId']>;
  lessonFlowItemIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  name: Scalars['String'];
};

export type CreateLessonFlowItemInput = {
  academicWeek: LessonFlowAcademicWeekInput;
  lessonFlowId: Scalars['ObjectId'];
  lessonNumber: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type CreateLessonInput = {
  academicWeekNumber?: InputMaybe<Scalars['Int']>;
  classId: Scalars['ObjectId'];
  lessonEnd: Scalars['DateTime'];
  lessonNotes?: InputMaybe<Scalars['String']>;
  lessonStart: Scalars['DateTime'];
  teacherId?: InputMaybe<Scalars['ObjectId']>;
  venueId?: InputMaybe<Scalars['ObjectId']>;
};

/** Input used to Create lesson sequence of a LessonFlowItem. */
export type CreateLessonSequenceInput = {
  /** Optional description of the lesson sequence item */
  description?: InputMaybe<Scalars['String']>;
  /** The duration (in minutes) of the lesson sequence item */
  duration: Scalars['Float'];
  /** The linked exercise ID of the lesson sequence item */
  exerciseId?: InputMaybe<Scalars['String']>;
  /** The ID of the LessonFlowItem to insert the lesson sequence to. */
  lessonFlowItemId: Scalars['ObjectId'];
  /** The zero-based index order of the lesson sequence item in the list */
  order?: InputMaybe<Scalars['Float']>;
};

export type CreateLogInput = {
  attachment?: InputMaybe<Scalars['String']>;
  log: Scalars['String'];
  type: LogType;
  userId: Scalars['ObjectId'];
};

export type CreateMailMergeInput = {
  templateId: Scalars['ObjectId'];
};

export type CreateMerchandiseInput = {
  dateIssued?: InputMaybe<Scalars['DateTime']>;
  distributionChannel?: InputMaybe<Scalars['String']>;
  item: Scalars['String'];
  remarks?: InputMaybe<Scalars['String']>;
  status: MerchandiseStatus;
  studentId: Scalars['ObjectId'];
};

export type CreateNoteInput = {
  note: Scalars['String'];
  userId: Scalars['ObjectId'];
};

export type CreateParentInput = {
  address?: InputMaybe<UserAddressInput>;
  alias: Scalars['String'];
  alternateUserId?: InputMaybe<Scalars['String']>;
  alternativeEmails?: InputMaybe<Array<Scalars['String']>>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender: Scalars['String'];
  homePhone?: InputMaybe<TelephoneInput>;
  linkedWhatsappAccountId: Scalars['ObjectId'];
  mobile: TelephoneInput;
  officePhone?: InputMaybe<TelephoneInput>;
  remarks?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  whatsAppName?: InputMaybe<Scalars['String']>;
};

export type CreateParentOutput = {
  __typename?: 'CreateParentOutput';
  address?: Maybe<UserAddressOutput>;
  alias: Scalars['String'];
  alternateUserId?: Maybe<Scalars['String']>;
  alternativeEmails?: Maybe<Array<Scalars['String']>>;
  avatarUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender: Scalars['String'];
  homePhone?: Maybe<TelephoneOutput>;
  linkedWhatsappAccountId: Scalars['ObjectId'];
  mobile: TelephoneOutput;
  officePhone?: Maybe<TelephoneOutput>;
  remarks?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  whatsAppName?: Maybe<Scalars['String']>;
};

export type CreatePaymentRecordInput = {
  amount: Scalars['Decimal'];
  bankName?: InputMaybe<Scalars['String']>;
  chequeNumber?: InputMaybe<Scalars['String']>;
  depositDate?: InputMaybe<Scalars['DateTime']>;
  invoiceIds: Array<Scalars['ObjectId']>;
  isUseRemainingCredit?: InputMaybe<Scalars['Boolean']>;
  lastDigits?: InputMaybe<Scalars['String']>;
  method: PaymentMode;
  /** The date when the payment was made */
  paymentDate?: InputMaybe<Scalars['DateTime']>;
  paymentProcessor?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
};

export type CreatePromotionInput = {
  academicLevelIds: Array<Scalars['ObjectId']>;
  promoteToLevelId: Scalars['ObjectId'];
  scheduledDate: Scalars['DateTime'];
  selectedStudentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type CreateReferenceDataInput = {
  category: ReferenceDataCategory;
  value: Scalars['String'];
};

export type CreateRefundInput = {
  adminFee?: InputMaybe<Scalars['Decimal']>;
  amountRefunded: Scalars['Decimal'];
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  chequeNumber?: InputMaybe<Scalars['String']>;
  lastDigits?: InputMaybe<Scalars['String']>;
  parentId: Scalars['ObjectId'];
  payNowNumber?: InputMaybe<Scalars['String']>;
  paymentProcessor?: InputMaybe<Scalars['String']>;
  processedDate?: InputMaybe<Scalars['DateTime']>;
  reason?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  refundMethod: PaymentMode;
  refundedDate: Scalars['DateTime'];
};

export type CreateRegistrationInput = {
  classId: Scalars['ObjectId'];
  currentStep: Scalars['Int'];
  dueDate?: InputMaybe<Scalars['DateTime']>;
  group?: InputMaybe<EnrollmentGroupType>;
  linkRegistrationId?: InputMaybe<Scalars['ObjectId']>;
  preferredLearningArrangement?: InputMaybe<LearningArrangementType>;
  remarks?: InputMaybe<Scalars['String']>;
  selectedLessons?: InputMaybe<Array<SelectedLessonInput>>;
  stage: RegistrationStage;
  startDate: Scalars['DateTime'];
  studentId: Scalars['ObjectId'];
};

export type CreateRegularClassCommand = {
  academicYearId: Scalars['ObjectId'];
  classCode: Scalars['String'];
  classFee: StandardCourseFeeInput;
  courseId: Scalars['ObjectId'];
  /** Used to record the original CSV document index for import class creation records. */
  csvIndex?: InputMaybe<Scalars['Float']>;
  defaultLessonDays: Array<WeekDay>;
  defaultLessonEnd: Scalars['DateTime'];
  defaultLessonStart: Scalars['DateTime'];
  digitalClassAccessCredentials?: InputMaybe<DigitalClassAccessCredentialsInput>;
  firstLessonDate: Scalars['DateTime'];
  isReplacementLesson?: InputMaybe<Scalars['Boolean']>;
  isSkipScheduledHoliday?: InputMaybe<Scalars['Boolean']>;
  lastLessonDate: Scalars['DateTime'];
  learningArrangement: LearningArrangementType;
  maximumClassCapacity?: InputMaybe<Scalars['Int']>;
  maximumMakeUpCapacity?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  shorthand: Scalars['String'];
  teacherId?: InputMaybe<Scalars['ObjectId']>;
  venueId?: InputMaybe<Scalars['ObjectId']>;
};

export type CreateRegularCourseInput = {
  academicLevelIds: Array<Scalars['ObjectId']>;
  academicStreams?: InputMaybe<Array<Scalars['String']>>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  colorCode?: InputMaybe<Scalars['String']>;
  courseFee: CourseFeeInput;
  courseMaterial?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disclaimer: Array<Scalars['String']>;
  duration: Scalars['Float'];
  gamePlanLink?: InputMaybe<Scalars['String']>;
  isRequireFeedback?: InputMaybe<Scalars['Boolean']>;
  isSkipScheduledHoliday?: InputMaybe<Scalars['Boolean']>;
  learningResourceUrl?: InputMaybe<Scalars['String']>;
  makeUpCredits: RegularMakeUpCreditInput;
  name: Scalars['String'];
  nameShort: Scalars['String'];
  subject?: InputMaybe<Scalars['String']>;
  termsAndConditions: Scalars['String'];
};

export type CreateRemarkInput = {
  /** A string that contains a remark in a formatted string that will be formatted further in the front-end. */
  content: Scalars['String'];
  /** A boolean that signifies the pinned status of the remark. */
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** The level type of the remark */
  levelType?: InputMaybe<RemarkLevelType>;
  /** The ID of a remark this remark is created as a reply for. */
  parentId?: InputMaybe<Scalars['ObjectId']>;
  /** The `ObjectId` of the `Entity` to bind this remark to. For example, if this remark is for an attendance, use the id of the attendance record. */
  subjectId: Scalars['ObjectId'];
  /** The type of the `Entity` defined from an enum to bind this remark to. For example, if this remark is for an attendance, use the enum value `RemarkType.Attendance` */
  type: RemarkType;
};

export type CreateSchoolInput = {
  alias?: InputMaybe<Scalars['String']>;
  educationStage: EducationStage;
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateSubmissionInput = {
  breakdown?: InputMaybe<MarkBreakDownInput>;
  classId: Scalars['ObjectId'];
  enrollmentId: Scalars['ObjectId'];
  gradableId: Scalars['ObjectId'];
  grade?: InputMaybe<Scalars['Int']>;
};

export type CreateSystemUserCommand = {
  alias?: InputMaybe<Scalars['String']>;
  alternateUserId?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  branchIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  departmentId?: InputMaybe<Scalars['ObjectId']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
  linkedWhatsappNumber?: InputMaybe<TelephoneInput>;
  mobile?: InputMaybe<TelephoneInput>;
  officePhone?: InputMaybe<TelephoneInput>;
  roles?: InputMaybe<Array<UserRole>>;
  salutation?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateTaskChecklistInput = {
  assigneeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  items?: InputMaybe<Array<CreateTaskChecklistItemInput>>;
  name: Scalars['String'];
  taskId?: InputMaybe<Scalars['ObjectId']>;
};

export type CreateTaskChecklistItemInput = {
  assigneeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  checklistId?: InputMaybe<Scalars['ObjectId']>;
  name: Scalars['String'];
};

export type CreateTaskInput = {
  assigneeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  attendanceId?: InputMaybe<Scalars['ObjectId']>;
  checklists?: InputMaybe<Array<CreateTaskChecklistInput>>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  listId?: InputMaybe<Scalars['ObjectId']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateTaskListInput = {
  endAt?: InputMaybe<Scalars['DateTime']>;
  generateAt?: InputMaybe<Scalars['DateTime']>;
  isAutoGenerate: Scalars['Boolean'];
  name: Scalars['String'];
  startAt?: InputMaybe<Scalars['DateTime']>;
  weekDay?: InputMaybe<WeekDay>;
};

export type CreateTeacherInput = {
  alias: Scalars['String'];
  alternateUserId?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  branchIds: Array<Scalars['ObjectId']>;
  departmentId: Scalars['ObjectId'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender: Scalars['String'];
  initials: Scalars['String'];
  linkedWhatsappNumber?: InputMaybe<TelephoneInput>;
  mobile?: InputMaybe<TelephoneInput>;
  officePhone?: InputMaybe<TelephoneInput>;
  salutation?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userRole?: InputMaybe<UserRole>;
};

export type CreateTeacherOutput = {
  __typename?: 'CreateTeacherOutput';
  alias: Scalars['String'];
  alternateUserId?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  branchIds: Array<Scalars['ObjectId']>;
  departmentId: Scalars['ObjectId'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender: Scalars['String'];
  initials: Scalars['String'];
  linkedWhatsappNumber?: Maybe<TelephoneOutput>;
  mobile?: Maybe<TelephoneOutput>;
  officePhone?: Maybe<TelephoneOutput>;
  salutation?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRole?: Maybe<UserRole>;
};

export type CreateTemplateInput = {
  content?: InputMaybe<Scalars['String']>;
  emailMessage?: InputMaybe<Scalars['String']>;
  status: TemplateStatus;
  subject?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type: TemplateType;
  usageType: TemplateUsageType;
  whatsAppMessage?: InputMaybe<Scalars['String']>;
};

export type CreateTopicInput = {
  courseId: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type CreateUserCustomiseColumnInput = {
  columnName: Scalars['String'];
  columnShowName?: InputMaybe<Scalars['String']>;
  isRequired: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  ownerId?: InputMaybe<Scalars['ObjectId']>;
  tableName: Scalars['String'];
  tableShowName?: InputMaybe<Scalars['String']>;
  weight: Scalars['Int'];
};

export type CreateVenueInput = {
  /** Address of the Venue */
  address: AddressInput;
  /** Id of the branch where the venue is located */
  branchId: Scalars['ObjectId'];
  /** Maximum number of students that a venue can hold */
  capacity?: InputMaybe<Scalars['Int']>;
  /** Name of the Venue, this is the combination of the venue unit and classroom number e.g 02-5A CR1 */
  name: Scalars['String'];
};

export type CreateWaitingListsForStudentInput = {
  classIds: Array<Scalars['ObjectId']>;
  startDate: Scalars['DateTime'];
  studentId: Scalars['ObjectId'];
};

export type CreateWhatsappAccountInput = {
  name: Scalars['String'];
  number: PhoneNumberInput;
};

export type CreateWorkShopClassCommand = {
  academicYearId: Scalars['ObjectId'];
  classCode: Scalars['String'];
  classFee: StandardCourseFeeInput;
  courseId: Scalars['ObjectId'];
  /** Used to record the original CSV document index for import class creation records. */
  csvIndex?: InputMaybe<Scalars['Float']>;
  dailySessionTimeSlots: Array<TimeRangeInput>;
  defaultLessonDays: Array<WeekDay>;
  digitalClassAccessCredentials?: InputMaybe<DigitalClassAccessCredentialsInput>;
  firstLessonDate: Scalars['DateTime'];
  isSkipScheduledHoliday?: InputMaybe<Scalars['Boolean']>;
  learningArrangement: LearningArrangementType;
  maximumClassCapacity?: InputMaybe<Scalars['Int']>;
  maximumMakeUpCapacity?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  numberOfLessons: Scalars['Int'];
  repeat: Scalars['Int'];
  runNumber: Scalars['Int'];
  shorthand: Scalars['String'];
  teacherId?: InputMaybe<Scalars['ObjectId']>;
  venueId?: InputMaybe<Scalars['ObjectId']>;
};

export type CreateWorkShopCourseInput = {
  academicLevelIds: Array<Scalars['ObjectId']>;
  academicStreams?: InputMaybe<Array<Scalars['String']>>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  colorCode?: InputMaybe<Scalars['String']>;
  courseFee: CourseFeeInput;
  courseMaterial?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disclaimer: Array<Scalars['String']>;
  duration: Scalars['Float'];
  gamePlanLink?: InputMaybe<Scalars['String']>;
  isRequireFeedback?: InputMaybe<Scalars['Boolean']>;
  isSkipScheduledHoliday?: InputMaybe<Scalars['Boolean']>;
  learningResourceUrl?: InputMaybe<Scalars['String']>;
  makeUpCredits: WorkshopMakeUpCreditInput;
  name: Scalars['String'];
  nameShort: Scalars['String'];
  numberOfLessons: Scalars['Int'];
  subject?: InputMaybe<Scalars['String']>;
  termsAndConditions: Scalars['String'];
};

export type Csv = {
  __typename?: 'Csv';
  url: Scalars['String'];
};

export type DateRange = {
  __typename?: 'DateRange';
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type DateRangeInput = {
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type DateTimeRange = {
  __typename?: 'DateTimeRange';
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type DateTimeRangeInput = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

/** Reward points input to deduct for onr student item */
export type DeductRewardPointsInput = {
  /** Linked gradable (Exercise or Homework) ID if points are from award */
  gradableId?: InputMaybe<Scalars['ObjectId']>;
  /** Reward order to redeem */
  orderDetail?: InputMaybe<RewardDetailInput>;
  /** Amount of reward points */
  points: Scalars['Float'];
  /** Other notes associated with the reward transaction */
  remarks: Scalars['String'];
  /** Student ID on where reward points will be added */
  studentId: Scalars['ObjectId'];
  /** Type of reward history */
  type?: InputMaybe<RewardHistoryType>;
};

/** Input used to Delete a collect item of a LessonFlowItem. */
export type DeleteCollectInput = {
  /** The ID of the collect to delete. */
  collectId: Scalars['ObjectId'];
  /** The ID of the LessonFlowItem containing the collect to find. */
  lessonFlowItemId: Scalars['ObjectId'];
};

/** Input used to Delete lesson sequence of a LessonFlowItem. */
export type DeleteLessonSequenceInput = {
  /** The ID of the `LessonFlowItem` to update. */
  lessonFlowItemId: Scalars['ObjectId'];
  /** The ID of the Lesson sequence ID to remove from the `LessonFlowItem` */
  sequenceId: Scalars['ObjectId'];
};

export type DeleteSubmissionInput = {
  submissionId: Scalars['ObjectId'];
};

export type DeleteTopicInput = {
  topicId: Scalars['ObjectId'];
};

export type DeleteUserCustomiseColumnInput = {
  columnName: Scalars['String'];
  id?: InputMaybe<Scalars['ObjectId']>;
  ownerId: Scalars['ObjectId'];
  tableName: Scalars['String'];
};

export type Delivery = {
  __typename?: 'Delivery';
  _id: Scalars['ObjectId'];
  address: Scalars['String'];
  admin?: Maybe<SystemUser>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  dropOffAddress: Scalars['String'];
  dropOffMobile: Telephone;
  dropOffPostalCode: Scalars['String'];
  dropOffRemarks?: Maybe<Scalars['String']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  materialIds: Array<Scalars['ObjectId']>;
  materials?: Maybe<Array<Material>>;
  parcelSize: DeliveryParcelSize;
  pickupEndAt: Scalars['DateTime'];
  pickupStartAt: Scalars['DateTime'];
  remarks?: Maybe<Scalars['String']>;
  status: DeliveryStatus;
  trackingNo?: Maybe<Scalars['String']>;
  trackingUrl?: Maybe<Scalars['String']>;
  type: DeliveryType;
  userId?: Maybe<Scalars['ObjectId']>;
  waybillUrl?: Maybe<Scalars['String']>;
};

export type DeliveryFilterInput = {
  dateRange?: InputMaybe<DateTimeRangeInput>;
  name?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<DeliveryStatus>>;
  types?: InputMaybe<Array<DeliveryType>>;
};

export type DeliveryIndex = {
  __typename?: 'DeliveryIndex';
  _id: Scalars['ObjectId'];
  address: Scalars['String'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  dropOffAddress: Scalars['String'];
  dropOffMobile?: Maybe<Telephone>;
  dropOffPostalCode: Scalars['String'];
  dropOffRemarks?: Maybe<Scalars['String']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifyUser?: Maybe<DeliveryUserIndex>;
  materialIds: Array<Scalars['ObjectId']>;
  materials?: Maybe<Array<DeliveryMaterialIndex>>;
  parcelSize: DeliveryParcelSize;
  pickupEndAt: Scalars['DateTime'];
  pickupStartAt: Scalars['DateTime'];
  remarks?: Maybe<Scalars['String']>;
  status: DeliveryStatus;
  students?: Maybe<Array<DeliveryStudentIndex>>;
  trackingNo?: Maybe<Scalars['String']>;
  trackingUrl?: Maybe<Scalars['String']>;
  type: DeliveryType;
  user?: Maybe<DeliveryUserIndex>;
  userId?: Maybe<Scalars['ObjectId']>;
};

export type DeliveryIndexPage = {
  __typename?: 'DeliveryIndexPage';
  items: Array<DeliveryIndex>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type DeliveryMaterialIndex = {
  __typename?: 'DeliveryMaterialIndex';
  _id: Scalars['ObjectId'];
  adHocMaterials?: Maybe<Scalars['String']>;
  /** PackingListEnvelope. */
  envelope?: Maybe<MaterialEnvelopeDo>;
  /** Material Trial Envelopes. */
  trialEnvelopes?: Maybe<Array<MaterialEnvelopeDo>>;
};

export enum DeliveryMethod {
  DeliveryByOtherCouriers = 'DeliveryByOtherCouriers',
  HandoverOnsite = 'HandoverOnsite'
}

export enum DeliveryParcelSize {
  Big = 'Big',
  Medium = 'Medium',
  Small = 'Small'
}

export enum DeliveryStatus {
  Completed = 'Completed',
  Delivered = 'Delivered',
  Delivering = 'Delivering',
  Draft = 'Draft',
  Dropped = 'Dropped',
  Exception = 'Exception',
  MissedDelivery = 'MissedDelivery',
  Pending = 'Pending',
  ProcessingHub = 'ProcessingHub'
}

export type DeliveryStudentIndex = {
  __typename?: 'DeliveryStudentIndex';
  _id: Scalars['ObjectId'];
  fullName: Scalars['String'];
};

export enum DeliveryType {
  ParkNParcel = 'ParkNParcel'
}

export type DeliveryUserIndex = {
  __typename?: 'DeliveryUserIndex';
  _id: Scalars['ObjectId'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  mobile?: Maybe<Telephone>;
};

export type Department = {
  __typename?: 'Department';
  _id: Scalars['ObjectId'];
  centre: Centre;
  centreId: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  systemId?: Maybe<Scalars['String']>;
};

export type DigitalClassAccessCredentials = {
  __typename?: 'DigitalClassAccessCredentials';
  autoGenerateGoogleMeetingDetails?: Maybe<Scalars['Boolean']>;
  autoGenerateZoomMeetingDetails?: Maybe<Scalars['Boolean']>;
  googleMeetId?: Maybe<Scalars['String']>;
  googleMeetLink?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['String']>;
  zoomMeetingLink?: Maybe<Scalars['String']>;
  zoomMeetingPassword?: Maybe<Scalars['String']>;
};

export type DigitalClassAccessCredentialsDo = {
  __typename?: 'DigitalClassAccessCredentialsDO';
  autoGenerateZoomMeetingDetails?: Maybe<Scalars['Boolean']>;
  googleMeetId?: Maybe<Scalars['String']>;
  googleMeetLink?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['String']>;
  zoomMeetingLink?: Maybe<Scalars['String']>;
  zoomMeetingPassword?: Maybe<Scalars['String']>;
};

export type DigitalClassAccessCredentialsInput = {
  autoGenerateGoogleMeetingDetails?: InputMaybe<Scalars['Boolean']>;
  autoGenerateZoomMeetingDetails?: InputMaybe<Scalars['Boolean']>;
  googleMeetId?: InputMaybe<Scalars['String']>;
  googleMeetLink?: InputMaybe<Scalars['String']>;
  zoomMeetingId?: InputMaybe<Scalars['String']>;
  zoomMeetingLink?: InputMaybe<Scalars['String']>;
  zoomMeetingPassword?: InputMaybe<Scalars['String']>;
};

export type DigitalClassAccessCredentialsOutput = {
  __typename?: 'DigitalClassAccessCredentialsOutput';
  autoGenerateGoogleMeetingDetails?: Maybe<Scalars['Boolean']>;
  autoGenerateZoomMeetingDetails?: Maybe<Scalars['Boolean']>;
  googleMeetId?: Maybe<Scalars['String']>;
  googleMeetLink?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['String']>;
  zoomMeetingLink?: Maybe<Scalars['String']>;
  zoomMeetingPassword?: Maybe<Scalars['String']>;
};

export type Discount = {
  __typename?: 'Discount';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  discountAmount?: Maybe<Scalars['Decimal']>;
  discountName?: Maybe<Scalars['String']>;
  discountRate?: Maybe<Scalars['Decimal']>;
  discountType?: Maybe<DiscountType>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
};

export enum DiscountType {
  Amount = 'Amount',
  Percentage = 'Percentage'
}

export enum EducationStage {
  Primary = 'Primary',
  Secondary = 'Secondary'
}

export type EligibilityFlow = {
  __typename?: 'EligibilityFlow';
  _id: Scalars['ObjectId'];
  courseId: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  startNode: FlowNode;
  startNodeJsonString: Scalars['String'];
};

export type EligibleScore = {
  __typename?: 'EligibleScore';
  /** The maximum score. */
  max?: Maybe<Scalars['Float']>;
  /** The minimum score. */
  min?: Maybe<Scalars['Float']>;
};

/** This sets the minimum and maximum score for the student's score to determine eligibility for a reward. */
export type EligibleScoreInput = {
  /** The maximum score. */
  max: Scalars['Int'];
  /** The minimum score. */
  min: Scalars['Int'];
};

export type EmailReminderInput = {
  file?: InputMaybe<Scalars['Upload']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileType?: InputMaybe<Scalars['String']>;
  isIncludeAttachment?: InputMaybe<Scalars['Boolean']>;
  message: Scalars['String'];
  receiverParentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  scheduledDate?: InputMaybe<Scalars['DateTime']>;
  subject?: InputMaybe<Scalars['String']>;
  templateId: Scalars['ObjectId'];
};

export type EmailValidation = {
  __typename?: 'EmailValidation';
  valid: Scalars['Boolean'];
  validationError?: Maybe<Scalars['String']>;
};

export type Employee = {
  branches?: Maybe<Array<Branch>>;
  department?: Maybe<Department>;
};

export type Enrollment = {
  __typename?: 'Enrollment';
  _id: Scalars['ObjectId'];
  arrangements?: Maybe<Array<Arrangement>>;
  attendances?: Maybe<Array<Attendance>>;
  class: Class;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  followUpStatus?: Maybe<FollowUpStatus>;
  followUpStatusLastModifiedBy?: Maybe<SystemUser>;
  followUpStatusLastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  group?: Maybe<EnrollmentGroupType>;
  invoiceByLessonId?: Maybe<Invoice>;
  invoices?: Maybe<Array<Invoice>>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isEndRecurring?: Maybe<Scalars['Boolean']>;
  isFullEnrollment?: Maybe<Scalars['Boolean']>;
  isReserved?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  preferredLearningArrangement?: Maybe<LearningArrangementType>;
  registration?: Maybe<Registration>;
  reservation?: Maybe<Registration>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: EnrollmentStatus;
  student?: Maybe<Student>;
  /** Enrollment suitability information that tells if student is suitable for registration */
  suitability?: Maybe<EnrollmentSuitability>;
  trialRecord?: Maybe<Array<Registration>>;
  type: EnrollmentType;
};


export type EnrollmentInvoiceByLessonIdArgs = {
  lessonId: Scalars['ObjectId'];
};

export enum EnrollmentGroupType {
  A = 'A',
  B = 'B',
  Unassigned = 'Unassigned'
}

export type EnrollmentIndex = {
  __typename?: 'EnrollmentIndex';
  _id: Scalars['ObjectId'];
  attendances?: Maybe<Array<Attendance>>;
  class?: Maybe<Class>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  followUpStatus?: Maybe<FollowUpStatus>;
  followUpStatusLastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  group?: Maybe<EnrollmentGroupType>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  preferredLearningArrangement?: Maybe<LearningArrangementType>;
  remarks?: Maybe<Array<Remark>>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: EnrollmentStatus;
  student?: Maybe<EnrollmentStudentIndex>;
  /** Enrollment suitability information that tells if student is suitable for registration */
  suitability?: Maybe<EnrollmentSuitability>;
  type: EnrollmentType;
};

export type EnrollmentIndexPage = {
  __typename?: 'EnrollmentIndexPage';
  items: Array<EnrollmentIndex>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum EnrollmentStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Holding = 'Holding'
}

export type EnrollmentStudentIndex = {
  __typename?: 'EnrollmentStudentIndex';
  _id: Scalars['ObjectId'];
  alias: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

/** Suitability class that includes suitability information of student on an `Enrollment` record */
export type EnrollmentSuitability = {
  __typename?: 'EnrollmentSuitability';
  remark?: Maybe<Remark>;
  /** Optional remark entity ID field; Applicable only for suitability status with attached student remarks */
  remarkId?: Maybe<Scalars['ObjectId']>;
  /** Status of suitability of the student's enrollment (either Suitable or pending for contact parent) */
  status?: Maybe<EnrollmentSuitabilityStatus>;
};

export enum EnrollmentSuitabilityStatus {
  Cjco = 'CJCO',
  Cjdo = 'CJDO',
  ContactParent = 'ContactParent',
  Dno = 'DNO',
  Suitable = 'Suitable'
}

export enum EnrollmentType {
  Additional = 'Additional',
  Regular = 'Regular',
  Trial = 'Trial'
}

export type ExamScore = {
  __typename?: 'ExamScore';
  _id: Scalars['ObjectId'];
  academicLevel?: Maybe<AcademicLevel>;
  academicLevelId?: Maybe<Scalars['ObjectId']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  examType: Scalars['String'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  marksPercentage: Scalars['Float'];
  mcq?: Maybe<ScoreRecord>;
  practical?: Maybe<ScoreRecord>;
  qeq?: Maybe<ScoreRecord>;
  scoreGrade: Scalars['String'];
  stream: Scalars['String'];
  student?: Maybe<Student>;
  studentId?: Maybe<Scalars['ObjectId']>;
  subject: Scalars['String'];
  totalMarks: ScoreRecord;
  year: Scalars['Float'];
};

export type Exercise = {
  __typename?: 'Exercise';
  _id: Scalars['ObjectId'];
  content: Scalars['String'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  gradableType?: Maybe<GradableType>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lessonNumber: Scalars['Int'];
  needSubmit?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  /** Contains the `status` and `mechanics` of an `Activity` which this case is an `Exercise`. */
  reward?: Maybe<RewardInfo>;
  totalBreakdown?: Maybe<MarkBreakDown>;
  totalGrade?: Maybe<Scalars['Int']>;
  workSheet: WorkSheet;
  workSheetId: Scalars['ObjectId'];
};

export type ExtraReminderVariables = {
  IsPaid?: InputMaybe<Scalars['Boolean']>;
};

export type FeatureAccess = {
  __typename?: 'FeatureAccess';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  keywords: FeatureAccessKeyword;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type FeatureAccessExtendInput = {
  enrollmentGroup?: InputMaybe<EnrollmentGroupType>;
  enrollmentPreferredLearningArrangement?: InputMaybe<LearningArrangementType>;
};

export enum FeatureAccessKeyword {
  Campaign = 'Campaign',
  Customise = 'Customise',
  Dashboard = 'Dashboard',
  Email = 'Email',
  Exports = 'Exports',
  Filters = 'Filters',
  Gap = 'GAP',
  HybridGrouping = 'HybridGrouping',
  LearningArrangement = 'LearningArrangement',
  Logistics = 'Logistics',
  Promotion = 'Promotion',
  Replacement = 'Replacement',
  Report = 'Report',
  Rewards = 'Rewards',
  WhatsApp = 'WhatsApp'
}

export type Fee = {
  __typename?: 'Fee';
  amount?: Maybe<Scalars['Decimal']>;
  isApplicable: Scalars['Boolean'];
  name: OtherFeeType;
};

export type Feedback = {
  __typename?: 'Feedback';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isResolved?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /**
   * A singlular LessonFlowItem referred to by the feedback.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  lessonFlow?: Maybe<LessonFlow>;
  /**
   * Linked Lesson Flow ID
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  lessonFlowId?: Maybe<Scalars['ObjectId']>;
  /**
   * Linked Lesson Flow Item resolved field
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  lessonFlowItem: LessonFlow;
  /**
   * Linked Lesson Flow Item ID
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  lessonFlowItemId?: Maybe<Scalars['ObjectId']>;
  quoted: Scalars['String'];
  reply?: Maybe<Array<FeedbackReply>>;
  /** Derived status field of feedback record (e.g. SEEN, UNSEEN) */
  status?: Maybe<FeedbackSeenStatus>;
  text: Scalars['String'];
  type: FeedbackType;
};

export type FeedbackFilterInput = {
  isResolved?: InputMaybe<Scalars['Boolean']>;
  lessonFlowId?: InputMaybe<Scalars['ObjectId']>;
  lessonFlowItemId?: InputMaybe<Scalars['ObjectId']>;
  type?: InputMaybe<FeedbackType>;
  /** Check feedback seen receipt records by User ID  */
  userId?: InputMaybe<Scalars['ObjectId']>;
};

export type FeedbackPage = {
  __typename?: 'FeedbackPage';
  items: Array<Feedback>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type FeedbackReply = {
  __typename?: 'FeedbackReply';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
};

export type FeedbackSeenReceipt = {
  __typename?: 'FeedbackSeenReceipt';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  feedbackId: Scalars['ObjectId'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  lessonFlowItemId: Scalars['ObjectId'];
  type: FeedbackType;
};

export type FeedbackSeenReceiptPage = {
  __typename?: 'FeedbackSeenReceiptPage';
  items: Array<FeedbackSeenReceipt>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/** Filter input in fetching feedback seen receipt records */
export type FeedbackSeenReceiptsFilterInput = {
  /** Filter by Lesson Flow ID */
  lessonFlowItem?: InputMaybe<Scalars['ObjectId']>;
  /** Filter by Lesson Flow Item ID */
  lessonFlowItemId?: InputMaybe<Scalars['ObjectId']>;
  /** Filter by Feedback type (e.g. NOTE or ERROR) */
  type?: InputMaybe<FeedbackType>;
  /** Filter by User ID */
  userId?: InputMaybe<Scalars['ObjectId']>;
};

export enum FeedbackSeenStatus {
  Seen = 'SEEN',
  Unseen = 'UNSEEN'
}

export enum FeedbackType {
  Error = 'Error',
  Note = 'Note'
}

/** Result object for count unseen feedback query */
export type FeedbackUnseenCount = {
  __typename?: 'FeedbackUnseenCount';
  /** Total count of unseen feedback records */
  total: Scalars['Float'];
};

export type FlowEnrollmentCheckExpression = {
  __typename?: 'FlowEnrollmentCheckExpression';
  type: FlowEnrollmentComparatorType;
};

export type FlowEnrollmentCheckExpressionInput = {
  type: FlowEnrollmentComparatorType;
};

export enum FlowEnrollmentComparatorType {
  AttendanceBefore = 'AttendanceBefore',
  CurrentlyAttending = 'CurrentlyAttending',
  NeverAttended = 'NeverAttended',
  WillAttend = 'WillAttend'
}

export type FlowLevelCheckExpression = {
  __typename?: 'FlowLevelCheckExpression';
  academicLevelId: Scalars['ObjectId'];
};

export type FlowLevelCheckExpressionInput = {
  academicLevelId: Scalars['ObjectId'];
};

export type FlowNode = {
  __typename?: 'FlowNode';
  enrollmentCheckExpressions?: Maybe<Array<FlowEnrollmentCheckExpression>>;
  failNode?: Maybe<FlowNode>;
  levelCheckExpressions?: Maybe<Array<FlowLevelCheckExpression>>;
  logicalOperator?: Maybe<LogicalOperator>;
  passNode?: Maybe<FlowNode>;
  result?: Maybe<FlowResult>;
  timePeriodExpressions?: Maybe<Array<FlowTimePeriodCheckExpression>>;
  type: FlowNodeType;
};

export type FlowNodeInput = {
  enrollmentCheckExpressions?: InputMaybe<Array<FlowEnrollmentCheckExpressionInput>>;
  failNode?: InputMaybe<FlowNodeInput>;
  levelCheckExpressions?: InputMaybe<Array<FlowLevelCheckExpressionInput>>;
  logicalOperator?: InputMaybe<LogicalOperator>;
  passNode?: InputMaybe<FlowNodeInput>;
  result?: InputMaybe<FlowResultInput>;
  timePeriodExpressions?: InputMaybe<Array<FlowTimePeriodCheckExpressionInput>>;
  type: FlowNodeType;
};

export enum FlowNodeType {
  Enrollment = 'Enrollment',
  Level = 'Level',
  Result = 'Result',
  TimePeriod = 'TimePeriod'
}

export type FlowResult = {
  __typename?: 'FlowResult';
  message: Scalars['String'];
  outcome: ResultOutcome;
};

export type FlowResultInput = {
  message: Scalars['String'];
  outcome: ResultOutcome;
};

export type FlowTimePeriodCheckExpression = {
  __typename?: 'FlowTimePeriodCheckExpression';
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type FlowTimePeriodCheckExpressionInput = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export enum FollowUpStatus {
  Enrolled = 'Enrolled',
  FirstFollowUpSent = 'FirstFollowUpSent',
  FollowUpSent = 'FollowUpSent',
  Pending = 'Pending',
  ScheduleCall = 'ScheduleCall',
  SeatReleased = 'SeatReleased',
  SecondFollowUpSent = 'SecondFollowUpSent'
}

export type GenerateUserIdInput = {
  parentId?: InputMaybe<Scalars['ObjectId']>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
};

export type GetTopicsByCourseIdsInput = {
  /** The IDs of the courses to find topics by. */
  courseIds: Array<Scalars['ObjectId']>;
};

/** Input for `GetTopicsByLessonFlowId` query. */
export type GetTopicsByLessonFlowIdInput = {
  /** The IDs of the lesson flows to find topics by. */
  lessonFlowId: Scalars['ObjectId'];
};

/** Input for `GetWorksheetsByLessonFlowId` query. */
export type GetWorksheetsByLessonFlowIdInput = {
  /** The IDs of the lesson flows to find worksheets by. */
  lessonFlowId: Scalars['ObjectId'];
};

/** Object defining submission statistics by Gradable (e.g. Exercise, Homework) */
export type GradableSubmissionStats = {
  __typename?: 'GradableSubmissionStats';
  /** Object ID of Gradable */
  _id: Scalars['ObjectId'];
  /** Gradable Name */
  gradableName: Scalars['String'];
  /** Gradable Type (e.g. GradableType.EXERCISE) */
  gradableType: Scalars['String'];
  /** Score statistics object containing min, max, and avg scores of the Gradable */
  score: ScoreStats;
};

export type GradableSubmissionStatsInput = {
  /** Filter gradable score statistics by Academic Year ID */
  academicYearId?: InputMaybe<Scalars['ObjectId']>;
  /** Filter gradable score statistics by Class ID */
  classId?: InputMaybe<Scalars['ObjectId']>;
  /** Filter gradable score statistics by date range */
  dateTimeRange?: InputMaybe<DateRangeInput>;
  /** Filter gradable score statistics by Worksheet ID */
  worksheetId?: InputMaybe<Scalars['ObjectId']>;
};

export enum GradableType {
  Exercise = 'Exercise',
  Homework = 'Homework'
}

export type GradableUnion = Exercise | Homework;

/** The grading template used on the test worksheets. */
export type GradingTemplate = {
  __typename?: 'GradingTemplate';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /** This is the name identifier of each template */
  name: Scalars['String'];
  /** Contains the list of templates to be copied to the worksheets model. */
  sections: Array<GradingTemplateSection>;
  /** The overall grade of all the sections */
  totalScore: Scalars['Float'];
};

/** These are the sections being copied to the worksheets model. */
export type GradingTemplateSection = {
  __typename?: 'GradingTemplateSection';
  /** This is the template name of the section on each test */
  name: Scalars['String'];
  /** This is the template total of each section */
  total: Scalars['Float'];
};

export type GradingTemplateSectionInput = {
  name: Scalars['String'];
  total: Scalars['Int'];
};

export type GroupAssignment = {
  __typename?: 'GroupAssignment';
  _id: Scalars['ObjectId'];
  academicYear: AcademicYear;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  items?: Maybe<Array<GroupAssignmentItem>>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
};

export type GroupAssignmentItem = {
  __typename?: 'GroupAssignmentItem';
  endDate: Scalars['DateTime'];
  groupA: GroupAssignmentType;
  groupB: GroupAssignmentType;
  startDate: Scalars['DateTime'];
  weekNumber: Scalars['Int'];
};

export type GroupAssignmentItemInput = {
  endDate: Scalars['DateTime'];
  groupA: GroupAssignmentType;
  groupB: GroupAssignmentType;
  startDate: Scalars['DateTime'];
  weekNumber: Scalars['Int'];
};

export enum GroupAssignmentType {
  Digital = 'Digital',
  Onsite = 'Onsite'
}

/** A handout. This is used in Teachers -> Reminders and can be of three different types of `HandoutType`. */
export type Handout = {
  __typename?: 'Handout';
  _id: Scalars['ObjectId'];
  academicWeek: AcademicWeek;
  /** The channel the handout is sent to. */
  channel: HandoutChannel;
  /** The Reference to the Course this handout belongs to. */
  course: Course;
  /** The ID of the course this handout belongs to. */
  courseId: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The enrollment type the Handout is intended for. Only Regular and Trial is allowed. */
  enrollmentType: EnrollmentType;
  /** A file to be attached to the course. This is used for handouts that are of type `HandoutType.Answers` */
  file?: Maybe<HandoutFile>;
  /** The instructions for the handout. This is formatted in Markdown. */
  instructions?: Maybe<Scalars['String']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /** The type of handout. This is either instructions for the handout itself, or instructions to parents. */
  type: HandoutType;
  /** The Reference to the Worksheet this handout is linked to. */
  worksheet?: Maybe<WorkSheet>;
  /** The ID of the Worksheet this handout is linked to. */
  worksheetId: Scalars['ObjectId'];
};

export enum HandoutChannel {
  Portal = 'Portal',
  WhatsApp = 'WhatsApp'
}

/** An uploaded file for a handout. This is used for handouts that are of type `HandoutType.Answers` */
export type HandoutFile = {
  __typename?: 'HandoutFile';
  /** The name of the file. */
  fileName: Scalars['String'];
  /** The URL of the file. */
  url: Scalars['String'];
};

/** An uploaded file for a handout. This is used for handouts that are of type `HandoutType.Answers` */
export type HandoutFileInput = {
  /** The name of the file. */
  fileName: Scalars['String'];
  /** The URL of the file. */
  url: Scalars['String'];
};

/** Arguments to be used when filtering handouts. */
export type HandoutFilterInput = {
  /** The Academic Week to filter the handouts by. This only needs the weekNumber as an input. */
  academicWeek?: InputMaybe<AcademicWeekInput>;
  channels?: InputMaybe<Array<HandoutChannel>>;
  /** The IDs of the courses to filter by. If not provided, handouts for all courses will be returned. */
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** The enrollment types to filter by. */
  enrollmentTypes?: InputMaybe<Array<EnrollmentType>>;
  /** The handout types to filter by. This determines who the handout is intended for. */
  types?: InputMaybe<Array<HandoutType>>;
};

export type HandoutPage = {
  __typename?: 'HandoutPage';
  items: Array<Handout>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum HandoutType {
  Answers = 'Answers',
  Course = 'Course',
  Parent = 'Parent'
}

/** Handover Onsite Material. */
export type HandoverOnsiteMaterialCommand = {
  additionalIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  id: Scalars['ObjectId'];
};

export type Holiday = {
  __typename?: 'Holiday';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  period: DateRange;
  type: HolidayType;
};

export type HolidayFilterInput = {
  year?: InputMaybe<Scalars['Int']>;
};

export enum HolidayType {
  Public = 'Public',
  School = 'School'
}

export type Homework = {
  __typename?: 'Homework';
  _id: Scalars['ObjectId'];
  content: Scalars['String'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The duration of the `homework` in minutes. */
  duration?: Maybe<Scalars['Int']>;
  gradableType?: Maybe<GradableType>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lessonNumber: Scalars['Int'];
  needSubmit?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  /** Contains the `status` and `mechanics` of an `Activity` which this case is a `Homework`. */
  reward?: Maybe<RewardInfo>;
  totalBreakdown?: Maybe<MarkBreakDown>;
  totalGrade?: Maybe<Scalars['Int']>;
  workSheet: WorkSheet;
  workSheetId?: Maybe<Scalars['ObjectId']>;
};

export type IdInput = {
  id: Scalars['ObjectId'];
};

/** Third party integration object type */
export type Integration = {
  __typename?: 'Integration';
  _id: Scalars['ObjectId'];
  /** Integration application account id */
  accountId?: Maybe<Scalars['String']>;
  /** Integration application client id */
  clientId?: Maybe<Scalars['String']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  googleCalendarHostEmail?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  isConfigured?: Maybe<Scalars['Boolean']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /** Integration application type */
  type: IntegrationType;
};

export enum IntegrationType {
  GoogleCalendar = 'GoogleCalendar',
  Xero = 'Xero',
  ZoomMeeting = 'ZoomMeeting'
}

export type Invoice = {
  __typename?: 'Invoice';
  _id: Scalars['ObjectId'];
  amountPaid?: Maybe<Scalars['Decimal']>;
  arrangement?: Maybe<Arrangement>;
  arrangementBillingCheck?: Maybe<ArrangementBillingCheckResult>;
  billedFor?: Maybe<Student>;
  billedTo?: Maybe<Parent>;
  billingFrequency?: Maybe<BillingFrequency>;
  billingPeriod?: Maybe<Scalars['Int']>;
  billingYear?: Maybe<Scalars['Int']>;
  class?: Maybe<Class>;
  course?: Maybe<Course>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  destination?: Maybe<Enrollment>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasTransferred: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isImported?: Maybe<Scalars['Boolean']>;
  isOnHold?: Maybe<Scalars['Boolean']>;
  isOtherFee?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lessonDates?: Maybe<Array<Scalars['DateTime']>>;
  lessons?: Maybe<Array<Lesson>>;
  lineItems?: Maybe<Array<InvoiceLineItem>>;
  nextInvoice?: Maybe<Invoice>;
  nextInvoiceDate?: Maybe<Scalars['DateTime']>;
  onHoldRemarks?: Maybe<Scalars['String']>;
  payments?: Maybe<Array<Payment>>;
  receipts?: Maybe<Array<Receipt>>;
  referenceNumber: Scalars['String'];
  reminders?: Maybe<Array<Reminder>>;
  snapshot?: Maybe<InvoiceSnapshot>;
  status: InvoiceStatus;
  subtotal?: Maybe<Scalars['Decimal']>;
  taxRate?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Decimal']>;
  totalTax?: Maybe<Scalars['Decimal']>;
  xeroInvoices?: Maybe<Array<InvoiceXero>>;
};


export type InvoiceArrangementBillingCheckArgs = {
  lessonId: Scalars['ObjectId'];
};


export type InvoiceLineItemsArgs = {
  isFormat?: InputMaybe<Scalars['Boolean']>;
};

export type InvoiceAcademicYearIndex = {
  __typename?: 'InvoiceAcademicYearIndex';
  _id: Scalars['ObjectId'];
  year: Scalars['Int'];
};

export type InvoiceClassIndex = {
  __typename?: 'InvoiceClassIndex';
  _id: Scalars['ObjectId'];
  defaultLessonDays?: Maybe<Array<WeekDay>>;
  name: Scalars['String'];
  teacherId?: Maybe<Scalars['ObjectId']>;
};

export type InvoiceCourseIndex = {
  __typename?: 'InvoiceCourseIndex';
  _id: Scalars['ObjectId'];
  nameShort: Scalars['String'];
};

export type InvoiceFilterInput = {
  billedForStudentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  dateRange?: InputMaybe<DateTimeRangeInput>;
  dueDateRange?: InputMaybe<DateTimeRangeInput>;
  invoiceIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  isOtherFee?: InputMaybe<Scalars['Boolean']>;
  lessonDays?: InputMaybe<Array<WeekDay>>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ObjectId']>;
  showOnHold?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Array<InvoiceStatus>>;
  teacherIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  xeroInvoiceDate?: InputMaybe<XeroInvoiceDateInput>;
};

export type InvoiceIndex = {
  __typename?: 'InvoiceIndex';
  _id: Scalars['ObjectId'];
  academicYear?: Maybe<InvoiceAcademicYearIndex>;
  amountPaid?: Maybe<Scalars['Decimal']>;
  billedToParent?: Maybe<InvoiceParentIndex>;
  billingFrequency?: Maybe<BillingFrequency>;
  billingPeriod?: Maybe<Scalars['Int']>;
  billingYear?: Maybe<Scalars['Int']>;
  class?: Maybe<InvoiceClassIndex>;
  course?: Maybe<InvoiceCourseIndex>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  emailStatus?: Maybe<ReminderStatus>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isImported?: Maybe<Scalars['Boolean']>;
  isOnHold?: Maybe<Scalars['Boolean']>;
  isOtherFee?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lessonDates?: Maybe<Array<Scalars['DateTime']>>;
  nextInvoiceDate?: Maybe<Scalars['DateTime']>;
  onHoldRemarks?: Maybe<Scalars['String']>;
  paymentAdviceEmailStatus?: Maybe<ReminderStatus>;
  paymentAdviceWaAttachmentStatus?: Maybe<ReminderStatus>;
  paymentAdviceWaStatus?: Maybe<ReminderStatus>;
  referenceNumber: Scalars['String'];
  secondaryParents?: Maybe<Array<InvoiceParentIndex>>;
  status: InvoiceStatus;
  student?: Maybe<InvoiceStudentIndex>;
  subtotal?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  totalTax?: Maybe<Scalars['Decimal']>;
  waAttachmentStatus?: Maybe<ReminderStatus>;
  waStatus?: Maybe<ReminderStatus>;
};

export type InvoiceIndexPage = {
  __typename?: 'InvoiceIndexPage';
  items: Array<InvoiceIndex>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type InvoiceItemOption = {
  __typename?: 'InvoiceItemOption';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  unitAmount: Scalars['Decimal'];
};

export type InvoiceLessonSnapshot = {
  __typename?: 'InvoiceLessonSnapshot';
  _id: Scalars['ObjectId'];
  lessonEnd: Scalars['DateTime'];
  lessonStart: Scalars['DateTime'];
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  _id: Scalars['ObjectId'];
  class?: Maybe<Class>;
  classId?: Maybe<Scalars['ObjectId']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Decimal']>;
  discountId?: Maybe<Scalars['ObjectId']>;
  discountRate?: Maybe<Scalars['Decimal']>;
  discountRemarks?: Maybe<Scalars['String']>;
  discountType?: Maybe<DiscountType>;
  invoice?: Maybe<Invoice>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isPaid?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lessons?: Maybe<Array<InvoiceLineItemLesson>>;
  lineAmount?: Maybe<Scalars['Decimal']>;
  otherFeeType?: Maybe<OtherFeeType>;
  paid?: Maybe<Scalars['Decimal']>;
  quantity?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Int']>;
  unitAmount?: Maybe<Scalars['Decimal']>;
};

export type InvoiceLineItemLesson = {
  __typename?: 'InvoiceLineItemLesson';
  amount?: Maybe<Scalars['Decimal']>;
  isMakeUp?: Maybe<Scalars['Boolean']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  lesson?: Maybe<Lesson>;
  lessonId?: Maybe<Scalars['ObjectId']>;
  paid?: Maybe<Scalars['Decimal']>;
};

export type InvoiceLineItemPage = {
  __typename?: 'InvoiceLineItemPage';
  items: Array<InvoiceLineItem>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type InvoicePage = {
  __typename?: 'InvoicePage';
  items: Array<Invoice>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type InvoicePaidSnapshot = {
  __typename?: 'InvoicePaidSnapshot';
  beforePaid?: Maybe<Scalars['Decimal']>;
  invoiceId: Scalars['ObjectId'];
  paid?: Maybe<Scalars['Decimal']>;
  snapshot?: Maybe<InvoiceSnapshot>;
};

export type InvoiceParentIndex = {
  __typename?: 'InvoiceParentIndex';
  _id: Scalars['ObjectId'];
  avatarUrl?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  mobile: Telephone;
  salutation?: Maybe<Scalars['String']>;
};

export type InvoiceReport = {
  __typename?: 'InvoiceReport';
  /** Unique identifier for the record */
  _id: Scalars['ObjectId'];
  classId?: Maybe<Scalars['ObjectId']>;
  courseId?: Maybe<Scalars['ObjectId']>;
  courseName?: Maybe<Scalars['String']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  feeWithoutGST: Scalars['Decimal'];
  fullLessonDate?: Maybe<Array<Scalars['DateTime']>>;
  invoiceDate?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['ObjectId']>;
  invoiceTotal: Scalars['Decimal'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isOnHold?: Maybe<Scalars['Boolean']>;
  isVoid?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lessonDate?: Maybe<Scalars['DateTime']>;
  lessonId?: Maybe<Scalars['ObjectId']>;
  lineItemFee: Scalars['Decimal'];
  lineItemFeeWithoutGST: Scalars['Decimal'];
  lineItemId?: Maybe<Scalars['ObjectId']>;
  parentFullName?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ObjectId']>;
  parentUserId?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  remarks?: Maybe<Array<Scalars['String']>>;
  studentFullName?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['ObjectId']>;
  studentUserId?: Maybe<Scalars['String']>;
  type: InvoiceReportType;
};

export type InvoiceReportFilterInput = {
  classIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  invoiceDateRange?: InputMaybe<DateTimeRangeInput>;
  lessonDateRange?: InputMaybe<DateTimeRangeInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type InvoiceReportPage = {
  __typename?: 'InvoiceReportPage';
  items: Array<InvoiceReport>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum InvoiceReportType {
  CourseFee = 'CourseFee',
  OtherFee = 'OtherFee'
}

export type InvoiceSnapshot = {
  __typename?: 'InvoiceSnapshot';
  lessons?: Maybe<Array<InvoiceLessonSnapshot>>;
  parentFullName: Scalars['String'];
  studentFullName?: Maybe<Scalars['String']>;
};

export enum InvoiceStatus {
  Draft = 'Draft',
  Overdue = 'Overdue',
  Paid = 'Paid',
  Pending = 'Pending',
  Void = 'Void'
}

export type InvoiceStudentIndex = {
  __typename?: 'InvoiceStudentIndex';
  _id: Scalars['ObjectId'];
  alias: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type InvoiceXero = {
  __typename?: 'InvoiceXero';
  amount?: Maybe<Scalars['Decimal']>;
  lastSyncedDate?: Maybe<Scalars['DateTime']>;
  month: Scalars['Int'];
  url?: Maybe<Scalars['String']>;
  xeroInvoiceId?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
};

export enum LearningArrangementType {
  Digital = 'Digital',
  Hybrid = 'Hybrid',
  Onsite = 'Onsite'
}

export type Lesson = {
  __typename?: 'Lesson';
  _id: Scalars['ObjectId'];
  absentStudent?: Maybe<Scalars['Int']>;
  academicWeek?: Maybe<AcademicWeek>;
  activeEnrolled?: Maybe<Scalars['Int']>;
  attendance?: Maybe<Array<Attendance>>;
  attendanceSnapshot?: Maybe<Array<AttendanceSnapshot>>;
  capacity?: Maybe<LessonCapacity>;
  capacityNumber?: Maybe<LessonCapacityNumber>;
  class?: Maybe<Class>;
  classId: Scalars['ObjectId'];
  course: Course;
  courseId: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  enrollment?: Maybe<Array<Enrollment>>;
  holidayEffectedEnrollments?: Maybe<Array<Enrollment>>;
  holidayIds?: Maybe<Array<Scalars['ObjectId']>>;
  holidays?: Maybe<Array<Holiday>>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isHoliday?: Maybe<Scalars['Boolean']>;
  isHolidayEffectedAdditionalLesson?: Maybe<Scalars['Boolean']>;
  isOverlappedTeacher?: Maybe<Scalars['Boolean']>;
  isOverlappedVenue?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lessonDay: WeekDay;
  lessonEnd: Scalars['DateTime'];
  /** The lesson flow copied from the lesson plan when the class this lesson is assigned to is applied to the lesson plan. */
  lessonFlow: LessonFlowV2;
  lessonNotes?: Maybe<Scalars['String']>;
  lessonNumber: Scalars['Float'];
  lessonStart: Scalars['DateTime'];
  maximumClassCapacity?: Maybe<Scalars['Int']>;
  noStatusStudent?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  status?: Maybe<LessonStatus>;
  teacher?: Maybe<SystemUser>;
  teacherId?: Maybe<Scalars['ObjectId']>;
  totalStudent?: Maybe<Scalars['Int']>;
  venue?: Maybe<Venue>;
  venueId?: Maybe<Scalars['ObjectId']>;
};


export type LessonAttendanceArgs = {
  status?: InputMaybe<Array<AttendanceStatus>>;
  type?: InputMaybe<Array<AttendanceType>>;
};


export type LessonAttendanceSnapshotArgs = {
  status?: InputMaybe<Array<AttendanceStatus>>;
  type?: InputMaybe<Array<AttendanceType>>;
};

export type LessonBranchIndex = {
  __typename?: 'LessonBranchIndex';
  _id: Scalars['ObjectId'];
  alias: Scalars['String'];
  name: Scalars['String'];
};

export type LessonCapacity = {
  __typename?: 'LessonCapacity';
  class?: Maybe<Scalars['Int']>;
  enrolled?: Maybe<Scalars['Int']>;
  makeUp?: Maybe<Scalars['Int']>;
  remainingMakeUpSeat?: Maybe<Scalars['Int']>;
  remainingSeat?: Maybe<Scalars['Int']>;
  replacing?: Maybe<Scalars['Int']>;
  seats?: Maybe<Array<LessonSeat>>;
  total?: Maybe<Scalars['Int']>;
};

export type LessonCapacityNumber = {
  __typename?: 'LessonCapacityNumber';
  class?: Maybe<Scalars['Int']>;
  enrolled?: Maybe<Scalars['Int']>;
  makeUp?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export enum LessonCapacitySeatType {
  Additional = 'Additional',
  MakeupIn = 'MakeupIn',
  Regular = 'Regular',
  Reserved = 'Reserved',
  TransferIn = 'TransferIn',
  TransferOut = 'TransferOut',
  Trial = 'Trial',
  Withdrawn = 'Withdrawn'
}

export type LessonClassIndex = {
  __typename?: 'LessonClassIndex';
  _id: Scalars['ObjectId'];
  academicYearId: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  shorthand?: Maybe<Scalars['String']>;
  type?: Maybe<ClassType>;
};

export type LessonCourseIndex = {
  __typename?: 'LessonCourseIndex';
  _id: Scalars['ObjectId'];
  avatarUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameShort: Scalars['String'];
};

export type LessonFilterInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  academicWeek?: InputMaybe<AcademicWeekInput>;
  academicYearIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  classIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  classStatus?: InputMaybe<Array<ClassStatus>>;
  classTypes?: InputMaybe<Array<ClassType>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  dateRange?: InputMaybe<DateTimeRangeInput>;
  endTime?: InputMaybe<Scalars['Time']>;
  holidayIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  isAdHoc?: InputMaybe<Scalars['Boolean']>;
  isFull?: InputMaybe<Scalars['Boolean']>;
  isReplacementSeatFull?: InputMaybe<Scalars['Boolean']>;
  learningArrangements?: InputMaybe<Array<LearningArrangementType>>;
  lessonDays?: InputMaybe<Array<WeekDay>>;
  lessonNumber?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  showVoided?: InputMaybe<Scalars['Boolean']>;
  skipHoliday?: InputMaybe<Scalars['Boolean']>;
  startAndEndTime?: InputMaybe<TimeRangeInput>;
  startTime?: InputMaybe<Scalars['Time']>;
  studentId?: InputMaybe<Scalars['ObjectId']>;
  teacherIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  venueIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type LessonFlow = {
  __typename?: 'LessonFlow';
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  _id: Scalars['ObjectId'];
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  academicYear?: Maybe<AcademicYear>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  academicYearId: Scalars['ObjectId'];
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  appliedClasses?: Maybe<Array<Class>>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  appliedCourses?: Maybe<Array<Course>>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  course?: Maybe<Course>;
  /**
   * The ID of the course assigned to the lesson flow. This will be used by the course to determine it's assigned `lessonFlow`s. Notice: `courseId` will soon be changed to required in the future.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  courseId?: Maybe<Scalars['ObjectId']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /**
   * Reference of Lesson Flow Items assigned to the Lesson Flow. These contain activities for the Lesson.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  lessonFlowItemIds?: Maybe<Array<Scalars['ObjectId']>>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  lessonFlowItems?: Maybe<Array<LessonFlowItem>>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  name: Scalars['String'];
};

/** The academic week generated based on the Term. */
export type LessonFlowAcademicWeek = {
  __typename?: 'LessonFlowAcademicWeek';
  /** The date of the last day of the week. */
  endDate: Scalars['DateTime'];
  /** The date of the first day of the week. */
  startDate: Scalars['DateTime'];
  /** The week number in an academic year. */
  weekNumber: Scalars['Int'];
};

export type LessonFlowAcademicWeekInput = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  weekNumber: Scalars['Int'];
};

/** The payload for homework type `OTHER`. */
export type LessonFlowAddOtherHomeworkInput = {
  /** The custom description which can be any text that the teacher puts. */
  description?: InputMaybe<Scalars['String']>;
  /** The custom title which can be any text that the teacher puts. */
  title: Scalars['String'];
};

/** The payload for homework type `WORKSHEET`. */
export type LessonFlowAddWorksheetHomeworkInput = {
  /** The description to add for the chosen worksheet to be used as a homework. */
  description?: InputMaybe<Scalars['String']>;
  /** When true, this worksheet will be considered a trial worksheet. Only used for REGULAR courses. */
  forTrial?: InputMaybe<Scalars['Boolean']>;
  /** The worksheet to be used as a homework. */
  worksheetId: Scalars['ObjectId'];
};

/**
 * The entry on the lesson flow containing the break time details.
 *   This is currently only being used in Schedule.
 */
export type LessonFlowBreakItem = {
  __typename?: 'LessonFlowBreakItem';
  /** The description that the teacher placed for the break time. */
  description?: Maybe<Scalars['String']>;
};

/** Inputs for adding "Break" Item in LessonFlow. */
export type LessonFlowBreakItemInput = {
  /** The description that the teacher placed for the break time. */
  description?: InputMaybe<Scalars['String']>;
};

/** The object containing the collectable item details by the teachers. */
export type LessonFlowCollectable = {
  __typename?: 'LessonFlowCollectable';
  /**
   * The manually generated id that will be used
   *     for reference when performing operations.
   */
  id: Scalars['ObjectId'];
  /**
   * The object containing the other details.
   *     This is required if the collectable type is `OTHER`.
   */
  other?: Maybe<LessonFlowOtherItem>;
  /** The different types of collectable items: WORKSHEET, OTHER */
  type: LessonFlowCollectableType;
  /**
   * The object containing the worksheet details.
   *     This is required if the collectable type is `WORKSHEET`.
   */
  worksheet?: Maybe<LessonFlowWorksheetItem>;
};

export enum LessonFlowCollectableType {
  Other = 'OTHER',
  Worksheet = 'WORKSHEET'
}

export type LessonFlowFilterInput = {
  /** Filter by one or more academic levels */
  academicLevels?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Filter by one or more academic year IDs */
  academicYears?: InputMaybe<Array<Scalars['ObjectId']>>;
  courseId?: InputMaybe<Scalars['ObjectId']>;
  /** Filter by one or more course types */
  courseTypes?: InputMaybe<Array<CourseType>>;
  /** Filter condition if archived items should be returned */
  showArchived?: InputMaybe<Scalars['Boolean']>;
};

/** The object containing the homework details. */
export type LessonFlowHomework = {
  __typename?: 'LessonFlowHomework';
  /** Reference to the Homework entity. */
  id: Scalars['ObjectId'];
  /** True if the related packing list envelope is already set to "Printed"/"Packed for Delivery" and this item will not be used for this lesson flow. */
  isSkipped?: Maybe<Scalars['Boolean']>;
  /**
   * The object containing the other details.
   *     This is required if the homework type is `OTHER`.
   */
  other?: Maybe<LessonFlowOtherItem>;
  /** The different types of homeworks: WORKSHEET, OTHER */
  type: LessonFlowHomeworkType;
  /**
   * The object containing the worksheet details.
   *     This is required if the homework type is `WORKSHEET`.
   */
  worksheet?: Maybe<LessonFlowWorksheetItem>;
};

/** The payload for homework type `OTHER`. */
export type LessonFlowHomeworkOtherInput = {
  /** The custom description which can be any text that the teacher puts. */
  description?: InputMaybe<Scalars['String']>;
  /** The custom title which can be any text that the teacher puts. */
  title: Scalars['String'];
};

export enum LessonFlowHomeworkType {
  Other = 'OTHER',
  Worksheet = 'WORKSHEET'
}

/** The payload for homework type `WORKSHEET`. */
export type LessonFlowHomeworkWorksheetInput = {
  /** The description to set for the chosen worksheet to be used as a homework. */
  description?: InputMaybe<Scalars['String']>;
  /** When true, this worksheet will be considered a trial worksheet. Only used for REGULAR courses. */
  forTrial?: InputMaybe<Scalars['Boolean']>;
  /** The worksheet to be used as a homework. */
  worksheetId: Scalars['ObjectId'];
};

export type LessonFlowItem = {
  __typename?: 'LessonFlowItem';
  _id: Scalars['ObjectId'];
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  academicWeek?: Maybe<AcademicWeek>;
  /**
   * @deprecated Keeping collect to ensure the frontend in admin does not break. Currently unsure wether this would be an issue since the split happened before this has been implemented.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  collect?: Maybe<Scalars['String']>;
  /**
   * An array of strings that are received from a markdown input from a Rich Text Editor
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  collects?: Maybe<Array<Collect>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  exceptedClassIds: Array<Scalars['ObjectId']>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  exceptions?: Maybe<Array<Class>>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  exercises?: Maybe<Array<Exercise>>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  feedbacks?: Maybe<Array<Feedback>>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  homeworks?: Maybe<Array<Homework>>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  lessonFlowId: Scalars['ObjectId'];
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  lessonNumber: Scalars['Int'];
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  name: Scalars['String'];
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  order: Scalars['Int'];
  /**
   * List of lesson sequence items to represent schedule of Exercises and breaks
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  sequence?: Maybe<Array<SequenceItem>>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  suitability?: Maybe<Suitability>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  version: Scalars['Int'];
};

export type LessonFlowItemPage = {
  __typename?: 'LessonFlowItemPage';
  items: Array<LessonFlowItem>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/**
 * Used as a flexible entry on the lesson flow.
 *   Currently being used in Schedule, Homework, and Collect.
 */
export type LessonFlowOtherItem = {
  __typename?: 'LessonFlowOtherItem';
  /** The custom description which can be any text that the teacher puts. */
  description?: Maybe<Scalars['String']>;
  /** The custom title which can be any text that the teacher puts. */
  title: Scalars['String'];
};

/** Inputs for adding "Other" Item in LessonFlow. */
export type LessonFlowOtherItemInput = {
  /** The custom description which can be any text that the teacher puts. */
  description?: InputMaybe<Scalars['String']>;
  /** The custom title which can be any text that the teacher puts. */
  title: Scalars['String'];
};

export type LessonFlowPage = {
  __typename?: 'LessonFlowPage';
  items: Array<LessonFlow>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/** The schedule template of a lesson. */
export type LessonFlowSchedule = {
  __typename?: 'LessonFlowSchedule';
  /**
   * The object containing the break time details.
   *     This is required if the schedule type is `BREAK`.
   */
  break?: Maybe<LessonFlowBreakItem>;
  /**
   * The manually generated id that will be used
   *     for reference when performing operations.
   */
  id: Scalars['ObjectId'];
  /** This can be set to true if the worksheet is already included in a packing list envelope that is already printed/packed for delivery to indicate that it won't be used anymore in a class. */
  isSkipped?: Maybe<Scalars['Boolean']>;
  /** The object containing the other details. This is required if the schedule type is `OTHER`. */
  other?: Maybe<LessonFlowOtherItem>;
  /**
   * The object containing the test details. This is required if the schedule type is `TEST`.
   *     When added to the lesson flow schedule, the corresponding worksheet must also be added to the collectable list
   */
  test?: Maybe<LessonFlowTestItem>;
  /**
   * The lesson flow schedule type.
   *     Used for identifying the item added to the schedule in the lesson flow.
   */
  type: LessonFlowScheduleType;
  /** The object containing the worksheet details. This is required if the schedule type is `WORKSHEET`. */
  worksheet?: Maybe<LessonFlowWorksheetItem>;
};

/** The object containing the lesson flow schedule ids and the order in which they are saved. */
export type LessonFlowSchedulePositionInput = {
  /** The lesson flow schedule to be reordered. */
  lessonFlowScheduleId: Scalars['ObjectId'];
  /**
   * The new position to save the lesson flow schedule.
   *     The value passed is the ordinal position rather than the index.
   *     Ex. position=2 instead of position=1
   *     to put the id as the second element of the array.
   */
  position: Scalars['Int'];
};

export enum LessonFlowScheduleType {
  Break = 'BREAK',
  Other = 'OTHER',
  Test = 'TEST',
  Worksheet = 'WORKSHEET'
}

/**
 * The entry on the lesson flow containing the test details.
 *   This is currently only being used in Schedule.
 */
export type LessonFlowTestItem = {
  __typename?: 'LessonFlowTestItem';
  /**
   * The description that the teacher placed for the test.
   *     (not copied from the WorkSheet entity).
   */
  description?: Maybe<Scalars['String']>;
  /** When true, this test (worksheet) will be considered a trial worksheet. Only used for REGULAR courses. */
  forTrial?: Maybe<Scalars['Boolean']>;
  /** The test name copied from the WorkSheet entity. */
  testName: Scalars['String'];
  /** Reference to the WorkSheet entity. */
  worksheetId: Scalars['ObjectId'];
  /** The title copied from the WorkSheet entity. */
  worksheetTitle: Scalars['String'];
};

/** Inputs for adding "Test" Item in LessonFlow. */
export type LessonFlowTestItemInput = {
  /**
   * The description that the teacher placed for the test.
   *     (not copied from the WorkSheet entity).
   */
  description?: InputMaybe<Scalars['String']>;
  /** When true, this test (worksheet) will be considered a trial worksheet. Only used for REGULAR courses. */
  forTrial?: InputMaybe<Scalars['Boolean']>;
  /** Reference to the WorkSheet entity. */
  worksheetId: Scalars['ObjectId'];
};

/** This describes the payload of the lesson flow update notification */
export type LessonFlowUpdate = {
  __typename?: 'LessonFlowUpdate';
  /** The date and time that the lesson flow was modified */
  dateModified: Scalars['DateTime'];
  /** The supervisor who modified the lesson flow */
  modifiedBy: SystemUser;
  /** Type of the lesson flow update */
  type: LessonFlowUpdateType;
};

export enum LessonFlowUpdateType {
  Collect = 'COLLECT',
  Homework = 'HOMEWORK',
  LessonFlowItem = 'LESSON_FLOW_ITEM',
  Sequence = 'SEQUENCE'
}

/** The lesson flow for an academic week. */
export type LessonFlowV2 = {
  __typename?: 'LessonFlowV2';
  /**
   * The academic week when this lesson flow is to be used.
   *     This is only available when the associated course for this lesson plan is of type REGULAR.
   */
  academicWeek?: Maybe<LessonFlowAcademicWeek>;
  /**
   * The materials that the teacher will collect
   *     from the students for this lesson flow.
   */
  collectables: Array<LessonFlowCollectable>;
  /**
   * The homeworks to be used within this lesson flow
   *     for the academic week.
   */
  homeworks: Array<LessonFlowHomework>;
  /**
   * The manually generated id that will be used
   *     for reference by other entities such as the Lesson entity.
   */
  id: Scalars['ObjectId'];
  /** The lesson number assigned to this lesson flow. Starts at `1`. */
  lessonNo: Scalars['Int'];
  /** The sequence of lessons to be executed on the lesson flow. */
  schedules: Array<LessonFlowSchedule>;
  /**
   * The term period number. An academic term is a portion of an academic year,
   *     the time during which an educational institution holds classes.
   *     This is only available when the associated course for this lesson plan is of type REGULAR.
   *     Starts at `1`.
   *     Ex: `1 > 2 > 3`
   */
  termNo?: Maybe<Scalars['Int']>;
  /**
   * Variations of this lesson flow
   *     which can be applied to a specific class for a special reason.
   *     Note that a lesson flow can have multiple variants.
   */
  variants: Array<LessonFlowVariant>;
};

/**
 * A special kind of lesson flow that is
 *   applied to specific classes for a special reason.
 */
export type LessonFlowVariant = {
  __typename?: 'LessonFlowVariant';
  /**
   * The academic week when this lesson flow is to be used.
   *     This is only available when the associated course for this lesson plan is of type REGULAR.
   */
  academicWeek?: Maybe<LessonFlowAcademicWeek>;
  /** Reference to the class the variant is applied to. */
  classesApplied: Array<LessonPlanClass>;
  /**
   * The materials that the teacher will collect
   *     from the students for this lesson flow.
   */
  collectables: Array<LessonFlowCollectable>;
  /**
   * The homeworks to be used within this lesson flow
   *     for the academic week.
   */
  homeworks: Array<LessonFlowHomework>;
  /**
   * The manually generated id that will be used
   *     for reference by other entities such as the Lesson entity.
   */
  id: Scalars['ObjectId'];
  /** The lesson number assigned to this lesson flow. Starts at `1`. */
  lessonNo: Scalars['Int'];
  /** The sequence of lessons to be executed on the lesson flow. */
  schedules: Array<LessonFlowSchedule>;
  /**
   * The term period number. An academic term is a portion of an academic year,
   *     the time during which an educational institution holds classes.
   *     This is only available when the associated course for this lesson plan is of type REGULAR.
   *     Starts at `1`.
   *     Ex: `1 > 2 > 3`
   */
  termNo?: Maybe<Scalars['Int']>;
};

/** Inputs for adding "WorkSheet" Item in LessonFlow. */
export type LessonFlowWorkSheetItemInput = {
  /**
   * The description that the teacher placed for the referenced WorkSheet.
   *     (not copied from the WorkSheet entity).
   */
  description?: InputMaybe<Scalars['String']>;
  /** When true, this worksheet will be considered a trial worksheet. Only used for REGULAR courses. */
  forTrial?: InputMaybe<Scalars['Boolean']>;
  /** Reference to the WorkSheet entity. */
  worksheetId: Scalars['ObjectId'];
};

/** The base item on the lesson flow containing the worksheet details. */
export type LessonFlowWorksheetBaseItem = {
  __typename?: 'LessonFlowWorksheetBaseItem';
  /** When true, this worksheet will be considered a trial worksheet. Only used for REGULAR courses. */
  forTrial?: Maybe<Scalars['Boolean']>;
  /** The title copied from the WorkSheet entity. */
  title: Scalars['String'];
  /** Reference to the WorkSheet entity. */
  worksheetId: Scalars['ObjectId'];
};

/**
 * The entry on the lesson flow containing the worksheet details.
 *   Currently used in Schedule, Homework, and Collect.
 */
export type LessonFlowWorksheetItem = {
  __typename?: 'LessonFlowWorksheetItem';
  /**
   * The description that the teacher placed for the referenced WorkSheet.
   *     (not copied from the WorkSheet entity).
   */
  description?: Maybe<Scalars['String']>;
  /** When true, this worksheet will be considered a trial worksheet. Only used for REGULAR courses. */
  forTrial?: Maybe<Scalars['Boolean']>;
  /** The title copied from the WorkSheet entity. */
  title: Scalars['String'];
  /** Reference to the topic of the WorkSheet. Taken from the WorkSheet entity. */
  topicId: Scalars['ObjectId'];
  /** Reference to the WorkSheet entity. */
  worksheetId: Scalars['ObjectId'];
};

export type LessonIndex = {
  __typename?: 'LessonIndex';
  _id: Scalars['ObjectId'];
  absentStudents?: Maybe<Scalars['Int']>;
  academicWeek?: Maybe<AcademicWeek>;
  branch?: Maybe<LessonBranchIndex>;
  class?: Maybe<LessonClassIndex>;
  classId: Scalars['ObjectId'];
  course?: Maybe<LessonCourseIndex>;
  courseId: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isHoliday?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  lastModifiedByFullName?: Maybe<Scalars['String']>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lessonDay: WeekDay;
  lessonEnd: Scalars['DateTime'];
  lessonNumber: Scalars['Float'];
  lessonStart: Scalars['DateTime'];
  noStatusStudents?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  status?: Maybe<LessonStatus>;
  teacher?: Maybe<LessonTeacherIndex>;
  totalStudents?: Maybe<Scalars['Int']>;
  venue?: Maybe<LessonVenueIndex>;
};

export type LessonIndexPage = {
  __typename?: 'LessonIndexPage';
  items: Array<LessonIndex>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type LessonPage = {
  __typename?: 'LessonPage';
  items: Array<Lesson>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/** The yearly lesson plan of the teachers for a course. */
export type LessonPlan = {
  __typename?: 'LessonPlan';
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'];
  /** References the academic year record which this lesson plan is applied to. */
  academicYear: LessonPlanAcademicYear;
  /** Reference to the classes applied to this lesson plan. */
  classesApplied: Array<LessonPlanClass>;
  /** Reference to the source lesson plan that this is duplicated from. */
  copiedFrom: ParentLessonPlan;
  /** References the course entity which this lesson plan is applied. */
  course: LessonPlanCourse;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The flag that marks if the lesson plan is archived or not. */
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /** The set of lesson flow to be used within the term. */
  lessonFlows: Array<LessonFlowV2>;
  /** The name to display for the lesson plan. */
  name: Scalars['String'];
  /** The number of lesson flows in the lesson plan. */
  noOfLessonFlows: Scalars['Int'];
};

/**
 * The object containing the necessary fields used in the lesson plan
 *   that is copied from the AcademicLevel entity.
 */
export type LessonPlanAcademicLevel = {
  __typename?: 'LessonPlanAcademicLevel';
  /**
   * The education stage copied from the AcademicLevel entity.
   *     This is used for filtering lesson plans to be displayed in FE.
   */
  educationStage: EducationStage;
  /** Reference to the AcademicLevel entity. */
  id: Scalars['ObjectId'];
  /** The name copied from the AcademicLevel entity. */
  name: Scalars['String'];
};

/**
 * The object containing the necessary fields used in the lesson plan
 *   that is copied from the AcademicYear entity.
 */
export type LessonPlanAcademicYear = {
  __typename?: 'LessonPlanAcademicYear';
  /** Reference to the AcademicYear entity. */
  id: Scalars['ObjectId'];
  /** The year copied from the AcademicYear entity. */
  year: Scalars['Int'];
};

/** Input for `lessonPlanAddLessonFlowHomework` mutation. */
export type LessonPlanAddLessonFlowHomeworkInput = {
  /** The type of homework to add to the lesson flow. */
  homeworkType: LessonFlowHomeworkType;
  /** The lesson flow to add the homework. */
  lessonFlowId: Scalars['ObjectId'];
  /** The lesson plan containing the lesson flow to add the homework. */
  lessonPlanId: Scalars['ObjectId'];
  /**
   * The payload for homework type `OTHER`.
   *     This field is required when the `homeworkType` is `OTHER`
   */
  other?: InputMaybe<LessonFlowAddOtherHomeworkInput>;
  /**
   * The payload for homework type `WORKSHEET`.
   *     This field is required when the `homeworkType` is `WORKSHEET`
   */
  worksheet?: InputMaybe<LessonFlowAddWorksheetHomeworkInput>;
};

/**
 * The object containing the necessary fields used in the lesson plan
 *   that is copied from the Class entity.
 */
export type LessonPlanClass = {
  __typename?: 'LessonPlanClass';
  /** The date this class was applied. */
  appliedDate: Scalars['DateTime'];
  /** Reference to the class entity. */
  id: Scalars['ObjectId'];
  /** The name copied from the Class entity. */
  name: Scalars['String'];
};

/**
 * The object containing the necessary fields used in the lesson plan
 *   that is copied from the Course entity.
 */
export type LessonPlanCourse = {
  __typename?: 'LessonPlanCourse';
  /** The academic levels copied from the Course entity. */
  academicLevels: Array<LessonPlanAcademicLevel>;
  /** Reference to the Course entity. */
  id: Scalars['ObjectId'];
  /** The link to the image of the course copied from the Course entity. */
  imageUrl?: Maybe<Scalars['String']>;
  /** The course name copied from the Course entity. */
  name: Scalars['String'];
  /**
   * The course type copied from the Course entity.
   *     This is used to determine if the lesson plan is `REGULAR` or `WORKSHOP`.
   */
  type: CourseType;
};

export type LessonPlanPage = {
  __typename?: 'LessonPlanPage';
  items: Array<LessonPlan>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/** Input for `lessonPlanRemoveLessonFlowHomework` mutation. */
export type LessonPlanRemoveLessonFlowHomeworkInput = {
  /** The homework to remove. */
  homeworkId: Scalars['ObjectId'];
  /** The lesson flow containing the homework to remove. */
  lessonFlowId: Scalars['ObjectId'];
  /** The lesson plan containing the lesson flow of the homework to remove. */
  lessonPlanId: Scalars['ObjectId'];
};

/** Input for `lessonPlanReorderLessonFlowSchedules` mutation. */
export type LessonPlanReorderLessonFlowSchedulesInput = {
  /** The lesson flow containing the schedules to reorder. */
  lessonFlowId: Scalars['ObjectId'];
  /** The lesson plan containing the lesson flow schedules to reorder. */
  lessonPlanId: Scalars['ObjectId'];
  /** The new schedule positions of the lesson flow. */
  schedulePositions: Array<LessonFlowSchedulePositionInput>;
};

/** Input for `LessonPlanSkipLessonFlowItem` mutation. */
export type LessonPlanSkipLessonFlowItemInput = {
  /** The homework item Id of the Lesson Flow Item to be updated. Required if scheduleId is not provided. */
  homeworkId?: InputMaybe<Scalars['ObjectId']>;
  /** The new `isSkipped` value of the lesson flow schedule/homework item. */
  isSkipped: Scalars['Boolean'];
  /** The lesson flow Id of the lesson flow item to update. */
  lessonFlowId: Scalars['ObjectId'];
  /** The lesson plan containing the lesson flow of the schedule or homework to update. */
  lessonPlanId: Scalars['ObjectId'];
  /** The schedule item Id of the Lesson Flow Item to be updated. Required if homeworkId is not provided. */
  scheduleId?: InputMaybe<Scalars['ObjectId']>;
};

/** Input for `lessonPlanUpdateLessonFlowHomework` mutation. */
export type LessonPlanUpdateLessonFlowHomeworkInput = {
  /** The homework to update. */
  homeworkId: Scalars['ObjectId'];
  /** The lesson flow containing the homework to update. */
  lessonFlowId: Scalars['ObjectId'];
  /** The lesson plan containing the lesson flow of the homework to update. */
  lessonPlanId: Scalars['ObjectId'];
  /**
   * The payload for homework type `OTHER`.
   *     This field is required when the homework to update is `OTHER`
   */
  other?: InputMaybe<LessonFlowHomeworkOtherInput>;
  /**
   * The payload for homework type `WORKSHEET`.
   *     This field is required when the homework to update is `WORKSHEET`
   */
  worksheet?: InputMaybe<LessonFlowHomeworkWorksheetInput>;
};

/** Input for `lessonPlanUpdateLessonFlowItemTrialFlag` mutation. */
export type LessonPlanUpdateLessonFlowItemTrialFlagInput = {
  /** The new `forTrial` value of the lesson flow item. */
  forTrial: Scalars['Boolean'];
  /** The lesson flow Id of the lesson flow item to update. */
  lessonFlowId: Scalars['ObjectId'];
  /** The lesson plan containing the lesson flow of the schedule or homework to update. */
  lessonPlanId: Scalars['ObjectId'];
  /**
   * The worksheet Id of the Lesson Flow Item to be updated.
   *     This could be the LessonFlowScheduleItem (worksheet or test) or the LessonFlowHomeworkItem (worksheet).
   */
  worksheetId: Scalars['ObjectId'];
};

/** Input for `LessonPlanWorksheets` query. */
export type LessonPlanWorksheetsInput = {
  /** Packing List Envelope we're getting the worksheets for. */
  packingListEnvelopeId: Scalars['ObjectId'];
  /** Packing List that contains the envelope we're getting the worksheets for. */
  packingListId: Scalars['ObjectId'];
  /** True if we're retrieving worksheets that are not part of the provided packing list envelope. */
  ungrouped?: InputMaybe<Scalars['Boolean']>;
};

/** Object for filtering the list of lesson plans. */
export type LessonPlansFilterInput = {
  /** Array of academic level Ids that the lesson plans should belong to. */
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Array of academic year Ids that the lesson plans should belong to. */
  academicYearIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** The reference to the course entity which this lesson plan is applied. */
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Array of courses that the lesson plans should belong to. */
  courseTypes?: InputMaybe<Array<CourseType>>;
  /** The name pattern to filter the lesson plan */
  name?: InputMaybe<Scalars['String']>;
  /** Filter condition if archived items should be returned */
  showArchived?: InputMaybe<Scalars['Boolean']>;
};

export type LessonSeat = {
  __typename?: 'LessonSeat';
  attendanceId: Scalars['ObjectId'];
  checkFirstLesson?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['DateTime']>;
  hasPaid?: Maybe<Scalars['Boolean']>;
  isSimulation?: Maybe<Scalars['Boolean']>;
  seatType?: Maybe<SeatType>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AttendanceStatus>;
  student?: Maybe<Student>;
  type?: Maybe<LessonCapacitySeatType>;
};

export enum LessonStatus {
  Created = 'Created',
  Incomplete = 'Incomplete',
  Void = 'Void'
}

export type LessonTeacherIndex = {
  __typename?: 'LessonTeacherIndex';
  _id: Scalars['ObjectId'];
  avatarUrl?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
};

export type LessonVenueIndex = {
  __typename?: 'LessonVenueIndex';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type LinkParentInput = {
  parentId1: Scalars['ObjectId'];
  parentId2: Scalars['ObjectId'];
};

/** Query input on fetching students award points details */
export type ListStudentAwardPointsInput = {
  /** Filter students by enrolled class ID */
  classId?: InputMaybe<Scalars['ObjectId']>;
  /** Filter students by linked gradable Exercise or Homework */
  gradableId: Scalars['ObjectId'];
  /** Filter students by name */
  search?: InputMaybe<Scalars['String']>;
};

export type Log = {
  __typename?: 'Log';
  _id: Scalars['ObjectId'];
  attachment?: Maybe<Scalars['String']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  log: Scalars['String'];
  type: LogType;
  userId: Scalars['ObjectId'];
};

export type LogPage = {
  __typename?: 'LogPage';
  items: Array<Log>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum LogType {
  Other = 'Other',
  PhoneCall = 'PhoneCall',
  WalkIn = 'WalkIn'
}

export enum LogicalOperator {
  And = 'And',
  Or = 'Or'
}

export type MailMerge = {
  __typename?: 'MailMerge';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  mergeList?: Maybe<Array<Scalars['Object']>>;
  mergeListHeaders?: Maybe<Array<Scalars['String']>>;
  sender?: Maybe<WhatsappAccount>;
  status: MailMergeStatus;
  template: Template;
};

export enum MailMergeStatus {
  Complete = 'Complete',
  Draft = 'Draft'
}

export type MakeUpCreditInput = {
  term1?: InputMaybe<Scalars['Int']>;
  term2?: InputMaybe<Scalars['Int']>;
  term3?: InputMaybe<Scalars['Int']>;
  term4?: InputMaybe<Scalars['Int']>;
  workshop?: InputMaybe<Scalars['Int']>;
};

export type MakeUpCreditOutput = {
  __typename?: 'MakeUpCreditOutput';
  term1?: Maybe<Scalars['Int']>;
  term2?: Maybe<Scalars['Int']>;
  term3?: Maybe<Scalars['Int']>;
  term4?: Maybe<Scalars['Int']>;
  workshop?: Maybe<Scalars['Int']>;
};

export type MakeUpCredits = {
  __typename?: 'MakeUpCredits';
  term1?: Maybe<Scalars['Int']>;
  term2?: Maybe<Scalars['Int']>;
  term3?: Maybe<Scalars['Int']>;
  term4?: Maybe<Scalars['Int']>;
  workshop?: Maybe<Scalars['Int']>;
};

export type MarkBreakDown = {
  __typename?: 'MarkBreakDown';
  ecq?: Maybe<Scalars['Float']>;
  mcq?: Maybe<Scalars['Float']>;
};

export type MarkBreakDownInput = {
  ecq?: InputMaybe<Scalars['Int']>;
  mcq?: InputMaybe<Scalars['Int']>;
};

/** Teaching Material information for student. */
export type Material = {
  __typename?: 'Material';
  _id: Scalars['ObjectId'];
  adHocMaterials?: Maybe<Scalars['String']>;
  address?: Maybe<UserAddress>;
  attendance?: Maybe<Attendance>;
  attendanceId?: Maybe<Scalars['ObjectId']>;
  attendances?: Maybe<Array<Attendance>>;
  courseId?: Maybe<Scalars['ObjectId']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  enrollmentEndAt?: Maybe<Scalars['DateTime']>;
  enrollmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  enrollmentStartAt?: Maybe<Scalars['DateTime']>;
  /** MaterialEnvelop. */
  envelope?: Maybe<MaterialEnvelope>;
  /** If user select materials and additional materials, will group these materials together. */
  handoverOnsiteMaterialId?: Maybe<Scalars['ObjectId']>;
  isAdHoc?: Maybe<Scalars['Boolean']>;
  isCollectFromReception?: Maybe<Scalars['Boolean']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  status?: Maybe<MaterialStatus>;
  student?: Maybe<Student>;
  studentId: Scalars['ObjectId'];
  /** Material Trial Envelope. */
  trialEnvelopes?: Maybe<Array<MaterialEnvelope>>;
};

/** Academic level index for material. */
export type MaterialAcademicLevelIndex = {
  __typename?: 'MaterialAcademicLevelIndex';
  _id: Scalars['ObjectId'];
  alias: Scalars['String'];
};

export type MaterialBookletTopic = {
  __typename?: 'MaterialBookletTopic';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type MaterialBookletTopicDo = {
  __typename?: 'MaterialBookletTopicDO';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
};

/** Class index for material. */
export type MaterialClassIndex = {
  __typename?: 'MaterialClassIndex';
  _id: Scalars['ObjectId'];
  shorthand?: Maybe<Scalars['String']>;
};

/** Course index for material. */
export type MaterialCourseIndex = {
  __typename?: 'MaterialCourseIndex';
  _id: Scalars['ObjectId'];
  nameShort: Scalars['String'];
};

/** Delivery index for material. */
export type MaterialDeliveryIndex = {
  __typename?: 'MaterialDeliveryIndex';
  _id: Scalars['ObjectId'];
  pickupEndAt: Scalars['DateTime'];
  pickupStartAt: Scalars['DateTime'];
  trackingNo?: Maybe<Scalars['String']>;
  type: DeliveryType;
};

/** Enrollment index for material. */
export type MaterialEnrollmentIndex = {
  __typename?: 'MaterialEnrollmentIndex';
  _id: Scalars['ObjectId'];
  classId: Scalars['ObjectId'];
  learningArrangement?: Maybe<LearningArrangementType>;
  type: EnrollmentType;
};

export type MaterialEnvelope = {
  __typename?: 'MaterialEnvelope';
  _id: Scalars['ObjectId'];
  academicWeeks: Array<PackingListAcademicWeek>;
  booklets: Array<MaterialEnvelopeBooklet>;
  title: Scalars['String'];
};

export type MaterialEnvelopeBooklet = {
  __typename?: 'MaterialEnvelopeBooklet';
  _id: Scalars['ObjectId'];
  serialNumber: Scalars['String'];
  title: Scalars['String'];
  topic: MaterialBookletTopic;
  worksheets: Array<MaterialEnvelopeBookletWorksheet>;
};

export type MaterialEnvelopeBookletDo = {
  __typename?: 'MaterialEnvelopeBookletDO';
  _id: Scalars['ObjectId'];
  serialNumber: Scalars['String'];
  title: Scalars['String'];
  topic: MaterialBookletTopicDo;
  worksheets: Array<MaterialEnvelopeBookletWorksheetDo>;
};

export type MaterialEnvelopeBookletWorksheet = {
  __typename?: 'MaterialEnvelopeBookletWorksheet';
  _id: Scalars['ObjectId'];
  serialNumber: Scalars['String'];
  title: Scalars['String'];
};

export type MaterialEnvelopeBookletWorksheetDo = {
  __typename?: 'MaterialEnvelopeBookletWorksheetDO';
  _id: Scalars['ObjectId'];
  serialNumber: Scalars['String'];
  title: Scalars['String'];
};

export type MaterialEnvelopeDo = {
  __typename?: 'MaterialEnvelopeDO';
  _id: Scalars['ObjectId'];
  academicWeeks: Array<PackingListAcademicWeek>;
  booklets: Array<MaterialEnvelopeBookletDo>;
  title: Scalars['String'];
};

/** Material filter input for Material list */
export type MaterialFilterInput = {
  /** To get materials that belong to these attendance Ids */
  attendanceIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  dateRange?: InputMaybe<DateTimeRangeInput>;
  enrollmentEndDate?: InputMaybe<Scalars['DateTime']>;
  enrollmentStartDate?: InputMaybe<Scalars['DateTime']>;
  enrollmentTypes?: InputMaybe<Array<EnrollmentType>>;
  envelopeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  hasAdditionalMaterial?: InputMaybe<Scalars['Boolean']>;
  hasUpcomingOnsiteGAPLesson?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['ObjectId']>>;
  isAdHoc?: InputMaybe<Scalars['Boolean']>;
  isNoStatus?: InputMaybe<Scalars['Boolean']>;
  learningArrangementTypes?: InputMaybe<Array<LearningArrangementType>>;
  lessonDateRange?: InputMaybe<DateTimeRangeInput>;
  name?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<MaterialStatus>>;
};

/** Input for `materialHandoverUpdate` mutation. */
export type MaterialHandoverUpdateInput = {
  _ids: Array<Scalars['ObjectId']>;
  /**
   * Flag for determining whether the materials were handed over already or not.
   *    true - means materials were already given, so the status becomes "Handed Onsite"
   *    false - means materials are not given, so the status becomes "Pending Handover"
   */
  areMaterialsGiven: Scalars['Boolean'];
};

/** Material mongo index. */
export type MaterialIndex = {
  __typename?: 'MaterialIndex';
  _id: Scalars['ObjectId'];
  academicLevel?: Maybe<MaterialAcademicLevelIndex>;
  adHocMaterials?: Maybe<Scalars['String']>;
  address?: Maybe<UserAddress>;
  attendanceId?: Maybe<Scalars['ObjectId']>;
  course?: Maybe<MaterialCourseIndex>;
  courseId?: Maybe<Scalars['ObjectId']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  delivery?: Maybe<MaterialDeliveryIndex>;
  enrollmentEndAt?: Maybe<Scalars['DateTime']>;
  enrollmentIds?: Maybe<Array<Scalars['ObjectId']>>;
  enrollmentStartAt?: Maybe<Scalars['DateTime']>;
  enrollments?: Maybe<Array<MaterialEnrollmentIndex>>;
  /** MaterialEnvelope. */
  envelope?: Maybe<MaterialEnvelopeDo>;
  handoverOnsiteMaterialId?: Maybe<Scalars['ObjectId']>;
  hasAdditionalMaterial?: Maybe<Scalars['Boolean']>;
  /** Workshop Has Upcoming Onsite GAP Lesson. */
  hasUpcomingOnsiteGAPLesson?: Maybe<Scalars['Boolean']>;
  isAdHoc?: Maybe<Scalars['Boolean']>;
  isAddLessonRemarks?: Maybe<Scalars['Boolean']>;
  isCollectFromReception?: Maybe<Scalars['Boolean']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifyUser?: Maybe<MaterialUserIndex>;
  lesson?: Maybe<MaterialLessonIndex>;
  lessonClass?: Maybe<MaterialClassIndex>;
  otherMaterials?: Maybe<Array<MaterialSubIndex>>;
  primaryParent?: Maybe<MaterialParentIndex>;
  primaryParentWhatsappAccount?: Maybe<MaterialWhatsappAccountIndex>;
  remarks?: Maybe<Scalars['String']>;
  secondaryParentWhatsappAccounts?: Maybe<Array<MaterialWhatsappAccountIndex>>;
  secondaryParents?: Maybe<Array<MaterialParentIndex>>;
  siblingMaterials?: Maybe<Array<MaterialSubIndex>>;
  status?: Maybe<MaterialStatus>;
  student?: Maybe<MaterialStudentIndex>;
  studentId: Scalars['ObjectId'];
  /** Material Trial Envelopes. */
  trialEnvelopes?: Maybe<Array<MaterialEnvelopeDo>>;
  /** Workshop Upcoming Onsite GAP Lesson LA. */
  upcomingOnsiteGAPLessonLA?: Maybe<Scalars['String']>;
};

/** Material index page type */
export type MaterialIndexPage = {
  __typename?: 'MaterialIndexPage';
  items: Array<MaterialIndex>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/** Lesson index for material. */
export type MaterialLessonIndex = {
  __typename?: 'MaterialLessonIndex';
  _id: Scalars['ObjectId'];
  lessonEnd: Scalars['DateTime'];
  lessonNumber: Scalars['Float'];
  lessonStart: Scalars['DateTime'];
};

/** Material page type */
export type MaterialPage = {
  __typename?: 'MaterialPage';
  items: Array<Material>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/** Parent index for material. */
export type MaterialParentIndex = {
  __typename?: 'MaterialParentIndex';
  _id: Scalars['ObjectId'];
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  mobile: Telephone;
  salutation?: Maybe<Scalars['String']>;
};

export enum MaterialStatus {
  AddressVerificationConfirmed = 'AddressVerificationConfirmed',
  AddressVerificationSent = 'AddressVerificationSent',
  Completed = 'Completed',
  Delivered = 'Delivered',
  Delivering = 'Delivering',
  DeliveryByOtherCouriers = 'DeliveryByOtherCouriers',
  Draft = 'Draft',
  Dropped = 'Dropped',
  Exception = 'Exception',
  HandOutOverdue = 'HandOutOverdue',
  HandedOnsite = 'HandedOnsite',
  MaterialsNoteSent = 'MaterialsNoteSent',
  MissedDelivery = 'MissedDelivery',
  Pending = 'Pending',
  PendingHandover = 'PendingHandover',
  ProcessingHub = 'ProcessingHub'
}

/** Student index for material. */
export type MaterialStudentIndex = {
  __typename?: 'MaterialStudentIndex';
  _id: Scalars['ObjectId'];
  address?: Maybe<UserAddress>;
  avatarUrl?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

/** Lesson index for material. */
export type MaterialSubIndex = {
  __typename?: 'MaterialSubIndex';
  _id: Scalars['ObjectId'];
  course?: Maybe<MaterialCourseIndex>;
  /** MaterialEnvelope. */
  envelope?: Maybe<MaterialEnvelopeDo>;
  student?: Maybe<MaterialStudentIndex>;
};

export type MaterialUserIndex = {
  __typename?: 'MaterialUserIndex';
  _id: Scalars['ObjectId'];
  fullName: Scalars['String'];
};

/** Whatsapp account index for material. */
export type MaterialWhatsappAccountIndex = {
  __typename?: 'MaterialWhatsappAccountIndex';
  _id: Scalars['ObjectId'];
  /** WhatsApp phone number. */
  number?: Maybe<Telephone>;
  parentId: Scalars['ObjectId'];
};

export type Merchandise = {
  __typename?: 'Merchandise';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  dateIssued?: Maybe<Scalars['DateTime']>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  distributionChannel?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  item: Scalars['String'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  status: MerchandiseStatus;
  student: Student;
};

export type MerchandisePage = {
  __typename?: 'MerchandisePage';
  items: Array<Merchandise>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum MerchandiseStatus {
  Issued = 'Issued',
  Pending = 'Pending'
}

export type MergeParentInput = {
  alias?: InputMaybe<MergeReservedType>;
  country?: InputMaybe<MergeReservedType>;
  email?: InputMaybe<MergeReservedType>;
  gateBuilding?: InputMaybe<MergeReservedType>;
  gender?: InputMaybe<MergeReservedType>;
  homePhone?: InputMaybe<MergeReservedType>;
  linkedWhatsappAccountId?: InputMaybe<MergeReservedType>;
  /** Parent to be merged */
  mergeParentId: Scalars['ObjectId'];
  officePhone?: InputMaybe<MergeReservedType>;
  postalCode?: InputMaybe<MergeReservedType>;
  /** Parent to be retained */
  retainParentId: Scalars['ObjectId'];
  salutation?: InputMaybe<MergeReservedType>;
  streetAddress?: InputMaybe<MergeReservedType>;
  unit?: InputMaybe<MergeReservedType>;
  whatsAppName?: InputMaybe<MergeReservedType>;
};

export enum MergeReservedType {
  Merge = 'Merge',
  Retain = 'Retain'
}

export type MergeStudentInput = {
  academicLevel?: InputMaybe<MergeReservedType>;
  alias?: InputMaybe<MergeReservedType>;
  birthDate?: InputMaybe<MergeReservedType>;
  country?: InputMaybe<MergeReservedType>;
  email?: InputMaybe<MergeReservedType>;
  gateBuilding?: InputMaybe<MergeReservedType>;
  gender?: InputMaybe<MergeReservedType>;
  homePhone?: InputMaybe<MergeReservedType>;
  /** Student to be merged */
  mergeStudentId: Scalars['ObjectId'];
  mobile?: InputMaybe<MergeReservedType>;
  postalCode?: InputMaybe<MergeReservedType>;
  primarySchool?: InputMaybe<MergeReservedType>;
  /** Student to be retained */
  retainStudentId: Scalars['ObjectId'];
  secondarySchool?: InputMaybe<MergeReservedType>;
  secondaryStream?: InputMaybe<MergeReservedType>;
  streetAddress?: InputMaybe<MergeReservedType>;
  unit?: InputMaybe<MergeReservedType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addInvoiceLineItem?: Maybe<Invoice>;
  /** Adds an item to the Lesson Flow's schedule. */
  addLessonFlowSchedule: AddLessonFlowScheduleResponse;
  /** Adds a lesson plan to a course. */
  addLessonPlan: LessonPlan;
  addReward?: Maybe<Reward>;
  /** Mutation to add reward points to student */
  addRewardPoints?: Maybe<Student>;
  addXeroAccount?: Maybe<OtherMappingAccount>;
  /** Update the material's status from Address Verification Sent to Address verification confirmed */
  addressVerificationConfirmed?: Maybe<Array<Material>>;
  adjustBalance?: Maybe<Balance>;
  applyDiscount?: Maybe<Invoice>;
  /** Applies the lesson flows of a lesson plan to the lessons of a class by id. */
  applyLessonPlanToClass: ApplyLessonPlanToClassResponse;
  approveRegistration?: Maybe<Registration>;
  archiveDiscount?: Maybe<Discount>;
  archiveHistoryReward?: Maybe<RewardHistory>;
  archiveReward?: Maybe<Reward>;
  archiveWhatsappAccount?: Maybe<WhatsappAccount>;
  /** Archives the worksheets. */
  archiveWorkSheets?: Maybe<Array<WorkSheet>>;
  arrangementEligibility?: Maybe<Scalars['Boolean']>;
  /** Mutation to add reward points to students by bulk */
  bulkAddRewardPoints?: Maybe<StudentBulkAwardsResult>;
  bulkCompileTemplate?: Maybe<Array<ReminderPreview>>;
  bulkCreateClass?: Maybe<Scalars['Boolean']>;
  bulkCreateDelivery?: Maybe<Array<Delivery>>;
  bulkCreateRegistration?: Maybe<Scalars['Boolean']>;
  /** Mutation to deduct reward points to students by bulk */
  bulkDeductRewardPoints?: Maybe<StudentBulkAwardsResult>;
  /** selected materials status will update to Pending Handout, handout lesson will be selected Academic Week. */
  bulkHandoverOnsite: Scalars['Boolean'];
  bulkSendReminder?: Maybe<Array<NotifyMissingDto>>;
  bulkUpdateDigitalClassVenue?: Maybe<Array<Class>>;
  bulkUpdateStudentAddressFromPrimaryParent?: Maybe<Array<Student>>;
  cancelArrangement?: Maybe<Arrangement>;
  cancelChangeAttendingClassForRegistration?: Maybe<Registration>;
  cancelChangeAttendingClassForTransfer?: Maybe<Arrangement>;
  cancelClassCreationFlow?: Maybe<Class>;
  cancelPromotion?: Maybe<Promotion>;
  cancelRegistration?: Maybe<Registration>;
  capacitySimulation?: Maybe<Scalars['Boolean']>;
  changeAcademicLevelArchive?: Maybe<AcademicLevel>;
  changeAcademicLevelVisibility?: Maybe<AcademicLevel>;
  changeAdminArchive?: Maybe<Admin>;
  changeAdminPassword?: Maybe<User>;
  changeAttendingClassForRegistration?: Maybe<Registration>;
  changeAttendingClassForTransfer?: Maybe<Arrangement>;
  changeBranchArchive?: Maybe<Branch>;
  changeClassVisibility?: Maybe<Class>;
  changeCourierPickupAddressArchive?: Maybe<CourierPickupAddress>;
  changeDepartmentArchive?: Maybe<Department>;
  /** Archive a remark. */
  changeRemarkArchive?: Maybe<Remark>;
  changeSchoolArchive?: Maybe<School>;
  changeSchoolVisibility?: Maybe<School>;
  changeUserArchive?: Maybe<User>;
  changeVenueArchive?: Maybe<Venue>;
  clearSimulation?: Maybe<Scalars['Boolean']>;
  compileTemplate?: Maybe<ReminderPreview>;
  completeClassCreationFlow?: Maybe<Class>;
  completeImportClasses?: Maybe<BulkCreateHistory>;
  completeImportRegistrations?: Maybe<BulkCreateHistory>;
  completeMailMerge?: Maybe<MailMerge>;
  completeTasks?: Maybe<Array<Task>>;
  connectXeroAccountForCourse?: Maybe<Course>;
  connectXeroAccountForOtherMapping?: Maybe<XeroIntegration>;
  copyTaskList?: Maybe<TaskList>;
  createAcademicLevel?: Maybe<AcademicLevel>;
  createAcademicYear: AcademicYear;
  createAdHocInvoice?: Maybe<Invoice>;
  /** Create a ad hoc material record. */
  createAdHocMaterial?: Maybe<Material>;
  createAdhocClass?: Maybe<Class>;
  createAdmin?: Maybe<Admin>;
  createArrangement?: Maybe<Arrangement>;
  createBillingForRegistration?: Maybe<Registration>;
  createBillingForTransfer?: Maybe<Arrangement>;
  createBranch?: Maybe<Branch>;
  createCampaign?: Maybe<Campaign>;
  /**
   * Create a collect for the current LessonFlowItem.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  createCollect?: Maybe<LessonFlowItem>;
  createCourierPickupAddress?: Maybe<CourierPickupAddress>;
  createCourseNotification?: Maybe<CourseNotification>;
  createDepartment?: Maybe<Department>;
  createDiscount?: Maybe<Discount>;
  createEligibilityFlow?: Maybe<EligibilityFlow>;
  createExercise?: Maybe<Exercise>;
  createFeedback?: Maybe<Feedback>;
  /** Mutation to create multiple feedback seen receipt records */
  createFeedbackSeenReceipts?: Maybe<Array<FeedbackSeenReceipt>>;
  createGradingTemplate: GradingTemplate;
  createGroupAssignment?: Maybe<GroupAssignment>;
  /** Create a new handout. */
  createHandout?: Maybe<Handout>;
  createHoliday?: Maybe<Holiday>;
  createHomework?: Maybe<Homework>;
  createInvoiceItemOption?: Maybe<InvoiceItemOption>;
  createLesson?: Maybe<Lesson>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  createLessonFlow?: Maybe<LessonFlow>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  createLessonFlowItem?: Maybe<LessonFlowItem>;
  /**
   * Mutation resolver that adds a lesson sequence to Lesson Flow Item.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  createLessonSequenceItem?: Maybe<LessonFlowItem>;
  createLog?: Maybe<Log>;
  createMailMerge?: Maybe<MailMerge>;
  createMerchandise?: Maybe<Merchandise>;
  createNote?: Maybe<Note>;
  createParent?: Maybe<Parent>;
  createPaymentRecord?: Maybe<Payment>;
  createPromotion?: Maybe<Promotion>;
  createReferenceData?: Maybe<ReferenceData>;
  createRefund?: Maybe<Refund>;
  createRegistration?: Maybe<Registration>;
  createRegularClass?: Maybe<Class>;
  createRegularCourse?: Maybe<Course>;
  /** Create a remark. All remarks require a `subjectId` and `type`. */
  createRemark?: Maybe<Remark>;
  createSchool?: Maybe<School>;
  createStudentReportFilter?: Maybe<StudentReportFilter>;
  createSystemUser?: Maybe<SystemUser>;
  createTask?: Maybe<Task>;
  createTaskChecklist?: Maybe<Task>;
  createTaskChecklistItem?: Maybe<Task>;
  createTaskList?: Maybe<TaskList>;
  createTeacher?: Maybe<Teacher>;
  createTemplate?: Maybe<Template>;
  createTopic?: Maybe<Topic>;
  createUserCustomiseColumn?: Maybe<UserCustomiseColumn>;
  createUserCustomiseColumns?: Maybe<Array<UserCustomiseColumn>>;
  createVenue?: Maybe<Venue>;
  createWaitingListsForMultipleClasses?: Maybe<Array<Registration>>;
  createWhatsappAccount?: Maybe<WhatsappAccount>;
  createWorkShopClass?: Maybe<Class>;
  createWorkShopCourse?: Maybe<Course>;
  /** Mutation to deduct reward points to student */
  deductRewardPoints?: Maybe<Student>;
  deleteAcademicLevel?: Maybe<AcademicLevel>;
  deleteAcademicYear: AcademicYear;
  /** Delete a ad hoc material record. */
  deleteAdHocMaterial?: Maybe<Material>;
  deleteAdmin?: Maybe<Admin>;
  deleteBranch?: Maybe<Branch>;
  deleteCampaign?: Maybe<Campaign>;
  /**
   * Delete a collect for the current LessonFlowItem.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  deleteCollect?: Maybe<LessonFlowItem>;
  deleteCourseNotification?: Maybe<CourseNotification>;
  deleteDelivery?: Maybe<Delivery>;
  deleteDepartment?: Maybe<Centre>;
  deleteGroupAssignment?: Maybe<GroupAssignment>;
  /** Delete a handout by id. */
  deleteHandout?: Maybe<Handout>;
  deleteHoliday?: Maybe<Holiday>;
  deleteInvoiceItemOption?: Maybe<InvoiceItemOption>;
  deleteLesson?: Maybe<Lesson>;
  /**
   * A Mutation to delete a lessonFlowItem and it's exercises, and homeworks.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  deleteLessonFlowItem?: Maybe<LessonFlowItem>;
  /**
   * Mutation resolver that removes a lesson sequence from Lesson Flow Item.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  deleteLessonSequenceItem?: Maybe<LessonFlowItem>;
  deleteLessons?: Maybe<Array<Lesson>>;
  deleteMerchandise?: Maybe<Merchandise>;
  deleteParent?: Maybe<Parent>;
  deletePromotion?: Maybe<Promotion>;
  deleteReferenceData?: Maybe<ReferenceData>;
  /** Delete a remark. */
  deleteRemark?: Maybe<Remark>;
  deleteSchool?: Maybe<School>;
  deleteStudent?: Maybe<Student>;
  deleteStudentReportFilter?: Maybe<StudentReportFilter>;
  deleteSubmission?: Maybe<Submission>;
  deleteTaskChecklist?: Maybe<Task>;
  deleteTaskChecklistItem?: Maybe<Task>;
  deleteTaskList?: Maybe<TaskList>;
  deleteTeacher?: Maybe<Teacher>;
  deleteTerm: Term;
  deleteTopic?: Maybe<Topic>;
  deleteUser?: Maybe<User>;
  deleteUserCustomiseColumn?: Maybe<UserCustomiseColumn>;
  deleteUserCustomiseColumns?: Maybe<Array<UserCustomiseColumn>>;
  deleteVenue?: Maybe<Venue>;
  disconnectXeroAccount?: Maybe<XeroIntegration>;
  downloadMailMerge?: Maybe<Csv>;
  exportFailedImportClasses?: Maybe<Csv>;
  exportFailedImportRegistrations?: Maybe<Csv>;
  extendsArrangementTime?: Maybe<Arrangement>;
  extendsRegistrationTime?: Maybe<Registration>;
  generateNextInvoice?: Maybe<Invoice>;
  incrementStep?: Maybe<Arrangement>;
  /** Adds a new homework item to a lesson flow of a lesson plan. */
  lessonPlanAddLessonFlowHomework: LessonPlan;
  /** Removes a homework item from a lesson flow of a lesson plan. */
  lessonPlanRemoveLessonFlowHomework: LessonPlan;
  /** Reorders the schedules of a lesson flow of a lesson plan. */
  lessonPlanReorderLessonFlowSchedules: LessonPlan;
  /** Updates a lesson flow schedule/homework item's `isSkipped` flag. Can only be updated if the lesson flow schedule item is a Schedule (worksheet or test) or Homework (worksheet) and related packing list envelope is 'Packed for Delivery' or 'Printed'. */
  lessonPlanSkipLessonFlowItem: LessonPlan;
  /** Updates a homework item of a lesson flow of a lesson plan. */
  lessonPlanUpdateLessonFlowHomework: LessonPlan;
  /** Updates a lesson flow item's trial flag. Schedule (worksheet or test) and Homework (worksheet). */
  lessonPlanUpdateLessonFlowItemTrialFlags: LessonPlan;
  linkParent?: Maybe<Relationship>;
  /**
   * When a material has been handed out,
   *     a teacher can mark the material on the attendance page as `Handed Onsite`.
   */
  materialHandoverUpdate?: Maybe<Array<Material>>;
  mergeParent?: Maybe<Parent>;
  mergeStudent?: Maybe<Student>;
  onHoldInvoice?: Maybe<Invoice>;
  /** Adds a booklet to an existing packing list envelope */
  packingListAddBooklets: PackingList;
  /** Add envelopes to an existing packing list record. */
  packingListAddEnvelopes: PackingList;
  /**
   * Adds a set of worksheets to the trial materials of a packing list.
   *     Duplicate worksheets will not be added to the trial materials.
   * @deprecated No need to expose a resolver for this since adding of
   *     trial worksheets is handled by services: LessonPlan.syncTrialWorksheets,
   *     PackingList.createTrialWorksheets, and PackingList.removePackingListWorksheets.
   */
  packingListAddTrialWorksheets: PackingList;
  /**
   * Adds a set of worksheets to a booklet of a packing list.
   *     Duplicate worksheets will not be added to the booklet.
   */
  packingListAddWorksheets: PackingList;
  /** Changes the status of a packing list envelope. */
  packingListChangeEnvelopeStatus: PackingList;
  /** Creates a new packing list configuration. */
  packingListConfigurationCreate: PackingListConfiguration;
  /** Updates the envelopes of a packing list configuration. */
  packingListConfigurationUpdateEnvelopes: PackingListConfiguration;
  /** Creates a packing list record. */
  packingListCreate: PackingList;
  /** Moves a worksheet between booklets within the same envelope. */
  packingListEnvelopeMoveWorksheetToBooklet: PackingList;
  /** Reduces the "Available" units of a packing list envelope in a packing list. */
  packingListEnvelopeReduceAvailableUnits: PackingList;
  /** Reduces the "Available" units of a packing list partial envelope in a packing list. */
  packingListPartialEnvelopeReduceAvailableUnits: PackingList;
  /** Remove booklets from a packing list envelope. */
  packingListRemoveBooklets: PackingList;
  /**
   * Remove envelopes from a packing list.
   *     ll of the child documents will also be removed.
   */
  packingListRemoveEnvelopes: PackingList;
  /**
   * Removes a set of worksheets from the trial materials of a packing list.
   * @deprecated No need to expose a resolver for this since adding of
   *     trial worksheets is handled by services: LessonPlan.syncTrialWorksheets,
   *     PackingList.createTrialWorksheets, and PackingList.removePackingListWorksheets.
   */
  packingListRemoveTrialWorksheets: PackingList;
  /** Removes a worksheet from a booklet of a packing list envelope. */
  packingListRemoveWorksheets: PackingList;
  /**
   * Reorders the booklets of an envelope in a packing list based the given
   *    * ordinal position
   */
  packingListReorderBooklets: PackingList;
  /**
   * Reorders the worksheets of an envelope in a packing list based
   *     on a source worksheet and destination worksheet.
   */
  packingListReorderWorksheets: PackingList;
  /** Reduces the "Available" units of a packing list trial envelope in a packing list. */
  packingListTrialEnvelopeReduceAvailableUnits: PackingList;
  /** Updates the "Available" units of an envelope or partial envelope in a packing list. */
  packingListUpdatePackedUnits: PackingList;
  /** Updates the `status` of multiple trial worksheets belonging to an academic week. */
  packingListUpdateTrialWorksheetAcademicWeekStatus: PackingList;
  /**
   * Updates the version name of the packing list when provided and
   *     updates the academic weeks for each envelope when the academic year
   *     of the version changes.
   */
  packingListUpdateVersion: PackingList;
  pauseWhatsAppMessage?: Maybe<WhatsappMessage>;
  pauseWhatsappAccount?: Maybe<WhatsappAccount>;
  placeOrderDelivery: Delivery;
  prioritizeRegistration?: Maybe<Registration>;
  processArrangement?: Maybe<Arrangement>;
  processRegistration?: Maybe<Registration>;
  queueNext?: Maybe<WhatsappMessage>;
  readSystemNotification?: Maybe<SystemNotification>;
  recallCampaign?: Maybe<Campaign>;
  reconnectWhatsappAccount?: Maybe<WhatsappAccount>;
  recordExamScore?: Maybe<ExamScore>;
  recoverWhatsappAccount?: Maybe<WhatsappAccount>;
  refreshXeroToken?: Maybe<Array<XeroIntegration>>;
  regenerateInvoice?: Maybe<Invoice>;
  regenerateRegistration?: Maybe<Registration>;
  registerStudent?: Maybe<Student>;
  registrationIncrementStep?: Maybe<Registration>;
  /** Removes a schedule item from a lesson flow of a lesson plan. */
  removeLessonFlowSchedule: RemoveLessonFlowScheduleResponse;
  /** Removes the lesson flows of the lesson plan from the lessons of a class by id. */
  removeLessonPlanFromClass: RemoveLessonPlanFromClassResponse;
  removeLineItem?: Maybe<Invoice>;
  removeLineItemDiscount?: Maybe<Invoice>;
  reopenTask?: Maybe<Task>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  reorderLessonFlowItems?: Maybe<Array<LessonFlowItem>>;
  replyFeedback?: Maybe<Feedback>;
  requestChangeSystemUserPassword?: Maybe<Scalars['Boolean']>;
  requeueWhatsAppMessage?: Maybe<WhatsappMessage>;
  resendReminder?: Maybe<Reminder>;
  resetDefault?: Maybe<Array<UserCustomiseColumn>>;
  /** Reset the status of the default material. */
  resetMaterialStatus?: Maybe<Material>;
  restoreDiscount?: Maybe<Discount>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  restoreLessonFlowItemVersion?: Maybe<LessonFlowItem>;
  restoreReward?: Maybe<Reward>;
  resumeWhatsAppMessage?: Maybe<WhatsappMessage>;
  resumeWhatsappAccount?: Maybe<WhatsappAccount>;
  retryUploadInvoice?: Maybe<Invoice>;
  retryWhatsAppMessage?: Maybe<WhatsappMessage>;
  retryXeroPayment?: Maybe<Payment>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  saveAsNewLessonFlow?: Maybe<LessonFlow>;
  saveSubmissions?: Maybe<Array<Submission>>;
  /** @deprecated No usage last 90 days based on Apollo graphql metrics. */
  saveTopics?: Maybe<Array<Topic>>;
  sendReminder?: Maybe<Array<Reminder>>;
  sendTestReminder?: Maybe<Reminder>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  setDefaultLessonFlow?: Maybe<Course>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  setLessonFlowItemExceptions?: Maybe<LessonFlowItem>;
  testSubmissionCreate: TestSubmission;
  /** Make a test submission to `Draft`. */
  testSubmissionMakeDraft: TestSubmission;
  /** Saves the marked resources of a test submission and changes the status to `Returned`. */
  testSubmissionSaveMarkedResource: TestSubmission;
  /** Adds the test scores to the submission and sets its status to `Marked`. */
  testSubmissionSaveScores: TestSubmission;
  /** Updates a test submission. */
  testSubmissionUpdate: TestSubmission;
  toggleFeature?: Maybe<FeatureAccess>;
  toggleFeatures?: Maybe<Array<FeatureAccess>>;
  /**
   * Adds a course to a topic.
   *     This would also enable this course to be associated with the worksheets of the topic.
   */
  topicAddCourse?: Maybe<Topic>;
  /**
   * Removes a course from a topic.
   *     This would also dissociate the worksheets of the topic from the course.
   */
  topicRemoveCourse?: Maybe<Topic>;
  /** Unarchives the worksheets. */
  unarchiveWorksheets?: Maybe<Array<WorkSheet>>;
  unlinkParent?: Maybe<Relationship>;
  updateAcademicLevel?: Maybe<AcademicLevel>;
  updateAcademicYear: AcademicYear;
  updateAdmin?: Maybe<Admin>;
  updateArchivable?: Maybe<Template>;
  updateAttendanceLessonMode?: Maybe<Attendance>;
  /** Updates one more attendance record. */
  updateAttendances?: Maybe<Array<Attendance>>;
  updateBranch?: Maybe<Branch>;
  updateCampaign?: Maybe<Campaign>;
  updateClass?: Maybe<Class>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  updateClassLessonFlow?: Maybe<Class>;
  /**
   * Update a collect for the current LessonFlowItem.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  updateCollect?: Maybe<LessonFlowItem>;
  updateCourierPickupAddress?: Maybe<CourierPickupAddress>;
  updateCourse?: Maybe<Course>;
  updateDelivery?: Maybe<Delivery>;
  updateDepartment?: Maybe<Department>;
  updateDiscount?: Maybe<Discount>;
  updateEligibilityFlow?: Maybe<EligibilityFlow>;
  /** Update enrollment end recurring. */
  updateEnrollmentEndRecurring?: Maybe<Enrollment>;
  updateEnrollmentFollowUpStatus?: Maybe<Enrollment>;
  updateEnrollmentGroup?: Maybe<Enrollment>;
  updateEnrollmentPreferredLearningArrangement?: Maybe<Enrollment>;
  /** Mutation that upddates an enrollment's suitability information */
  updateEnrollmentSuitability?: Maybe<Enrollment>;
  /** Updates multiple enrollment records. */
  updateEnrollments?: Maybe<Array<Enrollment>>;
  updateEnrollmentsPreferredLearningArrangement?: Maybe<Array<Enrollment>>;
  updateExamScore?: Maybe<ExamScore>;
  updateExercise?: Maybe<Exercise>;
  updateFeedback?: Maybe<Feedback>;
  updateGroupAssignment?: Maybe<GroupAssignment>;
  /** Update a handout by id. */
  updateHandout?: Maybe<Handout>;
  updateHoliday?: Maybe<Holiday>;
  updateHomework?: Maybe<Homework>;
  updateIntegrationClientInfo?: Maybe<Integration>;
  updateInvoiceDueDate?: Maybe<Invoice>;
  updateInvoiceItem?: Maybe<Invoice>;
  updateInvoiceItemOption?: Maybe<InvoiceItemOption>;
  updateLesson?: Maybe<Lesson>;
  /** @deprecated This has been moved to AttendanceResolver.updateAttendances. */
  updateLessonAttendance?: Maybe<Lesson>;
  /**
   * Updates lesson flow record with given classIds and lessonFlowItemIds.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  updateLessonFlow?: Maybe<LessonFlow>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  updateLessonFlowArchive?: Maybe<LessonFlow>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  updateLessonFlowItem?: Maybe<LessonFlowItem>;
  /** Updates an existing lesson flow schedule item in a lesson flow of a lesson plan. */
  updateLessonFlowSchedule: UpdateLessonFlowScheduleResponse;
  /**
   * Mutation resolver that updates a lesson sequence from Lesson Flow Item.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  updateLessonSequenceItem?: Maybe<LessonFlowItem>;
  updateLog?: Maybe<Log>;
  updateMailMerge?: Maybe<MailMerge>;
  /** Update the status of the material. */
  updateMaterialDeliveryOption?: Maybe<Material>;
  updateMerchandise?: Maybe<Merchandise>;
  updateNotificationItem?: Maybe<NotificationSetting>;
  updateParent?: Maybe<Parent>;
  /** Resolver to update existing payment record in an invoice */
  updatePaymentRecord?: Maybe<Payment>;
  updatePromotion?: Maybe<Promotion>;
  updateReferenceData?: Maybe<ReferenceData>;
  updateRegistration?: Maybe<Registration>;
  updateRelationName?: Maybe<Scalars['String']>;
  /** Update a remark. Only the `content` and `isPinned` props may be updated. */
  updateRemark?: Maybe<Remark>;
  updateReward?: Maybe<Reward>;
  updateRewardsHistory?: Maybe<RewardHistory>;
  updateSchool?: Maybe<School>;
  updateStudent?: Maybe<Student>;
  updateStudentRemarks?: Maybe<Student>;
  updateStudentReportFilter?: Maybe<StudentReportFilter>;
  updateSystemUser?: Maybe<SystemUser>;
  updateTask?: Maybe<Task>;
  updateTaskChecklist?: Maybe<Task>;
  updateTaskChecklistItem?: Maybe<Task>;
  updateTaskDueDate?: Maybe<Task>;
  updateTaskList?: Maybe<TaskList>;
  updateTeacher?: Maybe<Teacher>;
  updateTemplate?: Maybe<Template>;
  updateTopic?: Maybe<Topic>;
  updateUserCustomiseColumn?: Maybe<UserCustomiseColumn>;
  updateUserCustomiseColumns?: Maybe<Array<UserCustomiseColumn>>;
  updateUserPermission?: Maybe<User>;
  updateVenue?: Maybe<Venue>;
  updateWhatsappAccount?: Maybe<WhatsappAccount>;
  updateWorkSheet?: Maybe<WorkSheet>;
  uploadBase64File?: Maybe<UploadResult>;
  uploadFile?: Maybe<UploadResult>;
  uploadMailMergeMergeList?: Maybe<MailMerge>;
  upsertUserCustomiseColumn?: Maybe<UserCustomiseColumn>;
  upsertUserCustomiseColumns?: Maybe<Array<UserCustomiseColumn>>;
  useRewardPoints?: Maybe<Student>;
  validateIntegrationAccessToken?: Maybe<Scalars['Boolean']>;
  validateWhatsappNumber?: Maybe<Scalars['Boolean']>;
  voidClass?: Maybe<Class>;
  voidCourse?: Maybe<Course>;
  voidInvoice?: Maybe<Invoice>;
  voidParent?: Maybe<Parent>;
  voidPaymentRecord?: Maybe<Payment>;
  voidRefund?: Maybe<Refund>;
  voidStudent?: Maybe<Student>;
  voidTask?: Maybe<Task>;
  whatsappLogin?: Maybe<QrCode>;
  whatsappLogout?: Maybe<WhatsappAccount>;
  /** Adds a course to a worksheet. */
  worksheetAddCourse?: Maybe<WorkSheet>;
  /** Creates a worksheet on a course. */
  worksheetCreate: WorkSheet;
  /** Removes a course to a worksheet. */
  worksheetRemoveCourse?: Maybe<WorkSheet>;
  worksheetsCreate: Array<WorkSheet>;
  worksheetsParseCsv: WorksheetsParseCsvResponse;
  xeroApiCallback?: Maybe<XeroIntegration>;
};


export type MutationAddInvoiceLineItemArgs = {
  input: AddInvoiceLineItemCommand;
};


export type MutationAddLessonFlowScheduleArgs = {
  input: AddLessonFlowScheduleInput;
};


export type MutationAddLessonPlanArgs = {
  input: AddLessonPlanInput;
};


export type MutationAddRewardArgs = {
  input: AddRewardCatalogueInput;
};


export type MutationAddRewardPointsArgs = {
  input: AddRewardPointsInput;
};


export type MutationAddXeroAccountArgs = {
  input: AddXeroAccountInput;
};


export type MutationAddressVerificationConfirmedArgs = {
  ids: Array<Scalars['ObjectId']>;
};


export type MutationAdjustBalanceArgs = {
  input: AdjustBalanceInput;
};


export type MutationApplyDiscountArgs = {
  input: ApplyInvoiceDiscountCommand;
};


export type MutationApplyLessonPlanToClassArgs = {
  input: ApplyLessonPlanToClassInput;
};


export type MutationApproveRegistrationArgs = {
  input: ApproveRegistrationInput;
};


export type MutationArchiveDiscountArgs = {
  id: Scalars['ObjectId'];
};


export type MutationArchiveHistoryRewardArgs = {
  id: Scalars['ObjectId'];
};


export type MutationArchiveRewardArgs = {
  id: Scalars['ObjectId'];
};


export type MutationArchiveWhatsappAccountArgs = {
  id: Scalars['ObjectId'];
};


export type MutationArchiveWorkSheetsArgs = {
  input: ArchiveWorkSheetsInput;
};


export type MutationArrangementEligibilityArgs = {
  input: CreateArrangementInput;
};


export type MutationBulkAddRewardPointsArgs = {
  input: BulkAddRewardPointsInput;
};


export type MutationBulkCompileTemplateArgs = {
  input: BulkSendReminderInput;
};


export type MutationBulkCreateClassArgs = {
  input: BulkCreateClassCommand;
};


export type MutationBulkCreateDeliveryArgs = {
  input: BulkCreateDeliveryInput;
};


export type MutationBulkCreateRegistrationArgs = {
  input: BulkCreateRegistrationCommand;
};


export type MutationBulkDeductRewardPointsArgs = {
  input: BulkDeductRewardPointsInput;
};


export type MutationBulkHandoverOnsiteArgs = {
  input: BulkHandoverOnsiteCommand;
};


export type MutationBulkSendReminderArgs = {
  input: BulkSendReminderInput;
};


export type MutationBulkUpdateDigitalClassVenueArgs = {
  input: Array<UpdateClassCommand>;
};


export type MutationBulkUpdateStudentAddressFromPrimaryParentArgs = {
  ids: Array<Scalars['ObjectId']>;
};


export type MutationCancelArrangementArgs = {
  id: Scalars['ObjectId'];
};


export type MutationCancelChangeAttendingClassForRegistrationArgs = {
  input: ChangeAttendingClassForRegistrationInput;
};


export type MutationCancelChangeAttendingClassForTransferArgs = {
  input: ChangeAttendingClassForTransferInput;
};


export type MutationCancelClassCreationFlowArgs = {
  input: IdInput;
};


export type MutationCancelPromotionArgs = {
  id: Scalars['ObjectId'];
};


export type MutationCancelRegistrationArgs = {
  id: Scalars['ObjectId'];
  isCancelOtherRegistration?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCapacitySimulationArgs = {
  input: SimulationCapacityInput;
};


export type MutationChangeAcademicLevelArchiveArgs = {
  input: ChangeAcademicLevelArchive;
};


export type MutationChangeAcademicLevelVisibilityArgs = {
  input: ChangeAcademicLevelVisibility;
};


export type MutationChangeAdminArchiveArgs = {
  input: ChangeAdminArchiveInput;
};


export type MutationChangeAdminPasswordArgs = {
  id: Scalars['ObjectId'];
};


export type MutationChangeAttendingClassForRegistrationArgs = {
  input: ChangeAttendingClassForRegistrationInput;
};


export type MutationChangeAttendingClassForTransferArgs = {
  input: ChangeAttendingClassForTransferInput;
};


export type MutationChangeBranchArchiveArgs = {
  input: ChangeBranchArchiveInput;
};


export type MutationChangeClassVisibilityArgs = {
  input: ChangeClassVisibilityInput;
};


export type MutationChangeCourierPickupAddressArchiveArgs = {
  input: ChangeCourierPickupAddressArchiveInput;
};


export type MutationChangeDepartmentArchiveArgs = {
  input: ChangeDepartmentArchiveInput;
};


export type MutationChangeRemarkArchiveArgs = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};


export type MutationChangeSchoolArchiveArgs = {
  input: ChangeSchoolArchiveInput;
};


export type MutationChangeSchoolVisibilityArgs = {
  input: ChangeSchoolVisibilityInput;
};


export type MutationChangeUserArchiveArgs = {
  input: ChangeUserArchiveInput;
};


export type MutationChangeVenueArchiveArgs = {
  input: ChangeVenueArchiveInput;
};


export type MutationClearSimulationArgs = {
  input: IdInput;
};


export type MutationCompileTemplateArgs = {
  input: SendReminderInput;
};


export type MutationCompleteClassCreationFlowArgs = {
  input: IdInput;
};


export type MutationCompleteImportClassesArgs = {
  id: Scalars['ObjectId'];
};


export type MutationCompleteImportRegistrationsArgs = {
  id: Scalars['ObjectId'];
};


export type MutationCompleteMailMergeArgs = {
  input: CompleteMailMergeInput;
};


export type MutationCompleteTasksArgs = {
  ids: Array<Scalars['ObjectId']>;
};


export type MutationConnectXeroAccountForCourseArgs = {
  input: ConnectXeroAccountForCourseInput;
};


export type MutationConnectXeroAccountForOtherMappingArgs = {
  input: ConnectXeroAccountInput;
};


export type MutationCopyTaskListArgs = {
  input: CopyTaskListInput;
};


export type MutationCreateAcademicLevelArgs = {
  input: CreateAcademicLevelInput;
};


export type MutationCreateAcademicYearArgs = {
  input: CreateAcademicYearInput;
};


export type MutationCreateAdHocInvoiceArgs = {
  input: CreateAdHocInvoiceCommand;
};


export type MutationCreateAdHocMaterialArgs = {
  input: CreateAdHocMaterialInput;
};


export type MutationCreateAdhocClassArgs = {
  input: CreateAdhocClassCommand;
};


export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};


export type MutationCreateArrangementArgs = {
  input: CreateArrangementInput;
};


export type MutationCreateBillingForRegistrationArgs = {
  currentStep: Scalars['Int'];
  id: Scalars['ObjectId'];
};


export type MutationCreateBillingForTransferArgs = {
  id: Scalars['ObjectId'];
};


export type MutationCreateBranchArgs = {
  input: CreateBranchInput;
};


export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};


export type MutationCreateCollectArgs = {
  input: CreateCollectInput;
};


export type MutationCreateCourierPickupAddressArgs = {
  input: CreateCourierPickupAddressInput;
};


export type MutationCreateCourseNotificationArgs = {
  input: CreateCourseNotificationInput;
};


export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
};


export type MutationCreateDiscountArgs = {
  input: CreateDiscountInput;
};


export type MutationCreateEligibilityFlowArgs = {
  input: CreateEligibilityFlowInput;
};


export type MutationCreateExerciseArgs = {
  input: CreateExerciseInput;
};


export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};


export type MutationCreateFeedbackSeenReceiptsArgs = {
  inputs: CreateFeedbackSeenReceiptInputs;
};


export type MutationCreateGradingTemplateArgs = {
  input: CreateGradingTemplateInput;
};


export type MutationCreateGroupAssignmentArgs = {
  input: CreateGroupAssignmentInput;
};


export type MutationCreateHandoutArgs = {
  input: CreateHandoutInput;
};


export type MutationCreateHolidayArgs = {
  input: CreateHolidayInput;
};


export type MutationCreateHomeworkArgs = {
  input: CreateHomeworkInput;
};


export type MutationCreateInvoiceItemOptionArgs = {
  input: CreateInvoiceItemOptionInput;
};


export type MutationCreateLessonArgs = {
  input: CreateLessonInput;
};


export type MutationCreateLessonFlowArgs = {
  input: CreateLessonFlowInput;
};


export type MutationCreateLessonFlowItemArgs = {
  input: CreateLessonFlowItemInput;
};


export type MutationCreateLessonSequenceItemArgs = {
  input: CreateLessonSequenceInput;
};


export type MutationCreateLogArgs = {
  input: CreateLogInput;
};


export type MutationCreateMailMergeArgs = {
  input: CreateMailMergeInput;
};


export type MutationCreateMerchandiseArgs = {
  input: CreateMerchandiseInput;
};


export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


export type MutationCreateParentArgs = {
  input: CreateParentInput;
};


export type MutationCreatePaymentRecordArgs = {
  input: CreatePaymentRecordInput;
};


export type MutationCreatePromotionArgs = {
  input: CreatePromotionInput;
};


export type MutationCreateReferenceDataArgs = {
  input: CreateReferenceDataInput;
};


export type MutationCreateRefundArgs = {
  input: CreateRefundInput;
};


export type MutationCreateRegistrationArgs = {
  input: CreateRegistrationInput;
};


export type MutationCreateRegularClassArgs = {
  input: CreateRegularClassCommand;
};


export type MutationCreateRegularCourseArgs = {
  input: CreateRegularCourseInput;
};


export type MutationCreateRemarkArgs = {
  input: CreateRemarkInput;
};


export type MutationCreateSchoolArgs = {
  input: CreateSchoolInput;
};


export type MutationCreateStudentReportFilterArgs = {
  input: StudentReportFilterInput;
};


export type MutationCreateSystemUserArgs = {
  command: CreateSystemUserCommand;
};


export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};


export type MutationCreateTaskChecklistArgs = {
  input: CreateTaskChecklistInput;
};


export type MutationCreateTaskChecklistItemArgs = {
  input: CreateTaskChecklistItemInput;
};


export type MutationCreateTaskListArgs = {
  input: CreateTaskListInput;
};


export type MutationCreateTeacherArgs = {
  input: CreateTeacherInput;
};


export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput;
};


export type MutationCreateTopicArgs = {
  input: CreateTopicInput;
};


export type MutationCreateUserCustomiseColumnArgs = {
  input: CreateUserCustomiseColumnInput;
};


export type MutationCreateUserCustomiseColumnsArgs = {
  inputs: Array<CreateUserCustomiseColumnInput>;
};


export type MutationCreateVenueArgs = {
  input: CreateVenueInput;
};


export type MutationCreateWaitingListsForMultipleClassesArgs = {
  input: CreateWaitingListsForStudentInput;
};


export type MutationCreateWhatsappAccountArgs = {
  input: CreateWhatsappAccountInput;
};


export type MutationCreateWorkShopClassArgs = {
  input: CreateWorkShopClassCommand;
};


export type MutationCreateWorkShopCourseArgs = {
  input: CreateWorkShopCourseInput;
};


export type MutationDeductRewardPointsArgs = {
  input: DeductRewardPointsInput;
};


export type MutationDeleteAcademicLevelArgs = {
  input: IdInput;
};


export type MutationDeleteAcademicYearArgs = {
  input: IdInput;
};


export type MutationDeleteAdHocMaterialArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteAdminArgs = {
  input: IdInput;
};


export type MutationDeleteBranchArgs = {
  input: IdInput;
};


export type MutationDeleteCampaignArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteCollectArgs = {
  input: DeleteCollectInput;
};


export type MutationDeleteCourseNotificationArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteDeliveryArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteDepartmentArgs = {
  input: IdInput;
};


export type MutationDeleteGroupAssignmentArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteHandoutArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteHolidayArgs = {
  input: IdInput;
};


export type MutationDeleteInvoiceItemOptionArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteLessonArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteLessonFlowItemArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteLessonSequenceItemArgs = {
  input: DeleteLessonSequenceInput;
};


export type MutationDeleteLessonsArgs = {
  ids: Array<Scalars['ObjectId']>;
};


export type MutationDeleteMerchandiseArgs = {
  input: IdInput;
};


export type MutationDeleteParentArgs = {
  input: IdInput;
};


export type MutationDeletePromotionArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteReferenceDataArgs = {
  input: IdInput;
};


export type MutationDeleteRemarkArgs = {
  input: IdInput;
};


export type MutationDeleteSchoolArgs = {
  input: IdInput;
};


export type MutationDeleteStudentArgs = {
  input: IdInput;
};


export type MutationDeleteStudentReportFilterArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteSubmissionArgs = {
  input: DeleteSubmissionInput;
};


export type MutationDeleteTaskChecklistArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteTaskChecklistItemArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteTaskListArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteTeacherArgs = {
  input: IdInput;
};


export type MutationDeleteTermArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteTopicArgs = {
  input: DeleteTopicInput;
};


export type MutationDeleteUserArgs = {
  input: IdInput;
};


export type MutationDeleteUserCustomiseColumnArgs = {
  input: DeleteUserCustomiseColumnInput;
};


export type MutationDeleteUserCustomiseColumnsArgs = {
  inputs: Array<DeleteUserCustomiseColumnInput>;
};


export type MutationDeleteVenueArgs = {
  input: IdInput;
};


export type MutationDownloadMailMergeArgs = {
  id: Scalars['ObjectId'];
};


export type MutationExportFailedImportClassesArgs = {
  id: Scalars['ObjectId'];
};


export type MutationExportFailedImportRegistrationsArgs = {
  id: Scalars['ObjectId'];
};


export type MutationExtendsArrangementTimeArgs = {
  id: Scalars['ObjectId'];
};


export type MutationExtendsRegistrationTimeArgs = {
  id: Scalars['ObjectId'];
};


export type MutationGenerateNextInvoiceArgs = {
  id: Scalars['ObjectId'];
};


export type MutationIncrementStepArgs = {
  arrangementId: Scalars['ObjectId'];
  step?: InputMaybe<Scalars['Int']>;
};


export type MutationLessonPlanAddLessonFlowHomeworkArgs = {
  input: LessonPlanAddLessonFlowHomeworkInput;
};


export type MutationLessonPlanRemoveLessonFlowHomeworkArgs = {
  input: LessonPlanRemoveLessonFlowHomeworkInput;
};


export type MutationLessonPlanReorderLessonFlowSchedulesArgs = {
  input: LessonPlanReorderLessonFlowSchedulesInput;
};


export type MutationLessonPlanSkipLessonFlowItemArgs = {
  input: LessonPlanSkipLessonFlowItemInput;
};


export type MutationLessonPlanUpdateLessonFlowHomeworkArgs = {
  input: LessonPlanUpdateLessonFlowHomeworkInput;
};


export type MutationLessonPlanUpdateLessonFlowItemTrialFlagsArgs = {
  input: LessonPlanUpdateLessonFlowItemTrialFlagInput;
};


export type MutationLinkParentArgs = {
  input: LinkParentInput;
};


export type MutationMaterialHandoverUpdateArgs = {
  input: MaterialHandoverUpdateInput;
};


export type MutationMergeParentArgs = {
  input: MergeParentInput;
};


export type MutationMergeStudentArgs = {
  input: MergeStudentInput;
};


export type MutationOnHoldInvoiceArgs = {
  input: OnHoldInvoiceCommand;
};


export type MutationPackingListAddBookletsArgs = {
  input: PackingListEnvelopeAddBookletsInput;
};


export type MutationPackingListAddEnvelopesArgs = {
  input: PackingListAddEnvelopesInput;
};


export type MutationPackingListAddTrialWorksheetsArgs = {
  input: PackingListAddTrialMaterialsInput;
};


export type MutationPackingListAddWorksheetsArgs = {
  input: PackingListAddWorksheetsInput;
};


export type MutationPackingListChangeEnvelopeStatusArgs = {
  input: PackingListChangeEnvelopeStatusInput;
};


export type MutationPackingListConfigurationCreateArgs = {
  input: PackingListConfigurationCreateInput;
};


export type MutationPackingListConfigurationUpdateEnvelopesArgs = {
  input: PackingListConfigurationUpdateEnvelopesInput;
};


export type MutationPackingListCreateArgs = {
  input: PackingListCreateInput;
};


export type MutationPackingListEnvelopeMoveWorksheetToBookletArgs = {
  input: PackingListEnvelopeMoveWorksheetToBookletInput;
};


export type MutationPackingListEnvelopeReduceAvailableUnitsArgs = {
  input: PackingListEnvelopeReduceAvailableUnitsInput;
};


export type MutationPackingListPartialEnvelopeReduceAvailableUnitsArgs = {
  input: PackingListPartialEnvelopeReduceAvailableUnitsInput;
};


export type MutationPackingListRemoveBookletsArgs = {
  input: PackingListRemoveBookletsInput;
};


export type MutationPackingListRemoveEnvelopesArgs = {
  input: PackingListRemoveEnvelopesInput;
};


export type MutationPackingListRemoveTrialWorksheetsArgs = {
  input: PackingListRemoveTrialMaterialsInput;
};


export type MutationPackingListRemoveWorksheetsArgs = {
  input: PackingListRemoveWorksheetsInput;
};


export type MutationPackingListReorderBookletsArgs = {
  input: PackingListReorderBookletsInput;
};


export type MutationPackingListReorderWorksheetsArgs = {
  input: PackingListReorderWorksheetsInput;
};


export type MutationPackingListTrialEnvelopeReduceAvailableUnitsArgs = {
  input: PackingListTrialEnvelopeReduceAvailableUnitsInput;
};


export type MutationPackingListUpdatePackedUnitsArgs = {
  input: PackingListUpdatePackedUnitsInput;
};


export type MutationPackingListUpdateTrialWorksheetAcademicWeekStatusArgs = {
  input: PackingListUpdateTrialWorksheetAcademicWeekStatusInput;
};


export type MutationPackingListUpdateVersionArgs = {
  input: PackingListUpdateVersionInput;
};


export type MutationPauseWhatsAppMessageArgs = {
  id: Scalars['ObjectId'];
};


export type MutationPauseWhatsappAccountArgs = {
  id: Scalars['ObjectId'];
};


export type MutationPlaceOrderDeliveryArgs = {
  deliveryId: Scalars['ObjectId'];
};


export type MutationPrioritizeRegistrationArgs = {
  input: UpdateRegistrationInput;
};


export type MutationProcessArrangementArgs = {
  id: Scalars['ObjectId'];
};


export type MutationProcessRegistrationArgs = {
  id: Scalars['ObjectId'];
};


export type MutationQueueNextArgs = {
  id: Scalars['ObjectId'];
};


export type MutationReadSystemNotificationArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRecallCampaignArgs = {
  id: Scalars['ObjectId'];
};


export type MutationReconnectWhatsappAccountArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRecordExamScoreArgs = {
  input: RecordExamScoreInput;
};


export type MutationRecoverWhatsappAccountArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRegenerateInvoiceArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRegenerateRegistrationArgs = {
  input: RegenerateRegistrationInput;
};


export type MutationRegisterStudentArgs = {
  input: RegisterStudentInput;
};


export type MutationRegistrationIncrementStepArgs = {
  currentStep: Scalars['Int'];
  registrationId: Scalars['ObjectId'];
};


export type MutationRemoveLessonFlowScheduleArgs = {
  input: RemoveLessonFlowScheduleInput;
};


export type MutationRemoveLessonPlanFromClassArgs = {
  input: RemoveLessonPlanFromClassInput;
};


export type MutationRemoveLineItemArgs = {
  lineItemId: Scalars['ObjectId'];
};


export type MutationRemoveLineItemDiscountArgs = {
  lineItemId: Scalars['ObjectId'];
};


export type MutationReopenTaskArgs = {
  id: Scalars['ObjectId'];
};


export type MutationReorderLessonFlowItemsArgs = {
  input: ReorderLessonFlowItemsInput;
};


export type MutationReplyFeedbackArgs = {
  input: ReplyFeedbackInput;
};


export type MutationRequestChangeSystemUserPasswordArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRequeueWhatsAppMessageArgs = {
  id: Scalars['ObjectId'];
};


export type MutationResendReminderArgs = {
  id: Scalars['ObjectId'];
};


export type MutationResetDefaultArgs = {
  input: ResetDefaultInput;
};


export type MutationResetMaterialStatusArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRestoreDiscountArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRestoreLessonFlowItemVersionArgs = {
  input: RestoreLessonFlowItemVersion;
};


export type MutationRestoreRewardArgs = {
  id: Scalars['ObjectId'];
};


export type MutationResumeWhatsAppMessageArgs = {
  id: Scalars['ObjectId'];
};


export type MutationResumeWhatsappAccountArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRetryUploadInvoiceArgs = {
  date: Scalars['DateTime'];
  invoiceId: Scalars['ObjectId'];
};


export type MutationRetryWhatsAppMessageArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRetryXeroPaymentArgs = {
  paymentId: Scalars['ObjectId'];
};


export type MutationSaveAsNewLessonFlowArgs = {
  input: SaveAsNewLessonFlowInput;
};


export type MutationSaveSubmissionsArgs = {
  input: SaveSubmissionsInput;
};


export type MutationSaveTopicsArgs = {
  input: SaveTopicsInput;
};


export type MutationSendReminderArgs = {
  input: SendReminderInput;
};


export type MutationSendTestReminderArgs = {
  input: SendTestReminderInput;
};


export type MutationSetDefaultLessonFlowArgs = {
  id: Scalars['ObjectId'];
};


export type MutationSetLessonFlowItemExceptionsArgs = {
  input: SetLessonFlowItemExceptionsInput;
};


export type MutationTestSubmissionCreateArgs = {
  input: TestSubmissionCreateInput;
};


export type MutationTestSubmissionMakeDraftArgs = {
  input: TestSubmissionMakeDraftInput;
};


export type MutationTestSubmissionSaveMarkedResourceArgs = {
  input: TestSubmissionSaveMarkedResourceInput;
};


export type MutationTestSubmissionSaveScoresArgs = {
  input: TestSubmissionSaveScoresInput;
};


export type MutationTestSubmissionUpdateArgs = {
  input: TestSubmissionUpdateInput;
};


export type MutationToggleFeatureArgs = {
  input: UpdateFeatureAccessInput;
};


export type MutationToggleFeaturesArgs = {
  input: UpdateFeatureAccessesInput;
};


export type MutationTopicAddCourseArgs = {
  input: TopicAddCourseInput;
};


export type MutationTopicRemoveCourseArgs = {
  input: TopicRemoveCourseInput;
};


export type MutationUnarchiveWorksheetsArgs = {
  input: UnarchiveWorkSheetsInput;
};


export type MutationUnlinkParentArgs = {
  input: LinkParentInput;
};


export type MutationUpdateAcademicLevelArgs = {
  input: UpdateAcademicLevelInput;
};


export type MutationUpdateAcademicYearArgs = {
  input: UpdateAcademicYearInput;
};


export type MutationUpdateAdminArgs = {
  input: UpdateAdminInput;
};


export type MutationUpdateArchivableArgs = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};


export type MutationUpdateAttendanceLessonModeArgs = {
  input: UpdateAttendanceLessonModeInput;
};


export type MutationUpdateAttendancesArgs = {
  input: UpdateAttendancesInput;
};


export type MutationUpdateBranchArgs = {
  input: UpdateBranchInput;
};


export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};


export type MutationUpdateClassArgs = {
  input: UpdateClassCommand;
};


export type MutationUpdateClassLessonFlowArgs = {
  input: ChangeClassLessonFlowInput;
};


export type MutationUpdateCollectArgs = {
  input: UpdateCollectInput;
};


export type MutationUpdateCourierPickupAddressArgs = {
  input: UpdateCourierPickupAddressInput;
};


export type MutationUpdateCourseArgs = {
  input: UpdateCourseInput;
};


export type MutationUpdateDeliveryArgs = {
  input: UpdateDeliveryInput;
};


export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
};


export type MutationUpdateDiscountArgs = {
  input: UpdateDiscountInput;
};


export type MutationUpdateEligibilityFlowArgs = {
  input: UpdateEligibilityFlowInput;
};


export type MutationUpdateEnrollmentEndRecurringArgs = {
  id: Scalars['ObjectId'];
  isEndRecurring: Scalars['Boolean'];
};


export type MutationUpdateEnrollmentFollowUpStatusArgs = {
  input: UpdateEnrollmentFollowUpStatusInput;
};


export type MutationUpdateEnrollmentGroupArgs = {
  input: UpdateEnrollmentGroupInput;
};


export type MutationUpdateEnrollmentPreferredLearningArrangementArgs = {
  input: UpdateEnrollmentPreferredLearningArrangementInput;
};


export type MutationUpdateEnrollmentSuitabilityArgs = {
  input: UpdateEnrollmentSuitabilityInput;
};


export type MutationUpdateEnrollmentsArgs = {
  enrollments: Array<UpdateEnrollmentsInput>;
};


export type MutationUpdateEnrollmentsPreferredLearningArrangementArgs = {
  input: UpdateEnrollmentsPreferredLearningArrangementInput;
};


export type MutationUpdateExamScoreArgs = {
  input: UpdateExamScoreInput;
};


export type MutationUpdateExerciseArgs = {
  input: UpdateExerciseInput;
};


export type MutationUpdateFeedbackArgs = {
  input: UpdateFeedbackInput;
};


export type MutationUpdateGroupAssignmentArgs = {
  input: UpdateGroupAssignmentInput;
};


export type MutationUpdateHandoutArgs = {
  input: UpdateHandoutInput;
};


export type MutationUpdateHolidayArgs = {
  input: UpdateHolidayInput;
};


export type MutationUpdateHomeworkArgs = {
  input: UpdateHomeworkInput;
};


export type MutationUpdateIntegrationClientInfoArgs = {
  input: UpdateIntegrationCommand;
};


export type MutationUpdateInvoiceDueDateArgs = {
  input: UpdateInvoiceDueDateCommand;
};


export type MutationUpdateInvoiceItemArgs = {
  input: UpdateInvoiceLineItemCommand;
};


export type MutationUpdateInvoiceItemOptionArgs = {
  input: UpdateInvoiceItemOptionInput;
};


export type MutationUpdateLessonArgs = {
  input: UpdateLessonCommand;
};


export type MutationUpdateLessonAttendanceArgs = {
  input: UpdateLessonAttendanceInput;
};


export type MutationUpdateLessonFlowArgs = {
  input: UpdateLessonFlowInput;
};


export type MutationUpdateLessonFlowArchiveArgs = {
  archive: Scalars['Boolean'];
  id: Scalars['ObjectId'];
};


export type MutationUpdateLessonFlowItemArgs = {
  input: UpdateLessonFlowItemInput;
};


export type MutationUpdateLessonFlowScheduleArgs = {
  input: UpdateLessonFlowScheduleInput;
};


export type MutationUpdateLessonSequenceItemArgs = {
  input: UpdateLessonSequenceInput;
};


export type MutationUpdateLogArgs = {
  input: UpdateLogInput;
};


export type MutationUpdateMailMergeArgs = {
  input: UpdateMailMergeInput;
};


export type MutationUpdateMaterialDeliveryOptionArgs = {
  input: UpdateMaterialDeliveryOptionInput;
};


export type MutationUpdateMerchandiseArgs = {
  input: UpdateMerchandiseInput;
};


export type MutationUpdateNotificationItemArgs = {
  input: NotificationInput;
};


export type MutationUpdateParentArgs = {
  input: UpdateParentInput;
};


export type MutationUpdatePaymentRecordArgs = {
  input: UpdatePaymentRecordInput;
};


export type MutationUpdatePromotionArgs = {
  input: UpdatePromotionInput;
};


export type MutationUpdateReferenceDataArgs = {
  input: UpdateReferenceDataInput;
};


export type MutationUpdateRegistrationArgs = {
  input: UpdateRegistrationInput;
};


export type MutationUpdateRelationNameArgs = {
  input: UpdateRelationshipInput;
};


export type MutationUpdateRemarkArgs = {
  input: UpdateRemarkInput;
};


export type MutationUpdateRewardArgs = {
  input: UpdateRewardCatalogInput;
};


export type MutationUpdateRewardsHistoryArgs = {
  input: UpdateRewardsHistoryInput;
};


export type MutationUpdateSchoolArgs = {
  input: UpdateSchoolInput;
};


export type MutationUpdateStudentArgs = {
  input: UpdateStudentInput;
};


export type MutationUpdateStudentRemarksArgs = {
  input: UpdateStudentRemarksInput;
};


export type MutationUpdateStudentReportFilterArgs = {
  input: UpdateStudentReportFilterInput;
};


export type MutationUpdateSystemUserArgs = {
  command: UpdateSystemUserCommand;
};


export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};


export type MutationUpdateTaskChecklistArgs = {
  input: UpdateTaskChecklistInput;
};


export type MutationUpdateTaskChecklistItemArgs = {
  input: UpdateTaskChecklistItemInput;
};


export type MutationUpdateTaskDueDateArgs = {
  dueDate: Scalars['DateTime'];
  id: Scalars['ObjectId'];
};


export type MutationUpdateTaskListArgs = {
  input: UpdateTaskListInput;
};


export type MutationUpdateTeacherArgs = {
  input: UpdateTeacherInput;
};


export type MutationUpdateTemplateArgs = {
  input: UpdateTemplateInput;
};


export type MutationUpdateTopicArgs = {
  input: UpdateTopicInput;
};


export type MutationUpdateUserCustomiseColumnArgs = {
  input: UpdateUserCustomiseColumnInput;
};


export type MutationUpdateUserCustomiseColumnsArgs = {
  inputs: Array<UpdateUserCustomiseColumnInput>;
};


export type MutationUpdateUserPermissionArgs = {
  input: UpdateUserPermissionInput;
};


export type MutationUpdateVenueArgs = {
  input: UpdateVenueInput;
};


export type MutationUpdateWhatsappAccountArgs = {
  input: UpdateWhatsappAccountInput;
};


export type MutationUpdateWorkSheetArgs = {
  input: UpdateWorksheetInput;
};


export type MutationUploadBase64FileArgs = {
  body: Scalars['String'];
  path: UploadFilePath;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
  path: UploadFilePath;
};


export type MutationUploadMailMergeMergeListArgs = {
  input: UploadMailMergeMergeListInput;
};


export type MutationUpsertUserCustomiseColumnArgs = {
  input: UpsertUserCustomiseColumnInput;
};


export type MutationUpsertUserCustomiseColumnsArgs = {
  inputs: Array<UpsertUserCustomiseColumnInput>;
};


export type MutationUseRewardPointsArgs = {
  input: UseRewardPointsInput;
};


export type MutationValidateIntegrationAccessTokenArgs = {
  input: ValidateIntegrationAccessTokenCommand;
};


export type MutationValidateWhatsappNumberArgs = {
  input: ValidateWhatsappNumberInput;
};


export type MutationVoidClassArgs = {
  input: VoidInput;
};


export type MutationVoidCourseArgs = {
  input: VoidInput;
};


export type MutationVoidInvoiceArgs = {
  id: Scalars['ObjectId'];
};


export type MutationVoidParentArgs = {
  input: VoidInput;
};


export type MutationVoidPaymentRecordArgs = {
  input: VoidPaymentRecordInput;
};


export type MutationVoidRefundArgs = {
  id: Scalars['ObjectId'];
};


export type MutationVoidStudentArgs = {
  input: VoidInput;
};


export type MutationVoidTaskArgs = {
  id: Scalars['ObjectId'];
};


export type MutationWhatsappLoginArgs = {
  id: Scalars['ObjectId'];
};


export type MutationWhatsappLogoutArgs = {
  id: Scalars['ObjectId'];
};


export type MutationWorksheetAddCourseArgs = {
  input: WorksheetAddCourseInput;
};


export type MutationWorksheetCreateArgs = {
  input: WorksheetCreateInput;
};


export type MutationWorksheetRemoveCourseArgs = {
  input: WorksheetRemoveCourseInput;
};


export type MutationWorksheetsCreateArgs = {
  input: WorksheetsCreateInput;
};


export type MutationWorksheetsParseCsvArgs = {
  input: WorksheetsParseCsvInput;
};


export type MutationXeroApiCallbackArgs = {
  callbackUrl: Scalars['String'];
};

export type Note = {
  __typename?: 'Note';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  note: Scalars['String'];
  userId: Scalars['ObjectId'];
};

export type NotePage = {
  __typename?: 'NotePage';
  items: Array<Note>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum NotificationChannel {
  Email = 'Email',
  Whatsapp = 'Whatsapp'
}

export type NotificationCondition = {
  __typename?: 'NotificationCondition';
  attendanceStatuses: Array<AttendanceStatus>;
  attendanceTypes: Array<AttendanceType>;
  classStatuses: Array<ClassStatus>;
  courseStatuses: Array<CourseStatus>;
  courseTypes: Array<CourseType>;
  enrollmentStatuses: Array<EnrollmentStatus>;
  enrollmentTypes: Array<EnrollmentType>;
  invoiceStatuses: Array<InvoiceStatus>;
};

export type NotificationHyperLink = {
  __typename?: 'NotificationHyperLink';
  id: Scalars['ObjectId'];
  keyword: Scalars['String'];
  type: NotificationHyperLinkType;
};

export enum NotificationHyperLinkType {
  Class = 'Class',
  Parent = 'Parent',
  SystemUser = 'SystemUser',
  TaskList = 'TaskList'
}

export type NotificationInput = {
  id: Scalars['ObjectId'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  templateId?: InputMaybe<Scalars['ObjectId']>;
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  _id: Scalars['ObjectId'];
  conditions: NotificationCondition;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  daysBefore: Scalars['Int'];
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  groupName: Scalars['String'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isEnabled: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  template?: Maybe<Template>;
  type: NotificationType;
};

export enum NotificationType {
  LessonReminder = 'LessonReminder'
}

export type NotifyMissingDto = {
  __typename?: 'NotifyMissingDTO';
  class?: Maybe<Class>;
  classId?: Maybe<Scalars['ObjectId']>;
  course?: Maybe<Course>;
  courseId?: Maybe<Scalars['ObjectId']>;
  missingFields: Array<Scalars['String']>;
  parent?: Maybe<Parent>;
  parentId: Scalars['ObjectId'];
  student?: Maybe<Student>;
  studentId?: Maybe<Scalars['ObjectId']>;
  teacher?: Maybe<SystemUser>;
  teacherId?: Maybe<Scalars['ObjectId']>;
};

export type NumberRangeInput = {
  end: Scalars['Int'];
  start: Scalars['Int'];
};

export type OnHoldInvoiceCommand = {
  id: Scalars['ObjectId'];
  isOnHold: Scalars['Boolean'];
  onHoldRemarks?: InputMaybe<Scalars['String']>;
};

export type OrderHistory = {
  __typename?: 'OrderHistory';
  /** Unique identifier for the record */
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  status: RewardHistoryStatus;
};

export type OtherFeeInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  isApplicable: Scalars['Boolean'];
  name: OtherFeeType;
};

export type OtherFeeOutput = {
  __typename?: 'OtherFeeOutput';
  amount?: Maybe<Scalars['Decimal']>;
  isApplicable: Scalars['Boolean'];
  name: OtherFeeType;
};

export enum OtherFeeType {
  Additional = 'Additional',
  Deposit = 'Deposit',
  Materials = 'Materials',
  Registration = 'Registration',
  Trial = 'Trial'
}

export type OtherMappingAccount = {
  __typename?: 'OtherMappingAccount';
  _id: Scalars['ObjectId'];
  code: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  otherFeeType: OtherFeeType;
  taxType?: Maybe<Scalars['String']>;
  type: AccountType;
  xeroAccountsSyncStatus?: Maybe<Scalars['Boolean']>;
};

/** The list of the materials of a course to be packed. There could be one or more packing list under one course. */
export type PackingList = {
  __typename?: 'PackingList';
  /** Unique identifier for the record. */
  _id: Scalars['ObjectId'];
  /** The id of the packing list configuration that was applied to this packing list. */
  configurationId?: Maybe<Scalars['ObjectId']>;
  /** Associated course to the packing list. This record is referenced to an existing "course" record. */
  course: PackingListCourse;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /**
   * The envelopes under the packing list. These contains the items being packed.
   *      - Packing Lists on REGULAR courses should have 7 envelopes.
   *      - Packing Lists on WORKSHOP courses should only have 1 envelope.
   */
  envelopes: Array<PackingListEnvelope>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /** Associated lesson plan to the packing list. */
  lessonPlan?: Maybe<PackingListLessonPlan>;
  /** The name of the packing list. */
  name: Scalars['String'];
  /** The envelopes for late enrollments. */
  partialEnvelopes: Array<PackingListPartialEnvelope>;
  /** The envelopes for trial enrollments in GAP classes. This contains worksheets to be delivered to trial students. */
  trialEnvelopes: Array<PackingListTrialEnvelope>;
  /** Version of the packing list composed of month and academic year. */
  version: PackingListVersion;
  /** Display student who is currently enrolled this course, supposed to receive this materials but not yet paid. */
  willGeneratedMaterialEnrollments?: Maybe<Array<Enrollment>>;
};


/** The list of the materials of a course to be packed. There could be one or more packing list under one course. */
export type PackingListWillGeneratedMaterialEnrollmentsArgs = {
  envelopeId: Scalars['ObjectId'];
};

/** This is to indicate a list of academic week each envelope is assigned to. */
export type PackingListAcademicWeek = {
  __typename?: 'PackingListAcademicWeek';
  /** Unique identifier of the record. This is copied from the referenced academic week record. */
  _id: Scalars['ObjectId'];
  /** The date of the last day of the week. */
  endDate: Scalars['DateTime'];
  /** The date of the first day of the week. */
  startDate: Scalars['DateTime'];
  /** The week number in an academic year. */
  weekNumber: Scalars['Float'];
};

/** Academic week range for packing list add envelope input. */
export type PackingListAddEnvelopeAcademicWeekDateRangeInput = {
  /** ID of the academic week end. */
  academicWeekEndId: Scalars['ObjectId'];
  /** ID of the academic week start. */
  academicWeekStartId: Scalars['ObjectId'];
};

/** GraphQL input for adding an envelope to a packing list. */
export type PackingListAddEnvelopeInput = {
  /** Academic weeks to associated the packing list to. This is required when the packing list course type is Regular. */
  academicWeekDateRange?: InputMaybe<PackingListAddEnvelopeAcademicWeekDateRangeInput>;
  /** URL of the image cover of the envelope. */
  imageUrl?: InputMaybe<Scalars['String']>;
  /** Title of the envelope. */
  title: Scalars['String'];
};

/** Input for adding an envelope to a packing list record. */
export type PackingListAddEnvelopesInput = {
  /** Envelopes to add to a packing list. */
  envelopes: Array<PackingListAddEnvelopeInput>;
  /** ID of the packing list to add the envelope to */
  packingListId: Scalars['ObjectId'];
};

/** Input for the Packing List Add Trial Materials mutation. */
export type PackingListAddTrialMaterialsInput = {
  /** The lesson flow where the trial material worksheet is from. */
  lessonFlowId: Scalars['ObjectId'];
  /** The lesson plan where the trial material worksheet is from. */
  lessonPlanId: Scalars['ObjectId'];
  /** The packing list to add the worksheet to. */
  packingListId: Scalars['ObjectId'];
  /** The worksheets to be added as trial materials of the packing list. */
  worksheetIds: Array<Scalars['ObjectId']>;
};

/** Input for the Packing List Add Worksheets mutation. */
export type PackingListAddWorksheetsInput = {
  /** The booklet of the packing list to add the worksheet. */
  bookletId: Scalars['ObjectId'];
  /** The envelope of the packing list to add the worksheet. */
  envelopeId: Scalars['ObjectId'];
  /** The packing list to add the worksheet. */
  packingListId: Scalars['ObjectId'];
  /** The worksheets to be added to the booklet of the packing list. */
  worksheets: Array<PackingListWorksheetInput>;
};

/** The topic of the packing list booklet. */
export type PackingListBookletTopic = {
  __typename?: 'PackingListBookletTopic';
  /** Unique identifier of the record. The value of this field will be coming from the referenced "topic" record. */
  _id: Scalars['ObjectId'];
  /** Name of the packing list booklet. The value of this field will be coming from the referenced "topic" record. */
  name: Scalars['String'];
};

/** Input for `packingListChangeEnvelopeStatus` mutation. */
export type PackingListChangeEnvelopeStatusInput = {
  /** The envelope to change the status. */
  envelopeId: Scalars['ObjectId'];
  /** The packing list containing the envelope to change status. */
  packingListId: Scalars['ObjectId'];
  /** The desired status of the envelope. */
  status: PackingListEnvelopeStatus;
};

/** The configuration for the packing list feature. */
export type PackingListConfiguration = {
  __typename?: 'PackingListConfiguration';
  /** Unique identifier for the record. */
  _id: Scalars['ObjectId'];
  /** This configuration would only apply to the packing lists under this academic year. */
  academicYear: PackingListConfigurationAcademicYear;
  /** This configuration would only apply to the packing lists under a course with this course type. */
  courseType?: Maybe<Scalars['String']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The envelope presets for the affected packing lists. */
  envelopePresets: Array<PackingListConfigurationEnvelopePreset>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
};

/** Subset of the academic year entity. */
export type PackingListConfigurationAcademicYear = {
  __typename?: 'PackingListConfigurationAcademicYear';
  /** Unique ID referencing to the corresponding academic year record. */
  _id: Scalars['ObjectId'];
  /** The year copied from the academic year record. */
  year: Scalars['Float'];
};

/** The academic week range to set for this envelope. */
export type PackingListConfigurationCreateEnvelopeAcademicWeekRangeInput = {
  /** The ending week id to set for this envelope. */
  endingWeekId: Scalars['ObjectId'];
  /** The starting week id to set for this envelope. */
  startingWeekId: Scalars['ObjectId'];
};

/** The individual packing list configuration envelope to create. */
export type PackingListConfigurationCreateEnvelopeInput = {
  /** The acdemic week range to set for this envelope. Set this to `null` to not set anything. */
  academicWeekRange?: InputMaybe<PackingListConfigurationCreateEnvelopeAcademicWeekRangeInput>;
  /** The title to set for this envelope. If not specified or set, this defaults to `Envelope {n}` */
  title?: InputMaybe<Scalars['String']>;
};

/** Input for `packingListConfigurationCreate`. */
export type PackingListConfigurationCreateInput = {
  /**
   * The ID of the academic year the packing list configuration is associated to.
   *     This would limit the packing lists that this configuration applies to the packing lists under this academic year.
   */
  academicYearId: Scalars['ObjectId'];
  /** The course type to create the packing list configuration. */
  courseType: CourseType;
  /** The individual envelope configurations to assign to this packing list configuration. */
  envelopes: Array<PackingListConfigurationCreateEnvelopeInput>;
};

/** The academic week copied from the academic year entity. */
export type PackingListConfigurationEnvelopeAcademicWeek = {
  __typename?: 'PackingListConfigurationEnvelopeAcademicWeek';
  /** Unique ID referencing to the corresponding academic week in the academic year record. */
  _id: Scalars['ObjectId'];
  /** End date copied from the academic week. */
  endDate: Scalars['DateTime'];
  /** Start date copied from the academic week. */
  startDate: Scalars['DateTime'];
  /** Week number copied from the academic week. */
  weekNumber: Scalars['Float'];
};

/** This is to indicate a list of academic week each envelope is assigned to. */
export type PackingListConfigurationEnvelopeAcademicWeekRange = {
  __typename?: 'PackingListConfigurationEnvelopeAcademicWeekRange';
  /** The ending week number of the envelope. */
  endingWeek: PackingListConfigurationEnvelopeAcademicWeek;
  /** The starting week number of the envelope. */
  startingWeek: PackingListConfigurationEnvelopeAcademicWeek;
};

/** The envelope options to choose from for creating a new envelope. */
export type PackingListConfigurationEnvelopePreset = {
  __typename?: 'PackingListConfigurationEnvelopePreset';
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'];
  /**
   * This is to indicate the academic week range each envelope is assigned to.
   *     This is only applicable to REGULAR/GAP courses.
   */
  academicWeekRange?: Maybe<PackingListConfigurationEnvelopeAcademicWeekRange>;
  /** The title to display for this envelope. When not specified during creation, this defaults to 'Envelope {n}' */
  title: Scalars['String'];
  /** This is used to track the current latest version of the envelope. */
  version?: Maybe<Scalars['Float']>;
};

/** Input for `packingListConfiguration`. */
export type PackingListConfigurationInput = {
  /** Gets the packing list configuration matching the specified year. */
  academicYearId: Scalars['ObjectId'];
  /** Gets the packing list configuration matching the specified course type. */
  courseType: CourseType;
};

/** The individual packing list configuration envelope to update. */
export type PackingListConfigurationUpdateEnvelopeInput = {
  /** The envelope to update. */
  _id: Scalars['ObjectId'];
  /** The ending week id to set for this envelope. */
  endingWeekId: Scalars['ObjectId'];
  /** The starting week id to set for this envelope. */
  startingWeekId: Scalars['ObjectId'];
};

/** Input for `packingListConfigurationUpdateEnvelopes` mutation. */
export type PackingListConfigurationUpdateEnvelopesInput = {
  /** The individual envelope configurations to update in this packing list configuration. */
  envelopes: Array<PackingListConfigurationUpdateEnvelopeInput>;
  /** The packing list configuration containing the envelopes to update. */
  packingListConfigurationId: Scalars['ObjectId'];
};

/** Associated course to the packing list. This record is referenced to an existing "course" record. */
export type PackingListCourse = {
  __typename?: 'PackingListCourse';
  /** Unique ID of the packing list course record. This value is from the ID of the referenced course record. */
  _id: Scalars['ObjectId'];
  /** The name of the course. This value is from the "name" field of the referenced course. */
  name: Scalars['String'];
  /** The course type copied from the associated course record. */
  type: CourseType;
};

/** Academic week range for packing list add envelope input. */
export type PackingListCreateEnvelopeAcademicWeekDateRangeInput = {
  /** ID of the academic week end. */
  academicWeekEndId: Scalars['ObjectId'];
  /** ID of the academic week start. */
  academicWeekStartId: Scalars['ObjectId'];
};

/** Booklets to be added to the packing list envelope being created. */
export type PackingListCreateEnvelopeBookletInput = {
  /** URL of the image to be used as the booklet's cover image. */
  imageUrl?: InputMaybe<Scalars['String']>;
  /** Title of the booklet. */
  title: Scalars['String'];
  /** ID of the topic to associate the booklet to. This should be associated to the packing list course. */
  topicId: Scalars['ObjectId'];
  /** Worksheets to be added to the booklet. */
  worksheets?: InputMaybe<Array<PackingListCreateEnvelopeBookletWorksheetInput>>;
};

/** Worksheets to add to the packing list envelope booklet. */
export type PackingListCreateEnvelopeBookletWorksheetInput = {
  /** The worksheet to add to the booklet of the packing list. Worksheet should be associated to the packing list course and booklet topic. */
  id: Scalars['ObjectId'];
  /** URL of the cover image of the worksheet. */
  imageUrl?: InputMaybe<Scalars['String']>;
};

/** Input for including an envelope to the packing list record being created. */
export type PackingListCreateEnvelopeInput = {
  /** Academic weeks to associated the packing list to. This is required if the course's type associated with the packing list is 'Regular'. */
  academicWeekDateRange?: InputMaybe<PackingListCreateEnvelopeAcademicWeekDateRangeInput>;
  /** Booklets to be added to the envelope. */
  booklets?: InputMaybe<Array<PackingListCreateEnvelopeBookletInput>>;
  /** URL of the image cover of the envelope. */
  imageUrl?: InputMaybe<Scalars['String']>;
  /** Title of the envelope. */
  title: Scalars['String'];
};

/** GraphQL input for creating a packing list. */
export type PackingListCreateInput = {
  /** ID of the academic year associated with the packing list. */
  academicYearId: Scalars['ObjectId'];
  /** ID of the course associated with the packing list. */
  courseId: Scalars['ObjectId'];
  /** Envelopes to be included in the packing list record being created. */
  envelopes?: InputMaybe<Array<PackingListCreateEnvelopeInput>>;
  /** Name of the packing list. */
  name: Scalars['String'];
};

/** This serves as a grouping mechanism for the booklets. */
export type PackingListEnvelope = {
  __typename?: 'PackingListEnvelope';
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'];
  /**
   * This is to indicate a list of academic week each envelope is assigned to.
   *     This is only applicable to REGULAR courses.
   */
  academicWeeks: Array<PackingListAcademicWeek>;
  /** The booklet details keyed by the booklet id. */
  booklets: Array<PackingListEnvelopeBooklet>;
  /** The id of the envelope preset that was copied. */
  envelopePresetId?: Maybe<Scalars['ObjectId']>;
  /** The version of the envelope preset that was copied from the packing list configuration. */
  envelopePresetVersion?: Maybe<Scalars['Float']>;
  /** Image URL of the cover image of the envelope. */
  imageUrl?: Maybe<Scalars['String']>;
  /**
   * Is used material selected all booklets.
   * @deprecated Use `MaterialEnvelope.isSelectedAll` instead.
   */
  isSelectedAll?: Maybe<Scalars['Boolean']>;
  /** Packing status of the envelope. */
  status: PackingListEnvelopeStatus;
  /** The title of the envelope when it was added. */
  title: Scalars['String'];
  /** Quantity data for the envelope. */
  totalUnits: PackingListEnvelopeUnits;
};

/** A single booklet input to add to an existing envelope. */
export type PackingListEnvelopeAddBookletInput = {
  /** URL of the cover image of the booklet. */
  imageUrl?: InputMaybe<Scalars['String']>;
  /** Title of the booklet to add. */
  title: Scalars['String'];
  /** ID of the topic of the booklet. */
  topicId: Scalars['ObjectId'];
};

/** Input for adding booklets to an existing packing list envelope. */
export type PackingListEnvelopeAddBookletsInput = {
  /** Booklets to add to an existing packing list envelope. */
  booklets: Array<PackingListEnvelopeAddBookletInput>;
  /** ID of the packing list envelope to add the booklets to. */
  envelopeId: Scalars['ObjectId'];
};

/** This a small unit of a book, which contains the topic, the title, and the worksheets. */
export type PackingListEnvelopeBooklet = {
  __typename?: 'PackingListEnvelopeBooklet';
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'];
  /** Image URL of the booklet cover. */
  imageUrl?: Maybe<Scalars['String']>;
  /**
   * Is used material booklet selected all worksheets.
   * @deprecated Use `MaterialEnvelopeBooklet.isSelectedAll` instead.
   */
  isSelectedAll?: Maybe<Scalars['Boolean']>;
  /** Serial number of a booklet in 0.0 format. */
  serialNumber: Scalars['String'];
  /** Title of the booklet. */
  title: Scalars['String'];
  /** Topic of the booklet. */
  topic: PackingListBookletTopic;
  /** Worksheets under the booklet. */
  worksheets: Array<PackingListEnvelopeBookletWorksheet>;
};

/** A worksheet under a packing list booklet. */
export type PackingListEnvelopeBookletWorksheet = {
  __typename?: 'PackingListEnvelopeBookletWorksheet';
  /** Unique identifier of the record. This value is from the referenced 'worksheet' record. */
  _id: Scalars['ObjectId'];
  /** Image URL of the worksheet cover. */
  imageUrl?: Maybe<Scalars['String']>;
  /**
   * Name of the worksheet. This is coming from the referenced "worksheet" record.
   * @deprecated Use the title field instead.
   */
  name: Scalars['String'];
  /** Serial number of a worksheet in 0.0.A format. */
  serialNumber: Scalars['String'];
  /** Title of the worksheet. This is coming from the referenced "worksheet" record. */
  title: Scalars['String'];
};

/** Input for the Packing List Envelope Move Worksheet to Booklet mutation. */
export type PackingListEnvelopeMoveWorksheetToBookletInput = {
  /** The booklet where the worksheet-to-move will be moved to. */
  destinationBookletId: Scalars['ObjectId'];
  /** The envelope where the worksheet-to-move is found. */
  envelopeId: Scalars['ObjectId'];
  /** The packing list to update the envelope of. */
  packingListId: Scalars['ObjectId'];
  /** Optional: Position of the worksheet in the destination booklet. A positive number from 1 to n. If not provided will move worksheet to the last position in the destination booklet. */
  position?: InputMaybe<Scalars['Float']>;
  /** The booklet where the worksheet-to-move originates from. */
  sourceBookletId: Scalars['ObjectId'];
  /** The booklet where the worksheet-to-move will be moved to. */
  worksheetId: Scalars['ObjectId'];
};

/** Input for the Packing List Envelope Reduce Available Units mutation. */
export type PackingListEnvelopeReduceAvailableUnitsInput = {
  /** The envelope to reduce the available units of. */
  envelopeId?: InputMaybe<Scalars['ObjectId']>;
  /** The packing list to update the envelope of. */
  packingListId: Scalars['ObjectId'];
  /** Value to reduce 'available' units by. */
  unitsReduced: Scalars['Float'];
};

export enum PackingListEnvelopeStatus {
  Compiled = 'Compiled',
  Delayed = 'Delayed',
  InProgress = 'InProgress',
  Packed = 'Packed',
  Printed = 'Printed'
}

/** This serves as a grouping mechanism for the packing list envelope units. */
export type PackingListEnvelopeUnits = {
  __typename?: 'PackingListEnvelopeUnits';
  /** Number of envelopes already allocated to a student. */
  allocated: Scalars['Float'];
  /** 'Required' units of the envelope or the number of students who need to be allocated an envelope. */
  needed: Scalars['Float'];
  /** 'Available' units of the envelope or the number of envelopes ready to be allocated */
  packed: Scalars['Float'];
};

/** Associated lesson plan of the packing list. */
export type PackingListLessonPlan = {
  __typename?: 'PackingListLessonPlan';
  /** Unique ID of the referenced lesson plan. */
  _id: Scalars['ObjectId'];
  /** The name of the lesson plan. This value is from the "name" field of the referenced lesson plan. */
  name: Scalars['String'];
};

/** This contains the list to be used for late enrollments, specific enrollments. */
export type PackingListPartialEnvelope = {
  __typename?: 'PackingListPartialEnvelope';
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'];
  /**
   * This is to indicate a list of academic week each envelope is assigned to.
   *     This is only applicable to REGULAR courses.
   */
  academicWeeks: Array<PackingListAcademicWeek>;
  /** The booklets included in this envelope. */
  booklets: Array<PackingListPartialEnvelopeBooklet>;
  /** Id of the envelope where this partial envelope was taken from. */
  envelopeSourceId: Scalars['ObjectId'];
  /** Packing status of the envelope. */
  status: PackingListPartialEnvelopeStatus;
  /** The title of the envelope when it was added. */
  title: Scalars['String'];
  /** Quantity data for the envelope. */
  totalUnits: PackingListEnvelopeUnits;
};

/** This a small unit of a book, which contains the topic, the title, and the worksheets. */
export type PackingListPartialEnvelopeBooklet = {
  __typename?: 'PackingListPartialEnvelopeBooklet';
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'];
  /** Image URL of the booklet cover. */
  imageUrl?: Maybe<Scalars['String']>;
  /** Serial number of a booklet in 0.0 format. */
  serialNumber: Scalars['String'];
  /** Title of the booklet. */
  title: Scalars['String'];
  /** Topic of the booklet. */
  topic: PackingListBookletTopic;
  /** Worksheets under the booklet. */
  worksheets: Array<PackingListPartialEnvelopeBookletWorksheet>;
};

/** A worksheet under a packing list partial envelope booklet. */
export type PackingListPartialEnvelopeBookletWorksheet = {
  __typename?: 'PackingListPartialEnvelopeBookletWorksheet';
  /** Unique identifier of the record. This value is from the referenced 'worksheet' record. */
  _id: Scalars['ObjectId'];
  /** Image URL of the worksheet cover. */
  imageUrl?: Maybe<Scalars['String']>;
  /**
   * Name of the worksheet. This is coming from the referenced "worksheet" record.
   * @deprecated Use the title field instead.
   */
  name: Scalars['String'];
  /** Serial number of a worksheet in 0.0.A format. */
  serialNumber: Scalars['String'];
  /** Title of the worksheet. This is coming from the referenced "worksheet" record. */
  title: Scalars['String'];
};

/** Input for the Packing List Partial Envelope Reduce Available Units mutation. */
export type PackingListPartialEnvelopeReduceAvailableUnitsInput = {
  /** The packing list to update the envelope of. */
  packingListId: Scalars['ObjectId'];
  /** The partial envelope to reduce the available units of. */
  partialEnvelopeId?: InputMaybe<Scalars['ObjectId']>;
  /** Value to reduce 'available' units by. */
  unitsReduced: Scalars['Float'];
};

export enum PackingListPartialEnvelopeStatus {
  InProgress = 'InProgress',
  Packed = 'Packed'
}

/** Input for the packing list remove booklets mutation. */
export type PackingListRemoveBookletsInput = {
  /** The booklet ids to remove from the envelope of a packing list. */
  bookletIds: Array<Scalars['ObjectId']>;
  /** The envelope to remove the booklets from. */
  envelopeId: Scalars['ObjectId'];
  /** The packing list containing the booklets to remove. */
  packingListId: Scalars['ObjectId'];
};

/** Input for packing list remove envelope mutation. */
export type PackingListRemoveEnvelopesInput = {
  /** The envelope IDs of the envelopes to remove. */
  envelopeIds: Array<Scalars['ObjectId']>;
  /** The packing list containing the envelope to remove. */
  packingListId: Scalars['ObjectId'];
};

/** Input for the Packing List Remove Trial Materials mutation. */
export type PackingListRemoveTrialMaterialsInput = {
  /** The packing list to remove the worksheets from. */
  packingListId: Scalars['ObjectId'];
  /** The worksheets to be removed from the trial materials of the packing list. */
  worksheetIds: Array<Scalars['ObjectId']>;
};

/** Input for the packing list remove worksheets mutation. */
export type PackingListRemoveWorksheetsInput = {
  /** The booklet of the packing list containing the worksheet to remove. */
  bookletId: Scalars['ObjectId'];
  /** The envelope of the packing list containing the worksheet to remove. */
  envelopeId: Scalars['ObjectId'];
  /** The packing list containing the worksheet to remove. */
  packingListId: Scalars['ObjectId'];
  /** The worksheet IDs to remove from the packing list booklet. */
  worksheetIds: Array<Scalars['ObjectId']>;
};

/** Booklets to be reordered and its target ordinal position. */
export type PackingListReorderBookletsBookletInput = {
  /** The ID of the booklet being reordered. */
  id: Scalars['ObjectId'];
  /** A positive number from 1 to n. Indicates the target ordinal position of the booklet after the reordering */
  position: Scalars['Int'];
};

/** Input for the packing list reorder booklets mutation. */
export type PackingListReorderBookletsInput = {
  /** Booklets to be reordered and its target ordinal position. */
  booklets: Array<PackingListReorderBookletsBookletInput>;
  /** The envelope of the packing list with booklets to be sorted. */
  envelopeId: Scalars['ObjectId'];
  /** The Packing list with booklets to be sorted. */
  packingListId: Scalars['ObjectId'];
};

/** Input for the packing list reorder worksheets mutation. */
export type PackingListReorderWorksheetsInput = {
  /** The booklet ID of the packing list with worksheets to be sorted. */
  bookletId: Scalars['ObjectId'];
  /** The envelope ID of the packing list with worksheets to be sorted. */
  envelopeId: Scalars['ObjectId'];
  /** The packing list ID with worksheets to be sorted. */
  packingListId: Scalars['ObjectId'];
  /** The worksheets to be reordered and its target ordinal position. */
  worksheets: Array<PackingListReorderWorksheetsWorksheetInput>;
};

/** Worksheets to be reordered and its target ordinal position. */
export type PackingListReorderWorksheetsWorksheetInput = {
  /** The ID of the worksheet that is being reordered. */
  id: Scalars['ObjectId'];
  /** A positive number from 1 to n. Indicates the target ordinal position of the worksheet after the reordering */
  position: Scalars['Int'];
};

/** The envelopes for trial enrollments in GAP classes. This contains worksheets to be delivered to trial students. */
export type PackingListTrialEnvelope = {
  __typename?: 'PackingListTrialEnvelope';
  /** Unique identifier of the record. This is not related to an ID of original envelopes. */
  _id: Scalars['ObjectId'];
  /**
   * The academic week this envelope is assigned to.
   *     This also used to determine its corresponding lesson flow.
   */
  academicWeek: PackingListAcademicWeek;
  /** The booklets included in this envelope. */
  booklets: Array<PackingListEnvelopeBooklet>;
  /** This is copied from the referenced lesson flow record. This is to indicate the lesson flow a trial material is assigned to. */
  lessonFlowId: Scalars['ObjectId'];
  /** Packing status of the trial envelope. */
  status: PackingListTrialEnvelopeStatus;
  /** Quantity data for the envelope. */
  totalUnits: PackingListEnvelopeUnits;
};

/** Input for the Packing List Trial Envelope Reduce Available Units mutation. */
export type PackingListTrialEnvelopeReduceAvailableUnitsInput = {
  /** The packing list to update the envelope of. */
  packingListId: Scalars['ObjectId'];
  /** The trial envelope to reduce the available units of. */
  trialEnvelopeId?: InputMaybe<Scalars['ObjectId']>;
  /** Value to reduce 'available' units by. */
  unitsReduced: Scalars['Float'];
};

export enum PackingListTrialEnvelopeStatus {
  InProgress = 'InProgress',
  Packed = 'Packed'
}

/** Input for the Packing List Update Available Units mutation. */
export type PackingListUpdatePackedUnitsInput = {
  /** The envelope to update the available units of. Required when partialEnvelopeId is not provided. */
  envelopeId?: InputMaybe<Scalars['ObjectId']>;
  /** The packing list to update the envelope of. */
  packingListId: Scalars['ObjectId'];
  /** The envelope to update the available units of. Required when envelopeId is not provided. */
  partialEnvelopeId?: InputMaybe<Scalars['ObjectId']>;
  /** New value for 'available' units. */
  unitsPacked: Scalars['Float'];
};

/** Input for `updateTrialWorksheetAcademicWeekStatus` mutation. */
export type PackingListUpdateTrialWorksheetAcademicWeekStatusInput = {
  /** The academic week where the trial worksheets are. */
  academicWeekId: Scalars['ObjectId'];
  /** The packing list Id where the trial worksheets are. */
  packingListId: Scalars['ObjectId'];
  /** The new status to be updated to. (Must only use InProgress or Packed). */
  status: PackingListTrialEnvelopeStatus;
};

/** Input for `packingListChangeAcademicYear` mutation. */
export type PackingListUpdateVersionInput = {
  /** The academic year to assign to the packing list. */
  academicYearId: Scalars['ObjectId'];
  /** The desired name to assign to the packing list. */
  name?: InputMaybe<Scalars['String']>;
  /** The packing list to change the academic year. */
  packingListId: Scalars['ObjectId'];
};

/** The envelopes for trial enrollments in GAP classes. This contains worksheets to be delivered to trial students. */
export type PackingListVersion = {
  __typename?: 'PackingListVersion';
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'];
  /** Academic year ID and number. */
  year: PackingListVersionYear;
};

/** Year associated with the packing list version. */
export type PackingListVersionYear = {
  __typename?: 'PackingListVersionYear';
  /** Derived from the associated academic year record. */
  _id: Scalars['ObjectId'];
  /** Calendar year. Derrived from the provided academic year ID */
  year: Scalars['Float'];
};

/** Input for the worksheet data on the Packing List Add Worksheets mutation. */
export type PackingListWorksheetInput = {
  /** The worksheet to add to the booklet of the packing list. */
  id: Scalars['ObjectId'];
  /** URL of the cover image of the worksheet. */
  imageUrl?: InputMaybe<Scalars['String']>;
};

/** Input for getting the packing lists. */
export type PackingListsFilterInput = {
  /** Filter the packing lists with the matching course IDs. */
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /**
   * Filters the packing lists that contain at least 1 envelope with the
   *     given envelope statuses.
   */
  envelopeStatuses?: InputMaybe<Array<PackingListEnvelopeStatus>>;
  /** Filters the packing lists with the given lesson plan IDs */
  lessonPlanIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Filters the packing lists with the given packing list IDs */
  packingListIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Filters the packing lists with the given trial material IDs */
  trialMaterialIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type PackingListsPage = {
  __typename?: 'PackingListsPage';
  items: Array<PackingList>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type PageInfo = {
  pageIndex: Scalars['Int'];
  /** If you want to get data without pagination, you can set pageSize: 0. */
  pageSize: Scalars['Int'];
};

/** Pagination properties. */
export type Pageable = {
  __typename?: 'Pageable';
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type Parent = {
  __typename?: 'Parent';
  /** Unique identifier for the record */
  _id: Scalars['ObjectId'];
  address?: Maybe<UserAddress>;
  alias?: Maybe<Scalars['String']>;
  alternateUserId?: Maybe<Scalars['String']>;
  alternativeEmails?: Maybe<Array<Scalars['String']>>;
  avatarUrl?: Maybe<Scalars['String']>;
  canRemove?: Maybe<Scalars['Boolean']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  directlyRelatedStudents?: Maybe<Array<Student>>;
  email?: Maybe<Scalars['String']>;
  financeStatement?: Maybe<StatementOfAccount>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Telephone>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  latestStudentNumber: Scalars['Int'];
  linkedWhatsappAccount?: Maybe<WhatsappAccount>;
  linkedWhatsappAccountId?: Maybe<Scalars['ObjectId']>;
  mobile: Telephone;
  mobileLine?: Maybe<Scalars['String']>;
  officePhone?: Maybe<Telephone>;
  relatedParents?: Maybe<Array<Parent>>;
  relatedStudents?: Maybe<Array<Student>>;
  relationName?: Maybe<Scalars['String']>;
  remark?: Maybe<Remark>;
  salutation?: Maybe<Scalars['String']>;
  status?: Maybe<VoidableStatus>;
  userId?: Maybe<Scalars['String']>;
  voidRemarks?: Maybe<Scalars['String']>;
  whatsAppName?: Maybe<Scalars['String']>;
};


export type ParentCanRemoveArgs = {
  id?: InputMaybe<Scalars['ObjectId']>;
};


export type ParentRelationNameArgs = {
  id?: InputMaybe<Scalars['ObjectId']>;
};

export type ParentFilter = {
  alias?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  hasStatementOfAccount?: InputMaybe<Scalars['Boolean']>;
  invoiceStatuses?: InputMaybe<Array<InvoiceStatus>>;
  mobile?: InputMaybe<TelephoneInput>;
  mobileLine?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<VoidableStatus>>;
};

export type ParentIndex = {
  __typename?: 'ParentIndex';
  _id: Scalars['ObjectId'];
  address?: Maybe<UserAddress>;
  alias?: Maybe<Scalars['String']>;
  alternateUserId?: Maybe<Scalars['String']>;
  alternativeEmails?: Maybe<Array<Scalars['String']>>;
  avatarUrl?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Decimal']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  hasStatementOfAccount?: Maybe<Scalars['Boolean']>;
  homePhone?: Maybe<Telephone>;
  invoiceStatuses?: Maybe<Array<InvoiceStatus>>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  linkedWhatsappAccount?: Maybe<ParentWhatsappAccountIndex>;
  linkedWhatsappAccountId?: Maybe<Scalars['ObjectId']>;
  mobile: Telephone;
  mobileLine?: Maybe<Scalars['String']>;
  officePhone?: Maybe<Telephone>;
  onHold?: Maybe<Scalars['Decimal']>;
  overdue?: Maybe<Scalars['Decimal']>;
  paid?: Maybe<Scalars['Decimal']>;
  pending?: Maybe<Scalars['Decimal']>;
  salutation?: Maybe<Scalars['String']>;
  status?: Maybe<VoidableStatus>;
  students?: Maybe<Array<ParentStudentIndex>>;
  unpaid?: Maybe<Scalars['Decimal']>;
  userId?: Maybe<Scalars['String']>;
  whatsAppName?: Maybe<Scalars['String']>;
};

export type ParentIndexPage = {
  __typename?: 'ParentIndexPage';
  items: Array<ParentIndex>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/**
 * The object containing the necessary fields used in the lesson plan
 *   that is copied from the referenced LessonPlan entity.
 */
export type ParentLessonPlan = {
  __typename?: 'ParentLessonPlan';
  /** The date when the lesson plan was copied. */
  copiedDate: Scalars['DateTime'];
  /** Reference to the lesson plan copied. */
  id: Scalars['ObjectId'];
  /** The copied name of the lesson plan copied. */
  name: Scalars['String'];
};

export type ParentPage = {
  __typename?: 'ParentPage';
  items: Array<Parent>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type ParentStudentIndex = {
  __typename?: 'ParentStudentIndex';
  _id: Scalars['ObjectId'];
  fullName: Scalars['String'];
};

export type ParentWhatsappAccountIndex = {
  __typename?: 'ParentWhatsappAccountIndex';
  _id: Scalars['ObjectId'];
  number?: Maybe<Telephone>;
};

export type Payment = {
  __typename?: 'Payment';
  _id: Scalars['ObjectId'];
  amount?: Maybe<Scalars['Decimal']>;
  balanceAmount?: Maybe<Scalars['Decimal']>;
  bankName?: Maybe<Scalars['String']>;
  cashAmount?: Maybe<Scalars['Decimal']>;
  chequeNumber?: Maybe<Scalars['String']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  depositDate?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Array<PaymentDetail>>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isVoid?: Maybe<Scalars['Boolean']>;
  lastDigits?: Maybe<Scalars['String']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  method: Scalars['String'];
  payForInvoiceAmount?: Maybe<Scalars['Decimal']>;
  /** The date when the payment was made */
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentProcessor?: Maybe<Scalars['String']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedBy?: Maybe<SystemUser>;
  receipt?: Maybe<Receipt>;
  reference?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  xeroPaymentSyncStatus?: Maybe<XeroSyncStatus>;
  xeroPrepayments?: Maybe<Array<XeroPrepayment>>;
};

export type PaymentDetail = {
  __typename?: 'PaymentDetail';
  amount?: Maybe<Scalars['Decimal']>;
  /** Memory calculate,not save to db. */
  balanceAmount?: Maybe<Scalars['Decimal']>;
  invoice?: Maybe<Invoice>;
  invoiceId: Scalars['ObjectId'];
  xeroPrepayments?: Maybe<Array<XeroPrepayment>>;
};

export enum PaymentMode {
  AtmTransfer = 'ATMTransfer',
  BankTransfer = 'BankTransfer',
  Cash = 'Cash',
  Cheque = 'Cheque',
  Credit = 'Credit',
  CreditCard = 'CreditCard',
  DbsMax = 'DBSMax',
  PayNow = 'PayNow',
  PayNowBank = 'PayNowBank',
  PayNowStripe = 'PayNowStripe'
}

export type Pdf = {
  __typename?: 'Pdf';
  url: Scalars['String'];
};

export type Person = {
  alias?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  mobile: Telephone;
};

export type PhoneNumberInput = {
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type PointCounter = {
  __typename?: 'PointCounter';
  expired?: Maybe<Scalars['Int']>;
  expiring?: Maybe<Scalars['Int']>;
  redemptionsMade?: Maybe<Scalars['Int']>;
  remaining?: Maybe<Scalars['Int']>;
};

export type PreviousAttendanceIndex = {
  __typename?: 'PreviousAttendanceIndex';
  _id: Scalars['ObjectId'];
  classId: Scalars['ObjectId'];
  classShorthand?: Maybe<Scalars['String']>;
  status?: Maybe<AttendanceStatus>;
  type: AttendanceType;
};

export type Promotion = {
  __typename?: 'Promotion';
  _id: Scalars['ObjectId'];
  academicLevels: Array<AcademicLevel>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  promoteToLevel: AcademicLevel;
  scheduledDate: Scalars['DateTime'];
  selectedStudents?: Maybe<Array<Student>>;
  status?: Maybe<PromotionStatus>;
  students?: Maybe<Array<Student>>;
};

export enum PromotionStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending'
}

export type QrCode = {
  __typename?: 'QrCode';
  image: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  academicLevel?: Maybe<AcademicLevel>;
  academicLevels?: Maybe<Array<AcademicLevel>>;
  academicYear?: Maybe<AcademicYear>;
  academicYears?: Maybe<Array<AcademicYear>>;
  activityLogs?: Maybe<ActivityLogPage>;
  admin?: Maybe<Admin>;
  admins?: Maybe<AdminPage>;
  alternativeLessons?: Maybe<Array<Lesson>>;
  arrangement?: Maybe<Arrangement>;
  arrangementWithIndex?: Maybe<ArrangementIndex>;
  arrangements?: Maybe<ArrangementPage>;
  arrangementsWithIndex?: Maybe<ArrangementIndexPage>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  attachedLessonFlows?: Maybe<Array<LessonFlow>>;
  attendanceRemarks?: Maybe<Array<Remark>>;
  attendanceReports?: Maybe<EnrollmentIndexPage>;
  attendanceWithIndex?: Maybe<AttendanceIndex>;
  /** Retrieves the attendances. The results can be filtered usign the filter property. */
  attendances?: Maybe<AttendancePage>;
  attendancesWithIndex?: Maybe<AttendanceIndexPage>;
  auditLog?: Maybe<AuditLog>;
  auditLogs?: Maybe<AuditLogPage>;
  autocompleteAdmin: CreateAdminOutput;
  autocompleteClass: CreateClassOutput;
  autocompleteCourse: CreateCourseOutput;
  autocompleteParent: CreateParentOutput;
  autocompleteStudent: RegisterStudentOutput;
  autocompleteTeacher: CreateTeacherOutput;
  balanceHistories?: Maybe<BalanceHistoryPage>;
  balanceHistory?: Maybe<Invoice>;
  branch?: Maybe<Branch>;
  branches?: Maybe<Array<Branch>>;
  bulkCreateHistory?: Maybe<BulkCreateHistory>;
  bulkExportReceipts?: Maybe<Pdf>;
  bulkExportReminderDocx?: Maybe<Scalars['String']>;
  campaign?: Maybe<Campaign>;
  campaignExportHistories?: Maybe<CampaignExportHistoryPage>;
  campaignExportHistory?: Maybe<CampaignExportHistory>;
  campaigns?: Maybe<CampaignPage>;
  cancellationEffects?: Maybe<CancellationEffects>;
  centre?: Maybe<Centre>;
  centreAssignments?: Maybe<Array<CentreAssignments>>;
  class?: Maybe<Class>;
  classEnrollmentOverview?: Maybe<Array<ClassEnrollmentOverview>>;
  classFromStudent?: Maybe<Class>;
  /** Query to get score submission statistics by Class */
  classSubmissionStats?: Maybe<Array<ClassSubmissionStats>>;
  classWithIndex?: Maybe<ClassIndex>;
  classes?: Maybe<ClassPage>;
  classesFromStudent?: Maybe<ClassPage>;
  classesWithIndex?: Maybe<ClassIndexPage>;
  /** Count unseen feedback records with given set of filters */
  countUnseenFeedback?: Maybe<FeedbackUnseenCount>;
  countryCodes?: Maybe<Array<CountryCode>>;
  courierPickupAddresses?: Maybe<Array<CourierPickupAddress>>;
  course?: Maybe<Course>;
  courseNotification?: Maybe<CourseNotification>;
  courseNotifications?: Maybe<CourseNotificationPage>;
  courseWithIndex?: Maybe<CourseIndex>;
  courses?: Maybe<CoursePage>;
  coursesWithIndex?: Maybe<CourseIndexPage>;
  currentSystemUser?: Maybe<SystemUser>;
  deliveriesWithIndex?: Maybe<DeliveryIndexPage>;
  delivery?: Maybe<Delivery>;
  deliveryWithIndex?: Maybe<DeliveryIndex>;
  department?: Maybe<Department>;
  departments?: Maybe<Array<Department>>;
  discount?: Maybe<Discount>;
  discounts?: Maybe<Array<Discount>>;
  eligibilityFlow?: Maybe<EligibilityFlow>;
  enabledFeatures?: Maybe<Array<FeatureAccess>>;
  enrollment?: Maybe<Enrollment>;
  examScore?: Maybe<ExamScore>;
  examScores?: Maybe<Array<ExamScore>>;
  executeEligibilityFlowWithCourseId?: Maybe<FlowResult>;
  exportAdminCsv?: Maybe<Csv>;
  exportArrangementCsv?: Maybe<Csv>;
  exportAttendanceCsv?: Maybe<Csv>;
  exportAttendanceReportCsv?: Maybe<Csv>;
  exportCampaignDocx?: Maybe<Scalars['String']>;
  exportClassCsv?: Maybe<Csv>;
  exportClassCsvWithIndex?: Maybe<Csv>;
  exportCourseCsv?: Maybe<Csv>;
  exportCourseCsvWithIndex?: Maybe<Csv>;
  exportInvoiceCsv?: Maybe<Csv>;
  exportInvoiceReportCsv?: Maybe<Csv>;
  exportInvoiceRevenueReportCsv?: Maybe<Csv>;
  exportLessonCsv?: Maybe<Csv>;
  exportLessonCsvWithIndex?: Maybe<Csv>;
  exportParentCsv?: Maybe<Csv>;
  exportPaymentReportCsv?: Maybe<Csv>;
  exportPromotionCsv?: Maybe<Csv>;
  exportReceiptsCsv?: Maybe<Csv>;
  exportRefundCsv?: Maybe<Csv>;
  exportRegistrationCsv?: Maybe<Csv>;
  exportReminderDocx?: Maybe<Scalars['String']>;
  exportStatementOfAccountCsv?: Maybe<Csv>;
  exportStudentCsv?: Maybe<Csv>;
  exportStudentReportCsv?: Maybe<Csv>;
  /** Query to process submission audit logs to CSV */
  exportSubmissionAuditLog?: Maybe<Csv>;
  /** Query for exporting submission tracking report. */
  exportSubmissionTracking?: Maybe<Csv>;
  exportSystemUserCsv?: Maybe<Csv>;
  exportTeacherCsv?: Maybe<Csv>;
  /** Mutation to export list of worksheets to CSV. This will return the url of the generated CSV file that can be downloaded. */
  exportWorkSheets?: Maybe<Csv>;
  features?: Maybe<Array<FeatureAccess>>;
  feedback?: Maybe<Feedback>;
  /** Query to get a single Feedback Seen Receipt record */
  feedbackSeenReceipt?: Maybe<FeedbackSeenReceipt>;
  /** Query to fetch list of Feedback Seen Receipt records */
  feedbackSeenReceipts?: Maybe<FeedbackSeenReceiptPage>;
  feedbacks?: Maybe<FeedbackPage>;
  generateUserId?: Maybe<Scalars['String']>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  getAcademicWeeksByCourseId?: Maybe<AcademicWeekData>;
  getEligibilityFlowWithCourseId?: Maybe<EligibilityFlow>;
  getMailMerge?: Maybe<MailMerge>;
  getNotificationConfigurations?: Maybe<Array<NotificationSetting>>;
  getPendingOverdueTask: Array<TaskCount>;
  /** Query to get remarks with suitability information (if there is) for the student */
  getStudentRemarks?: Maybe<Array<StudentRemarks>>;
  getStudentsBirthDate?: Maybe<Array<Scalars['Date']>>;
  /** Get topics by one or more courseIds. */
  getTopicsByCourseIds?: Maybe<Array<Topic>>;
  /** Get topics by lesson flow ID. Check for related packing list envelope status if it is set to "Printed"/ "Packed for delivery". If so, retrieve topics that are only found within the packing list. Otherwise, retrieve all topics under parent course. */
  getTopicsByLessonFlowId?: Maybe<Array<Topic>>;
  /** Get worksheets by lesson flow ID. Check for related packing list envelope status if it is set to "Printed"/ "Packed for delivery". If so, retrieve worksheets that are only found within the packing list. Otherwise, retrieve all worksheets under parent course. */
  getWorksheetsByLessonFlowId?: Maybe<Array<WorkSheet>>;
  /** Query to get score submission statistics by Gradable (e.g. Exercise, Homework) */
  gradableSubmissionStats?: Maybe<Array<GradableSubmissionStats>>;
  gradingTemplates: Array<GradingTemplate>;
  /** Query the handouts depending on the search and filter. */
  handouts?: Maybe<HandoutPage>;
  historyPointCounterBaseDateRange?: Maybe<RewardsHistoryCounter>;
  holiday?: Maybe<Holiday>;
  holidayLessonsIncludeEnrollments?: Maybe<LessonPage>;
  holidays?: Maybe<Array<Holiday>>;
  integrations?: Maybe<Array<Integration>>;
  invoice?: Maybe<Invoice>;
  invoiceExport?: Maybe<Pdf>;
  invoiceItemOption?: Maybe<InvoiceItemOption>;
  invoiceItemOptions?: Maybe<Array<InvoiceItemOption>>;
  invoiceReports?: Maybe<InvoiceReportPage>;
  invoiceRevenueReports?: Maybe<InvoiceLineItemPage>;
  invoiceWithIndex?: Maybe<InvoiceIndex>;
  invoices?: Maybe<InvoicePage>;
  invoicesWithIndex?: Maybe<InvoiceIndexPage>;
  isCancelArrangementHasFullLesson?: Maybe<Scalars['Boolean']>;
  lesson?: Maybe<Lesson>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  lessonFlow?: Maybe<LessonFlow>;
  /**
   * Query Lesson flow item by ID with mapped lesson sequence information
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  lessonFlowItemWithSequence?: Maybe<LessonFlowItem>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  lessonFlowItems?: Maybe<Array<LessonFlowItem>>;
  /**
   * Query Lesson flow items by parent Lesson Flow ID with mapped lesson sequence information
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  lessonFlowItemsWithSequence?: Maybe<LessonFlowItemPage>;
  /** @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation. */
  lessonFlows?: Maybe<LessonFlowPage>;
  /** Get a lesson plan by id. */
  lessonPlan?: Maybe<LessonPlan>;
  /** Get all the worksheets of a lesson plan that can be added to a given packing list envelope. */
  lessonPlanPackingListEnvelopeWorksheets?: Maybe<Array<LessonFlowWorksheetBaseItem>>;
  /** Gets a paginated list of lesson plans of a course. */
  lessonPlans?: Maybe<LessonPlanPage>;
  lessons?: Maybe<LessonPage>;
  lessonsWithIndex?: Maybe<LessonIndexPage>;
  /** Query to list students with award point details */
  listStudentAwardPointsDetails?: Maybe<StudentAwardDetailsPage>;
  /** Retrieves the material. Can be filtered by ID associated with the material. */
  material?: Maybe<Material>;
  /** Get material with index */
  materialWithIndex?: Maybe<MaterialIndex>;
  /** Retrieves the materials. Can be filtered by input associated with the materials. */
  materials?: Maybe<MaterialPage>;
  /** Get materials with index */
  materialsWithIndex?: Maybe<MaterialIndexPage>;
  packingList?: Maybe<PackingList>;
  /** Gets the latest packing list configuration. */
  packingListConfiguration: PackingListConfiguration;
  /** Retrieves the packing lists. Can be filtered by course IDs associated with the packing list. */
  packingLists?: Maybe<PackingListsPage>;
  parent?: Maybe<Parent>;
  parentWithIndex?: Maybe<ParentIndex>;
  parents?: Maybe<ParentPage>;
  parentsWithIndex?: Maybe<ParentIndexPage>;
  previewAdHocInvoice?: Maybe<Invoice>;
  promotion?: Maybe<Promotion>;
  promotions?: Maybe<Array<Promotion>>;
  receipt?: Maybe<Receipt>;
  receiptExport?: Maybe<Pdf>;
  receiptWithIndex?: Maybe<ReceiptIndex>;
  receipts?: Maybe<ReceiptPage>;
  receiptsWithIndex?: Maybe<ReceiptIndexPage>;
  referenceData?: Maybe<ReferenceData>;
  referenceDatas?: Maybe<Array<ReferenceData>>;
  refund?: Maybe<Refund>;
  refundExport?: Maybe<Pdf>;
  refunds?: Maybe<RefundPage>;
  registration?: Maybe<Registration>;
  registrationWithIndex?: Maybe<RegistrationIndex>;
  registrations?: Maybe<RegistrationPage>;
  registrationsWithIndex?: Maybe<RegistrationIndexPage>;
  /** Get a paginated list of remarks that are filtered with the filterInput. Requires `subjectId` and `type`. */
  remarks?: Maybe<RemarkPage>;
  reminder?: Maybe<Reminder>;
  reminders?: Maybe<ReminderPage>;
  replacementCreditWithCourse?: Maybe<StudentReplacementCreditDto>;
  revenueCoursesWithIndex?: Maybe<CourseIndexPage>;
  reward?: Maybe<Reward>;
  rewards?: Maybe<RewardPage>;
  /** This is a query that returns a list of `Rewards` that have been awarded to students. This contains information about what the current status of the reward is long with ordering and shipping information. */
  rewardsHistory?: Maybe<RewardHistoryPage>;
  school?: Maybe<School>;
  schools?: Maybe<Array<School>>;
  seatsAnalyticsByAcademicLevel?: Maybe<SeatAnalytics>;
  sessions?: Maybe<Array<Session>>;
  statementOfAccount?: Maybe<StatementOfAccount>;
  statementOfAccountNumber?: Maybe<StatementOfAccountNumber>;
  statementOfAccounts?: Maybe<StatementOfAccountPage>;
  student?: Maybe<Student>;
  studentMerchandise?: Maybe<MerchandisePage>;
  studentReportFilter?: Maybe<StudentReportFilter>;
  studentReportFilters?: Maybe<Array<StudentReportFilter>>;
  studentReports?: Maybe<StudentPage>;
  studentWithIndex?: Maybe<StudentIndex>;
  students?: Maybe<StudentPage>;
  studentsWithIndex?: Maybe<StudentIndexPage>;
  submissionAuditLogs?: Maybe<AuditLogPage>;
  submissionTracking?: Maybe<Array<SubmissionTracking>>;
  systemNotification?: Maybe<SystemNotification>;
  systemNotifications?: Maybe<SystemNotificationPage>;
  systemUser?: Maybe<SystemUser>;
  systemUsers?: Maybe<SystemUserPage>;
  task?: Maybe<Task>;
  taskList?: Maybe<TaskList>;
  taskLists?: Maybe<TaskListPage>;
  tasks?: Maybe<TaskPage>;
  tasksWithIndex?: Maybe<TaskIndexPage>;
  teacher?: Maybe<Teacher>;
  teachers?: Maybe<TeacherPage>;
  template?: Maybe<Template>;
  templates?: Maybe<TemplatePage>;
  /** Get a test submission by id. */
  testSubmission?: Maybe<TestSubmission>;
  /** Gets a paginated list of test submissions. */
  testSubmissions?: Maybe<TestSubmissionsPage>;
  topic?: Maybe<Topic>;
  topics?: Maybe<Array<Topic>>;
  upcomingLesson?: Maybe<Lesson>;
  user?: Maybe<User>;
  userCustomiseColumn?: Maybe<UserCustomiseColumn>;
  userCustomiseColumns?: Maybe<UserCustomiseColumnPage>;
  userLogs?: Maybe<LogPage>;
  userNotes?: Maybe<NotePage>;
  users?: Maybe<UserPage>;
  validateEmail?: Maybe<EmailValidation>;
  validateTeacherAndVenue?: Maybe<TeacherAndVenueValidateResult>;
  venue?: Maybe<Venue>;
  venues?: Maybe<Array<Venue>>;
  whatsappAccount?: Maybe<WhatsappAccount>;
  whatsappAccounts?: Maybe<WhatsappAccountPage>;
  whatsappMessage?: Maybe<WhatsappMessage>;
  whatsappMessageCounts?: Maybe<WhatsappMessageCounts>;
  whatsappMessages?: Maybe<WhatsappMessagePage>;
  workSheet?: Maybe<WorkSheet>;
  workSheets?: Maybe<WorkSheetPage>;
  xeroAccounts?: Maybe<Array<XeroAccount>>;
  xeroConsentUrl?: Maybe<Scalars['String']>;
  xeroTokenInfo?: Maybe<XeroIntegration>;
};


export type QueryAcademicLevelArgs = {
  id: Scalars['ObjectId'];
};


export type QueryAcademicLevelsArgs = {
  ids?: InputMaybe<Array<Scalars['ObjectId']>>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
  showHided?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAcademicYearArgs = {
  id?: InputMaybe<Scalars['ObjectId']>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryActivityLogsArgs = {
  pageInfo?: InputMaybe<PageInfo>;
  query: ActivityLogListQuery;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryAdminArgs = {
  id: Scalars['ObjectId'];
};


export type QueryAdminsArgs = {
  filterInput?: InputMaybe<AdminFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryAlternativeLessonsArgs = {
  input: AlternativeLessonsForChangeAttendingClassInput;
};


export type QueryArrangementArgs = {
  id: Scalars['ObjectId'];
};


export type QueryArrangementWithIndexArgs = {
  id: Scalars['ObjectId'];
};


export type QueryArrangementsArgs = {
  filterInput?: InputMaybe<ArrangementFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryArrangementsWithIndexArgs = {
  filter?: InputMaybe<ArrangementFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryAttachedLessonFlowsArgs = {
  workSheetId: Scalars['ObjectId'];
};


export type QueryAttendanceRemarksArgs = {
  id: Scalars['ObjectId'];
  includesStudentRemarks?: InputMaybe<Scalars['Boolean']>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAttendanceReportsArgs = {
  filter?: InputMaybe<StudentEnrollmentFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
};


export type QueryAttendanceWithIndexArgs = {
  id: Scalars['ObjectId'];
};


export type QueryAttendancesArgs = {
  filter?: InputMaybe<StudentAttendanceFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryAttendancesWithIndexArgs = {
  filter?: InputMaybe<StudentAttendanceFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryAuditLogArgs = {
  id: Scalars['ObjectId'];
};


export type QueryAuditLogsArgs = {
  filterInput?: InputMaybe<AuditLogFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryAutocompleteClassArgs = {
  courseId: Scalars['ObjectId'];
  isAdHoc: Scalars['Boolean'];
};


export type QueryAutocompleteCourseArgs = {
  type: CourseType;
};


export type QueryAutocompleteStudentArgs = {
  primaryParentId: Scalars['ObjectId'];
};


export type QueryBalanceHistoriesArgs = {
  filterInput?: InputMaybe<BalanceHistoryFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryBalanceHistoryArgs = {
  id: Scalars['ObjectId'];
};


export type QueryBranchArgs = {
  id: Scalars['ObjectId'];
};


export type QueryBranchesArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBulkCreateHistoryArgs = {
  type: BulkCreateHistoryType;
};


export type QueryBulkExportReceiptsArgs = {
  filterInput?: InputMaybe<ReceiptFilterInput>;
  printBase64?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBulkExportReminderDocxArgs = {
  input: BulkSendReminderInput;
};


export type QueryCampaignArgs = {
  id: Scalars['ObjectId'];
};


export type QueryCampaignExportHistoriesArgs = {
  filterInput?: InputMaybe<CampaignFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryCampaignExportHistoryArgs = {
  id: Scalars['ObjectId'];
};


export type QueryCampaignsArgs = {
  filterInput?: InputMaybe<CampaignFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryCancellationEffectsArgs = {
  id: Scalars['ObjectId'];
};


export type QueryCentreAssignmentsArgs = {
  studentId: Scalars['ObjectId'];
};


export type QueryClassArgs = {
  id: Scalars['ObjectId'];
};


export type QueryClassEnrollmentOverviewArgs = {
  filterInput?: InputMaybe<ClassFilterInput>;
};


export type QueryClassFromStudentArgs = {
  classId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};


export type QueryClassSubmissionStatsArgs = {
  input: ClassSubmissionStatsInput;
};


export type QueryClassWithIndexArgs = {
  id: Scalars['ObjectId'];
};


export type QueryClassesArgs = {
  filterInput?: InputMaybe<ClassFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryClassesFromStudentArgs = {
  courseId?: InputMaybe<Scalars['ObjectId']>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
  studentId: Scalars['ObjectId'];
};


export type QueryClassesWithIndexArgs = {
  filter?: InputMaybe<ClassFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryCountUnseenFeedbackArgs = {
  filterInput?: InputMaybe<FeedbackFilterInput>;
};


export type QueryCourierPickupAddressesArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCourseArgs = {
  id: Scalars['ObjectId'];
};


export type QueryCourseNotificationArgs = {
  id: Scalars['ObjectId'];
};


export type QueryCourseNotificationsArgs = {
  filterInput?: InputMaybe<CourseNotificationFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryCourseWithIndexArgs = {
  id: Scalars['ObjectId'];
};


export type QueryCoursesArgs = {
  filterInput?: InputMaybe<CourseFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryCoursesWithIndexArgs = {
  filter?: InputMaybe<CourseFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryDeliveriesWithIndexArgs = {
  filter?: InputMaybe<DeliveryFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryDeliveryArgs = {
  id: Scalars['ObjectId'];
};


export type QueryDeliveryWithIndexArgs = {
  id: Scalars['ObjectId'];
};


export type QueryDepartmentArgs = {
  id: Scalars['ObjectId'];
};


export type QueryDepartmentsArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDiscountArgs = {
  id: Scalars['ObjectId'];
};


export type QueryDiscountsArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
  showVisible?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEligibilityFlowArgs = {
  id: Scalars['ObjectId'];
};


export type QueryEnrollmentArgs = {
  id: Scalars['ObjectId'];
};


export type QueryExamScoreArgs = {
  id: Scalars['ObjectId'];
};


export type QueryExamScoresArgs = {
  studentId: Scalars['ObjectId'];
};


export type QueryExecuteEligibilityFlowWithCourseIdArgs = {
  courseId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};


export type QueryExportAdminCsvArgs = {
  filterInput?: InputMaybe<AdminFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportArrangementCsvArgs = {
  filterInput?: InputMaybe<ArrangementFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportAttendanceCsvArgs = {
  filter?: InputMaybe<StudentAttendanceFilterInput>;
};


export type QueryExportAttendanceReportCsvArgs = {
  filter?: InputMaybe<StudentEnrollmentFilterInput>;
};


export type QueryExportCampaignDocxArgs = {
  id: Scalars['ObjectId'];
};


export type QueryExportClassCsvArgs = {
  filterInput?: InputMaybe<ClassFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportClassCsvWithIndexArgs = {
  filterInput?: InputMaybe<ClassFilterInput>;
};


export type QueryExportCourseCsvArgs = {
  filterInput?: InputMaybe<CourseFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportCourseCsvWithIndexArgs = {
  filterInput?: InputMaybe<CourseFilterInput>;
};


export type QueryExportInvoiceCsvArgs = {
  filterInput?: InputMaybe<InvoiceFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportInvoiceReportCsvArgs = {
  filter?: InputMaybe<InvoiceReportFilterInput>;
};


export type QueryExportInvoiceRevenueReportCsvArgs = {
  filter: CourseFilterInput;
};


export type QueryExportLessonCsvArgs = {
  filterInput?: InputMaybe<LessonFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportLessonCsvWithIndexArgs = {
  filterInput?: InputMaybe<LessonFilterInput>;
};


export type QueryExportParentCsvArgs = {
  filterInput?: InputMaybe<ParentFilter>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportPaymentReportCsvArgs = {
  filter?: InputMaybe<BalanceHistoryFilterInput>;
};


export type QueryExportReceiptsCsvArgs = {
  filterInput?: InputMaybe<ReceiptFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportRefundCsvArgs = {
  filterInput?: InputMaybe<RefundFilterInput>;
};


export type QueryExportRegistrationCsvArgs = {
  filterInput?: InputMaybe<RegistrationFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportReminderDocxArgs = {
  input: SendReminderInput;
};


export type QueryExportStatementOfAccountCsvArgs = {
  filter?: InputMaybe<StatementOfAccountFilter>;
};


export type QueryExportStudentCsvArgs = {
  columns?: InputMaybe<Array<Scalars['String']>>;
  filterInput?: InputMaybe<StudentFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportStudentReportCsvArgs = {
  filter: StudentReportFilterInput;
};


export type QueryExportSubmissionAuditLogArgs = {
  filterInput?: InputMaybe<SubmissionAuditLogFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportSubmissionTrackingArgs = {
  classId: Scalars['ObjectId'];
  filter?: InputMaybe<SubmissionTrackingFilter>;
  view: Scalars['String'];
};


export type QueryExportSystemUserCsvArgs = {
  filterInput?: InputMaybe<SystemUserQuery>;
};


export type QueryExportTeacherCsvArgs = {
  filterInput?: InputMaybe<TeacherFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportWorkSheetsArgs = {
  filterInput?: InputMaybe<WorkSheetItemInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryFeedbackArgs = {
  id: Scalars['ObjectId'];
};


export type QueryFeedbackSeenReceiptArgs = {
  id: Scalars['ObjectId'];
};


export type QueryFeedbackSeenReceiptsArgs = {
  filterInput?: InputMaybe<FeedbackSeenReceiptsFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryFeedbacksArgs = {
  filterInput?: InputMaybe<FeedbackFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryGenerateUserIdArgs = {
  input: GenerateUserIdInput;
};


export type QueryGetAcademicWeeksByCourseIdArgs = {
  courseId: Scalars['ObjectId'];
};


export type QueryGetEligibilityFlowWithCourseIdArgs = {
  courseId: Scalars['ObjectId'];
};


export type QueryGetMailMergeArgs = {
  id: Scalars['ObjectId'];
};


export type QueryGetNotificationConfigurationsArgs = {
  type: NotificationType;
};


export type QueryGetStudentRemarksArgs = {
  enrollmentId?: InputMaybe<Scalars['ObjectId']>;
  studentId: Scalars['ObjectId'];
};


export type QueryGetTopicsByCourseIdsArgs = {
  input: GetTopicsByCourseIdsInput;
};


export type QueryGetTopicsByLessonFlowIdArgs = {
  input: GetTopicsByLessonFlowIdInput;
};


export type QueryGetWorksheetsByLessonFlowIdArgs = {
  input: GetWorksheetsByLessonFlowIdInput;
};


export type QueryGradableSubmissionStatsArgs = {
  input: GradableSubmissionStatsInput;
};


export type QueryHandoutsArgs = {
  filterInput?: InputMaybe<HandoutFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryHistoryPointCounterBaseDateRangeArgs = {
  dateRange: DateRangeInput;
  studentId: Scalars['ObjectId'];
};


export type QueryHolidayArgs = {
  id: Scalars['ObjectId'];
};


export type QueryHolidayLessonsIncludeEnrollmentsArgs = {
  filterInput?: InputMaybe<LessonFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryHolidaysArgs = {
  filterInput?: InputMaybe<HolidayFilterInput>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ObjectId'];
};


export type QueryInvoiceExportArgs = {
  id: Scalars['ObjectId'];
  printBase64?: InputMaybe<Scalars['Boolean']>;
};


export type QueryInvoiceItemOptionArgs = {
  id: Scalars['ObjectId'];
};


export type QueryInvoiceReportsArgs = {
  filter?: InputMaybe<InvoiceReportFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryInvoiceRevenueReportsArgs = {
  filter: RevenueReportQuery;
  pageInfo?: InputMaybe<PageInfo>;
};


export type QueryInvoiceWithIndexArgs = {
  id: Scalars['ObjectId'];
};


export type QueryInvoicesArgs = {
  filterInput?: InputMaybe<InvoiceFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryInvoicesWithIndexArgs = {
  filter?: InputMaybe<InvoiceFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryIsCancelArrangementHasFullLessonArgs = {
  id: Scalars['ObjectId'];
};


export type QueryLessonArgs = {
  id: Scalars['ObjectId'];
};


export type QueryLessonFlowArgs = {
  id: Scalars['ObjectId'];
};


export type QueryLessonFlowItemWithSequenceArgs = {
  lessonFlowItemId: Scalars['ObjectId'];
};


export type QueryLessonFlowItemsArgs = {
  lessonFlowId: Scalars['ObjectId'];
};


export type QueryLessonFlowItemsWithSequenceArgs = {
  lessonFlowId: Scalars['ObjectId'];
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryLessonFlowsArgs = {
  filterInput?: InputMaybe<LessonFlowFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryLessonPlanArgs = {
  id: Scalars['ObjectId'];
};


export type QueryLessonPlanPackingListEnvelopeWorksheetsArgs = {
  input: LessonPlanWorksheetsInput;
};


export type QueryLessonPlansArgs = {
  filterInput?: InputMaybe<LessonPlansFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryLessonsArgs = {
  filterInput?: InputMaybe<LessonFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryLessonsWithIndexArgs = {
  filter?: InputMaybe<LessonFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryListStudentAwardPointsDetailsArgs = {
  filterInput?: InputMaybe<ListStudentAwardPointsInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryMaterialArgs = {
  id: Scalars['ObjectId'];
};


export type QueryMaterialWithIndexArgs = {
  id: Scalars['ObjectId'];
};


export type QueryMaterialsArgs = {
  filterInput?: InputMaybe<MaterialFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryMaterialsWithIndexArgs = {
  filter?: InputMaybe<MaterialFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryPackingListArgs = {
  id: Scalars['ObjectId'];
};


export type QueryPackingListConfigurationArgs = {
  input: PackingListConfigurationInput;
};


export type QueryPackingListsArgs = {
  filter?: InputMaybe<PackingListsFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfos?: InputMaybe<Array<SortInfo>>;
};


export type QueryParentArgs = {
  id: Scalars['ObjectId'];
};


export type QueryParentWithIndexArgs = {
  id: Scalars['ObjectId'];
};


export type QueryParentsArgs = {
  filterInput?: InputMaybe<ParentFilter>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryParentsWithIndexArgs = {
  filter?: InputMaybe<ParentFilter>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryPreviewAdHocInvoiceArgs = {
  input: CreateAdHocInvoiceCommand;
};


export type QueryPromotionArgs = {
  id: Scalars['ObjectId'];
};


export type QueryReceiptArgs = {
  id: Scalars['ObjectId'];
};


export type QueryReceiptExportArgs = {
  id: Scalars['ObjectId'];
  printBase64?: InputMaybe<Scalars['Boolean']>;
};


export type QueryReceiptWithIndexArgs = {
  id: Scalars['ObjectId'];
};


export type QueryReceiptsArgs = {
  filterInput?: InputMaybe<ReceiptFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryReceiptsWithIndexArgs = {
  filter?: InputMaybe<ReceiptFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryReferenceDataArgs = {
  id: Scalars['ObjectId'];
};


export type QueryReferenceDatasArgs = {
  category?: InputMaybe<ReferenceDataCategory>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRefundArgs = {
  id: Scalars['ObjectId'];
};


export type QueryRefundExportArgs = {
  id: Scalars['ObjectId'];
  printBase64?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRefundsArgs = {
  filterInput?: InputMaybe<RefundFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryRegistrationArgs = {
  id: Scalars['ObjectId'];
};


export type QueryRegistrationWithIndexArgs = {
  id: Scalars['ObjectId'];
};


export type QueryRegistrationsArgs = {
  filterInput?: InputMaybe<RegistrationFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryRegistrationsWithIndexArgs = {
  filterInput?: InputMaybe<RegistrationFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryRemarksArgs = {
  filterInput?: InputMaybe<RemarkFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryReminderArgs = {
  id: Scalars['ObjectId'];
};


export type QueryRemindersArgs = {
  filterInput?: InputMaybe<ReminderFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryReplacementCreditWithCourseArgs = {
  query: StudentReplacementCreditsQuery;
};


export type QueryRevenueCoursesWithIndexArgs = {
  filter?: InputMaybe<CourseFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
};


export type QueryRewardArgs = {
  id: Scalars['ObjectId'];
};


export type QueryRewardsArgs = {
  filterInput?: InputMaybe<RewardFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryRewardsHistoryArgs = {
  filterInput?: InputMaybe<RewardHistoryFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QuerySchoolArgs = {
  id: Scalars['ObjectId'];
};


export type QuerySchoolsArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
  showHided?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySeatsAnalyticsByAcademicLevelArgs = {
  filterInput?: InputMaybe<ClassFilterInput>;
};


export type QuerySessionsArgs = {
  types?: InputMaybe<Array<SessionType>>;
};


export type QueryStatementOfAccountArgs = {
  parentId: Scalars['ObjectId'];
};


export type QueryStatementOfAccountNumberArgs = {
  parentId: Scalars['ObjectId'];
};


export type QueryStatementOfAccountsArgs = {
  filterInput?: InputMaybe<StatementOfAccountFilter>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryStudentArgs = {
  id: Scalars['ObjectId'];
};


export type QueryStudentMerchandiseArgs = {
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
  studentId: Scalars['ObjectId'];
};


export type QueryStudentReportFilterArgs = {
  id: Scalars['ObjectId'];
};


export type QueryStudentReportFiltersArgs = {
  userId: Scalars['ObjectId'];
};


export type QueryStudentReportsArgs = {
  filter?: InputMaybe<StudentReportFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
};


export type QueryStudentWithIndexArgs = {
  id: Scalars['ObjectId'];
};


export type QueryStudentsArgs = {
  filterInput?: InputMaybe<StudentFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryStudentsWithIndexArgs = {
  filter?: InputMaybe<StudentFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QuerySubmissionAuditLogsArgs = {
  filterInput?: InputMaybe<SubmissionAuditLogFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QuerySubmissionTrackingArgs = {
  classId: Scalars['ObjectId'];
  enrollmentType?: InputMaybe<EnrollmentType>;
  filter?: InputMaybe<SubmissionTrackingFilter>;
};


export type QuerySystemNotificationArgs = {
  id: Scalars['ObjectId'];
};


export type QuerySystemNotificationsArgs = {
  filterInput?: InputMaybe<SystemNotificationFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QuerySystemUserArgs = {
  id: Scalars['ObjectId'];
};


export type QuerySystemUsersArgs = {
  filterInput?: InputMaybe<SystemUserQuery>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryTaskArgs = {
  id: Scalars['ObjectId'];
};


export type QueryTaskListArgs = {
  id: Scalars['ObjectId'];
};


export type QueryTaskListsArgs = {
  filterInput?: InputMaybe<TaskListFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryTasksArgs = {
  filterInput?: InputMaybe<TaskFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryTasksWithIndexArgs = {
  filterInput?: InputMaybe<TaskFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryTeacherArgs = {
  id: Scalars['ObjectId'];
};


export type QueryTeachersArgs = {
  filterInput?: InputMaybe<TeacherFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryTemplateArgs = {
  id: Scalars['ObjectId'];
};


export type QueryTemplatesArgs = {
  filterInput?: InputMaybe<TemplateFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryTestSubmissionArgs = {
  id: Scalars['ObjectId'];
};


export type QueryTestSubmissionsArgs = {
  filterInput?: InputMaybe<TestSubmissionFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryTopicArgs = {
  id: Scalars['ObjectId'];
};


export type QueryTopicsArgs = {
  filterInput?: InputMaybe<TopicsInput>;
};


export type QueryUpcomingLessonArgs = {
  input?: InputMaybe<UpcomingLessonInput>;
};


export type QueryUserArgs = {
  id: Scalars['ObjectId'];
};


export type QueryUserCustomiseColumnArgs = {
  id: Scalars['ObjectId'];
};


export type QueryUserCustomiseColumnsArgs = {
  filterInput?: InputMaybe<UserCustomiseColumnFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryUserLogsArgs = {
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
  userId: Scalars['ObjectId'];
};


export type QueryUserNotesArgs = {
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
  userId: Scalars['ObjectId'];
};


export type QueryUsersArgs = {
  filterInput?: InputMaybe<UserFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryValidateEmailArgs = {
  email: Scalars['String'];
  excludeUserId?: InputMaybe<Scalars['ObjectId']>;
};


export type QueryValidateTeacherAndVenueArgs = {
  input: ValidateTeacherAndVenueInput;
};


export type QueryVenueArgs = {
  input: QueryVenueInput;
};


export type QueryVenuesArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryWhatsappAccountArgs = {
  id: Scalars['ObjectId'];
};


export type QueryWhatsappAccountsArgs = {
  filterInput?: InputMaybe<WhatsappAccountFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryWhatsappMessageArgs = {
  id: Scalars['ObjectId'];
};


export type QueryWhatsappMessageCountsArgs = {
  createDate?: InputMaybe<DateTimeRangeInput>;
  id?: InputMaybe<Scalars['ObjectId']>;
};


export type QueryWhatsappMessagesArgs = {
  filterInput?: InputMaybe<WhatsappMessageFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryWorkSheetArgs = {
  id: Scalars['ObjectId'];
};


export type QueryWorkSheetsArgs = {
  filterInput?: InputMaybe<WorkSheetItemInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};

export type QueryVenueInput = {
  /** Id of the branch where the venue is located */
  branchId: Scalars['ObjectId'];
  /** Name of the Venue, this is the combination of the venue unit and classroom number e.g 02-5A CR1 */
  name: Scalars['String'];
};

export type Receipt = {
  __typename?: 'Receipt';
  _id: Scalars['ObjectId'];
  amount?: Maybe<Scalars['Decimal']>;
  amountPaidSnapshot?: Maybe<Scalars['Decimal']>;
  contact?: Maybe<Parent>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailStatus?: Maybe<ReminderStatus>;
  invoicePaidSnapshots?: Maybe<Array<InvoicePaidSnapshot>>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isNotified?: Maybe<Array<NotificationChannel>>;
  isOtherFee?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  parentId?: Maybe<Scalars['ObjectId']>;
  paymentId: Scalars['ObjectId'];
  paymentRecord?: Maybe<Payment>;
  referenceNumber: Scalars['String'];
  status: VoidableStatus;
  waStatus?: Maybe<ReminderStatus>;
};

export type ReceiptAcademicYearIndex = {
  __typename?: 'ReceiptAcademicYearIndex';
  _id: Scalars['ObjectId'];
  year: Scalars['Int'];
};

export type ReceiptClassIndex = {
  __typename?: 'ReceiptClassIndex';
  _id: Scalars['ObjectId'];
  shorthand?: Maybe<Scalars['String']>;
  type?: Maybe<ClassType>;
};

export type ReceiptCourseIndex = {
  __typename?: 'ReceiptCourseIndex';
  _id: Scalars['ObjectId'];
  nameShort: Scalars['String'];
};

export type ReceiptFilterInput = {
  dateRange?: InputMaybe<DateTimeRangeInput>;
  ids?: InputMaybe<Array<Scalars['ObjectId']>>;
  isNotified?: InputMaybe<Array<NotificationChannel>>;
  isOtherFee?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ObjectId']>;
  paymentMethods?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<VoidableStatus>>;
};

export type ReceiptIndex = {
  __typename?: 'ReceiptIndex';
  _id: Scalars['ObjectId'];
  academicYears?: Maybe<Array<ReceiptAcademicYearIndex>>;
  amount?: Maybe<Scalars['Decimal']>;
  amountDue?: Maybe<Scalars['Decimal']>;
  amountPaidSnapshot?: Maybe<Scalars['Decimal']>;
  classes?: Maybe<Array<ReceiptClassIndex>>;
  courses?: Maybe<Array<ReceiptCourseIndex>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailStatus?: Maybe<ReminderStatus>;
  invoicePaidSnapshots?: Maybe<Array<InvoicePaidSnapshot>>;
  invoices?: Maybe<Array<ReceiptInvoiceIndex>>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isNotified?: Maybe<Array<NotificationChannel>>;
  isOtherFee?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  parent?: Maybe<ReceiptParentIndex>;
  parentId?: Maybe<Scalars['ObjectId']>;
  payment?: Maybe<ReceiptPaymentIndex>;
  paymentId: Scalars['ObjectId'];
  referenceNumber: Scalars['String'];
  secondaryParents?: Maybe<Array<ReceiptParentIndex>>;
  status: VoidableStatus;
  students?: Maybe<Array<ReceiptStudentIndex>>;
  waStatus?: Maybe<ReminderStatus>;
};

export type ReceiptIndexPage = {
  __typename?: 'ReceiptIndexPage';
  items: Array<ReceiptIndex>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type ReceiptInvoiceIndex = {
  __typename?: 'ReceiptInvoiceIndex';
  _id: Scalars['ObjectId'];
  dueDate?: Maybe<Scalars['DateTime']>;
  total?: Maybe<Scalars['Decimal']>;
};

export type ReceiptPage = {
  __typename?: 'ReceiptPage';
  items: Array<Receipt>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type ReceiptParentIndex = {
  __typename?: 'ReceiptParentIndex';
  _id: Scalars['ObjectId'];
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  mobile: Telephone;
};

export type ReceiptPaymentIndex = {
  __typename?: 'ReceiptPaymentIndex';
  _id: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  method: Scalars['String'];
  /** The date when the payment was made */
  paymentDate?: Maybe<Scalars['DateTime']>;
  reference?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type ReceiptStudentIndex = {
  __typename?: 'ReceiptStudentIndex';
  _id: Scalars['ObjectId'];
  fullName: Scalars['String'];
};

export type RecordExamScoreInput = {
  academicLevelId: Scalars['ObjectId'];
  examType: Scalars['String'];
  marksPercentage: Scalars['Float'];
  mcq?: InputMaybe<ScoreRecordInput>;
  practical?: InputMaybe<ScoreRecordInput>;
  qeq?: InputMaybe<ScoreRecordInput>;
  scoreGrade: Scalars['String'];
  stream: Scalars['String'];
  studentId: Scalars['ObjectId'];
  subject: Scalars['String'];
  totalMarks: ScoreRecordInput;
  year: Scalars['Int'];
};

export type ReferenceData = {
  __typename?: 'ReferenceData';
  _id: Scalars['ObjectId'];
  category: ReferenceDataCategory;
  countryCode?: Maybe<Scalars['String']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  key: Scalars['String'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export enum ReferenceDataCategory {
  AcademicStream = 'AcademicStream',
  ExamType = 'ExamType',
  Gender = 'Gender',
  NameOfBank = 'NameOfBank',
  PaymentProcessor = 'PaymentProcessor',
  RewardCategory = 'RewardCategory',
  Salutation = 'Salutation',
  Subject = 'Subject'
}

export type Refund = {
  __typename?: 'Refund';
  _id: Scalars['ObjectId'];
  adminFee?: Maybe<Scalars['Decimal']>;
  amount?: Maybe<Scalars['Decimal']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  chequeNumber?: Maybe<Scalars['String']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailStatus?: Maybe<ReminderStatus>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isVoid?: Maybe<Scalars['Boolean']>;
  lastDigits?: Maybe<Scalars['String']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  parent?: Maybe<Parent>;
  parentId: Scalars['ObjectId'];
  payNowNumber?: Maybe<Scalars['String']>;
  paymentProcessor?: Maybe<Scalars['String']>;
  processedDate?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  refundMethod?: Maybe<PaymentMode>;
  refundedDate?: Maybe<Scalars['DateTime']>;
  snapshot?: Maybe<RefundSnapshot>;
  waStatus?: Maybe<ReminderStatus>;
};

export type RefundFilterInput = {
  dateRange?: InputMaybe<DateTimeRangeInput>;
  isVoid?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ObjectId']>;
  refundMethods?: InputMaybe<Array<Scalars['String']>>;
};

export type RefundPage = {
  __typename?: 'RefundPage';
  items: Array<Refund>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type RefundSnapshot = {
  __typename?: 'RefundSnapshot';
  parentFullName: Scalars['String'];
};

export type RegenerateRegistrationInput = {
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ObjectId'];
  selectedLessons?: InputMaybe<Array<SelectedLessonInput>>;
  startDate: Scalars['DateTime'];
};

export type RegisterStudentInput = {
  academicLevelId: Scalars['ObjectId'];
  address?: InputMaybe<UserAddressInput>;
  alias: Scalars['String'];
  alternateUserId?: InputMaybe<Scalars['String']>;
  alternativeEmails?: InputMaybe<Array<Scalars['String']>>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  consent: ConsentInput;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender: Scalars['String'];
  homePhone?: InputMaybe<TelephoneInput>;
  mobile?: InputMaybe<TelephoneInput>;
  parentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  primaryParentId: Scalars['ObjectId'];
  primarySchoolId?: InputMaybe<Scalars['ObjectId']>;
  primaryStream?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  secondarySchoolId?: InputMaybe<Scalars['ObjectId']>;
  secondaryStream?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type RegisterStudentOutput = {
  __typename?: 'RegisterStudentOutput';
  academicLevelId: Scalars['ObjectId'];
  address?: Maybe<UserAddressOutput>;
  alias: Scalars['String'];
  alternateUserId?: Maybe<Scalars['String']>;
  alternativeEmails?: Maybe<Array<Scalars['String']>>;
  avatarUrl?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  consent: ConsentOutput;
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender: Scalars['String'];
  homePhone?: Maybe<TelephoneOutput>;
  mobile?: Maybe<TelephoneOutput>;
  parentIds?: Maybe<Array<Scalars['ObjectId']>>;
  primaryParentId: Scalars['ObjectId'];
  primarySchoolId?: Maybe<Scalars['ObjectId']>;
  primaryStream?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  secondarySchoolId?: Maybe<Scalars['ObjectId']>;
  secondaryStream?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type Registration = {
  __typename?: 'Registration';
  _id: Scalars['ObjectId'];
  approvalDate?: Maybe<Scalars['DateTime']>;
  class?: Maybe<Class>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  currentStep?: Maybe<Scalars['Float']>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  effectedWaitingLists?: Maybe<Array<Registration>>;
  emailStatus?: Maybe<ReminderStatus>;
  enrollment?: Maybe<Enrollment>;
  incompleteDeadline: Scalars['DateTime'];
  invoice?: Maybe<Invoice>;
  invoices?: Maybe<Array<Invoice>>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isPrioritized?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  linkRegistration?: Maybe<Registration>;
  name?: Maybe<Scalars['String']>;
  otherRegistrations?: Maybe<Array<Registration>>;
  processedBy?: Maybe<SystemUser>;
  processedDateTime?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  reminders?: Maybe<Array<Reminder>>;
  reservationsForTrial?: Maybe<Array<Registration>>;
  selectedLessons?: Maybe<Array<SelectedLesson>>;
  session?: Maybe<Session>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: RegistrationsStatus;
  student: Student;
  trialRegistration?: Maybe<Registration>;
  type: RegistrationType;
  updateHistories?: Maybe<Array<WaitingListUpdateHistory>>;
  waStatus?: Maybe<ReminderStatus>;
  waitingListCreatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegistrationClassIndex = {
  __typename?: 'RegistrationClassIndex';
  _id: Scalars['ObjectId'];
  courseId: Scalars['ObjectId'];
  learningArrangement: LearningArrangementType;
  name: Scalars['String'];
};

export type RegistrationEnrollmentIndex = {
  __typename?: 'RegistrationEnrollmentIndex';
  _id: Scalars['ObjectId'];
  followUpStatus?: Maybe<FollowUpStatus>;
  followUpStatusLastModifiedByFullName?: Maybe<Scalars['String']>;
  followUpStatusLastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  group?: Maybe<EnrollmentGroupType>;
  preferredLearningArrangement?: Maybe<LearningArrangementType>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: EnrollmentStatus;
};

export type RegistrationFilterInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  classCode?: InputMaybe<Scalars['String']>;
  classIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  classTypes?: InputMaybe<Array<ClassType>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  createdBy?: InputMaybe<Scalars['ObjectId']>;
  dateRange?: InputMaybe<DateTimeRangeInput>;
  isWaitlisted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<RegistrationsStatus>>;
  studentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  teacherIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  types?: InputMaybe<Array<RegistrationType>>;
  venueIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  weekDays?: InputMaybe<Array<WeekDay>>;
};

export type RegistrationIndex = {
  __typename?: 'RegistrationIndex';
  _id: Scalars['ObjectId'];
  class?: Maybe<RegistrationClassIndex>;
  classCapacity?: Maybe<ClassCapacity>;
  course?: Maybe<Course>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  emailStatus?: Maybe<ReminderStatus>;
  enrollment?: Maybe<RegistrationEnrollmentIndex>;
  invoice?: Maybe<RegistrationInvoiceIndex>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  processedBy?: Maybe<SystemUser>;
  processedDateTime?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: RegistrationsStatus;
  student?: Maybe<RegistrationStudentIndex>;
  type: RegistrationType;
  waStatus?: Maybe<ReminderStatus>;
};

export type RegistrationIndexPage = {
  __typename?: 'RegistrationIndexPage';
  items: Array<RegistrationIndex>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type RegistrationInvoiceIndex = {
  __typename?: 'RegistrationInvoiceIndex';
  _id: Scalars['ObjectId'];
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  status: InvoiceStatus;
};

export type RegistrationPage = {
  __typename?: 'RegistrationPage';
  items: Array<Registration>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type RegistrationSession = {
  __typename?: 'RegistrationSession';
  regenerateStartData?: Maybe<Scalars['Date']>;
  registrationId: Scalars['ObjectId'];
  registrationStage: RegistrationStage;
};

export enum RegistrationStage {
  Regular = 'Regular',
  Reservation = 'Reservation',
  Trial = 'Trial',
  WaitingList = 'WaitingList'
}

export type RegistrationStudentIndex = {
  __typename?: 'RegistrationStudentIndex';
  _id: Scalars['ObjectId'];
  avatarUrl?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export enum RegistrationType {
  Regular = 'Regular',
  Trial = 'Trial'
}

export enum RegistrationsStatus {
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Draft = 'Draft',
  Expired = 'Expired',
  Paid = 'Paid',
  Reserved = 'Reserved',
  Waitlisted = 'Waitlisted'
}

export type RegularMakeUpCreditInput = {
  term1: Scalars['Int'];
  term2: Scalars['Int'];
  term3: Scalars['Int'];
  term4: Scalars['Int'];
};

export type Relationship = {
  __typename?: 'Relationship';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  parentId1: Scalars['ObjectId'];
  parentId2: Scalars['ObjectId'];
};

export type Remark = {
  __typename?: 'Remark';
  _id: Scalars['ObjectId'];
  attendance?: Maybe<Attendance>;
  /** A string that contains a remark in a formatted string that will be formatted further in the front-end. */
  content: Scalars['String'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  enrollmentSuitability?: Maybe<EnrollmentSuitability>;
  isArchived?: Maybe<Scalars['Boolean']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isFlagged?: Maybe<Scalars['Boolean']>;
  /** A boolean that signifies the pinned status of the remark. */
  isPinned?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /** Level type for Remark, used for parent remarks. */
  levelType?: Maybe<RemarkLevelType>;
  /** The ID of a remark this remark is created as a reply for. */
  parentId?: Maybe<Scalars['ObjectId']>;
  /** The `ObjectId` of the `Entity` to bind this remark to. For example, if this remark is for an attendance, use the id of the attendance record. */
  subjectId: Scalars['ObjectId'];
  /** The type of the `Entity` defined from an enum to bind this remark to. For example, if this remark is for an attendance, use the enum value `RemarkSubject.Attendance` */
  type: RemarkType;
};


export type RemarkAttendanceArgs = {
  attendanceId?: InputMaybe<Scalars['ObjectId']>;
};

export type RemarkFilterInput = {
  /** A boolean that signifies the pinned status of the remark. */
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** The ID of a remark this remark is created as a reply for. */
  parentId?: InputMaybe<Scalars['ObjectId']>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
  /** The `ObjectId` of the `Entity` to bind this remark to. For example, if this remark is for an attendance, use the id of the attendance record. */
  subjectId: Scalars['ObjectId'];
  /** The type of the `Entity` defined from an enum to bind this remark to. For example, if this remark is for an attendance, use the enum value `RemarkSubject.Attendance` */
  type: Scalars['String'];
};

export enum RemarkLevelType {
  General = 'General',
  High = 'High',
  Low = 'Low',
  Medium = 'Medium'
}

export type RemarkPage = {
  __typename?: 'RemarkPage';
  items: Array<Remark>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum RemarkType {
  Attendance = 'Attendance',
  Parent = 'Parent',
  Student = 'Student',
  Task = 'Task'
}

/** DEPRECATED, Please don't use this. */
export type RemarkUnion = Remark | StudentRemarks;

export type Reminder = {
  __typename?: 'Reminder';
  _id: Scalars['ObjectId'];
  arrangement?: Maybe<Arrangement>;
  arrangementId?: Maybe<Scalars['ObjectId']>;
  attachmentStatus?: Maybe<ReminderStatus>;
  attendance?: Maybe<Attendance>;
  attendanceId?: Maybe<Scalars['ObjectId']>;
  campaignId?: Maybe<Scalars['ObjectId']>;
  class?: Maybe<Class>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  destination?: Maybe<Scalars['String']>;
  file?: Maybe<ReminderFile>;
  invoiceIds?: Maybe<Array<Scalars['ObjectId']>>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  materialId?: Maybe<Scalars['ObjectId']>;
  message?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ObjectId']>;
  receiptId?: Maybe<Scalars['ObjectId']>;
  receiver?: Maybe<Parent>;
  refundId?: Maybe<Scalars['ObjectId']>;
  registration?: Maybe<Registration>;
  registrationId?: Maybe<Scalars['ObjectId']>;
  scheduledDate?: Maybe<Scalars['DateTime']>;
  sendDate?: Maybe<Scalars['DateTime']>;
  sendStatus?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  sendgridEmailStatus?: Maybe<Scalars['String']>;
  sendgridMessageId?: Maybe<Scalars['String']>;
  status?: Maybe<ReminderStatus>;
  student?: Maybe<Student>;
  studentIds?: Maybe<Array<Scalars['ObjectId']>>;
  subject?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['ObjectId']>;
  template?: Maybe<Template>;
  templateId?: Maybe<Scalars['ObjectId']>;
  type?: Maybe<ReminderType>;
  whatsappMessage?: Maybe<WhatsappMessage>;
  whatsappMessageId?: Maybe<Scalars['ObjectId']>;
};

export type ReminderFile = {
  __typename?: 'ReminderFile';
  content: Scalars['String'];
  disposition: Scalars['String'];
  fileName: Scalars['String'];
  type: Scalars['String'];
};

export type ReminderFilterInput = {
  campaignId?: InputMaybe<Scalars['ObjectId']>;
  dateRange?: InputMaybe<DateTimeRangeInput>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ObjectId']>;
  status?: InputMaybe<Array<ReminderStatus>>;
  templateTypes?: InputMaybe<Array<TemplateType>>;
  types?: InputMaybe<Array<ReminderType>>;
};

export type ReminderPage = {
  __typename?: 'ReminderPage';
  items: Array<Reminder>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type ReminderPreview = {
  __typename?: 'ReminderPreview';
  emailMessage?: Maybe<Scalars['String']>;
  parent?: Maybe<Parent>;
  students?: Maybe<Array<Student>>;
  subject?: Maybe<Scalars['String']>;
  whatsappMessage?: Maybe<Scalars['String']>;
};

export enum ReminderStatus {
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  Failed = 'Failed',
  NotSent = 'NotSent',
  Pending = 'Pending',
  Processing = 'Processing',
  Read = 'Read',
  Sent = 'Sent'
}

export enum ReminderType {
  Email = 'Email',
  Whatsapp = 'Whatsapp'
}

/**
 * Inputs for the Remove LessonFlow Schedule mutation.
 *   Remove a schedule item in a lesson flow.
 */
export type RemoveLessonFlowScheduleInput = {
  /** Id of the lesson flow containing the schedule to be removed. */
  lessonFlowId: Scalars['ObjectId'];
  /** Id of the lesson plan containing the schedule to be removed. */
  lessonPlanId: Scalars['ObjectId'];
  /** Id of the schedule in the lesson flow to remove. */
  scheduleId: Scalars['ObjectId'];
};

/** Indicates the effect of the remove lesson flow schedule operation. */
export type RemoveLessonFlowScheduleResponse = {
  __typename?: 'RemoveLessonFlowScheduleResponse';
  /** The updated lesson plan - removed schedule of lesson flow. */
  updatedLessonPlan: LessonPlan;
  /** The number of lessons updated with the schedule of the lesson flow. */
  updatedLessonsCount: Scalars['Int'];
};

/** Contains the lesson plan id and the class id where the lesson plan should be removed. */
export type RemoveLessonPlanFromClassInput = {
  /** The class to remove the lesson plan. */
  classId: Scalars['ObjectId'];
  /** The lesson plan containing the lesson flows to remove from a class. */
  lessonPlanId: Scalars['ObjectId'];
};

/** Indicates the effect of the remove lesson plan from class operation. */
export type RemoveLessonPlanFromClassResponse = {
  __typename?: 'RemoveLessonPlanFromClassResponse';
  /** The number of lessons removed with lesson flows. */
  lessonFlowsRemovedCount: Scalars['Int'];
  /** The updated class - removed applied lesson plan. */
  updatedClass: Class;
  /** The updated lesson plan - removed applied class. */
  updatedLessonPlan: LessonPlan;
};

export type ReorderLessonFlowItemsInput = {
  lessonFlowItemIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type ReplyFeedbackInput = {
  id: Scalars['ObjectId'];
  text: Scalars['String'];
};

export type Request = {
  processedAt?: Maybe<Scalars['DateTime']>;
  processedBy?: Maybe<Scalars['ObjectId']>;
  processedByUserInfo?: Maybe<SystemUser>;
};

export type ResetDefaultInput = {
  columns?: InputMaybe<Array<ResetUserCustomiseColumnInput>>;
  tableName?: InputMaybe<Scalars['String']>;
};

export type ResetUserCustomiseColumnInput = {
  columnName: Scalars['String'];
  columnShowName?: InputMaybe<Scalars['String']>;
  isRequired: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  ownerId?: InputMaybe<Scalars['ObjectId']>;
  tableName: Scalars['String'];
  tableShowName?: InputMaybe<Scalars['String']>;
  weight: Scalars['Int'];
};

export type RestoreLessonFlowItemVersion = {
  lessonFlowId: Scalars['ObjectId'];
  lessonNumber: Scalars['Int'];
  version: Scalars['Int'];
};

export enum ResultOutcome {
  CanAttend = 'CanAttend',
  CanAttendWithConditional = 'CanAttendWithConditional',
  NotEligible = 'NotEligible'
}

export type RevenueReportQuery = {
  courseId: Scalars['ObjectId'];
  dateRange: DateTimeRangeInput;
};

export type Reward = {
  __typename?: 'Reward';
  _id: Scalars['ObjectId'];
  avatar: Scalars['String'];
  category: Scalars['String'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  galleries?: Maybe<Array<Scalars['String']>>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  points: Scalars['Float'];
  remoteGalleries?: Maybe<Array<Scalars['String']>>;
  stock: Scalars['Float'];
};

export type RewardDetail = {
  __typename?: 'RewardDetail';
  orderUrl?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  reward?: Maybe<Reward>;
  rewardId: Scalars['ObjectId'];
  trackingNumber?: Maybe<Scalars['String']>;
};

/** Reward redemption order information */
export type RewardDetailInput = {
  /** Count of reward items in order */
  quantity: Scalars['Int'];
  /** Reward item id associated with the order */
  rewardId: Scalars['ObjectId'];
  /** Optional order tracking number */
  trackingNumber?: InputMaybe<Scalars['String']>;
};

export type RewardFilterInput = {
  categories?: InputMaybe<Array<Scalars['String']>>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  points?: InputMaybe<Array<NumberRangeInput>>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
  stock?: InputMaybe<Array<NumberRangeInput>>;
};

export type RewardHistory = {
  __typename?: 'RewardHistory';
  _id: Scalars['ObjectId'];
  adjustment: Scalars['Int'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Optional gradable (Exercise or Homework) linked to reward transaction */
  gradableId: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  orderDetail?: Maybe<RewardDetail>;
  orderHistory?: Maybe<Array<OrderHistory>>;
  remarks?: Maybe<Scalars['String']>;
  rewardPoints: Array<RewardPoints>;
  rewardPointsIds: Array<Scalars['ObjectId']>;
  status: RewardHistoryStatus;
  student: Student;
  studentId: Scalars['ObjectId'];
  type: RewardHistoryType;
};

export type RewardHistoryFilterInput = {
  /** Filter by course ID */
  courseId?: InputMaybe<Scalars['ObjectId']>;
  dateRange?: InputMaybe<DateRangeInput>;
  /** Filter Reward History by isArchived status */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Filter Reward History to toggle show archived or show all records. */
  showArchived?: InputMaybe<Scalars['Boolean']>;
  /** Filter Reward History by one or more status */
  statuses?: InputMaybe<Array<RewardHistoryStatus>>;
  /** Filter Reward History by one or more student IDs */
  studentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Filter Reward History by student name search */
  studentName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<RewardHistoryType>;
};

export type RewardHistoryPage = {
  __typename?: 'RewardHistoryPage';
  items: Array<RewardHistory>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum RewardHistoryStatus {
  Cancelled = 'Cancelled',
  Collected = 'Collected',
  HandedToCourier = 'HandedToCourier',
  OrderPending = 'OrderPending',
  Preparing = 'Preparing',
  ReadyForCollection = 'ReadyForCollection'
}

export enum RewardHistoryType {
  ManualAdjustment = 'ManualAdjustment',
  PointRefund = 'PointRefund',
  Redemption = 'Redemption',
  Reward = 'Reward'
}

export type RewardInfo = {
  __typename?: 'RewardInfo';
  /** A List of reward mechanics containing criteria on minimum and maximum scores and how many points will be awarded to each criteria. */
  rewardMechanics?: Maybe<Array<RewardMechanic>>;
  /** This will determine wether a `Reward` is `Active` or `Inactive` which can be used for awarding points to a student based on the mechanics. It could be used to determine if the mechanics in this reward will be applied or not. */
  status?: Maybe<RewardStatus>;
};

/** A Property of Exercise and Homework that contains information about Rewards. */
export type RewardInfoInput = {
  /** A List of reward mechanics containing criteria on minimum and maximum scores and how many points will be awarded to each criteria. */
  rewardMechanics?: InputMaybe<Array<RewardMechanicInput>>;
  /** The status set from Rewards Management. */
  status?: InputMaybe<Scalars['String']>;
};

export type RewardMechanic = {
  __typename?: 'RewardMechanic';
  /** The points that will be awarded if the minimum and maximum conditions are met. */
  award?: Maybe<Scalars['Float']>;
  /** Number used as the current Band number for the reward mechanic. */
  band?: Maybe<Scalars['Float']>;
  /** This holds the maximum and minimum score that determines wether a score is eligible for award. */
  score?: Maybe<EligibleScore>;
};

/** This will contain the Score Eligibility and Award score for the Reward. */
export type RewardMechanicInput = {
  /** The points to award the student. */
  award: Scalars['Int'];
  /** Number used as the current Band count for the reward mechanic. */
  band: Scalars['Int'];
  /** Contains the minimum and maximum score for the mechanic to be active. */
  score: EligibleScoreInput;
};

export type RewardPage = {
  __typename?: 'RewardPage';
  items: Array<Reward>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type RewardPoints = {
  __typename?: 'RewardPoints';
  _id: Scalars['ObjectId'];
  /** Reward Point attributed to a gradable */
  gradableId?: Maybe<Scalars['ObjectId']>;
  points: Scalars['Int'];
  status: RewardPointsStatus;
  used: Scalars['Int'];
};

/** Reward points input to add/deduct by student */
export type RewardPointsItemInput = {
  /** Amount of reward points */
  points: Scalars['Float'];
  /** Student ID on where reward points will be added */
  studentId: Scalars['ObjectId'];
};

export enum RewardPointsStatus {
  Active = 'Active',
  Expired = 'Expired'
}

export enum RewardStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type RewardsHistoryCounter = {
  __typename?: 'RewardsHistoryCounter';
  accumulated: Scalars['Int'];
  redeemed: Scalars['Int'];
};

export type SaveAsNewLessonFlowInput = {
  academicYearId: Scalars['ObjectId'];
  lessonFlowId: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type SaveExerciseInput = {
  create?: InputMaybe<Array<CreateExerciseInput>>;
  delete?: InputMaybe<Array<IdInput>>;
  update?: InputMaybe<Array<UpdateExerciseInput>>;
};

export type SaveHomeworkInput = {
  create?: InputMaybe<Array<CreateHomeworkInput>>;
  delete?: InputMaybe<Array<IdInput>>;
  update?: InputMaybe<Array<UpdateHomeworkInput>>;
};

export type SaveSubmissionsInput = {
  classId: Scalars['ObjectId'];
  create?: InputMaybe<Array<CreateSubmissionInput>>;
  delete?: InputMaybe<Array<DeleteSubmissionInput>>;
  update?: InputMaybe<Array<UpdateSubmissionInput>>;
};

/** Deprecated - No usage last 90 days based on Apollo graphql metrics. */
export type SaveTopicsInput = {
  create: Array<CreateTopicInput>;
  delete: Array<DeleteTopicInput>;
  update: Array<UpdateTopicInput>;
};

export type School = {
  __typename?: 'School';
  _id: Scalars['ObjectId'];
  alias?: Maybe<Scalars['String']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  educationStage: EducationStage;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  systemId?: Maybe<Scalars['String']>;
};

export type ScoreRecord = {
  __typename?: 'ScoreRecord';
  score: Scalars['Float'];
  total: Scalars['Float'];
};

export type ScoreRecordInput = {
  score: Scalars['Float'];
  total: Scalars['Float'];
};

export type ScoreStats = {
  __typename?: 'ScoreStats';
  average?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type Seat = {
  __typename?: 'Seat';
  end?: Maybe<Scalars['DateTime']>;
  seatInfo?: Maybe<Array<ClassSeat>>;
  start?: Maybe<Scalars['DateTime']>;
};

export type SeatAnalytics = {
  __typename?: 'SeatAnalytics';
  enrollmentRating: Scalars['Int'];
  totalAvailableSlots: Scalars['Int'];
  totalStudents: Scalars['Int'];
};

export enum SeatType {
  Bypass = 'Bypass',
  Makeup = 'Makeup',
  Regular = 'Regular'
}

export type SelectedLesson = {
  __typename?: 'SelectedLesson';
  asMakeUp?: Maybe<Scalars['Boolean']>;
  byPass?: Maybe<Scalars['Boolean']>;
  lesson: Lesson;
  makeUpLesson?: Maybe<Lesson>;
};

export type SelectedLessonInput = {
  asMakeUp?: InputMaybe<Scalars['Boolean']>;
  byPass?: InputMaybe<Scalars['Boolean']>;
  lessonId: Scalars['ObjectId'];
  makeUpLessonId?: InputMaybe<Scalars['ObjectId']>;
};

export type SelectedReasonInput = {
  checked?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
};

export type SelectionData = {
  __typename?: 'SelectionData';
  academicWeek?: Maybe<AcademicWeek>;
  lessonNumber?: Maybe<Scalars['Int']>;
  lessonStart?: Maybe<Scalars['DateTime']>;
};

export type SendReminderInput = {
  arrangementId?: InputMaybe<Scalars['ObjectId']>;
  attendanceId?: InputMaybe<Scalars['ObjectId']>;
  classIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  emailInput?: InputMaybe<EmailReminderInput>;
  enrollmentId?: InputMaybe<Scalars['ObjectId']>;
  extraVariables?: InputMaybe<ExtraReminderVariables>;
  invoiceId?: InputMaybe<Scalars['ObjectId']>;
  paymentId?: InputMaybe<Scalars['ObjectId']>;
  receiptId?: InputMaybe<Scalars['ObjectId']>;
  refundId?: InputMaybe<Scalars['ObjectId']>;
  registrationId?: InputMaybe<Scalars['ObjectId']>;
  taskId?: InputMaybe<Scalars['ObjectId']>;
  types: Array<ReminderType>;
  whatsappInput?: InputMaybe<WhatsappReminderInput>;
};

export type SendTestReminderInput = {
  recipient?: InputMaybe<Scalars['String']>;
  senderId?: InputMaybe<Scalars['ObjectId']>;
  templateId: Scalars['ObjectId'];
  type: ReminderType;
  whatsappRecipient?: InputMaybe<TelephoneInput>;
};

/**
 * The Lesson Sequence Item object that contains the exercise ID information (if not a schedule break),
 *     the order and duration of the item as part of the Lesson Flow Item's sequence
 */
export type SequenceItem = {
  __typename?: 'SequenceItem';
  /**
   * The ID of the Lesson Sequence item.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  _id: Scalars['ObjectId'];
  /**
   * Optional description of the sequence item
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  description?: Maybe<Scalars['String']>;
  /**
   * The duration (in minutes) of the exercise / break.
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  duration: Scalars['Float'];
  /**
   * Populated Exercise object in reference to `exerciseId`
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  exercise?: Maybe<Exercise>;
  /**
   * Optional exercise ID. If not set, sequence item will be considered as a break
   * @deprecated Old implementation of Lesson Flow business requirement. Will be replaced by the Lesson Plan implementation.
   */
  exerciseId?: Maybe<Scalars['ObjectId']>;
};

export type Session = {
  __typename?: 'Session';
  _id: Scalars['ObjectId'];
  arrangement?: Maybe<Arrangement>;
  arrangementSession?: Maybe<ArrangementSession>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt: Scalars['DateTime'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  registration?: Maybe<Registration>;
  registrationSession?: Maybe<RegistrationSession>;
  status: SessionStatus;
  type: SessionType;
};

export enum SessionStatus {
  Complete = 'Complete',
  Discard = 'Discard',
  Processing = 'Processing'
}

export enum SessionType {
  Arrangement = 'Arrangement',
  Registration = 'Registration'
}

export type SetLessonFlowItemExceptionsInput = {
  exceptedClassIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  lessonFlowItemId: Scalars['ObjectId'];
};

export type SimulationCapacityInput = {
  classId: Scalars['ObjectId'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  quantity: Scalars['Int'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  type: CapacitySimulationType;
};

export enum SortDirection {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type SortInfo = {
  direction: SortDirection;
  field: Scalars['String'];
};

export type StandardCourseFee = {
  __typename?: 'StandardCourseFee';
  amount: Scalars['Decimal'];
  type: CourseFeeType;
};

export type StandardCourseFeeDo = {
  __typename?: 'StandardCourseFeeDO';
  amount: Scalars['Decimal'];
  type: CourseFeeType;
};

export type StandardCourseFeeInput = {
  amount: Scalars['Decimal'];
  type: CourseFeeType;
};

export type StatementOfAccount = {
  __typename?: 'StatementOfAccount';
  amountAudits?: Maybe<AmountAudit>;
  creditRemain?: Maybe<Scalars['Decimal']>;
  hasUnpaidInvoice?: Maybe<Scalars['Boolean']>;
  id: Scalars['ObjectId'];
  invoices?: Maybe<Array<Invoice>>;
  parent: Parent;
  receipts?: Maybe<Array<Receipt>>;
  refund?: Maybe<Scalars['Decimal']>;
  refunds?: Maybe<Array<Refund>>;
};

export type StatementOfAccountFilter = {
  invoiceStatuses?: InputMaybe<Array<InvoiceStatus>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<VoidableStatus>>;
};

export type StatementOfAccountNumber = {
  __typename?: 'StatementOfAccountNumber';
  balanceHistories?: Maybe<Scalars['Int']>;
  invoices?: Maybe<Scalars['Int']>;
  receipts?: Maybe<Scalars['Int']>;
  refunds?: Maybe<Scalars['Int']>;
};

export type StatementOfAccountPage = {
  __typename?: 'StatementOfAccountPage';
  items: Array<StatementOfAccount>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type Student = {
  __typename?: 'Student';
  _id: Scalars['ObjectId'];
  academicLevel?: Maybe<AcademicLevel>;
  academicMatters?: Maybe<Array<Parent>>;
  address?: Maybe<UserAddress>;
  alias?: Maybe<Scalars['String']>;
  alternateUserId?: Maybe<Scalars['String']>;
  alternativeEmails?: Maybe<Array<Scalars['String']>>;
  attendances?: Maybe<Array<Attendance>>;
  avatarUrl?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  consent?: Maybe<Consent>;
  courses?: Maybe<Array<Course>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  eligibleArrangements?: Maybe<Array<ArrangementType>>;
  email?: Maybe<Scalars['String']>;
  enrollmentMatters?: Maybe<Array<Parent>>;
  enrollments?: Maybe<Array<Enrollment>>;
  examScores?: Maybe<Array<ExamScore>>;
  flagRemark?: Maybe<Remark>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Telephone>;
  isActive: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  mobile?: Maybe<Telephone>;
  parents?: Maybe<Array<Parent>>;
  pinnedRemark?: Maybe<Remark>;
  primaryParent?: Maybe<Parent>;
  primarySchool?: Maybe<School>;
  primaryStream?: Maybe<Scalars['String']>;
  /** Field that resolves to registrations linked to student */
  registrations?: Maybe<Array<Registration>>;
  relationName?: Maybe<Scalars['String']>;
  /** The remarks associated with this student are 'General Remarks' and 'Enrollment Suitability Remarks'. */
  remarks?: Maybe<Array<RemarkUnion>>;
  replacementCredit?: Maybe<StudentReplacementCreditDto>;
  rewardPoints?: Maybe<Array<RewardPoints>>;
  rewardPointsCounter?: Maybe<PointCounter>;
  secondarySchool?: Maybe<School>;
  secondaryStream?: Maybe<Scalars['String']>;
  siblings?: Maybe<Array<Student>>;
  status?: Maybe<VoidableStatus>;
  term?: Maybe<Term>;
  userId?: Maybe<Scalars['String']>;
  voidRemarks?: Maybe<Scalars['String']>;
};


export type StudentAttendancesArgs = {
  filterInput?: InputMaybe<StudentAttendanceFilterInput>;
};


export type StudentEnrollmentsArgs = {
  filterInput?: InputMaybe<StudentEnrollmentFilterInput>;
};


export type StudentRegistrationsArgs = {
  status?: InputMaybe<Array<RegistrationsStatus>>;
};


export type StudentRelationNameArgs = {
  id?: InputMaybe<Scalars['ObjectId']>;
};


export type StudentRemarksArgs = {
  lessonId?: InputMaybe<Scalars['ObjectId']>;
};


export type StudentReplacementCreditArgs = {
  lessonId: Scalars['ObjectId'];
};


export type StudentTermArgs = {
  year: Scalars['Int'];
};

export type StudentAcademicLevelIndex = {
  __typename?: 'StudentAcademicLevelIndex';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type StudentAttendanceFilterInput = {
  attendanceTypes?: InputMaybe<Array<AttendanceType>>;
  classIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  datetimeRange?: InputMaybe<DateTimeRangeInput>;
  ids?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Retrieve all the attendances matching the lesson IDs provided. */
  lessonIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  name?: InputMaybe<Scalars['String']>;
  paymentStatuses?: InputMaybe<Array<InvoiceStatus>>;
  /** Retrieve all the attendances matching the status provided. */
  statuses?: InputMaybe<Array<AttendanceStatus>>;
  teacherIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type StudentAttendanceIndex = {
  __typename?: 'StudentAttendanceIndex';
  _id: Scalars['ObjectId'];
  startDateTime: Scalars['DateTime'];
  status?: Maybe<AttendanceStatus>;
  type: AttendanceType;
};

/** Paginated response for List Students with Award Points detail */
export type StudentAwardDetailsPage = {
  __typename?: 'StudentAwardDetailsPage';
  items: Array<StudentAwardPointsDetail>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetail = {
  __typename?: 'StudentAwardPointsDetail';
  _id: Scalars['ObjectId'];
  academicLevel?: Maybe<AcademicLevel>;
  academicMatters?: Maybe<Array<Parent>>;
  address?: Maybe<UserAddress>;
  alias?: Maybe<Scalars['String']>;
  alternateUserId?: Maybe<Scalars['String']>;
  alternativeEmails?: Maybe<Array<Scalars['String']>>;
  /** Applicable points that can be applied to Student */
  applicablePoints: Scalars['Float'];
  attendances?: Maybe<Array<Attendance>>;
  avatarUrl?: Maybe<Scalars['String']>;
  /** Awarded points that is already applied to Student */
  awardedPoints: Scalars['Float'];
  birthDate?: Maybe<Scalars['DateTime']>;
  consent?: Maybe<Consent>;
  courses?: Maybe<Array<Course>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  eligibleArrangements?: Maybe<Array<ArrangementType>>;
  email?: Maybe<Scalars['String']>;
  enrollmentMatters?: Maybe<Array<Parent>>;
  enrollments?: Maybe<Array<Enrollment>>;
  examScores?: Maybe<Array<ExamScore>>;
  flagRemark?: Maybe<Remark>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Telephone>;
  isActive: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  mobile?: Maybe<Telephone>;
  parents?: Maybe<Array<Parent>>;
  pinnedRemark?: Maybe<Remark>;
  primaryParent?: Maybe<Parent>;
  primarySchool?: Maybe<School>;
  primaryStream?: Maybe<Scalars['String']>;
  /** Field that resolves to registrations linked to student */
  registrations?: Maybe<Array<Registration>>;
  relationName?: Maybe<Scalars['String']>;
  /** The remarks associated with this student are 'General Remarks' and 'Enrollment Suitability Remarks'. */
  remarks?: Maybe<Array<RemarkUnion>>;
  replacementCredit?: Maybe<StudentReplacementCreditDto>;
  rewardPoints?: Maybe<Array<RewardPoints>>;
  rewardPointsCounter?: Maybe<PointCounter>;
  /** Score that is computed for awarding based on reward mechanics */
  score: Scalars['Float'];
  secondarySchool?: Maybe<School>;
  secondaryStream?: Maybe<Scalars['String']>;
  siblings?: Maybe<Array<Student>>;
  status?: Maybe<VoidableStatus>;
  /** Submission record attributed to student */
  submission?: Maybe<Submission>;
  term?: Maybe<Term>;
  /** Overall score for gradable */
  totalScore: Scalars['Float'];
  userId?: Maybe<Scalars['String']>;
  voidRemarks?: Maybe<Scalars['String']>;
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailAttendancesArgs = {
  filterInput?: InputMaybe<StudentAttendanceFilterInput>;
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailEnrollmentsArgs = {
  filterInput?: InputMaybe<StudentEnrollmentFilterInput>;
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailRegistrationsArgs = {
  status?: InputMaybe<Array<RegistrationsStatus>>;
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailRelationNameArgs = {
  id?: InputMaybe<Scalars['ObjectId']>;
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailRemarksArgs = {
  lessonId?: InputMaybe<Scalars['ObjectId']>;
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailReplacementCreditArgs = {
  lessonId: Scalars['ObjectId'];
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailTermArgs = {
  year: Scalars['Int'];
};

/** Object extending Student with a submission record */
export type StudentBulkAwardsErrorResult = {
  __typename?: 'StudentBulkAwardsErrorResult';
  /** Error message on award operation */
  errorMessage: Scalars['String'];
  /** Object ID of Student */
  studentId: Scalars['ObjectId'];
};

/** Object extending Student with a submission record */
export type StudentBulkAwardsResult = {
  __typename?: 'StudentBulkAwardsResult';
  /** Errors in bulk awards operation */
  errors: Array<StudentBulkAwardsErrorResult>;
  /** Student */
  students: Array<Student>;
};

export type StudentEnrollmentFilterInput = {
  academicYearIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  classIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  classStatus?: InputMaybe<Array<ClassStatus>>;
  classTypes?: InputMaybe<Array<ClassType>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  dateTimeRange?: InputMaybe<DateTimeRangeInput>;
  enrollmentTypes?: InputMaybe<Array<EnrollmentType>>;
  isHolidayAffected?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Array<EnrollmentStatus>>;
};

export type StudentEnrollmentIndex = {
  __typename?: 'StudentEnrollmentIndex';
  _id: Scalars['ObjectId'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  status?: Maybe<EnrollmentStatus>;
  type: EnrollmentType;
};

export type StudentExamScoreIndex = {
  __typename?: 'StudentExamScoreIndex';
  _id: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  marksPercentage: Scalars['Float'];
  subject: Scalars['String'];
  year: Scalars['Float'];
};

export type StudentFilterInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  academicYearIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  attendanceStatuses?: InputMaybe<Array<AttendanceStatus>>;
  attendanceType?: InputMaybe<Array<AttendanceType>>;
  birthDateRange?: InputMaybe<DateTimeRangeInput>;
  classIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Optional column names that will be returned in Student data */
  columns?: InputMaybe<Array<Scalars['String']>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Date range filter */
  dateRange?: InputMaybe<DateTimeRangeInput>;
  enrollmentStatuses?: InputMaybe<Array<EnrollmentStatus>>;
  /** Optional gradableId filter on student's rewarded points */
  gradableId?: InputMaybe<Scalars['ObjectId']>;
  lessonDays?: InputMaybe<Array<WeekDay>>;
  lessonIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  mobile?: InputMaybe<TelephoneInput>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ObjectId']>;
  promotionId?: InputMaybe<Scalars['ObjectId']>;
  statuses?: InputMaybe<Array<VoidableStatus>>;
  studentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  studentNames?: InputMaybe<Array<Scalars['String']>>;
  teacherIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
  venueIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type StudentIndex = {
  __typename?: 'StudentIndex';
  _id: Scalars['ObjectId'];
  academicLevel?: Maybe<StudentAcademicLevelIndex>;
  address?: Maybe<UserAddress>;
  alias?: Maybe<Scalars['String']>;
  alternateUserId?: Maybe<Scalars['String']>;
  alternativeEmails?: Maybe<Array<Scalars['String']>>;
  avatarUrl?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  consent?: Maybe<Consent>;
  courseIds?: Maybe<Array<Scalars['ObjectId']>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  enrollments?: Maybe<Array<StudentEnrollmentIndex>>;
  examScores?: Maybe<Array<StudentExamScoreIndex>>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Telephone>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lessonDays?: Maybe<Array<WeekDay>>;
  mobile?: Maybe<Telephone>;
  primaryParent?: Maybe<StudentParentIndex>;
  primaryParentWhatsappAccount?: Maybe<StudentWhatsappAccountIndex>;
  primarySchool?: Maybe<StudentSchoolIndex>;
  primaryStream?: Maybe<Scalars['String']>;
  secondaryParentWhatsappAccounts?: Maybe<Array<StudentWhatsappAccountIndex>>;
  secondaryParents?: Maybe<Array<StudentParentIndex>>;
  secondarySchool?: Maybe<StudentSchoolIndex>;
  secondaryStream?: Maybe<Scalars['String']>;
  siblings?: Maybe<Array<StudentSiblingIndex>>;
  status?: Maybe<VoidableStatus>;
  teacherIds?: Maybe<Array<Scalars['ObjectId']>>;
  trialAttendances?: Maybe<Array<StudentAttendanceIndex>>;
  userId?: Maybe<Scalars['String']>;
  venueIds?: Maybe<Array<Scalars['ObjectId']>>;
  voidRemarks?: Maybe<Scalars['String']>;
};

export type StudentIndexPage = {
  __typename?: 'StudentIndexPage';
  items: Array<StudentIndex>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type StudentPage = {
  __typename?: 'StudentPage';
  items: Array<Student>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type StudentParentIndex = {
  __typename?: 'StudentParentIndex';
  _id: Scalars['ObjectId'];
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  mobile: Telephone;
  salutation?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

/** Object based on global remarks entity but extends with suitability information of student on an `Enrollment` record */
export type StudentRemarks = {
  __typename?: 'StudentRemarks';
  _id: Scalars['ObjectId'];
  attendance?: Maybe<Attendance>;
  /** A string that contains a remark in a formatted string that will be formatted further in the front-end. */
  content: Scalars['String'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  enrollmentSuitability?: Maybe<EnrollmentSuitability>;
  isArchived?: Maybe<Scalars['Boolean']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isFlagged?: Maybe<Scalars['Boolean']>;
  /** A boolean that signifies the pinned status of the remark. */
  isPinned?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /** Level type for Remark, used for parent remarks. */
  levelType?: Maybe<RemarkLevelType>;
  /**
   * Optional class information tagged with the remark
   * @deprecated Will be removed. Retrieve this from the linked class record.
   */
  linkedClass?: Maybe<Class>;
  /**
   * Optional suitability information tagged with the remark
   * @deprecated Will be removed. Retrieve this from the linked enrollment record.
   */
  linkedSuitability?: Maybe<EnrollmentSuitability>;
  /** The ID of a remark this remark is created as a reply for. */
  parentId?: Maybe<Scalars['ObjectId']>;
  /** The `ObjectId` of the `Entity` to bind this remark to. For example, if this remark is for an attendance, use the id of the attendance record. */
  subjectId: Scalars['ObjectId'];
  /** The type of the `Entity` defined from an enum to bind this remark to. For example, if this remark is for an attendance, use the enum value `RemarkSubject.Attendance` */
  type: RemarkType;
};


/** Object based on global remarks entity but extends with suitability information of student on an `Enrollment` record */
export type StudentRemarksAttendanceArgs = {
  attendanceId?: InputMaybe<Scalars['ObjectId']>;
};

export type StudentReplacementCreditDto = {
  __typename?: 'StudentReplacementCreditDTO';
  academicYear?: Maybe<AcademicYear>;
  academicYearId?: Maybe<Scalars['ObjectId']>;
  course?: Maybe<Course>;
  courseId?: Maybe<Scalars['ObjectId']>;
  currentTerm?: Maybe<StudentReplacementCreditDetailDto>;
  term1?: Maybe<StudentReplacementCreditDetailDto>;
  term2?: Maybe<StudentReplacementCreditDetailDto>;
  term3?: Maybe<StudentReplacementCreditDetailDto>;
  term4?: Maybe<StudentReplacementCreditDetailDto>;
  workshop?: Maybe<StudentReplacementCreditDetailDto>;
};

export type StudentReplacementCreditDetailDto = {
  __typename?: 'StudentReplacementCreditDetailDTO';
  exceptions?: Maybe<Scalars['Int']>;
  termNumber?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  used?: Maybe<Scalars['Int']>;
};

export type StudentReplacementCreditsQuery = {
  academicYearId?: InputMaybe<Scalars['ObjectId']>;
  courseId?: InputMaybe<Scalars['ObjectId']>;
  studentId: Scalars['ObjectId'];
};

export type StudentReportFilter = {
  __typename?: 'StudentReportFilter';
  _id: Scalars['ObjectId'];
  academicLevelIds?: Maybe<Array<Scalars['ObjectId']>>;
  academicLevels?: Maybe<Array<AcademicLevel>>;
  academicYearIds?: Maybe<Array<Scalars['ObjectId']>>;
  academicYears?: Maybe<Array<AcademicYear>>;
  courseIds?: Maybe<Array<Scalars['ObjectId']>>;
  courses?: Maybe<Array<Course>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  dateRange: DateTimeRange;
  dateRangeType: StudentReportFilterDateRangeType;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lessonDays?: Maybe<Array<WeekDay>>;
  name?: Maybe<Scalars['String']>;
  show?: Maybe<Array<StudentReportFilterShowGroup>>;
  teacherIds?: Maybe<Array<Scalars['ObjectId']>>;
  teachers?: Maybe<Array<SystemUser>>;
  user?: Maybe<SystemUser>;
  userId: Scalars['ObjectId'];
  venueIds?: Maybe<Array<Scalars['ObjectId']>>;
  venues?: Maybe<Array<Venue>>;
};

export enum StudentReportFilterDateRangeType {
  Day = 'Day',
  Range = 'Range',
  Week = 'Week'
}

export type StudentReportFilterInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  academicYearIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  dateRange: DateTimeRangeInput;
  dateRangeType: StudentReportFilterDateRangeType;
  lessonDays?: InputMaybe<Array<WeekDay>>;
  name?: InputMaybe<Scalars['String']>;
  show?: InputMaybe<Array<StudentReportFilterShowGroup>>;
  teacherIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  venueIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export enum StudentReportFilterShowGroup {
  Current = 'Current',
  Future = 'Future',
  Past = 'Past'
}

export type StudentSchoolIndex = {
  __typename?: 'StudentSchoolIndex';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type StudentSiblingIndex = {
  __typename?: 'StudentSiblingIndex';
  _id: Scalars['ObjectId'];
  fullName: Scalars['String'];
};

export type StudentWhatsappAccountIndex = {
  __typename?: 'StudentWhatsappAccountIndex';
  _id: Scalars['ObjectId'];
  number?: Maybe<Telephone>;
  parentId: Scalars['ObjectId'];
};

export type Submission = {
  __typename?: 'Submission';
  _id: Scalars['ObjectId'];
  breakdown?: Maybe<MarkBreakDown>;
  class?: Maybe<Class>;
  classId: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  enrollment?: Maybe<Enrollment>;
  enrollmentId: Scalars['ObjectId'];
  gradable?: Maybe<GradableUnion>;
  gradableId: Scalars['ObjectId'];
  grade?: Maybe<Scalars['Float']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
};

export type SubmissionAuditLogFilterInput = {
  dateTimeRange?: InputMaybe<DateRangeInput>;
  descriptions?: InputMaybe<Array<AuditLogDescriptionType>>;
  fieldNames?: InputMaybe<Array<Scalars['String']>>;
  operationTypes?: InputMaybe<Array<AuditLogOperationType>>;
  principalId?: InputMaybe<Scalars['ObjectId']>;
  principalType?: InputMaybe<Array<UserRole>>;
  resourceTypes?: InputMaybe<Array<AuditLogResourceType>>;
};

export type SubmissionDateRange = {
  end?: InputMaybe<Scalars['DateTime']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export type SubmissionTracking = {
  __typename?: 'SubmissionTracking';
  content?: Maybe<Array<Content>>;
  enrollment?: Maybe<Enrollment>;
};

export type SubmissionTrackingFilter = {
  academicLevels?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Will be used to filter class that has this academicYearId */
  academicYearId?: InputMaybe<Scalars['ObjectId']>;
  activities?: InputMaybe<Activities>;
  /** Search by gradable's worksheet topic nand and worksheet title */
  activityName?: InputMaybe<Scalars['String']>;
  dateRange?: InputMaybe<SubmissionDateRange>;
  enrollmentType?: InputMaybe<Array<EnrollmentType>>;
  isGraded?: InputMaybe<Scalars['Boolean']>;
  lessonDays?: InputMaybe<Array<WeekDay>>;
  workSheetId?: InputMaybe<Scalars['ObjectId']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  deleteDraftClass: Scalars['Boolean'];
  healthServiceChanged: Scalars['Boolean'];
  postBranchData: Branch;
  postDepartmentData: Department;
  postFeatureData: Array<FeatureAccess>;
  postImportClassHistory: BulkCreateHistory;
  postImportRegistrationHistory: BulkCreateHistory;
  postIncompleteArrangement: Arrangement;
  postIncompleteRegistration: Registration;
  postLessonFlowUpdate: LessonFlowUpdate;
  postReferenceData: ReferenceData;
  postVenueData: Venue;
  systemNotification: SystemNotification;
  whatsappAccountLoginFailed: WhatsappAccount;
  whatsappAccountStatusChanged: WhatsappAccount;
  whatsappMessageSendFailed: WhatsappMessage;
};


export type SubscriptionPostIncompleteArrangementArgs = {
  type: ArrangementType;
};


export type SubscriptionPostLessonFlowUpdateArgs = {
  lessonFlowId: Scalars['ObjectId'];
};

export type Suitability = {
  __typename?: 'Suitability';
  description?: Maybe<Scalars['String']>;
  isForTrial?: Maybe<Scalars['Boolean']>;
};

export type SuitabilityInput = {
  description?: InputMaybe<Scalars['String']>;
  isForTrial?: InputMaybe<Scalars['Boolean']>;
};

export type SystemNotification = {
  __typename?: 'SystemNotification';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  hyperLinkMessage?: Maybe<Scalars['String']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  links?: Maybe<Array<NotificationHyperLink>>;
  message: Scalars['String'];
  read: Scalars['Boolean'];
  relatedUser?: Maybe<SystemUser>;
  relatedUserId: Scalars['ObjectId'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<SystemNotificationType>;
  whatsappMessage?: Maybe<WhatsappMessage>;
  whatsappMessageId?: Maybe<Scalars['ObjectId']>;
};

export type SystemNotificationFilterInput = {
  relatedUserId?: InputMaybe<Scalars['ObjectId']>;
};

export type SystemNotificationPage = {
  __typename?: 'SystemNotificationPage';
  items: Array<SystemNotification>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SystemNotificationType {
  AsyncRequestFailed = 'AsyncRequestFailed',
  ClassIsAvailableFromFull = 'ClassIsAvailableFromFull',
  ClassSyncGoogleCalendarFailed = 'ClassSyncGoogleCalendarFailed',
  ClassSyncGoogleCalendarSuccess = 'ClassSyncGoogleCalendarSuccess',
  ClassSyncZoomMeetingFailed = 'ClassSyncZoomMeetingFailed',
  ClassSyncZoomMeetingSuccess = 'ClassSyncZoomMeetingSuccess',
  EmailSendFailed = 'EmailSendFailed',
  MessagePreparedFailed = 'MessagePreparedFailed',
  TaskAssignment = 'TaskAssignment',
  WhatsappAbnormalOffline = 'WhatsappAbnormalOffline',
  WhatsappLoginFailed = 'WhatsappLoginFailed',
  WhatsappLogoutFailed = 'WhatsappLogoutFailed',
  WhatsappMessageSendFailed = 'WhatsappMessageSendFailed',
  WhatsappOffline = 'WhatsappOffline',
  WhatsappOnline = 'WhatsappOnline'
}

export type SystemUser = {
  __typename?: 'SystemUser';
  _id: Scalars['ObjectId'];
  alias?: Maybe<Scalars['String']>;
  alternateUserId?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  branchIds?: Maybe<Array<Scalars['ObjectId']>>;
  branches?: Maybe<Array<Branch>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Department>;
  departmentId?: Maybe<Scalars['ObjectId']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  linkedWhatsappNumber?: Maybe<Telephone>;
  mobile?: Maybe<Telephone>;
  officePhone?: Maybe<Telephone>;
  roles?: Maybe<Array<UserRole>>;
  salutation?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type SystemUserPage = {
  __typename?: 'SystemUserPage';
  items: Array<SystemUser>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type SystemUserQuery = {
  departmentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<UserRole>>;
};

export type Task = {
  __typename?: 'Task';
  _id: Scalars['ObjectId'];
  /** Used for type - Operational, Storing admin ids. */
  assigneeIds?: Maybe<Array<Scalars['ObjectId']>>;
  assignees?: Maybe<Array<SystemUser>>;
  attendance?: Maybe<Attendance>;
  /** Used for type - Attendance */
  attendanceId?: Maybe<Scalars['ObjectId']>;
  balance?: Maybe<Balance>;
  /** Used for type - Fee balance */
  balanceId?: Maybe<Scalars['ObjectId']>;
  changeLog?: Maybe<ClassChangeLog>;
  /** Used for type - Reminder Change Class */
  changeLogId?: Maybe<Scalars['ObjectId']>;
  /** Used for type - Operational */
  checklists?: Maybe<Array<TaskChecklist>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Used for type - FollowUpTrial/ReminderLesson */
  creationContext?: Maybe<TaskCreationContext>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Used for type - Operational */
  description?: Maybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  emailStatus?: Maybe<ReminderStatus>;
  enrollment?: Maybe<Enrollment>;
  /** Used for type - Trial and Reservation */
  enrollmentId?: Maybe<Scalars['ObjectId']>;
  flagRemark?: Maybe<Remark>;
  /** Used for type - Lesson Under Holiday, Storing holiday ids. */
  holidayIds?: Maybe<Array<Scalars['ObjectId']>>;
  holidays?: Maybe<Array<Holiday>>;
  invoice?: Maybe<Invoice>;
  /** Used for type - Invoice */
  invoiceId?: Maybe<Scalars['ObjectId']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  /** Used for type - Operational */
  isTemplate?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lesson?: Maybe<Lesson>;
  /** Used for type - Additional Lesson Offer Reminder */
  lessonId?: Maybe<Scalars['ObjectId']>;
  /** Used for type - Operational */
  listId?: Maybe<Scalars['ObjectId']>;
  /** Used for type - Operational */
  name?: Maybe<Scalars['String']>;
  pinnedRemark?: Maybe<Remark>;
  registration?: Maybe<Registration>;
  /** Used for type - Reservation */
  registrationId?: Maybe<Scalars['ObjectId']>;
  remarks?: Maybe<Array<Remark>>;
  status: TaskStatus;
  type: TaskType;
  waStatus?: Maybe<ReminderStatus>;
};


export type TaskRemarksArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};

/** Task ActivityLog date value. */
export type TaskActivityLogDateValue = {
  __typename?: 'TaskActivityLogDateValue';
  previousValue: Scalars['DateTime'];
  value: Scalars['DateTime'];
};

export type TaskChecklist = {
  __typename?: 'TaskChecklist';
  _id: Scalars['ObjectId'];
  /** Storing admin ids. */
  assigneeIds?: Maybe<Array<Scalars['ObjectId']>>;
  assignees?: Maybe<Array<SystemUser>>;
  items?: Maybe<Array<TaskChecklistItem>>;
  name: Scalars['String'];
};

export type TaskChecklistItem = {
  __typename?: 'TaskChecklistItem';
  _id: Scalars['ObjectId'];
  /** Storing admin ids. */
  assigneeIds?: Maybe<Array<Scalars['ObjectId']>>;
  assignees?: Maybe<Array<SystemUser>>;
  isDone: Scalars['Boolean'];
  name: Scalars['String'];
};

export type TaskCount = {
  __typename?: 'TaskCount';
  overdue: Scalars['Int'];
  pending: Scalars['Int'];
  type: TaskType;
};

export type TaskCreationContext = {
  __typename?: 'TaskCreationContext';
  /** TaskOriginRecordType */
  type?: Maybe<TaskOriginRecordType>;
};

export type TaskFilterInput = {
  dueDateRange?: InputMaybe<DateTimeRangeInput>;
  isIncludeAllOverDueTask?: InputMaybe<Scalars['Boolean']>;
  isIncludeTodayCompleteTask?: InputMaybe<Scalars['Boolean']>;
  isIncludeTodayCreateTask?: InputMaybe<Scalars['Boolean']>;
  isIncludeTodayVoidTask?: InputMaybe<Scalars['Boolean']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  listIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  name?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<TaskStatus>>;
  types?: InputMaybe<Array<TaskType>>;
  whatsappAccountIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type TaskIndex = {
  __typename?: 'TaskIndex';
  /** Unique identifier for the record */
  _id: Scalars['ObjectId'];
  /** Used for type - Operational, Storing admin ids. */
  assigneeIds?: Maybe<Array<Scalars['ObjectId']>>;
  attendance?: Maybe<Attendance>;
  /** Used for type - Attendance */
  attendanceId?: Maybe<Scalars['ObjectId']>;
  balance?: Maybe<Balance>;
  /** Used for type - Fee balance */
  balanceId?: Maybe<Scalars['ObjectId']>;
  changeLog?: Maybe<ClassChangeLog>;
  /** Used for type - Reminder Change Class */
  changeLogId?: Maybe<Scalars['ObjectId']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Used for type - FollowUpTrial/ReminderLesson */
  creationContext?: Maybe<TaskCreationContext>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Used for type - Operational */
  description?: Maybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  emailStatus?: Maybe<ReminderStatus>;
  enrollment?: Maybe<Enrollment>;
  /** Used for type - Trial and Reservation */
  enrollmentId?: Maybe<Scalars['ObjectId']>;
  flagRemark?: Maybe<Remark>;
  /** Used for type - Lesson Under Holiday, Storing holiday ids. */
  holidayIds?: Maybe<Array<Scalars['ObjectId']>>;
  holidays?: Maybe<Array<Holiday>>;
  invoice?: Maybe<Invoice>;
  /** Used for type - Invoice */
  invoiceId?: Maybe<Scalars['ObjectId']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  /** Used for type - Operational */
  isTemplate?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lesson?: Maybe<Lesson>;
  /** Used for type - Additional Lesson Offer Reminder */
  lessonId?: Maybe<Scalars['ObjectId']>;
  /** Used for type - Operational */
  listId?: Maybe<Scalars['ObjectId']>;
  /** Used for type - Operational */
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<TaskParentIndex>;
  parentWhatsappAccount?: Maybe<TaskWhatsappAccountIndex>;
  pinnedRemark?: Maybe<Remark>;
  registration?: Maybe<Registration>;
  /** Used for type - Reservation */
  registrationId?: Maybe<Scalars['ObjectId']>;
  remarks?: Maybe<Array<Remark>>;
  status: TaskStatus;
  statusWithSort: Scalars['String'];
  student?: Maybe<TaskStudentIndex>;
  type: TaskType;
  waStatus?: Maybe<ReminderStatus>;
};


export type TaskIndexRemarksArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};

export type TaskIndexPage = {
  __typename?: 'TaskIndexPage';
  items: Array<TaskIndex>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type TaskList = {
  __typename?: 'TaskList';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  generateAt?: Maybe<Scalars['DateTime']>;
  isAutoGenerate: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  parent?: Maybe<TaskList>;
  parentId?: Maybe<Scalars['ObjectId']>;
  startAt?: Maybe<Scalars['DateTime']>;
  weekDay?: Maybe<WeekDay>;
};

export type TaskListFilterInput = {
  weekDays?: InputMaybe<Array<WeekDay>>;
};

export type TaskListPage = {
  __typename?: 'TaskListPage';
  items: Array<TaskList>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TaskOriginRecordType {
  FirstTrialFollowUpTask = 'FirstTrialFollowUpTask',
  GapLessonReminder = 'GapLessonReminder',
  SecondaryTrialFollowUpTask = 'SecondaryTrialFollowUpTask',
  TermBillingDue = 'TermBillingDue',
  TermBillingOverDue = 'TermBillingOverDue',
  TermBillingPending = 'TermBillingPending',
  WorkshopDayContinuouslyLessonReminder = 'WorkshopDayContinuouslyLessonReminder',
  WorkshopDayNonContinuouslyLessonReminder = 'WorkshopDayNonContinuouslyLessonReminder',
  WorkshopWeekLessonReminder = 'WorkshopWeekLessonReminder'
}

export type TaskPage = {
  __typename?: 'TaskPage';
  items: Array<Task>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type TaskParentIndex = {
  __typename?: 'TaskParentIndex';
  _id: Scalars['ObjectId'];
  avatarUrl?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  mobile: Telephone;
  salutation?: Maybe<Scalars['String']>;
};

export enum TaskStatus {
  Complete = 'Complete',
  Overdue = 'Overdue',
  Pending = 'Pending',
  Void = 'Void'
}

export type TaskStudentIndex = {
  __typename?: 'TaskStudentIndex';
  _id: Scalars['ObjectId'];
  alias: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export enum TaskType {
  FollowUpAbsent = 'FollowUpAbsent',
  FollowUpFeeBalance = 'FollowUpFeeBalance',
  FollowUpPayment = 'FollowUpPayment',
  FollowUpReplacementLessonOffer = 'FollowUpReplacementLessonOffer',
  FollowUpReservation = 'FollowUpReservation',
  FollowUpTermBilling = 'FollowUpTermBilling',
  FollowUpTrial = 'FollowUpTrial',
  Operational = 'Operational',
  ReminderAdditionalLesson = 'ReminderAdditionalLesson',
  ReminderAdditionalLessonOffer = 'ReminderAdditionalLessonOffer',
  ReminderChangeClass = 'ReminderChangeClass',
  ReminderLesson = 'ReminderLesson',
  ReminderLessonUnderHoliday = 'ReminderLessonUnderHoliday',
  ReminderTrialLesson = 'ReminderTrialLesson',
  ReminderWorkshopReplacementLesson = 'ReminderWorkshopReplacementLesson'
}

export type TaskWhatsappAccountIndex = {
  __typename?: 'TaskWhatsappAccountIndex';
  _id: Scalars['ObjectId'];
  number?: Maybe<Telephone>;
  parentId: Scalars['ObjectId'];
};

export type Teacher = {
  __typename?: 'Teacher';
  /** Unique identifier for the record */
  _id: Scalars['ObjectId'];
  alias?: Maybe<Scalars['String']>;
  alternateUserId?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  branches?: Maybe<Array<Branch>>;
  /** @deprecated Will be removed in the future. */
  colorCode?: Maybe<Scalars['String']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Department>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  linkUser?: Maybe<User>;
  linkedWhatsappNumber?: Maybe<Telephone>;
  mobile?: Maybe<Telephone>;
  officePhone?: Maybe<Telephone>;
  salutation?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type TeacherAndVenueValidateResult = {
  __typename?: 'TeacherAndVenueValidateResult';
  teacher: Scalars['String'];
  venue: Scalars['String'];
};

export type TeacherFilterInput = {
  gender?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<TelephoneInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type TeacherPage = {
  __typename?: 'TeacherPage';
  items: Array<Teacher>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type Telephone = {
  __typename?: 'Telephone';
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type TelephoneInput = {
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type TelephoneOutput = {
  __typename?: 'TelephoneOutput';
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type Template = {
  __typename?: 'Template';
  _id: Scalars['ObjectId'];
  content?: Maybe<Scalars['String']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailMessage?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  sendFrequency?: Maybe<Scalars['Float']>;
  status: TemplateStatus;
  subject?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: TemplateType;
  usageType?: Maybe<TemplateUsageType>;
  whatsAppMessage?: Maybe<Scalars['String']>;
};

export type TemplateFilterInput = {
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TemplateStatus>;
  type?: InputMaybe<TemplateType>;
  usageTypes?: InputMaybe<Array<TemplateUsageType>>;
};

export type TemplatePage = {
  __typename?: 'TemplatePage';
  items: Array<Template>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TemplateStatus {
  Complete = 'Complete',
  Draft = 'Draft'
}

export enum TemplateType {
  Confirmation = 'Confirmation',
  CopyList = 'CopyList',
  FollowUp = 'FollowUp',
  General = 'General',
  Reminder = 'Reminder',
  System = 'System'
}

export enum TemplateUsageType {
  ConfirmationAdditionalLesson = 'ConfirmationAdditionalLesson',
  ConfirmationAddressVerification = 'ConfirmationAddressVerification',
  ConfirmationInvoice = 'ConfirmationInvoice',
  ConfirmationMaterialsNote = 'ConfirmationMaterialsNote',
  ConfirmationReceipt = 'ConfirmationReceipt',
  ConfirmationRefund = 'ConfirmationRefund',
  ConfirmationRegistration = 'ConfirmationRegistration',
  ConfirmationReplacementLesson = 'ConfirmationReplacementLesson',
  ConfirmationReservation = 'ConfirmationReservation',
  ConfirmationTransfer = 'ConfirmationTransfer',
  ConfirmationTrial = 'ConfirmationTrial',
  ConfirmationWaitingList = 'ConfirmationWaitingList',
  ConfirmationWithdrawal = 'ConfirmationWithdrawal',
  CopyListAdditionalLesson = 'CopyListAdditionalLesson',
  CopyListClass = 'CopyListClass',
  CopyListGml = 'CopyListGML',
  CopyListReplacement = 'CopyListReplacement',
  CopyListSms = 'CopyListSMS',
  FollowUpAbsent = 'FollowUpAbsent',
  FollowUpPayment = 'FollowUpPayment',
  FollowUpPaymentAdvice = 'FollowUpPaymentAdvice',
  FollowUpReplacementLessonOffer = 'FollowUpReplacementLessonOffer',
  FollowUpReservation = 'FollowUpReservation',
  FollowUpTermBilling = 'FollowUpTermBilling',
  FollowUpTrial = 'FollowUpTrial',
  FollowUpWaitingList = 'FollowUpWaitingList',
  General = 'General',
  ReminderAdditionalLesson = 'ReminderAdditionalLesson',
  ReminderAdditionalLessonOffer = 'ReminderAdditionalLessonOffer',
  ReminderAddressVerification = 'ReminderAddressVerification',
  ReminderLesson = 'ReminderLesson',
  ReminderLessonUnderHoliday = 'ReminderLessonUnderHoliday',
  ReminderTeacherOrVenueUpdate = 'ReminderTeacherOrVenueUpdate',
  ReminderTrialLesson = 'ReminderTrialLesson',
  ReminderWorkshopReplacementLesson = 'ReminderWorkshopReplacementLesson',
  SystemDisclaimer = 'SystemDisclaimer',
  SystemDropOffRemarks = 'SystemDropOffRemarks',
  SystemPickupRemarks = 'SystemPickupRemarks',
  SystemTermsConditions = 'SystemTermsConditions'
}

export type Term = {
  __typename?: 'Term';
  _id: Scalars['ObjectId'];
  academicLevels?: Maybe<Array<AcademicLevel>>;
  academicYear: AcademicYear;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  term1Period: DateTimeRange;
  term2Period: DateTimeRange;
  term3Period: DateTimeRange;
  term4Period: DateTimeRange;
  year: Scalars['Int'];
};

/** A single instance of Term Billing Due Date within an academic year. */
export type TermBillingDueDate = {
  __typename?: 'TermBillingDueDate';
  term1?: Maybe<Scalars['DateTime']>;
  term2?: Maybe<Scalars['DateTime']>;
  term3?: Maybe<Scalars['DateTime']>;
  term4?: Maybe<Scalars['DateTime']>;
};

export type TermBillingDueDateInput = {
  term1?: InputMaybe<Scalars['DateTime']>;
  term2?: InputMaybe<Scalars['DateTime']>;
  term3?: InputMaybe<Scalars['DateTime']>;
  term4?: InputMaybe<Scalars['DateTime']>;
};

export type TermInput = {
  academicLevelIds: Array<Scalars['ObjectId']>;
  id?: InputMaybe<Scalars['ObjectId']>;
  term1Period: DateTimeRangeInput;
  term2Period: DateTimeRangeInput;
  term3Period: DateTimeRangeInput;
  term4Period: DateTimeRangeInput;
};

/** The test submission of each student. */
export type TestSubmission = {
  __typename?: 'TestSubmission';
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'];
  /**
   * Indicates whether the test will be marked digitally or
   *     the test has already been marked physically by the teacher.
   *     When not provided, it should default to `Digital`.
   */
  annotationType?: Maybe<TestSubmissionAnnotationType>;
  /** Date time when the test submission was archived. */
  archivedDate?: Maybe<Scalars['DateTime']>;
  /** Class of the student. */
  class: TestSubmissionClass;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Student who took the exam. */
  examinee: TestSubmissionStudent;
  /** The person who exported the test. */
  exportedBy: TestSubmissionOperator;
  /** Date time when the test submission was exported. */
  exportedDate: Scalars['DateTime'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /** Lesson associated with the test that was submitted. */
  lesson: TestSubmissionLesson;
  /**
   * The marked/final version of the test file of the examinee that was submitted/uploaded.
   *     This is required when the test is marked.
   */
  markedResource?: Maybe<TestSubmissionResource>;
  /** Indicates the current state of the test submission. */
  status: TestSubmissionStatus;
  /** Date time when the test was submitted. */
  submissionDate: Scalars['DateTime'];
  /** The person who submitted/uploaded the test file. */
  submittedBy: TestSubmissionOperator;
  /**
   * The original test file of the examinee that was submitted/uploaded.
   *     This is only available for digital test submissions.
   */
  unmarkedResource?: Maybe<TestSubmissionResource>;
  /** The submitted worksheet used as the test. */
  worksheet: TestSubmissionWorksheet;
};

/** Academic week that the lesson is to be used. */
export type TestSubmissionAcademicWeek = {
  __typename?: 'TestSubmissionAcademicWeek';
  /** Reference to the academic week of an academic year. */
  _id: Scalars['ObjectId'];
  /** The date of the last day of the week. */
  endDate: Scalars['DateTime'];
  /** The date of the first day of the week. */
  startDate: Scalars['DateTime'];
  /** The week number in an academic year. */
  weekNumber: Scalars['Int'];
};

export enum TestSubmissionAnnotationType {
  Digital = 'Digital',
  Physical = 'Physical'
}

/** Class where the student is assigned to. */
export type TestSubmissionClass = {
  __typename?: 'TestSubmissionClass';
  /** Reference to the class entity. */
  _id: Scalars['ObjectId'];
  /** Name of the class copied from the class entity. */
  name: Scalars['String'];
  /** Short hand of the class name copied from the class entity. */
  shortName: Scalars['String'];
};

/** Input for `testSubmissionCreate` mutation. */
export type TestSubmissionCreateInput = {
  /**
   * Indicates whether the test will be marked digitally or
   *     the test has already been marked physically by the teacher.
   *     When not provided, it should default to `Digital`.
   */
  annotationType: TestSubmissionAnnotationType;
  /** The student who took the exam. */
  examineeId: Scalars['ObjectId'];
  /** The lesson that the test was taken on. */
  lessonId: Scalars['ObjectId'];
  /**
   * The list of scores (written physically) that the teacher placed on the test.
   * This is required for `annotationType`="Physical" test submission.
   */
  scores?: InputMaybe<Array<TestSubmissionScoreInput>>;
  /** Url to the test file that was submitted. */
  testFileUrl: Scalars['String'];
  /** Url to the thumbnail of the test file that was submitted. */
  testThumbnailFileUrl: Scalars['String'];
  /** The worksheet that was used as a test. */
  worksheetId: Scalars['ObjectId'];
};

/** Input for getting a list of TestSubmissions */
export type TestSubmissionFilterInput = {
  /** Filter the TestSubmission with matching annotationTypes. */
  annotationTypes?: InputMaybe<Array<TestSubmissionAnnotationType>>;
  /** Filter the TestSubmission with matching classIds. */
  classIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Filter the TestSubmissions from end date. */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** Filter the TestSubmission with matching examineeIds */
  examineeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Filter the TestSubmission with matching lessonIds. */
  lessonIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Filter the TestSubmissions from start date. */
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** Filter the TestSubmission with matching statuses. */
  statuses?: InputMaybe<Array<TestSubmissionStatus>>;
};

/** Lesson associated with the test that was submitted. */
export type TestSubmissionLesson = {
  __typename?: 'TestSubmissionLesson';
  /** Reference to the lesson entity. */
  _id: Scalars['ObjectId'];
  /** Academic week that the lesson is to be used on. */
  academicWeek: TestSubmissionAcademicWeek;
  /** Lesson number of the class. */
  lessonNo: Scalars['Int'];
};

/** Input for `testSubmissionMakeDraft` mutation. */
export type TestSubmissionMakeDraftInput = {
  /** The test submission to make into `Draft` */
  testSubmissionId: Scalars['ObjectId'];
};

/** The person who performed the operation. */
export type TestSubmissionOperator = {
  __typename?: 'TestSubmissionOperator';
  /** Reference to the user entity. */
  _id: Scalars['ObjectId'];
  /** The email of the user who submitted the test. */
  email: Scalars['String'];
  /** The token sub (subject) of the user who submitted the test. */
  sub: Scalars['String'];
};

/** Contains the resources submitted for the test. */
export type TestSubmissionResource = {
  __typename?: 'TestSubmissionResource';
  /** The url to the test file that was submitted. */
  testFileUrl: Scalars['String'];
  /** The Url to the thumbnail of the test file that was submitted. */
  testThumbnailFileUrl: Scalars['String'];
};

/** Input for `testSubmissionSaveMarkedResource` mutation. */
export type TestSubmissionSaveMarkedResourceInput = {
  /** The annotated test file. */
  testFileUrl: Scalars['String'];
  /** The entity to perform the operation on. */
  testSubmissionId: Scalars['ObjectId'];
  /** The thumbnail of the annotated test file. */
  testThumbnailFileUrl: Scalars['String'];
};

/** Input for `testSubmissionSaveScores` mutation. */
export type TestSubmissionSaveScoresInput = {
  /**
   * The list of scores (written physically) that the teacher placed on the test.
   * This is required for `annotationType`="Physical" test submission.
   */
  scores: Array<TestSubmissionScoreInput>;
  /** The entity to perform the operation on. */
  testSubmissionId: Scalars['ObjectId'];
};

/**
 * Contains scores of a physical/marked test.
 *   This is required for `annotationType`="Physical" test submission.
 */
export type TestSubmissionScoreInput = {
  /** The section name to provide the score. */
  name: Scalars['String'];
  /** The score to provide the test section. */
  score: Scalars['Float'];
};

export enum TestSubmissionStatus {
  Draft = 'Draft',
  ForReview = 'ForReview',
  Marked = 'Marked',
  Returned = 'Returned',
  Reviewed = 'Reviewed',
  Unmarked = 'Unmarked'
}

/** Student who took the exam. */
export type TestSubmissionStudent = {
  __typename?: 'TestSubmissionStudent';
  /** Reference to the student entity. */
  _id: Scalars['ObjectId'];
  /** The email of the student who took the test (examinee). */
  email: Scalars['String'];
  /** The full name of the student who took the test (examinee). */
  fullName: Scalars['String'];
};

/** The teacher who scored the exam. */
export type TestSubmissionTeacher = {
  __typename?: 'TestSubmissionTeacher';
  /** Reference to the teacher entity. */
  _id: Scalars['ObjectId'];
  /** The email of the teacher. */
  email: Scalars['String'];
  /** The full name of the teacher. */
  fullName: Scalars['String'];
};

/** Contains the test information. */
export type TestSubmissionTest = {
  __typename?: 'TestSubmissionTest';
  /** Unique identifier for the test. */
  _id: Scalars['ObjectId'];
  /** The total number of points that can be acquired in the test. */
  maxTotalScore?: Maybe<Scalars['Int']>;
  /** The name of the test. */
  name: Scalars['String'];
  /** The teacher who scored the exam. */
  scoredBy?: Maybe<TestSubmissionTeacher>;
  /** Date time when the test was scored. */
  scoredDate?: Maybe<Scalars['DateTime']>;
  /** The different section groups in the test. */
  sections: Array<TestSubmissionTestSection>;
  /** The total score of the examinee, which is the sum of all the section scores in the test. */
  totalScore: Scalars['Int'];
};

/** The different section groups in the test. */
export type TestSubmissionTestSection = {
  __typename?: 'TestSubmissionTestSection';
  /** The total number of points that can be acquired in the section. */
  maxScore: Scalars['Int'];
  /** The name of each section in the test. */
  name: Scalars['String'];
  /** The score of the examinee in the section. */
  score: Scalars['Int'];
};

/** Input for `testSubmissionUpdate` mutation. */
export type TestSubmissionUpdateInput = {
  /** The new status to set. */
  status: TestSubmissionStatus;
  /** The test submission to update. */
  testSubmissionId: Scalars['ObjectId'];
};

/** The submitted worksheet used as the test. */
export type TestSubmissionWorksheet = {
  __typename?: 'TestSubmissionWorksheet';
  /** Reference to the worksheet entity. */
  _id: Scalars['ObjectId'];
  /** Contains the test information: name, scores, scoredBy. */
  test: TestSubmissionTest;
  /** Title of the worksheet copied from the worksheet entity. */
  title: Scalars['String'];
  /** The topic assigned to the worksheet. */
  topic: TestSubmissionWorksheetTopic;
};

/** The topic assigned to the worksheet. */
export type TestSubmissionWorksheetTopic = {
  __typename?: 'TestSubmissionWorksheetTopic';
  /** Reference to the topic entity. */
  _id: Scalars['ObjectId'];
  /** Name of the topic copied from the topic entity. */
  name: Scalars['String'];
};

/** A paginated response containing the packing lists being retrieved. */
export type TestSubmissionsPage = {
  __typename?: 'TestSubmissionsPage';
  items: Array<TestSubmission>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type TimeRange = {
  __typename?: 'TimeRange';
  end: Scalars['Time'];
  start: Scalars['Time'];
};

export type TimeRangeInput = {
  end: Scalars['Time'];
  start: Scalars['Time'];
};

export type TimeRangeOutput = {
  __typename?: 'TimeRangeOutput';
  end: Scalars['Time'];
  start: Scalars['Time'];
};

export type Topic = {
  __typename?: 'Topic';
  _id: Scalars['ObjectId'];
  /** List of associated courses */
  courseIds: Array<Scalars['ObjectId']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  /** The worksheets assigned under the current topic. */
  worksheets?: Maybe<Array<WorkSheet>>;
};

/** Input for `topicAddCourse` mutation. */
export type TopicAddCourseInput = {
  /** The course to associate to the topic. */
  courseId: Scalars['ObjectId'];
  /** The topic to add the course. */
  topicId: Scalars['ObjectId'];
};

/** Input for `topicRemoveCourse` mutation. */
export type TopicRemoveCourseInput = {
  /** The course to dissociate from the topic. */
  courseId: Scalars['ObjectId'];
  /** The topic to remove the course. */
  topicId: Scalars['ObjectId'];
};

export type TopicsInput = {
  /** Course Ids that the topic should belong to. Leave null to get all. */
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type TransferCancelInfo = {
  __typename?: 'TransferCancelInfo';
  firstFullLesson?: Maybe<Lesson>;
  isFullFromClass: Scalars['Boolean'];
  isFullFromLesson: Scalars['Boolean'];
};

/** Input for unarchiving the worksheet. */
export type UnarchiveWorkSheetsInput = {
  /** The ids of the worksheets to be unarchived. */
  worksheetIds: Array<Scalars['ObjectId']>;
};

export type UpcomingLessonInput = {
  classId?: InputMaybe<Scalars['ObjectId']>;
  date?: InputMaybe<Scalars['DateTime']>;
  teacherId?: InputMaybe<Scalars['ObjectId']>;
};

export type UpdateAcademicLevelInput = {
  alias?: InputMaybe<Scalars['String']>;
  educationStage?: InputMaybe<EducationStage>;
  id: Scalars['ObjectId'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateAcademicYearInput = {
  academicWeekEnd: WeekDay;
  academicWeekStart: WeekDay;
  id: Scalars['ObjectId'];
  period: DateTimeRangeInput;
  taxRate: Scalars['Int'];
  termBillingDueDate?: InputMaybe<TermBillingDueDateInput>;
  terms: Array<TermInput>;
};

export type UpdateAdminInput = {
  alias?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  branchIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  departmentId?: InputMaybe<Scalars['ObjectId']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  initials?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<TelephoneInput>;
  officePhone?: InputMaybe<TelephoneInput>;
  salutation?: InputMaybe<Scalars['String']>;
  userRole?: InputMaybe<UserRole>;
};

export type UpdateAttendanceLessonModeInput = {
  attendanceId: Scalars['ObjectId'];
  lessonMode?: InputMaybe<LearningArrangementType>;
};

/** Input for updating one or more attendance records. */
export type UpdateAttendancesInput = {
  /** Attendance IDs. */
  ids: Array<Scalars['ObjectId']>;
  /** The status to set the attendance to. */
  status: AttendanceStatus;
};

export type UpdateBranchInput = {
  address?: InputMaybe<AddressInput>;
  alias?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateCampaignInput = {
  emailSubject?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  isIncludeAttachment?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  recipientTypes?: InputMaybe<Array<CampaignRecipientType>>;
  recipients?: InputMaybe<Array<CampaignRecipientInput>>;
  scheduleSendAt?: InputMaybe<Scalars['DateTime']>;
  sendMethod?: InputMaybe<ReminderType>;
  status?: InputMaybe<CampaignStatus>;
  templateId?: InputMaybe<Scalars['ObjectId']>;
};

export type UpdateClassCommand = {
  academicWeekNumber?: InputMaybe<Scalars['Int']>;
  classCode?: InputMaybe<Scalars['String']>;
  classFee?: InputMaybe<StandardCourseFeeInput>;
  defaultLessonDays?: InputMaybe<Array<WeekDay>>;
  defaultLessonEnd?: InputMaybe<Scalars['DateTime']>;
  defaultLessonStart?: InputMaybe<Scalars['DateTime']>;
  digitalClassAccessCredentials?: InputMaybe<DigitalClassAccessCredentialsInput>;
  id: Scalars['ObjectId'];
  learningArrangement?: InputMaybe<LearningArrangementType>;
  /** ID of the Lesson Flow to be assigned to the class. */
  lessonFlowId?: InputMaybe<Scalars['ObjectId']>;
  maximumClassCapacity?: InputMaybe<Scalars['Int']>;
  maximumMakeUpCapacity?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  runNumber?: InputMaybe<Scalars['Int']>;
  shorthand?: InputMaybe<Scalars['String']>;
  startUpdateLessonId?: InputMaybe<Scalars['ObjectId']>;
  teacherId?: InputMaybe<Scalars['ObjectId']>;
  venueId?: InputMaybe<Scalars['ObjectId']>;
};

/** Input used to Update collect details of a LessonFlowItem. */
export type UpdateCollectInput = {
  /** The ID of the collect to update. */
  collectId: Scalars['ObjectId'];
  /** The text of the collect generated from a rich text editor. */
  description: Scalars['String'];
  /** The ID of the LessonFlowItem containing the collect to find. */
  lessonFlowItemId: Scalars['ObjectId'];
};

export type UpdateCourierPickupAddressInput = {
  address?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

export type UpdateCourseInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  academicStreams?: InputMaybe<Array<Scalars['String']>>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  colorCode?: InputMaybe<Scalars['String']>;
  courseFee?: InputMaybe<CourseFeeInput>;
  courseMaterial?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disclaimer?: InputMaybe<Array<Scalars['String']>>;
  duration?: InputMaybe<Scalars['Float']>;
  gamePlanLink?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  isRequireFeedback?: InputMaybe<Scalars['Boolean']>;
  isSkipScheduledHoliday?: InputMaybe<Scalars['Boolean']>;
  learningResourceUrl?: InputMaybe<Scalars['String']>;
  /** ID of the Lesson Flow to be assigned to the class. */
  lessonFlowId?: InputMaybe<Scalars['ObjectId']>;
  makeUpCredits?: InputMaybe<MakeUpCreditInput>;
  name?: InputMaybe<Scalars['String']>;
  nameShort?: InputMaybe<Scalars['String']>;
  numberOfLessons?: InputMaybe<Scalars['Int']>;
  subject?: InputMaybe<Scalars['String']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CourseType>;
};

export type UpdateDeliveryInput = {
  address?: InputMaybe<Scalars['String']>;
  dropOffAddress?: InputMaybe<Scalars['String']>;
  dropOffMobile?: InputMaybe<TelephoneInput>;
  dropOffPostalCode?: InputMaybe<Scalars['String']>;
  dropOffRemarks?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  parcelSize?: InputMaybe<DeliveryParcelSize>;
  pickupEndAt?: InputMaybe<Scalars['DateTime']>;
  pickupStartAt?: InputMaybe<Scalars['DateTime']>;
  remarks?: InputMaybe<Scalars['String']>;
};

export type UpdateDepartmentInput = {
  centreId?: InputMaybe<Scalars['ObjectId']>;
  id: Scalars['ObjectId'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateDiscountInput = {
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountName?: InputMaybe<Scalars['String']>;
  discountRate?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id: Scalars['ObjectId'];
  isVisible?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateEligibilityFlowInput = {
  id: Scalars['ObjectId'];
  startNode: FlowNodeInput;
};

export type UpdateEnrollmentFollowUpStatusInput = {
  followUpStatus: FollowUpStatus;
  id: Scalars['ObjectId'];
};

export type UpdateEnrollmentGroupInput = {
  group?: InputMaybe<EnrollmentGroupType>;
  id: Scalars['ObjectId'];
};

export type UpdateEnrollmentPreferredLearningArrangementInput = {
  id: Scalars['ObjectId'];
  preferredLearningArrangement: LearningArrangementType;
};

/** Input in updating an enrollment record's suitability information */
export type UpdateEnrollmentSuitabilityInput = {
  /** Input in updating an enrollment record's suitability information */
  enrollmentId: Scalars['ObjectId'];
  /** Remarks on the suitability of the student's enrollment */
  remarks?: InputMaybe<Scalars['String']>;
  /** Input in updating an enrollment record's suitability information. Set to `null` to give 'Unassigned' status. */
  status?: InputMaybe<EnrollmentSuitabilityStatus>;
};

/** Input for updating multiple enrollment records. */
export type UpdateEnrollmentsInput = {
  /** Input in updating an enrollment record's suitability information */
  enrollmentId: Scalars['ObjectId'];
  /** Remarks on the suitability of the student's enrollment */
  remarks?: InputMaybe<Scalars['String']>;
  /** Input in updating an enrollment record's suitability information */
  suitabilityStatus?: InputMaybe<EnrollmentSuitabilityStatus>;
};

export type UpdateEnrollmentsPreferredLearningArrangementInput = {
  classId: Scalars['ObjectId'];
  preferredLearningArrangement?: InputMaybe<LearningArrangementType>;
};

export type UpdateExamScoreInput = {
  academicLevelId: Scalars['ObjectId'];
  examType?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  marksPercentage?: InputMaybe<Scalars['Float']>;
  mcq?: InputMaybe<ScoreRecordInput>;
  practical?: InputMaybe<ScoreRecordInput>;
  qeq?: InputMaybe<ScoreRecordInput>;
  scoreGrade?: InputMaybe<Scalars['String']>;
  stream?: InputMaybe<Scalars['String']>;
  studentId: Scalars['ObjectId'];
  subject?: InputMaybe<Scalars['String']>;
  totalMarks?: InputMaybe<ScoreRecordInput>;
  year: Scalars['Int'];
};

export type UpdateExerciseInput = {
  /** An HTML string that is taken from Braft-Editor. */
  content?: InputMaybe<Scalars['String']>;
  /** The duration of the `exercise` in minutes. */
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['ObjectId'];
  /** The index of a `lesson`. This is used to link similar lessons for applied `lessonFlow`s to the `lessonflowItem`s. */
  lessonNumber?: InputMaybe<Scalars['Int']>;
  /** This determines wether the `exercise` requires to be submitted. */
  needSubmit?: InputMaybe<Scalars['Boolean']>;
  /** The a number to set what position in a list the `exercise` is located. */
  order?: InputMaybe<Scalars['Int']>;
  /** Contains the `status` and `mechanics` of an `Activity` which in this case is an `Exercise`. */
  reward?: InputMaybe<RewardInfoInput>;
  /** This is the breakdown of the achievable scores for the `exercise`. The sum of the breakdown must be equal to the `totalGrade` */
  totalBreakdown?: InputMaybe<MarkBreakDownInput>;
  /** This is the total grade that can be achieved in the `exercise`. If `totalBreakdown` exists, it must be equal to the sum of the breakdown. */
  totalGrade?: InputMaybe<Scalars['Int']>;
  /** The ID of the `worksheet` to referrence for the `exercise`. */
  workSheetId?: InputMaybe<Scalars['ObjectId']>;
};

export type UpdateFeatureAccessInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  extendParams?: InputMaybe<FeatureAccessExtendInput>;
  keywords: FeatureAccessKeyword;
};

export type UpdateFeatureAccessesInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  extendParams?: InputMaybe<FeatureAccessExtendInput>;
  keywords?: InputMaybe<Array<FeatureAccessKeyword>>;
};

export type UpdateFeedbackInput = {
  id: Scalars['ObjectId'];
  isResolved?: InputMaybe<Scalars['Boolean']>;
  quoted?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type UpdateGroupAssignmentInput = {
  id: Scalars['ObjectId'];
  items?: InputMaybe<Array<GroupAssignmentItemInput>>;
};

/** Arguments to be used when updating a handout. */
export type UpdateHandoutInput = {
  /** The URL of the file, if the handout is a file. */
  file?: InputMaybe<HandoutFileInput>;
  /** The ID of the handout to update. */
  id: Scalars['ObjectId'];
  /** The instructions for the handout. This is formatted in Markdown */
  instructions?: InputMaybe<Scalars['String']>;
};

export type UpdateHolidayInput = {
  id: Scalars['ObjectId'];
  name?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<DateRangeInput>;
};

export type UpdateHomeworkInput = {
  /** An HTML string that is taken from Braft-Editor. */
  content?: InputMaybe<Scalars['String']>;
  /** The duration of the `homework` in minutes. */
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['ObjectId'];
  /** The index of a `lesson`. This is used to link similar lessons for applied `lessonFlow`s to the `lessonflowItem`s. */
  lessonNumber?: InputMaybe<Scalars['Int']>;
  /** This determines wether the `exercise` requires to be submitted. */
  needSubmit?: InputMaybe<Scalars['Boolean']>;
  /** The a number to set what position in a list the `exercise` is located. */
  order?: InputMaybe<Scalars['Int']>;
  /** Contains the `status` and `mechanics` of an `Ac tivity` which in this case is a `Homework`. */
  reward?: InputMaybe<RewardInfoInput>;
  /** This is the breakdown of the achievable scores for the `exercise`. The sum of the breakdown must be equal to the `totalGrade` */
  totalBreakdown?: InputMaybe<MarkBreakDownInput>;
  /** This is the total grade that can be achieved in the `exercise`. If `totalBreakdown` exists, it must be equal to the sum of the breakdown. */
  totalGrade?: InputMaybe<Scalars['Int']>;
  /** The ID of the `worksheet` to referrence for the `exercise`. */
  workSheetId?: InputMaybe<Scalars['ObjectId']>;
};

/** Update integration command. */
export type UpdateIntegrationCommand = {
  /** Integration application account id */
  accountId?: InputMaybe<Scalars['String']>;
  /** Integration application client id */
  clientId?: InputMaybe<Scalars['String']>;
  /** Integration application client secret */
  clientSecret?: InputMaybe<Scalars['String']>;
  googleCalendarHostEmail?: InputMaybe<Scalars['String']>;
  googleCalendarId?: InputMaybe<Scalars['String']>;
  /** Google credentials */
  googleCredentials?: InputMaybe<Scalars['Upload']>;
  id: Scalars['ObjectId'];
};

export type UpdateInvoiceDueDateCommand = {
  dueDate: Scalars['DateTime'];
  id: Scalars['ObjectId'];
};

export type UpdateInvoiceItemOptionInput = {
  description: Scalars['String'];
  id: Scalars['ObjectId'];
  unitAmount: Scalars['Decimal'];
};

export type UpdateInvoiceLineItemCommand = {
  description: Scalars['String'];
  lineItemId: Scalars['ObjectId'];
  quantity: Scalars['Int'];
  unitAmount: Scalars['Decimal'];
};

export type UpdateLessonAttendanceInput = {
  lessonId: Scalars['ObjectId'];
  status: AttendanceStatus;
  studentIds: Array<Scalars['ObjectId']>;
};

export type UpdateLessonCommand = {
  academicWeekNumber?: InputMaybe<Scalars['Int']>;
  id: Scalars['ObjectId'];
  lessonEnd: Scalars['DateTime'];
  lessonNotes?: InputMaybe<Scalars['String']>;
  lessonStart: Scalars['DateTime'];
  teacherId?: InputMaybe<Scalars['ObjectId']>;
  updateForSubsequentLessons?: InputMaybe<Scalars['Boolean']>;
  venueId?: InputMaybe<Scalars['ObjectId']>;
};

/** An input used to provide values for updating a lesson flow. Currently being used for setting Lesson Flow Items. */
export type UpdateLessonFlowInput = {
  /** Current Lesson Flow ID to update */
  lessonFlowId: Scalars['ObjectId'];
  /** An array of Lesson Flow Item Ids */
  lessonFlowItemIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UpdateLessonFlowItemInput = {
  /** The academic week object to assign to the lessonFlowItem. */
  academicWeek?: InputMaybe<LessonFlowAcademicWeekInput>;
  exceptedClassIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  exerciseInputs?: InputMaybe<SaveExerciseInput>;
  homeworkInputs?: InputMaybe<SaveHomeworkInput>;
  lessonFlowItemId: Scalars['ObjectId'];
  name?: InputMaybe<Scalars['String']>;
  suitability?: InputMaybe<SuitabilityInput>;
};

/**
 * Inputs for the Update LessonFlow Schedule mutation.
 *   Updates an existing lesson flow schedule item.
 *   Passing a different item payload would result in an error.
 */
export type UpdateLessonFlowScheduleInput = {
  /** Required for updating existing lesson plan item of type BREAK. */
  break?: InputMaybe<LessonFlowBreakItemInput>;
  /** Id of the lesson flow containing the schedule item to be edited. */
  lessonFlowId: Scalars['ObjectId'];
  /** Id of the lesson plan containing the schedule item to be edited. */
  lessonPlanId: Scalars['ObjectId'];
  /** Required for updating existing lesson plan item of type OTHER. */
  other?: InputMaybe<LessonFlowOtherItemInput>;
  /** Id of the schedule in the lesson flow to edit. */
  scheduleId: Scalars['ObjectId'];
  /** Required for updating existing lesson plan item of type TEST. */
  test?: InputMaybe<LessonFlowTestItemInput>;
  /** Required for updating existing lesson plan item of type WORKSHEET. */
  worksheet?: InputMaybe<LessonFlowWorkSheetItemInput>;
};

/** Indicates the effect of the update lesson flow schedule operation. */
export type UpdateLessonFlowScheduleResponse = {
  __typename?: 'UpdateLessonFlowScheduleResponse';
  /** The updated lesson plan - edited schedule of lesson flow. */
  updatedLessonPlan: LessonPlan;
  /** The number of lessons updated with the schedule of the lesson flow. */
  updatedLessonsCount: Scalars['Int'];
};

/** Input used to update lesson sequence of a LessonFlowItem. */
export type UpdateLessonSequenceInput = {
  /** Optional description of the lesson sequence item */
  description?: InputMaybe<Scalars['String']>;
  /** The updated duration (in minutes) of the sequence item */
  duration: Scalars['Float'];
  /** The updated linked exercise ID of the sequence item in the list. */
  exerciseId?: InputMaybe<Scalars['ObjectId']>;
  /** The ID of the LessonFlowItem to be updated. */
  lessonFlowItemId: Scalars['ObjectId'];
  /** The updated zero-based index order of the sequence item in the list */
  order?: InputMaybe<Scalars['Float']>;
  /** The ID of the LessonFlowItem sequence item to update. */
  sequenceId: Scalars['ObjectId'];
};

export type UpdateLogInput = {
  id: Scalars['ObjectId'];
  log: Scalars['String'];
};

export type UpdateMailMergeInput = {
  id: Scalars['ObjectId'];
  templateId: Scalars['ObjectId'];
};

/** Update Ad Hoc Material Input */
export type UpdateMaterialDeliveryOptionInput = {
  attendanceId?: InputMaybe<Scalars['ObjectId']>;
  id: Scalars['ObjectId'];
  isCollectFromReception?: InputMaybe<Scalars['Boolean']>;
  method: DeliveryMethod;
  remarks?: InputMaybe<Scalars['String']>;
};

export type UpdateMerchandiseInput = {
  dateIssued?: InputMaybe<Scalars['DateTime']>;
  distributionChannel?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  item?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MerchandiseStatus>;
};

export type UpdateParentInput = {
  address?: InputMaybe<UserAddressInput>;
  alias?: InputMaybe<Scalars['String']>;
  alternateUserId?: InputMaybe<Scalars['String']>;
  alternativeEmails?: InputMaybe<Array<Scalars['String']>>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  homePhone?: InputMaybe<TelephoneInput>;
  id: Scalars['ObjectId'];
  linkedWhatsappAccountId?: InputMaybe<Scalars['ObjectId']>;
  mobile?: InputMaybe<TelephoneInput>;
  officePhone?: InputMaybe<TelephoneInput>;
  remarks?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  whatsAppName?: InputMaybe<Scalars['String']>;
};

export type UpdatePaymentRecordInput = {
  bankName?: InputMaybe<Scalars['String']>;
  chequeNumber?: InputMaybe<Scalars['String']>;
  depositDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ObjectId'];
  lastDigits?: InputMaybe<Scalars['String']>;
  /** Updated Payment method to overwrite in record */
  method?: InputMaybe<PaymentMode>;
  /** The date when the payment was made */
  paymentDate?: InputMaybe<Scalars['DateTime']>;
  paymentProcessor?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
};

export type UpdatePromotionInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  id: Scalars['ObjectId'];
  promoteToLevelId: Scalars['ObjectId'];
  scheduledDate?: InputMaybe<Scalars['DateTime']>;
  selectedStudentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UpdateReferenceDataInput = {
  id: Scalars['ObjectId'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateRegistrationInput = {
  classIds?: InputMaybe<Scalars['ObjectId']>;
  currentStep?: InputMaybe<Scalars['Int']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ObjectId'];
  invoiceId?: InputMaybe<Scalars['ObjectId']>;
  isPrioritized?: InputMaybe<Scalars['Boolean']>;
  preferredLearningArrangement?: InputMaybe<LearningArrangementType>;
  remarks?: InputMaybe<Scalars['String']>;
  selectedLessons?: InputMaybe<Array<SelectedLessonInput>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateRelationshipInput = {
  destId: Scalars['ObjectId'];
  relationName?: InputMaybe<Scalars['String']>;
  sourceId: Scalars['ObjectId'];
};

export type UpdateRemarkInput = {
  /** A string that contains a remark in a formatted string that will be formatted further in the front-end. */
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  /** A boolean that signifies the pinned status of the remark. */
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** The type of the remark */
  levelType?: InputMaybe<RemarkLevelType>;
};

export type UpdateRewardCatalogInput = {
  avatar?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  galleries?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ObjectId'];
  isVisible?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  points?: InputMaybe<Scalars['Int']>;
  stock?: InputMaybe<Scalars['Int']>;
};

export type UpdateRewardsHistoryInput = {
  id: Scalars['ObjectId'];
  orderUrl?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RewardHistoryStatus>;
  trackingNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateSchoolInput = {
  alias?: InputMaybe<Scalars['String']>;
  educationStage?: InputMaybe<EducationStage>;
  id: Scalars['ObjectId'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateStudentInput = {
  academicLevelId?: InputMaybe<Scalars['ObjectId']>;
  academicMatterIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  address?: InputMaybe<UserAddressInput>;
  alias?: InputMaybe<Scalars['String']>;
  alternateUserId?: InputMaybe<Scalars['String']>;
  alternativeEmails?: InputMaybe<Array<Scalars['String']>>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  consent?: InputMaybe<ConsentInput>;
  email?: InputMaybe<Scalars['String']>;
  enrollmentMatterIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  fullName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  homePhone?: InputMaybe<TelephoneInput>;
  id: Scalars['ObjectId'];
  mobile?: InputMaybe<TelephoneInput>;
  parentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  primaryParentId?: InputMaybe<Scalars['ObjectId']>;
  primarySchoolId?: InputMaybe<Scalars['ObjectId']>;
  primaryStream?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  secondarySchoolId?: InputMaybe<Scalars['ObjectId']>;
  secondaryStream?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UpdateStudentRemarksInput = {
  id: Scalars['ObjectId'];
  remarks: Scalars['String'];
};

export type UpdateStudentReportFilterInput = {
  id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type UpdateSubmissionInput = {
  breakdown?: InputMaybe<MarkBreakDownInput>;
  /** Flag to set if update operation involves removing/ nulling a breakdown value. */
  deleteFlag?: InputMaybe<Scalars['Boolean']>;
  grade?: InputMaybe<Scalars['Int']>;
  submissionId: Scalars['ObjectId'];
};

export type UpdateSystemUserCommand = {
  alias?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  branchIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  departmentId?: InputMaybe<Scalars['ObjectId']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  initials?: InputMaybe<Scalars['String']>;
  linkedWhatsappNumber?: InputMaybe<TelephoneInput>;
  mobile?: InputMaybe<TelephoneInput>;
  officePhone?: InputMaybe<TelephoneInput>;
  roles?: InputMaybe<Array<UserRole>>;
  salutation?: InputMaybe<Scalars['String']>;
};

export type UpdateTaskChecklistInput = {
  assigneeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  id: Scalars['ObjectId'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateTaskChecklistItemInput = {
  assigneeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  id: Scalars['ObjectId'];
  isDone?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateTaskInput = {
  assigneeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateTaskListInput = {
  endAt?: InputMaybe<Scalars['DateTime']>;
  generateAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ObjectId'];
  isAutoGenerate?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  weekDay?: InputMaybe<WeekDay>;
};

export type UpdateTeacherInput = {
  alias?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  branchIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  departmentId?: InputMaybe<Scalars['ObjectId']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  initials?: InputMaybe<Scalars['String']>;
  linkedWhatsappNumber?: InputMaybe<TelephoneInput>;
  mobile?: InputMaybe<TelephoneInput>;
  officePhone?: InputMaybe<TelephoneInput>;
  salutation?: InputMaybe<Scalars['String']>;
  userRole?: InputMaybe<UserRole>;
};

export type UpdateTemplateInput = {
  content?: InputMaybe<Scalars['String']>;
  emailMessage?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  status: TemplateStatus;
  subject?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TemplateType>;
  usageType?: InputMaybe<TemplateUsageType>;
  whatsAppMessage?: InputMaybe<Scalars['String']>;
};

export type UpdateTopicInput = {
  courseId: Scalars['ObjectId'];
  name: Scalars['String'];
  topicId: Scalars['ObjectId'];
};

export type UpdateUserCustomiseColumnInput = {
  columnName: Scalars['String'];
  columnShowName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ObjectId']>;
  isRequired: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  ownerId?: InputMaybe<Scalars['ObjectId']>;
  tableName: Scalars['String'];
  tableShowName?: InputMaybe<Scalars['String']>;
  weight: Scalars['Int'];
};

export type UpdateUserPermissionInput = {
  id: Scalars['ObjectId'];
  userRole: UserRole;
};

export type UpdateVenueInput = {
  /** Address of the Venue */
  address: AddressInput;
  /** Id of the branch where the venue is located */
  branchId?: InputMaybe<Scalars['ObjectId']>;
  /** The maximum number of students that a venue can hold */
  capacity?: InputMaybe<Scalars['Int']>;
  /** Id of the venue */
  id: Scalars['ObjectId'];
  /** Indicator that the venue is archived, archiving the venue means it cannot be associated to a class */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Name of the Venue, this is the combination of the venue unit and classroom number e.g 02-5A CR1 */
  name: Scalars['String'];
};

export type UpdateWhatsappAccountInput = {
  id: Scalars['ObjectId'];
  name?: InputMaybe<Scalars['String']>;
};

/**
 * Inputs for the UpdateWorksheet mutation. Fields are optionally
 *   set so only defined fields will be updated.
 */
export type UpdateWorksheetInput = {
  id: Scalars['ObjectId'];
  test?: InputMaybe<WorkSheetTestInput>;
  title?: InputMaybe<Scalars['String']>;
  topicId?: InputMaybe<Scalars['ObjectId']>;
};

export enum UploadFilePath {
  ImagesCourse = 'ImagesCourse',
  ImagesLog = 'ImagesLog',
  ImagesReward = 'ImagesReward',
  ImagesUser = 'ImagesUser',
  ReminderFiles = 'ReminderFiles'
}

export type UploadMailMergeMergeListInput = {
  file: Scalars['Upload'];
  id: Scalars['ObjectId'];
};

export type UploadResult = {
  __typename?: 'UploadResult';
  remoteUrl: Scalars['String'];
  url: Scalars['String'];
};

export type UpsertUserCustomiseColumnInput = {
  columnName: Scalars['String'];
  columnShowName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ObjectId']>;
  isRequired: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  ownerId?: InputMaybe<Scalars['ObjectId']>;
  tableName: Scalars['String'];
  tableShowName?: InputMaybe<Scalars['String']>;
  weight: Scalars['Int'];
};

export type UseRewardPointsInput = {
  orderDetail: RewardDetailInput;
  points: Scalars['Int'];
  remarks?: InputMaybe<Scalars['String']>;
  studentId: Scalars['ObjectId'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ObjectId'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  sub?: Maybe<Scalars['String']>;
  userRoles?: Maybe<Array<UserRole>>;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  country?: Maybe<Scalars['String']>;
  gateBuilding?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type UserAddressInput = {
  country?: InputMaybe<Scalars['String']>;
  gateBuilding?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type UserAddressOutput = {
  __typename?: 'UserAddressOutput';
  country?: Maybe<Scalars['String']>;
  gateBuilding?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type UserCustomiseColumn = {
  __typename?: 'UserCustomiseColumn';
  _id: Scalars['ObjectId'];
  columnName: Scalars['String'];
  columnShowName?: Maybe<Scalars['String']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  ownerId?: Maybe<Scalars['ObjectId']>;
  tableName: Scalars['String'];
  tableShowName?: Maybe<Scalars['String']>;
  weight: Scalars['Float'];
};

export type UserCustomiseColumnFilterInput = {
  columnName?: InputMaybe<Scalars['String']>;
  columnShowName?: InputMaybe<Scalars['String']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['ObjectId']>;
  tableName?: InputMaybe<Scalars['String']>;
  tableShowName?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type UserCustomiseColumnPage = {
  __typename?: 'UserCustomiseColumnPage';
  items: Array<UserCustomiseColumn>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type UserFilterInput = {
  ref?: InputMaybe<Scalars['ObjectId']>;
  userRole?: InputMaybe<UserRole>;
};

export type UserPage = {
  __typename?: 'UserPage';
  items: Array<User>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum UserRole {
  AcademicSupport = 'AcademicSupport',
  Admin = 'Admin',
  CustomerRelationsAssociate = 'CustomerRelationsAssociate',
  Finance = 'Finance',
  Parent = 'Parent',
  SuperAdmin = 'SuperAdmin',
  Teacher = 'Teacher',
  TeacherSupervisor = 'TeacherSupervisor',
  TechSupport = 'TechSupport'
}

/** Validate integration command. */
export type ValidateIntegrationAccessTokenCommand = {
  /** Integration application client type */
  type: IntegrationType;
};

export type ValidateTeacherAndVenueInput = {
  dateTimeRanges: Array<DateTimeRangeInput>;
  excludeClassId?: InputMaybe<Scalars['ObjectId']>;
  excludeLessonId?: InputMaybe<Scalars['ObjectId']>;
  teacherId?: InputMaybe<Scalars['ObjectId']>;
  venueId?: InputMaybe<Scalars['ObjectId']>;
};

export type ValidateWhatsappNumberInput = {
  accountId?: InputMaybe<Scalars['ObjectId']>;
  number: TelephoneInput;
};

export type Venue = {
  __typename?: 'Venue';
  _id: Scalars['ObjectId'];
  address: Address;
  branch: Branch;
  capacity?: Maybe<Scalars['Float']>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  systemId?: Maybe<Scalars['String']>;
};

export type Visible = {
  isVisible: Scalars['Boolean'];
};

export type VoidInput = {
  id: Scalars['ObjectId'];
  voidRemarks?: InputMaybe<Scalars['String']>;
};

export type VoidPaymentRecordInput = {
  paymentId: Scalars['ObjectId'];
  remarks?: InputMaybe<Scalars['String']>;
};

export enum VoidableStatus {
  Normal = 'Normal',
  Void = 'Void'
}

export type WaitingListUpdateHistory = {
  __typename?: 'WaitingListUpdateHistory';
  originalStatus?: Maybe<RegistrationsStatus>;
  status?: Maybe<RegistrationsStatus>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export enum WeekDay {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type WhatsappAccount = {
  __typename?: 'WhatsappAccount';
  _id: Scalars['ObjectId'];
  contacts?: Maybe<Array<WhatsappContact>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  currentUserId?: Maybe<Scalars['ObjectId']>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  hasFailedMessages?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastSentDateTime?: Maybe<Scalars['DateTime']>;
  lastSentOffset?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Telephone>;
  platform?: Maybe<ReminderType>;
  processingStatus: WhatsappProcessingStatus;
  status: WhatsappAccountStatus;
  statusDate?: Maybe<Scalars['DateTime']>;
  statusDetail?: Maybe<Scalars['String']>;
};

export type WhatsappAccountFilterInput = {
  accountIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  processingStatus?: InputMaybe<Array<WhatsappProcessingStatus>>;
  status?: InputMaybe<Array<WhatsappAccountStatus>>;
};

export type WhatsappAccountPage = {
  __typename?: 'WhatsappAccountPage';
  items: Array<WhatsappAccount>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum WhatsappAccountStatus {
  Handoff = 'Handoff',
  Offline = 'Offline',
  Online = 'Online',
  Warning = 'Warning'
}

export type WhatsappContact = {
  __typename?: 'WhatsappContact';
  name: Scalars['String'];
  number: Scalars['String'];
};

export type WhatsappMessage = {
  __typename?: 'WhatsappMessage';
  _id: Scalars['ObjectId'];
  arrangement?: Maybe<Arrangement>;
  attachmentStatus?: Maybe<WhatsappMessageStatus>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  failedCount?: Maybe<Scalars['Int']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  isQueueNext?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  message: Scalars['String'];
  parent?: Maybe<Parent>;
  platform: ReminderType;
  queueOrder?: Maybe<Scalars['Int']>;
  recipient: Scalars['String'];
  registration?: Maybe<Registration>;
  sender: WhatsappAccount;
  sentDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<WhatsappMessageStatus>;
  statusDate?: Maybe<Scalars['DateTime']>;
  template?: Maybe<Template>;
};

export type WhatsappMessageCounts = {
  __typename?: 'WhatsappMessageCounts';
  cancelled: Scalars['Int'];
  delivered: Scalars['Int'];
  failed: Scalars['Int'];
  paused: Scalars['Int'];
  processing: Scalars['Int'];
  queued: Scalars['Int'];
  read: Scalars['Int'];
  sent: Scalars['Int'];
};

export type WhatsappMessageFilterInput = {
  createDate?: InputMaybe<DateTimeRangeInput>;
  senderId?: InputMaybe<Scalars['ObjectId']>;
  status?: InputMaybe<Array<WhatsappMessageStatus>>;
};

export type WhatsappMessagePage = {
  __typename?: 'WhatsappMessagePage';
  items: Array<WhatsappMessage>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum WhatsappMessageStatus {
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  Failed = 'Failed',
  Paused = 'Paused',
  Processing = 'Processing',
  Queued = 'Queued',
  Read = 'Read',
  Sent = 'Sent'
}

export enum WhatsappProcessingStatus {
  Paused = 'Paused',
  Ready = 'Ready'
}

export type WhatsappReminderInput = {
  isIncludeAttachment?: InputMaybe<Scalars['Boolean']>;
  message: Scalars['String'];
  receiverParentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  senderId?: InputMaybe<Scalars['ObjectId']>;
  templateId: Scalars['ObjectId'];
};

export type WithdrawReasonsInput = {
  otherReason?: InputMaybe<Scalars['String']>;
  reasons?: InputMaybe<Array<SelectedReasonInput>>;
  remarks?: InputMaybe<Scalars['String']>;
  suggestion?: InputMaybe<Scalars['String']>;
};

/** This refers to a sheet paper given by teachers to students to do a particular task. There are two types of worksheets: Regular worksheet and Test worksheet */
export type WorkSheet = {
  __typename?: 'WorkSheet';
  _id: Scalars['ObjectId'];
  /** Optional Worksheet abbreviation */
  abbreviation?: Maybe<Scalars['String']>;
  academicYear?: Maybe<AcademicYear>;
  /**
   * Academic Year ID assigned to Worksheet
   * @deprecated Academic Year will now be optional in future versions
   */
  academicYearId: Scalars['ObjectId'];
  /** List of associated courses */
  courseIds: Array<Scalars['ObjectId']>;
  courses?: Maybe<Array<Course>>;
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /** When this is not null, the worksheet is considered a test. */
  test?: Maybe<WorkSheetTest>;
  title: Scalars['String'];
  topic?: Maybe<Topic>;
  topicId: Scalars['ObjectId'];
};

export type WorkSheetItemInput = {
  academicLevelId?: InputMaybe<Scalars['ObjectId']>;
  /** Filter by Academic Year ID */
  academicYearId?: InputMaybe<Scalars['ObjectId']>;
  /** Course Ids that the worksheet should belong to. Leave null to get all. */
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  topicId?: InputMaybe<Scalars['ObjectId']>;
  workSheetId?: InputMaybe<Scalars['ObjectId']>;
};

export type WorkSheetPage = {
  __typename?: 'WorkSheetPage';
  items: Array<WorkSheet>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/** A worksheet that is used as a test for the students. */
export type WorkSheetTest = {
  __typename?: 'WorkSheetTest';
  /** Reference to the grading template used. */
  gradingTemplateId: Scalars['ObjectId'];
  /** Reference to the name of the grading template used. */
  gradingTemplateName: Scalars['String'];
  /** The name of the test used by the teachers */
  name: Scalars['String'];
  /** The different sections of a Test worksheet */
  sections: Array<WorkSheetTestSection>;
  /** The overall score of the test. It is the sum of all the section total. */
  totalScore: Scalars['Float'];
};

/**
 * When this is not null, the worksheet is considered a Test.
 *     If a test object exists, set this to null to remove it when updating.
 */
export type WorkSheetTestInput = {
  /**
   * Reference to the name of the grading template most recently
   *     used for this test. When used in the update mutation, it does not update the
   *     `sections` field since the grade totals can be different from the what
   *     the template originally had.
   */
  gradingTemplateId?: InputMaybe<Scalars['ObjectId']>;
  /** The name of the test used by the teachers */
  name?: InputMaybe<Scalars['String']>;
  sections?: InputMaybe<Array<WorkSheetTestSectionInput>>;
};

/** These are the sections copied from the grading template. */
export type WorkSheetTestSection = {
  __typename?: 'WorkSheetTestSection';
  /** The test section name, copied from the grading template section. */
  name: Scalars['String'];
  /** The section total, initially copied from the grading template, then can be updated afterwards. */
  total: Scalars['Float'];
};

/** The different sections of a Test worksheet indicating grade totals. */
export type WorkSheetTestSectionInput = {
  /** The test section name, copied from the grading template section. */
  name: Scalars['String'];
  /** The section total, initially copied from the grading template, then can be updated afterwards. */
  total: Scalars['Float'];
};

/** Input for the `worksheetAddCourse` mutation. */
export type WorksheetAddCourseInput = {
  /** The course to associate to the worksheet. */
  courseId: Scalars['ObjectId'];
  /** The worksheet to add the course. */
  worksheetId: Scalars['ObjectId'];
};

/** Input for `worksheetCreate` mutation. */
export type WorksheetCreateInput = {
  /** The course to add the worksheet. */
  courseId: Scalars['ObjectId'];
  /** When provided, it categorizes the worksheet as a test. */
  test?: InputMaybe<WorksheetTestCreateInput>;
  /** The provided title of the worksheet. */
  title: Scalars['String'];
  /** The topic of the course to assign to the worksheet. */
  topicId: Scalars['ObjectId'];
};

/** The data that was parsed on each row on the csv file. */
export type WorksheetParsedData = {
  __typename?: 'WorksheetParsedData';
  /** The grading template to assign to the test. */
  gradingTemplate: WorksheetParsedGradingTemplate;
  /** The name of the test of the worksheet. */
  testName?: Maybe<Scalars['String']>;
  /** The title of the worksheet. */
  title?: Maybe<Scalars['String']>;
  /** The topic of the worksheet. */
  topic: WorksheetParsedTopic;
};

/** The errors encountered while parsing the data of a row on the csv file. */
export type WorksheetParsedError = {
  __typename?: 'WorksheetParsedError';
  /**
   * The type of error that occurred while parsing the csv file.
   *     This is to help FE determine what message to display.
   */
  errorCode: WorksheetParsedErrorCode;
  /** The incorrect field of the worksheet. */
  field: Scalars['String'];
  /** A human readable error message indicating the problem. */
  reason: Scalars['String'];
};

export enum WorksheetParsedErrorCode {
  Duplicate = 'DUPLICATE',
  IncorrectField = 'INCORRECT_FIELD',
  MissingField = 'MISSING_FIELD'
}

/** Contains the parsed grading template data provided on a row of the csv file. */
export type WorksheetParsedGradingTemplate = {
  __typename?: 'WorksheetParsedGradingTemplate';
  /** Reference to the grading template entity. */
  _id?: Maybe<Scalars['ObjectId']>;
  /** Name of the referenced grading template. */
  name?: Maybe<Scalars['String']>;
};

/** The result of parsing a row on the csv file. */
export type WorksheetParsedResult = {
  __typename?: 'WorksheetParsedResult';
  /** Contains the data of a parsed row of the csv file. */
  data: WorksheetParsedData;
  /** Contains the errors encountered while parsing the data of a row on the csv file. */
  errors: Array<WorksheetParsedError>;
};

/** Contains the parsed topic data provided on a row of the csv file. */
export type WorksheetParsedTopic = {
  __typename?: 'WorksheetParsedTopic';
  /** Reference to the topic entity. */
  _id?: Maybe<Scalars['ObjectId']>;
  /** Name of the referenced topic. */
  name?: Maybe<Scalars['String']>;
};

/** Input for the `worksheetRemoveCourse` mutation. */
export type WorksheetRemoveCourseInput = {
  /** The course to dissociate from the worksheet. */
  courseId: Scalars['ObjectId'];
  /** The worksheet to remove the course from. */
  worksheetId: Scalars['ObjectId'];
};

/** Input for the `test` field of the `worksheetCreate` mutation. */
export type WorksheetTestCreateInput = {
  /** The grading template to be used for the test. */
  gradingTemplateId: Scalars['ObjectId'];
  /**
   * The name of the test of the worksheet.
   *     When specified, it categorizes the worksheet as a test.
   */
  name: Scalars['String'];
  /** Customized section totals based on the provided grading template. */
  sections?: InputMaybe<Array<WorkSheetTestSectionInput>>;
};

/** Input for  the `worksheets` field of the `worksheetsCreate` mutation. */
export type WorksheetsCreateDataInput = {
  /** When provided, it categorizes the worksheet as a test. */
  test?: InputMaybe<WorksheetTestCreateInput>;
  /** The provided title of the worksheet. */
  title: Scalars['String'];
  /**
   * Name of the topic of the course to assign to the worksheet.
   *     If the topic name is not existing, it will be created.
   */
  topicName: Scalars['String'];
};

/** Input for `worksheetsCreate` mutation. */
export type WorksheetsCreateInput = {
  /** The course to add the worksheet. */
  courseId: Scalars['ObjectId'];
  /** List of worksheets to create. */
  worksheets: Array<WorksheetsCreateDataInput>;
};

/** Input for the `worksheetsParseCsv` mutation. This contains the file to upload as a FileUpload object. */
export type WorksheetsParseCsvInput = {
  /** Course ID where worksheets will be created */
  courseId: Scalars['ObjectId'];
  /** The file to upload. This is a FileUpload object that is created by the `<Upload/>` component of Antd. */
  file: Scalars['Upload'];
};

/** Response object of the `workshetsParseCsv` mutation */
export type WorksheetsParseCsvResponse = {
  __typename?: 'WorksheetsParseCsvResponse';
  /** A list containing the CSV parsed results */
  results: Array<WorksheetParsedResult>;
  /** The total number of rows parsed on the csv file, excluding the empty rows. */
  total: Scalars['Int'];
};

export type WorkshopMakeUpCreditInput = {
  workshop: Scalars['Int'];
};

export type XeroAccount = {
  __typename?: 'XeroAccount';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<AccountType>;
};

export type XeroIntegration = {
  __typename?: 'XeroIntegration';
  _id: Scalars['ObjectId'];
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<SystemUser>;
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<SystemUser>;
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  otherAccounts: Array<OtherMappingAccount>;
  tenant?: Maybe<XeroTenant>;
  token?: Maybe<XeroTokenSet>;
};

export type XeroInvoiceDateInput = {
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type XeroPrepayment = {
  __typename?: 'XeroPrepayment';
  allocatedAmount?: Maybe<Scalars['Decimal']>;
  amount: Scalars['Decimal'];
  id?: Maybe<Scalars['String']>;
  lastSyncedDate?: Maybe<Scalars['DateTime']>;
  lineItemId: Scalars['ObjectId'];
};

export enum XeroSyncStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending'
}

export type XeroTenant = {
  __typename?: 'XeroTenant';
  authEventId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
  tenantName?: Maybe<Scalars['String']>;
  tenantType?: Maybe<Scalars['Float']>;
};

export type XeroTokenSet = {
  __typename?: 'XeroTokenSet';
  access_token?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['Float']>;
  expires_in?: Maybe<Scalars['Float']>;
  id_token?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  session_state?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
};

export type GetUserCustomiseColumnsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  filterInput?: InputMaybe<UserCustomiseColumnFilterInput>;
  sortInfo?: InputMaybe<SortInfo>;
  pageInfo?: InputMaybe<PageInfo>;
}>;


export type GetUserCustomiseColumnsQuery = { __typename?: 'Query', userCustomiseColumns?: { __typename?: 'UserCustomiseColumnPage', items: Array<{ __typename?: 'UserCustomiseColumn', tableName: string, tableShowName?: string | null, columnName: string, columnShowName?: string | null, isVisible: boolean, isRequired: boolean, weight: number }> } | null };

export type UpsertUserCustomiseColumnsMutationVariables = Exact<{
  input: Array<UpsertUserCustomiseColumnInput> | UpsertUserCustomiseColumnInput;
}>;


export type UpsertUserCustomiseColumnsMutation = { __typename?: 'Mutation', upsertUserCustomiseColumns?: Array<{ __typename?: 'UserCustomiseColumn', tableName: string, tableShowName?: string | null, columnName: string, columnShowName?: string | null, isVisible: boolean, isRequired: boolean, weight: number }> | null };

export type ResetDefaultMutationVariables = Exact<{
  input: ResetDefaultInput;
}>;


export type ResetDefaultMutation = { __typename?: 'Mutation', resetDefault?: Array<{ __typename?: 'UserCustomiseColumn', tableName: string, tableShowName?: string | null, columnName: string, columnShowName?: string | null, isVisible: boolean, isRequired: boolean, weight: number }> | null };

export type FeatureFieldsFragment = { __typename?: 'FeatureAccess', description?: string | null, enabled?: boolean | null, keywords: FeatureAccessKeyword, name: string, id: any };

export type GetFeaturesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeaturesQuery = { __typename?: 'Query', features?: Array<{ __typename?: 'FeatureAccess', description?: string | null, enabled?: boolean | null, keywords: FeatureAccessKeyword, name: string, id: any }> | null };

export type GetEnabledFeaturesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEnabledFeaturesQuery = { __typename?: 'Query', enabledFeatures?: Array<{ __typename?: 'FeatureAccess', description?: string | null, enabled?: boolean | null, keywords: FeatureAccessKeyword, name: string, id: any }> | null };

export type PostFeatureDataSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type PostFeatureDataSubscription = { __typename?: 'Subscription', postFeatureData: Array<{ __typename?: 'FeatureAccess', description?: string | null, enabled?: boolean | null, keywords: FeatureAccessKeyword, name: string, id: any }> };

export type GetEligibilityFlowWithCourseIdQueryVariables = Exact<{
  courseId: Scalars['ObjectId'];
}>;


export type GetEligibilityFlowWithCourseIdQuery = { __typename?: 'Query', getEligibilityFlowWithCourseId?: { __typename?: 'EligibilityFlow', _id: any, courseId: any, createDateTime: any, deletedAt?: any | null, isDeleted: boolean, lastModifiedDateTime?: any | null, startNodeJsonString: string } | null };

export type GetAcademicLevelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAcademicLevelsQuery = { __typename?: 'Query', academicLevels?: Array<{ __typename?: 'AcademicLevel', key: any, label: string }> | null };

export type GetCourseDetailQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type GetCourseDetailQuery = { __typename?: 'Query', course?: { __typename?: 'Course', _id: any, name: string, avatarUrl?: string | null } | null };

export type CreateEligibilityFlowMutationVariables = Exact<{
  input: CreateEligibilityFlowInput;
}>;


export type CreateEligibilityFlowMutation = { __typename?: 'Mutation', createEligibilityFlow?: { __typename?: 'EligibilityFlow', _id: any, courseId: any, createDateTime: any, deletedAt?: any | null, isDeleted: boolean, lastModifiedDateTime?: any | null } | null };

export type UpdateEligibilityFlowMutationVariables = Exact<{
  input: UpdateEligibilityFlowInput;
}>;


export type UpdateEligibilityFlowMutation = { __typename?: 'Mutation', updateEligibilityFlow?: { __typename?: 'EligibilityFlow', _id: any, courseId: any, createDateTime: any, deletedAt?: any | null, isDeleted: boolean, lastModifiedDateTime?: any | null } | null };

export type GetListCourseDataQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  filterInput?: InputMaybe<CourseFilterInput>;
  sortInfo?: InputMaybe<SortInfo>;
  pageInfo?: InputMaybe<PageInfo>;
}>;


export type GetListCourseDataQuery = { __typename?: 'Query', courses?: { __typename?: 'CoursePage', items: Array<{ __typename?: 'Course', _id: any, name: string, nameShort: string, status?: CourseStatus | null, type: CourseType }> } | null };

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  path: UploadFilePath;
}>;


export type UploadFileMutation = { __typename?: 'Mutation', uploadFile?: { __typename?: 'UploadResult', remoteUrl: string, url: string } | null };

export type SystemUserFieldsFragment = { __typename?: 'SystemUser', fullName: string, alias?: string | null, avatarUrl?: string | null, email: string, gender?: string | null, initials?: string | null, salutation?: string | null, userId?: string | null, roles?: Array<UserRole> | null, alternateUserId?: string | null, id: any, mobile?: { __typename?: 'Telephone', countryCode: string, number: string } | null, officePhone?: { __typename?: 'Telephone', countryCode: string, number: string } | null, branches?: Array<{ __typename?: 'Branch', name: string, id: any }> | null, department?: { __typename?: 'Department', name: string, id: any } | null, linkedWhatsappNumber?: { __typename?: 'Telephone', countryCode: string, number: string } | null };

export type GetSystemUsersQueryVariables = Exact<{
  filterInput?: InputMaybe<SystemUserQuery>;
  sortInfo?: InputMaybe<SortInfo>;
  pageInfo?: InputMaybe<PageInfo>;
}>;


export type GetSystemUsersQuery = { __typename?: 'Query', systemUsers?: { __typename?: 'SystemUserPage', pageIndex: number, pageSize: number, total: number, items: Array<{ __typename?: 'SystemUser', fullName: string, alias?: string | null, avatarUrl?: string | null, email: string, gender?: string | null, initials?: string | null, salutation?: string | null, userId?: string | null, roles?: Array<UserRole> | null, alternateUserId?: string | null, id: any, mobile?: { __typename?: 'Telephone', countryCode: string, number: string } | null, officePhone?: { __typename?: 'Telephone', countryCode: string, number: string } | null, branches?: Array<{ __typename?: 'Branch', name: string, id: any }> | null, department?: { __typename?: 'Department', name: string, id: any } | null, linkedWhatsappNumber?: { __typename?: 'Telephone', countryCode: string, number: string } | null }> } | null };

export type GetSystemUserQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type GetSystemUserQuery = { __typename?: 'Query', systemUser?: { __typename?: 'SystemUser', fullName: string, alias?: string | null, avatarUrl?: string | null, email: string, gender?: string | null, initials?: string | null, salutation?: string | null, userId?: string | null, roles?: Array<UserRole> | null, alternateUserId?: string | null, id: any, mobile?: { __typename?: 'Telephone', countryCode: string, number: string } | null, officePhone?: { __typename?: 'Telephone', countryCode: string, number: string } | null, branches?: Array<{ __typename?: 'Branch', name: string, id: any }> | null, department?: { __typename?: 'Department', name: string, id: any } | null, linkedWhatsappNumber?: { __typename?: 'Telephone', countryCode: string, number: string } | null } | null };

export type GetCurrentSystemUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentSystemUserQuery = { __typename?: 'Query', currentSystemUser?: { __typename?: 'SystemUser', fullName: string, alias?: string | null, avatarUrl?: string | null, email: string, gender?: string | null, initials?: string | null, salutation?: string | null, userId?: string | null, roles?: Array<UserRole> | null, alternateUserId?: string | null, id: any, mobile?: { __typename?: 'Telephone', countryCode: string, number: string } | null, officePhone?: { __typename?: 'Telephone', countryCode: string, number: string } | null, branches?: Array<{ __typename?: 'Branch', name: string, id: any }> | null, department?: { __typename?: 'Department', name: string, id: any } | null, linkedWhatsappNumber?: { __typename?: 'Telephone', countryCode: string, number: string } | null } | null };

export type ExportSystemUserCsvQueryVariables = Exact<{
  filterInput?: InputMaybe<SystemUserQuery>;
}>;


export type ExportSystemUserCsvQuery = { __typename?: 'Query', csv?: { __typename?: 'Csv', url: string } | null };

export type CreateSystemUserMutationVariables = Exact<{
  command: CreateSystemUserCommand;
}>;


export type CreateSystemUserMutation = { __typename?: 'Mutation', createSystemUser?: { __typename?: 'SystemUser', id: any } | null };

export type UpdateSystemUserMutationVariables = Exact<{
  command: UpdateSystemUserCommand;
}>;


export type UpdateSystemUserMutation = { __typename?: 'Mutation', updateSystemUser?: { __typename?: 'SystemUser', id: any } | null };

export type RequestChangeSystemUserPasswordMutationVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type RequestChangeSystemUserPasswordMutation = { __typename?: 'Mutation', requestChangeSystemUserPassword?: boolean | null };

export type GetReferencesQueryVariables = Exact<{
  category?: InputMaybe<ReferenceDataCategory>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetReferencesQuery = { __typename?: 'Query', referenceDatas?: Array<{ __typename?: 'ReferenceData', category: ReferenceDataCategory, order?: number | null, key: string, value: string, isArchived: boolean, id: any }> | null };

export type RemarkUserFieldsFragment = { __typename?: 'SystemUser', fullName: string, alias?: string | null, avatarUrl?: string | null, id: any };

export type RemarkFieldsFragment = { __typename?: 'Remark', content: string, levelType?: RemarkLevelType | null, type: RemarkType, isPinned?: boolean | null, isFlagged?: boolean | null, subjectId: any, createDateTime: any, lastModifiedDateTime?: any | null, isArchived?: boolean | null, id: any, enrollmentSuitability?: { __typename?: 'EnrollmentSuitability', status?: EnrollmentSuitabilityStatus | null } | null, createdBy?: { __typename?: 'SystemUser', fullName: string, alias?: string | null, avatarUrl?: string | null, id: any } | null, lastModifiedBy?: { __typename?: 'SystemUser', fullName: string, alias?: string | null, avatarUrl?: string | null, id: any } | null };

export type GetSystemUserFormDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSystemUserFormDataQuery = { __typename?: 'Query', branches?: Array<{ __typename?: 'Branch', name: string, isArchived: boolean, id: any }> | null, departments?: Array<{ __typename?: 'Department', name: string, isArchived: boolean, id: any }> | null };

export type GetCountryCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountryCodesQuery = { __typename?: 'Query', countryCodes?: Array<{ __typename?: 'CountryCode', order?: number | null, areaCode: string, countryCode?: string | null }> | null };

export type GetDepartmentsQueryVariables = Exact<{
  showArchive?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetDepartmentsQuery = { __typename?: 'Query', departments?: Array<{ __typename?: 'Department', _id: any, name: string }> | null };

export type GetBranchesQueryVariables = Exact<{
  showArchive?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetBranchesQuery = { __typename?: 'Query', branches?: Array<{ __typename?: 'Branch', _id: any, name: string }> | null };

export const FeatureFieldsFragmentDoc = gql`
    fragment FeatureFields on FeatureAccess {
  id: _id
  description
  enabled
  keywords
  name
}
    `;
export const SystemUserFieldsFragmentDoc = gql`
    fragment SystemUserFields on SystemUser {
  id: _id
  fullName
  alias
  avatarUrl
  email
  gender
  initials
  salutation
  userId
  roles
  alternateUserId
  mobile {
    countryCode
    number
  }
  officePhone {
    countryCode
    number
  }
  branches {
    id: _id
    name
  }
  department {
    id: _id
    name
  }
  linkedWhatsappNumber {
    countryCode
    number
  }
}
    `;
export const RemarkUserFieldsFragmentDoc = gql`
    fragment RemarkUserFields on SystemUser {
  id: _id
  fullName
  alias
  avatarUrl
}
    `;
export const RemarkFieldsFragmentDoc = gql`
    fragment RemarkFields on Remark {
  id: _id
  content
  levelType
  type
  isPinned
  isFlagged
  enrollmentSuitability {
    status
  }
  createdBy {
    ...RemarkUserFields
  }
  subjectId
  createDateTime
  lastModifiedDateTime
  lastModifiedBy {
    ...RemarkUserFields
  }
  isArchived
}
    ${RemarkUserFieldsFragmentDoc}`;
export const GetUserCustomiseColumnsDocument = gql`
    query getUserCustomiseColumns($search: String, $filterInput: UserCustomiseColumnFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  userCustomiseColumns(
    search: $search
    filterInput: $filterInput
    sortInfo: $sortInfo
    pageInfo: $pageInfo
  ) {
    items {
      tableName
      tableShowName
      columnName
      columnShowName
      isVisible
      isRequired
      weight
    }
  }
}
    `;

/**
 * __useGetUserCustomiseColumnsQuery__
 *
 * To run a query within a React component, call `useGetUserCustomiseColumnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCustomiseColumnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCustomiseColumnsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filterInput: // value for 'filterInput'
 *      sortInfo: // value for 'sortInfo'
 *      pageInfo: // value for 'pageInfo'
 *   },
 * });
 */
export function useGetUserCustomiseColumnsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserCustomiseColumnsQuery, GetUserCustomiseColumnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCustomiseColumnsQuery, GetUserCustomiseColumnsQueryVariables>(GetUserCustomiseColumnsDocument, options);
      }
export function useGetUserCustomiseColumnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCustomiseColumnsQuery, GetUserCustomiseColumnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCustomiseColumnsQuery, GetUserCustomiseColumnsQueryVariables>(GetUserCustomiseColumnsDocument, options);
        }
export type GetUserCustomiseColumnsQueryHookResult = ReturnType<typeof useGetUserCustomiseColumnsQuery>;
export type GetUserCustomiseColumnsLazyQueryHookResult = ReturnType<typeof useGetUserCustomiseColumnsLazyQuery>;
export type GetUserCustomiseColumnsQueryResult = Apollo.QueryResult<GetUserCustomiseColumnsQuery, GetUserCustomiseColumnsQueryVariables>;
export const UpsertUserCustomiseColumnsDocument = gql`
    mutation upsertUserCustomiseColumns($input: [UpsertUserCustomiseColumnInput!]!) {
  upsertUserCustomiseColumns(inputs: $input) {
    tableName
    tableShowName
    columnName
    columnShowName
    isVisible
    isRequired
    weight
  }
}
    `;
export type UpsertUserCustomiseColumnsMutationFn = Apollo.MutationFunction<UpsertUserCustomiseColumnsMutation, UpsertUserCustomiseColumnsMutationVariables>;

/**
 * __useUpsertUserCustomiseColumnsMutation__
 *
 * To run a mutation, you first call `useUpsertUserCustomiseColumnsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserCustomiseColumnsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserCustomiseColumnsMutation, { data, loading, error }] = useUpsertUserCustomiseColumnsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertUserCustomiseColumnsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertUserCustomiseColumnsMutation, UpsertUserCustomiseColumnsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertUserCustomiseColumnsMutation, UpsertUserCustomiseColumnsMutationVariables>(UpsertUserCustomiseColumnsDocument, options);
      }
export type UpsertUserCustomiseColumnsMutationHookResult = ReturnType<typeof useUpsertUserCustomiseColumnsMutation>;
export type UpsertUserCustomiseColumnsMutationResult = Apollo.MutationResult<UpsertUserCustomiseColumnsMutation>;
export type UpsertUserCustomiseColumnsMutationOptions = Apollo.BaseMutationOptions<UpsertUserCustomiseColumnsMutation, UpsertUserCustomiseColumnsMutationVariables>;
export const ResetDefaultDocument = gql`
    mutation resetDefault($input: ResetDefaultInput!) {
  resetDefault(input: $input) {
    tableName
    tableShowName
    columnName
    columnShowName
    isVisible
    isRequired
    weight
  }
}
    `;
export type ResetDefaultMutationFn = Apollo.MutationFunction<ResetDefaultMutation, ResetDefaultMutationVariables>;

/**
 * __useResetDefaultMutation__
 *
 * To run a mutation, you first call `useResetDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetDefaultMutation, { data, loading, error }] = useResetDefaultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetDefaultMutation(baseOptions?: Apollo.MutationHookOptions<ResetDefaultMutation, ResetDefaultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetDefaultMutation, ResetDefaultMutationVariables>(ResetDefaultDocument, options);
      }
export type ResetDefaultMutationHookResult = ReturnType<typeof useResetDefaultMutation>;
export type ResetDefaultMutationResult = Apollo.MutationResult<ResetDefaultMutation>;
export type ResetDefaultMutationOptions = Apollo.BaseMutationOptions<ResetDefaultMutation, ResetDefaultMutationVariables>;
export const GetFeaturesDocument = gql`
    query getFeatures {
  features {
    ...FeatureFields
  }
}
    ${FeatureFieldsFragmentDoc}`;

/**
 * __useGetFeaturesQuery__
 *
 * To run a query within a React component, call `useGetFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<GetFeaturesQuery, GetFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeaturesQuery, GetFeaturesQueryVariables>(GetFeaturesDocument, options);
      }
export function useGetFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturesQuery, GetFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeaturesQuery, GetFeaturesQueryVariables>(GetFeaturesDocument, options);
        }
export type GetFeaturesQueryHookResult = ReturnType<typeof useGetFeaturesQuery>;
export type GetFeaturesLazyQueryHookResult = ReturnType<typeof useGetFeaturesLazyQuery>;
export type GetFeaturesQueryResult = Apollo.QueryResult<GetFeaturesQuery, GetFeaturesQueryVariables>;
export const GetEnabledFeaturesDocument = gql`
    query getEnabledFeatures {
  enabledFeatures {
    ...FeatureFields
  }
}
    ${FeatureFieldsFragmentDoc}`;

/**
 * __useGetEnabledFeaturesQuery__
 *
 * To run a query within a React component, call `useGetEnabledFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnabledFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnabledFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnabledFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<GetEnabledFeaturesQuery, GetEnabledFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnabledFeaturesQuery, GetEnabledFeaturesQueryVariables>(GetEnabledFeaturesDocument, options);
      }
export function useGetEnabledFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnabledFeaturesQuery, GetEnabledFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnabledFeaturesQuery, GetEnabledFeaturesQueryVariables>(GetEnabledFeaturesDocument, options);
        }
export type GetEnabledFeaturesQueryHookResult = ReturnType<typeof useGetEnabledFeaturesQuery>;
export type GetEnabledFeaturesLazyQueryHookResult = ReturnType<typeof useGetEnabledFeaturesLazyQuery>;
export type GetEnabledFeaturesQueryResult = Apollo.QueryResult<GetEnabledFeaturesQuery, GetEnabledFeaturesQueryVariables>;
export const PostFeatureDataDocument = gql`
    subscription postFeatureData {
  postFeatureData {
    ...FeatureFields
  }
}
    ${FeatureFieldsFragmentDoc}`;

/**
 * __usePostFeatureDataSubscription__
 *
 * To run a query within a React component, call `usePostFeatureDataSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePostFeatureDataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostFeatureDataSubscription({
 *   variables: {
 *   },
 * });
 */
export function usePostFeatureDataSubscription(baseOptions?: Apollo.SubscriptionHookOptions<PostFeatureDataSubscription, PostFeatureDataSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PostFeatureDataSubscription, PostFeatureDataSubscriptionVariables>(PostFeatureDataDocument, options);
      }
export type PostFeatureDataSubscriptionHookResult = ReturnType<typeof usePostFeatureDataSubscription>;
export type PostFeatureDataSubscriptionResult = Apollo.SubscriptionResult<PostFeatureDataSubscription>;
export const GetEligibilityFlowWithCourseIdDocument = gql`
    query getEligibilityFlowWithCourseId($courseId: ObjectId!) {
  getEligibilityFlowWithCourseId(courseId: $courseId) {
    _id
    courseId
    createDateTime
    deletedAt
    isDeleted
    lastModifiedDateTime
    startNodeJsonString
  }
}
    `;

/**
 * __useGetEligibilityFlowWithCourseIdQuery__
 *
 * To run a query within a React component, call `useGetEligibilityFlowWithCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibilityFlowWithCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibilityFlowWithCourseIdQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetEligibilityFlowWithCourseIdQuery(baseOptions: Apollo.QueryHookOptions<GetEligibilityFlowWithCourseIdQuery, GetEligibilityFlowWithCourseIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEligibilityFlowWithCourseIdQuery, GetEligibilityFlowWithCourseIdQueryVariables>(GetEligibilityFlowWithCourseIdDocument, options);
      }
export function useGetEligibilityFlowWithCourseIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEligibilityFlowWithCourseIdQuery, GetEligibilityFlowWithCourseIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEligibilityFlowWithCourseIdQuery, GetEligibilityFlowWithCourseIdQueryVariables>(GetEligibilityFlowWithCourseIdDocument, options);
        }
export type GetEligibilityFlowWithCourseIdQueryHookResult = ReturnType<typeof useGetEligibilityFlowWithCourseIdQuery>;
export type GetEligibilityFlowWithCourseIdLazyQueryHookResult = ReturnType<typeof useGetEligibilityFlowWithCourseIdLazyQuery>;
export type GetEligibilityFlowWithCourseIdQueryResult = Apollo.QueryResult<GetEligibilityFlowWithCourseIdQuery, GetEligibilityFlowWithCourseIdQueryVariables>;
export const GetAcademicLevelsDocument = gql`
    query getAcademicLevels {
  academicLevels(showArchived: true, showHided: true) {
    key: _id
    label: name
  }
}
    `;

/**
 * __useGetAcademicLevelsQuery__
 *
 * To run a query within a React component, call `useGetAcademicLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcademicLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcademicLevelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAcademicLevelsQuery(baseOptions?: Apollo.QueryHookOptions<GetAcademicLevelsQuery, GetAcademicLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAcademicLevelsQuery, GetAcademicLevelsQueryVariables>(GetAcademicLevelsDocument, options);
      }
export function useGetAcademicLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAcademicLevelsQuery, GetAcademicLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAcademicLevelsQuery, GetAcademicLevelsQueryVariables>(GetAcademicLevelsDocument, options);
        }
export type GetAcademicLevelsQueryHookResult = ReturnType<typeof useGetAcademicLevelsQuery>;
export type GetAcademicLevelsLazyQueryHookResult = ReturnType<typeof useGetAcademicLevelsLazyQuery>;
export type GetAcademicLevelsQueryResult = Apollo.QueryResult<GetAcademicLevelsQuery, GetAcademicLevelsQueryVariables>;
export const GetCourseDetailDocument = gql`
    query getCourseDetail($id: ObjectId!) {
  course(id: $id) {
    _id
    name
    avatarUrl
  }
}
    `;

/**
 * __useGetCourseDetailQuery__
 *
 * To run a query within a React component, call `useGetCourseDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseDetailQuery(baseOptions: Apollo.QueryHookOptions<GetCourseDetailQuery, GetCourseDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCourseDetailQuery, GetCourseDetailQueryVariables>(GetCourseDetailDocument, options);
      }
export function useGetCourseDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseDetailQuery, GetCourseDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCourseDetailQuery, GetCourseDetailQueryVariables>(GetCourseDetailDocument, options);
        }
export type GetCourseDetailQueryHookResult = ReturnType<typeof useGetCourseDetailQuery>;
export type GetCourseDetailLazyQueryHookResult = ReturnType<typeof useGetCourseDetailLazyQuery>;
export type GetCourseDetailQueryResult = Apollo.QueryResult<GetCourseDetailQuery, GetCourseDetailQueryVariables>;
export const CreateEligibilityFlowDocument = gql`
    mutation createEligibilityFlow($input: CreateEligibilityFlowInput!) {
  createEligibilityFlow(input: $input) {
    _id
    courseId
    createDateTime
    deletedAt
    isDeleted
    lastModifiedDateTime
  }
}
    `;
export type CreateEligibilityFlowMutationFn = Apollo.MutationFunction<CreateEligibilityFlowMutation, CreateEligibilityFlowMutationVariables>;

/**
 * __useCreateEligibilityFlowMutation__
 *
 * To run a mutation, you first call `useCreateEligibilityFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEligibilityFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEligibilityFlowMutation, { data, loading, error }] = useCreateEligibilityFlowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEligibilityFlowMutation(baseOptions?: Apollo.MutationHookOptions<CreateEligibilityFlowMutation, CreateEligibilityFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEligibilityFlowMutation, CreateEligibilityFlowMutationVariables>(CreateEligibilityFlowDocument, options);
      }
export type CreateEligibilityFlowMutationHookResult = ReturnType<typeof useCreateEligibilityFlowMutation>;
export type CreateEligibilityFlowMutationResult = Apollo.MutationResult<CreateEligibilityFlowMutation>;
export type CreateEligibilityFlowMutationOptions = Apollo.BaseMutationOptions<CreateEligibilityFlowMutation, CreateEligibilityFlowMutationVariables>;
export const UpdateEligibilityFlowDocument = gql`
    mutation updateEligibilityFlow($input: UpdateEligibilityFlowInput!) {
  updateEligibilityFlow(input: $input) {
    _id
    courseId
    createDateTime
    deletedAt
    isDeleted
    lastModifiedDateTime
  }
}
    `;
export type UpdateEligibilityFlowMutationFn = Apollo.MutationFunction<UpdateEligibilityFlowMutation, UpdateEligibilityFlowMutationVariables>;

/**
 * __useUpdateEligibilityFlowMutation__
 *
 * To run a mutation, you first call `useUpdateEligibilityFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEligibilityFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEligibilityFlowMutation, { data, loading, error }] = useUpdateEligibilityFlowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEligibilityFlowMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEligibilityFlowMutation, UpdateEligibilityFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEligibilityFlowMutation, UpdateEligibilityFlowMutationVariables>(UpdateEligibilityFlowDocument, options);
      }
export type UpdateEligibilityFlowMutationHookResult = ReturnType<typeof useUpdateEligibilityFlowMutation>;
export type UpdateEligibilityFlowMutationResult = Apollo.MutationResult<UpdateEligibilityFlowMutation>;
export type UpdateEligibilityFlowMutationOptions = Apollo.BaseMutationOptions<UpdateEligibilityFlowMutation, UpdateEligibilityFlowMutationVariables>;
export const GetListCourseDataDocument = gql`
    query GetListCourseData($search: String, $filterInput: CourseFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  courses(
    search: $search
    filterInput: $filterInput
    sortInfo: $sortInfo
    pageInfo: $pageInfo
  ) {
    items {
      _id
      name
      nameShort
      status
      type
    }
  }
}
    `;

/**
 * __useGetListCourseDataQuery__
 *
 * To run a query within a React component, call `useGetListCourseDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListCourseDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListCourseDataQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filterInput: // value for 'filterInput'
 *      sortInfo: // value for 'sortInfo'
 *      pageInfo: // value for 'pageInfo'
 *   },
 * });
 */
export function useGetListCourseDataQuery(baseOptions?: Apollo.QueryHookOptions<GetListCourseDataQuery, GetListCourseDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListCourseDataQuery, GetListCourseDataQueryVariables>(GetListCourseDataDocument, options);
      }
export function useGetListCourseDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListCourseDataQuery, GetListCourseDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListCourseDataQuery, GetListCourseDataQueryVariables>(GetListCourseDataDocument, options);
        }
export type GetListCourseDataQueryHookResult = ReturnType<typeof useGetListCourseDataQuery>;
export type GetListCourseDataLazyQueryHookResult = ReturnType<typeof useGetListCourseDataLazyQuery>;
export type GetListCourseDataQueryResult = Apollo.QueryResult<GetListCourseDataQuery, GetListCourseDataQueryVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($file: Upload!, $path: UploadFilePath!) {
  uploadFile(file: $file, path: $path) {
    remoteUrl
    url
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const GetSystemUsersDocument = gql`
    query GetSystemUsers($filterInput: SystemUserQuery, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  systemUsers(filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "systemUsers", filter: ["filterInput", "sortInfo"]) {
    pageIndex
    pageSize
    total
    items {
      ...SystemUserFields
    }
  }
}
    ${SystemUserFieldsFragmentDoc}`;

/**
 * __useGetSystemUsersQuery__
 *
 * To run a query within a React component, call `useGetSystemUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemUsersQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      sortInfo: // value for 'sortInfo'
 *      pageInfo: // value for 'pageInfo'
 *   },
 * });
 */
export function useGetSystemUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetSystemUsersQuery, GetSystemUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSystemUsersQuery, GetSystemUsersQueryVariables>(GetSystemUsersDocument, options);
      }
export function useGetSystemUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSystemUsersQuery, GetSystemUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSystemUsersQuery, GetSystemUsersQueryVariables>(GetSystemUsersDocument, options);
        }
export type GetSystemUsersQueryHookResult = ReturnType<typeof useGetSystemUsersQuery>;
export type GetSystemUsersLazyQueryHookResult = ReturnType<typeof useGetSystemUsersLazyQuery>;
export type GetSystemUsersQueryResult = Apollo.QueryResult<GetSystemUsersQuery, GetSystemUsersQueryVariables>;
export const GetSystemUserDocument = gql`
    query GetSystemUser($id: ObjectId!) {
  systemUser(id: $id) {
    ...SystemUserFields
  }
}
    ${SystemUserFieldsFragmentDoc}`;

/**
 * __useGetSystemUserQuery__
 *
 * To run a query within a React component, call `useGetSystemUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSystemUserQuery(baseOptions: Apollo.QueryHookOptions<GetSystemUserQuery, GetSystemUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSystemUserQuery, GetSystemUserQueryVariables>(GetSystemUserDocument, options);
      }
export function useGetSystemUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSystemUserQuery, GetSystemUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSystemUserQuery, GetSystemUserQueryVariables>(GetSystemUserDocument, options);
        }
export type GetSystemUserQueryHookResult = ReturnType<typeof useGetSystemUserQuery>;
export type GetSystemUserLazyQueryHookResult = ReturnType<typeof useGetSystemUserLazyQuery>;
export type GetSystemUserQueryResult = Apollo.QueryResult<GetSystemUserQuery, GetSystemUserQueryVariables>;
export const GetCurrentSystemUserDocument = gql`
    query GetCurrentSystemUser {
  currentSystemUser {
    ...SystemUserFields
  }
}
    ${SystemUserFieldsFragmentDoc}`;

/**
 * __useGetCurrentSystemUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentSystemUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSystemUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSystemUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentSystemUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentSystemUserQuery, GetCurrentSystemUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentSystemUserQuery, GetCurrentSystemUserQueryVariables>(GetCurrentSystemUserDocument, options);
      }
export function useGetCurrentSystemUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentSystemUserQuery, GetCurrentSystemUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentSystemUserQuery, GetCurrentSystemUserQueryVariables>(GetCurrentSystemUserDocument, options);
        }
export type GetCurrentSystemUserQueryHookResult = ReturnType<typeof useGetCurrentSystemUserQuery>;
export type GetCurrentSystemUserLazyQueryHookResult = ReturnType<typeof useGetCurrentSystemUserLazyQuery>;
export type GetCurrentSystemUserQueryResult = Apollo.QueryResult<GetCurrentSystemUserQuery, GetCurrentSystemUserQueryVariables>;
export const ExportSystemUserCsvDocument = gql`
    query ExportSystemUserCsv($filterInput: SystemUserQuery) {
  csv: exportSystemUserCsv(filterInput: $filterInput) {
    url
  }
}
    `;

/**
 * __useExportSystemUserCsvQuery__
 *
 * To run a query within a React component, call `useExportSystemUserCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSystemUserCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSystemUserCsvQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useExportSystemUserCsvQuery(baseOptions?: Apollo.QueryHookOptions<ExportSystemUserCsvQuery, ExportSystemUserCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportSystemUserCsvQuery, ExportSystemUserCsvQueryVariables>(ExportSystemUserCsvDocument, options);
      }
export function useExportSystemUserCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSystemUserCsvQuery, ExportSystemUserCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSystemUserCsvQuery, ExportSystemUserCsvQueryVariables>(ExportSystemUserCsvDocument, options);
        }
export type ExportSystemUserCsvQueryHookResult = ReturnType<typeof useExportSystemUserCsvQuery>;
export type ExportSystemUserCsvLazyQueryHookResult = ReturnType<typeof useExportSystemUserCsvLazyQuery>;
export type ExportSystemUserCsvQueryResult = Apollo.QueryResult<ExportSystemUserCsvQuery, ExportSystemUserCsvQueryVariables>;
export const CreateSystemUserDocument = gql`
    mutation CreateSystemUser($command: CreateSystemUserCommand!) {
  createSystemUser(command: $command) {
    id: _id
  }
}
    `;
export type CreateSystemUserMutationFn = Apollo.MutationFunction<CreateSystemUserMutation, CreateSystemUserMutationVariables>;

/**
 * __useCreateSystemUserMutation__
 *
 * To run a mutation, you first call `useCreateSystemUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSystemUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSystemUserMutation, { data, loading, error }] = useCreateSystemUserMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreateSystemUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateSystemUserMutation, CreateSystemUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSystemUserMutation, CreateSystemUserMutationVariables>(CreateSystemUserDocument, options);
      }
export type CreateSystemUserMutationHookResult = ReturnType<typeof useCreateSystemUserMutation>;
export type CreateSystemUserMutationResult = Apollo.MutationResult<CreateSystemUserMutation>;
export type CreateSystemUserMutationOptions = Apollo.BaseMutationOptions<CreateSystemUserMutation, CreateSystemUserMutationVariables>;
export const UpdateSystemUserDocument = gql`
    mutation UpdateSystemUser($command: UpdateSystemUserCommand!) {
  updateSystemUser(command: $command) {
    id: _id
  }
}
    `;
export type UpdateSystemUserMutationFn = Apollo.MutationFunction<UpdateSystemUserMutation, UpdateSystemUserMutationVariables>;

/**
 * __useUpdateSystemUserMutation__
 *
 * To run a mutation, you first call `useUpdateSystemUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemUserMutation, { data, loading, error }] = useUpdateSystemUserMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useUpdateSystemUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSystemUserMutation, UpdateSystemUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSystemUserMutation, UpdateSystemUserMutationVariables>(UpdateSystemUserDocument, options);
      }
export type UpdateSystemUserMutationHookResult = ReturnType<typeof useUpdateSystemUserMutation>;
export type UpdateSystemUserMutationResult = Apollo.MutationResult<UpdateSystemUserMutation>;
export type UpdateSystemUserMutationOptions = Apollo.BaseMutationOptions<UpdateSystemUserMutation, UpdateSystemUserMutationVariables>;
export const RequestChangeSystemUserPasswordDocument = gql`
    mutation RequestChangeSystemUserPassword($id: ObjectId!) {
  requestChangeSystemUserPassword(id: $id)
}
    `;
export type RequestChangeSystemUserPasswordMutationFn = Apollo.MutationFunction<RequestChangeSystemUserPasswordMutation, RequestChangeSystemUserPasswordMutationVariables>;

/**
 * __useRequestChangeSystemUserPasswordMutation__
 *
 * To run a mutation, you first call `useRequestChangeSystemUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestChangeSystemUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestChangeSystemUserPasswordMutation, { data, loading, error }] = useRequestChangeSystemUserPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequestChangeSystemUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RequestChangeSystemUserPasswordMutation, RequestChangeSystemUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestChangeSystemUserPasswordMutation, RequestChangeSystemUserPasswordMutationVariables>(RequestChangeSystemUserPasswordDocument, options);
      }
export type RequestChangeSystemUserPasswordMutationHookResult = ReturnType<typeof useRequestChangeSystemUserPasswordMutation>;
export type RequestChangeSystemUserPasswordMutationResult = Apollo.MutationResult<RequestChangeSystemUserPasswordMutation>;
export type RequestChangeSystemUserPasswordMutationOptions = Apollo.BaseMutationOptions<RequestChangeSystemUserPasswordMutation, RequestChangeSystemUserPasswordMutationVariables>;
export const GetReferencesDocument = gql`
    query GetReferences($category: ReferenceDataCategory, $showArchived: Boolean) {
  referenceDatas(category: $category, showArchived: $showArchived) {
    id: _id
    category
    order
    key
    value
    isArchived
  }
}
    `;

/**
 * __useGetReferencesQuery__
 *
 * To run a query within a React component, call `useGetReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferencesQuery({
 *   variables: {
 *      category: // value for 'category'
 *      showArchived: // value for 'showArchived'
 *   },
 * });
 */
export function useGetReferencesQuery(baseOptions?: Apollo.QueryHookOptions<GetReferencesQuery, GetReferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReferencesQuery, GetReferencesQueryVariables>(GetReferencesDocument, options);
      }
export function useGetReferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReferencesQuery, GetReferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReferencesQuery, GetReferencesQueryVariables>(GetReferencesDocument, options);
        }
export type GetReferencesQueryHookResult = ReturnType<typeof useGetReferencesQuery>;
export type GetReferencesLazyQueryHookResult = ReturnType<typeof useGetReferencesLazyQuery>;
export type GetReferencesQueryResult = Apollo.QueryResult<GetReferencesQuery, GetReferencesQueryVariables>;
export const GetSystemUserFormDataDocument = gql`
    query GetSystemUserFormData {
  branches {
    id: _id
    name
    isArchived
  }
  departments {
    id: _id
    name
    isArchived
  }
}
    `;

/**
 * __useGetSystemUserFormDataQuery__
 *
 * To run a query within a React component, call `useGetSystemUserFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemUserFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemUserFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSystemUserFormDataQuery(baseOptions?: Apollo.QueryHookOptions<GetSystemUserFormDataQuery, GetSystemUserFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSystemUserFormDataQuery, GetSystemUserFormDataQueryVariables>(GetSystemUserFormDataDocument, options);
      }
export function useGetSystemUserFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSystemUserFormDataQuery, GetSystemUserFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSystemUserFormDataQuery, GetSystemUserFormDataQueryVariables>(GetSystemUserFormDataDocument, options);
        }
export type GetSystemUserFormDataQueryHookResult = ReturnType<typeof useGetSystemUserFormDataQuery>;
export type GetSystemUserFormDataLazyQueryHookResult = ReturnType<typeof useGetSystemUserFormDataLazyQuery>;
export type GetSystemUserFormDataQueryResult = Apollo.QueryResult<GetSystemUserFormDataQuery, GetSystemUserFormDataQueryVariables>;
export const GetCountryCodesDocument = gql`
    query getCountryCodes {
  countryCodes {
    order
    areaCode
    countryCode
  }
}
    `;

/**
 * __useGetCountryCodesQuery__
 *
 * To run a query within a React component, call `useGetCountryCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountryCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetCountryCodesQuery, GetCountryCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountryCodesQuery, GetCountryCodesQueryVariables>(GetCountryCodesDocument, options);
      }
export function useGetCountryCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountryCodesQuery, GetCountryCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountryCodesQuery, GetCountryCodesQueryVariables>(GetCountryCodesDocument, options);
        }
export type GetCountryCodesQueryHookResult = ReturnType<typeof useGetCountryCodesQuery>;
export type GetCountryCodesLazyQueryHookResult = ReturnType<typeof useGetCountryCodesLazyQuery>;
export type GetCountryCodesQueryResult = Apollo.QueryResult<GetCountryCodesQuery, GetCountryCodesQueryVariables>;
export const GetDepartmentsDocument = gql`
    query getDepartments($showArchive: Boolean = false) {
  departments(showArchived: $showArchive) {
    _id
    name
  }
}
    `;

/**
 * __useGetDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentsQuery({
 *   variables: {
 *      showArchive: // value for 'showArchive'
 *   },
 * });
 */
export function useGetDepartmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetDepartmentsQuery, GetDepartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDepartmentsQuery, GetDepartmentsQueryVariables>(GetDepartmentsDocument, options);
      }
export function useGetDepartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDepartmentsQuery, GetDepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDepartmentsQuery, GetDepartmentsQueryVariables>(GetDepartmentsDocument, options);
        }
export type GetDepartmentsQueryHookResult = ReturnType<typeof useGetDepartmentsQuery>;
export type GetDepartmentsLazyQueryHookResult = ReturnType<typeof useGetDepartmentsLazyQuery>;
export type GetDepartmentsQueryResult = Apollo.QueryResult<GetDepartmentsQuery, GetDepartmentsQueryVariables>;
export const GetBranchesDocument = gql`
    query getBranches($showArchive: Boolean = false) {
  branches(showArchived: $showArchive) {
    _id
    name
  }
}
    `;

/**
 * __useGetBranchesQuery__
 *
 * To run a query within a React component, call `useGetBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchesQuery({
 *   variables: {
 *      showArchive: // value for 'showArchive'
 *   },
 * });
 */
export function useGetBranchesQuery(baseOptions?: Apollo.QueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
      }
export function useGetBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
        }
export type GetBranchesQueryHookResult = ReturnType<typeof useGetBranchesQuery>;
export type GetBranchesLazyQueryHookResult = ReturnType<typeof useGetBranchesLazyQuery>;
export type GetBranchesQueryResult = Apollo.QueryResult<GetBranchesQuery, GetBranchesQueryVariables>;