import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
} from "@apollo/client";
import { AppConfig } from "./config";
let client: ApolloClient<NormalizedCacheObject> | null = null;
export let accessToken: string;
export let refreshToken: string;
export let expireDate: number;
export let isRereshing: boolean = false;

const authLink = (getToken: () => Promise<string>): ApolloLink =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        getToken()
          .then((newToken) => {
            operation.setContext((context: Record<string, any>) => ({
              headers: {
                Authorization: `Bearer ${newToken}`,
                ...context.headers,
              },
            }));
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            forward(operation).subscribe(subscriber);
          })
          .catch((error) => {
            observer.error(error);
          });
      }),
  );

const httpLink = new HttpLink({
  uri: AppConfig.BackendBaseUrl + "/graphql",
});

const createApolloClient = (
  getToken?: () => Promise<string>,
): ApolloClient<NormalizedCacheObject> => {
  // accessToken = token;
  const links: ApolloLink[] = [authLink(getToken), httpLink];
  client = new ApolloClient({
    link: from(links),
    cache: new InMemoryCache(),
  });
  return client;
};

export default createApolloClient;
