import { Skeleton } from "antd";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import CacheProvider from "../components/CacheProvider";
import useRouter from "../config/router";

const SpecialLayout: React.FC = () => {
  const { routes } = useRouter();

  return (
    <CacheProvider>
      <Suspense fallback={<Skeleton />}>
        <Routes>
          {routes?.map(({ path, element: Element, items }, index) => {
            return items?.length ? (
              <Route path={path} key={`route-${index.toString()}`}>
                {items?.map(
                  ({ path: itemPath, element: ItemElement }, itemIndex) => (
                    <Route
                      index={!itemIndex}
                      key={`item-route${itemIndex.toString()}`}
                      path={itemIndex ? itemPath : undefined}
                      element={ItemElement ? <ItemElement /> : null}
                    />
                  )
                )}
              </Route>
            ) : (
              <Route
                key={`Route${index.toString()}`}
                path={path}
                element={Element ? <Element /> : null}
              />
            );
          })}
        </Routes>
      </Suspense>
    </CacheProvider>
  );
};

export default SpecialLayout;
