import React from "react";
import { Spin } from "@thepiquelab/archus-components-web";

interface LoadingProps {
  text?: string;
  height?: string;
}

const Loading = (props: LoadingProps) => {
  const { text, height = "100vh" } = props;
  return (
    <Spin
      size="large"
      tip={text || "Loading"}
      className="text-primary"
      wrapperClassName="w-full"
    >
      <div style={{ height }} />
    </Spin>
  );
};

export default Loading;
