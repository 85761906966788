import React, { createContext } from "react";
import { GetFeaturesQuery } from "../../generated/graphql";
// import Loading from "../Loading";
import { usePostFeatureDataSubscription } from "./hooks/usePostFeatureDataSubscription";

export const CommonCacheContext = createContext<{
  featureData?: GetFeaturesQuery["features"] | null;
  featureDataLoading: boolean;
  enabledFeatureData?: GetFeaturesQuery["features"] | null;
  enabledFeatureDataLoading: boolean;
} | null>(null);

const CacheProvider: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children }) => {
  const {
    featureData,
    featureDataLoading,
    enabledFeatureData,
    enabledFeatureDataLoading,
  } = usePostFeatureDataSubscription();

  const contextValue = {
    featureData,
    featureDataLoading,
    enabledFeatureData,
    enabledFeatureDataLoading,
  };

  return (
    <CommonCacheContext.Provider value={contextValue}>
      {featureDataLoading || enabledFeatureDataLoading ? <div /> : children}
    </CommonCacheContext.Provider>
  );
};

export default CacheProvider;
