import Icon from "@ant-design/icons/lib/components/Icon";
import { Layout, Menu } from "@thepiquelab/archus-components-web";
import { reverse } from "lodash";
import React, { useEffect, useState } from "react";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import { ReactComponent as IconMenuCollapsedAcion } from "../assets/images/IconMenuCollapsedAcion.svg";
import { ReactComponent as IconMenuCollapsedEnd } from "../assets/images/IconMenuCollapsedEnd.svg";
import PiLabLogo from "../assets/images/PiLabLogo.svg";
import logo from "../assets/images/TPLLogo.svg";
import { RoutesProps } from "../config/router";
import UserContent from "./UserContent";

const { Sider } = Layout;
const { SubMenu } = Menu;

export const showOrganizationLogo = (type: string): string => {
  switch (type) {
    case "TPL":
      return logo;
    case "Pilab":
      return PiLabLogo;
    default:
      return logo;
  }
};

interface Props {
  routes: RoutesProps[];
}

const SideBar: React.FC<Props> = (props) => {
  const { routes } = props;
  const { pathname } = useLocation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [collapse, setCollapse] = useState<boolean>(false);

  useEffect(() => {
    const result = routes
      .flatMap((item) =>
        item.items?.length
          ? [
              item,
              ...item.items.map((i) => ({
                ...i,
                path:
                  item.path === "/" ? `/${i.path}` : `${item.path}/${i.path}`,
              })),
            ]
          : item,
      )
      .flat();

    result.forEach((item) => {
      if (matchPath({ path: item.path }, pathname)) {
        const prefix = reverse([...routes])?.find(
          (i) => !!item.path.includes(i?.path),
        );

        setSelectedKeys([prefix?.path, item.path]);
      }
    });
  }, [pathname]);

  const onCollapse = (): void => {
    setCollapse(!collapse);
  };

  return (
    <Sider
      collapsible
      collapsed={collapse}
      theme="light"
      trigger={null}
      className="sideBar"
      width="310px"
    >
      <div className="flex flex-col h-inherit">
        <div
          className={`flex items-center pt-5 ${
            collapse ? "justify-center" : "px-5 justify-between"
          }`}
        >
          {!collapse && (
            <div className="w-1/2">
              <img
                src={showOrganizationLogo("TPL")}
                alt="logo"
                className="object-contain h-14 w-auto"
              />
            </div>
          )}

          <div className="text-center">
            {collapse ? (
              <IconMenuCollapsedAcion
                fill="#9093AF"
                onClick={onCollapse}
                className="cursor-pointer w-6 h-6"
              />
            ) : (
              <IconMenuCollapsedEnd
                fill="#9093AF"
                onClick={onCollapse}
                className="cursor-pointer w-6 h-6"
              />
            )}
          </div>
        </div>

        <UserContent collapse={collapse} />

        <div className="flex-grow overflow-y-auto">
          <Menu
            theme="light"
            mode={"inline"}
            defaultSelectedKeys={["/"]}
            selectedKeys={selectedKeys}
          >
            {routes
              .filter((item) => !item.hide)
              .map((item) =>
                item.items && item.items.length && !item?.hideItems ? (
                  <SubMenu
                    key={item.path}
                    icon={<Icon component={item?.icon} />}
                    title={
                      <NavLink to={item.path} className="inline-block w-full">
                        <span
                          className={`${
                            collapse ? "" : "sideBar__menu_spanPadding"
                          }`}
                        >
                          {item.label}
                        </span>
                      </NavLink>
                    }
                  >
                    {item.items
                      .filter((i) => !i.hide)
                      .map((submenuItem) => (
                        <Menu.Item
                          key={`${item.path}${submenuItem.path}`}
                          className={`${
                            selectedKeys.includes(
                              item.path === "/"
                                ? `/${submenuItem.path}`
                                : `${item.path}/${submenuItem.path}`,
                            )
                              ? "sideBar__menu_submenu_item--selected"
                              : ""
                          }`}
                        >
                          <NavLink to={`${item.path}${submenuItem.path}`}>
                            <span
                              className={`${
                                collapse ? "" : "sideBar__menu_spanPadding"
                              }`}
                            >
                              {submenuItem.label}
                            </span>
                          </NavLink>
                        </Menu.Item>
                      ))}
                  </SubMenu>
                ) : (
                  <Menu.Item
                    icon={<Icon component={item?.icon} />}
                    key={item.path}
                  >
                    <NavLink to={item.path}>
                      <span
                        className={`${
                          collapse ? "" : "sideBar__menu_spanPadding"
                        }`}
                      >
                        {item.label}
                      </span>
                    </NavLink>
                  </Menu.Item>
                ),
              )}
          </Menu>
        </div>
      </div>
    </Sider>
  );
};

export default SideBar;
