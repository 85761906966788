import { useMutation } from "@apollo/client";
import {
  UploadFileDocument,
  UploadFileMutation,
  UploadFileMutationVariables,
} from "../../generated/graphql";

export const useUpload = () => {
  const [uploadPublicFile, { data, loading }] = useMutation<
    UploadFileMutation,
    UploadFileMutationVariables
  >(UploadFileDocument);
  return { uploadPublicFile, data: data?.uploadFile, loading };
};
