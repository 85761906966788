// import { useAuth0 } from "@/Auth0Wrapper";
import { OAuthPermission } from "@thepiquelab/web-types";
import jwtDecode from "jwt-decode";
import { isBoolean, uniq } from "lodash";
import { IsPermissionDebugMode } from "../config";

interface IUseAccessControl {
  hasSomePermissions: (permissions: OAuthPermission[]) => boolean;
  hasAllPermissions: (permissions: OAuthPermission[]) => boolean;
}

const permissionsForTest: { [key in OAuthPermission]?: boolean } = {
  // [OAuthPermission.EnrollmentCreate]: true,
  // [OAuthPermission.EnrollmentView]: true,
  // [OAuthPermission.EnrollmentUpdate]: true,
  // [OAuthPermission.EnrollmentExport]: true,
  // [OAuthPermission.RegistrationView]: true,
  // [OAuthPermission.RegistrationCreate]: false,
  // [OAuthPermission.RegistrationUpdate]: true,
  // [OAuthPermission.RegistrationViewExport]: false,
  // [OAuthPermission.ArrangementView]: true,
  // [OAuthPermission.ArrangementUpdate]: false,
  // [OAuthPermission.ArrangementCreate]: true,
  // [OAuthPermission.WhatsappAccountView]: true,
  // [OAuthPermission.CampaignView]: true,
  // [OAuthPermission.ReportFinanceView]: true,
};

const mergePermissions = (
  permissions: OAuthPermission[],
): OAuthPermission[] => {
  if (!IsPermissionDebugMode) return permissions;
  return uniq(
    permissions
      .concat(Object.keys(permissionsForTest) as OAuthPermission[])
      .filter(
        (item) =>
          !isBoolean(permissionsForTest[item]) || permissionsForTest[item],
      ),
  );
};

/**
 *
 * @returns {object} { hasSomePermissions, hasAllPermissions }
 *  hasSomePermissions - if it has one of required permissions
 *  hasSomePermissions - if it has all of required permissions
 */
export function useAccessControl(): IUseAccessControl {
  const token = sessionStorage.getItem("token") || "";
  const decodedToken: { permissions: OAuthPermission[] } = jwtDecode(token);
  const permissionsAllowed = mergePermissions(decodedToken.permissions || []);

  /**
   * to check if it has one of required permissions
   * @param {OAuthPermission[]} - permissions to check
   * @returns {boolean}
   */
  const hasSomePermissions = (permissions: OAuthPermission[]): boolean => {
    const result = permissions.some((permission) =>
      permissionsAllowed.includes(permission),
    );
    return result;
  };

  /**
   * to check if it has all of required permissions
   * @param permissions {EnumPermission[]} - permissions to check
   * @returns {boolean}
   */
  const hasAllPermissions = (permissions: OAuthPermission[]): boolean => {
    const result = permissions.every((permission) =>
      permissionsAllowed.includes(permission),
    );
    return result;
  };

  return {
    hasSomePermissions,
    hasAllPermissions,
  };
}
