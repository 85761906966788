import { UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Dropdown, Row } from "@thepiquelab/archus-components-web";
import { UserApi } from "@thepiquelab/web-api";
import { useAuth0Client, useDBUser } from "@thepiquelab/web-auth0";
import { useRequest } from "ahooks";
import React, { createContext, useMemo, useState } from "react";
import packageJson from "../../package.json";
import { ReactComponent as IconSetting } from "../assets/images/IconSetting.svg";
import Loading from "../components/Loading";
import MenuBarDropdown from "./MenuBarDropdown";

interface Props {
  collapse: boolean;
}

export const CurrentAdminContext = createContext<{
  user: {
    fullName?: string;
    initials?: string;
    email?: string;
    userID?: string;
    emailAlerts?: boolean;
    avatar?: string;
    adminId?: string;
    mobile?: string;
    department?: string;
  };
  // @ts-ignore
}>(null);

const UserContent: React.FC<Props> = (props) => {
  const { logout } = useAuth0Client();
  const { dbUser } = useDBUser();

  const { data, loading } = useRequest(() => UserApi.me(), {
    onError: () => {
      logout();
    },
    onSuccess: (data) => {
      if (!data) {
        logout();
      }
    },
  });

  const { collapse } = props;
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useMemo(() => {
    if (dbUser) {
      const { sub } = dbUser;
      if (!sub) {
        logout();
      }
    }
  }, [dbUser]);

  const handleDropdown = (visible: boolean): void => {
    setDropdownVisible(visible);
  };

  if (loading) {
    return <Loading height="10rem" />;
  }

  return (
    <CurrentAdminContext.Provider
      value={{
        user: {
          fullName: data?.fullName || data?.alias,
          initials: data?.initials,
          email: data?.email,
          userID: data?.userId,
          avatar: data?.avatarUrl,
          adminId: data?._id,
          mobile: data?.mobile?.number,
          department: data?.department?.name,
        },
      }}
    >
      {!collapse && (
        <div className="px-5 mt-6 flex justify-between">
          <span>Version {packageJson.version}</span>

          <Dropdown
            onOpenChange={handleDropdown}
            open={dropdownVisible}
            overlay={<MenuBarDropdown onDropdownVisible={setDropdownVisible} />}
            arrow={{ pointAtCenter: true }}
          >
            <IconSetting className="cursor-pointer w-4 h-4" />
          </Dropdown>
        </div>
      )}

      <div
        className={`${
          collapse ? "flex flex-row items-center justify-center" : "px-5"
        } mt-4`}
      >
        {data?.avatarUrl ? (
          <Avatar
            size={60}
            shape={"circle"}
            style={{ boxShadow: "0px 4px 14px rgba(79, 83, 123, 0.21)" }}
            src={data?.avatarUrl}
          />
        ) : (
          <Avatar
            size={60}
            shape={"circle"}
            style={{ boxShadow: "0px 4px 14px rgba(79, 83, 123, 0.21)" }}
            className="flex flex-row items-center justify-center"
            icon={<UserOutlined />}
          />
        )}

        {!collapse && (
          <Row
            justify="space-between"
            className="items-center justify-start mt-3 mb-4"
          >
            <Col span={20} className="font-semibold text-lg leading-tight">
              {`Hello, ${data?.fullName || data?.alias || ""}`}
            </Col>
          </Row>
        )}
      </div>
    </CurrentAdminContext.Provider>
  );
};

export default UserContent;
