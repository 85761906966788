import { message } from "antd";

const formatErrorMessage = (e: any) => {
  const errorMessage =
    e?.response?.data?.message ||
    e?.response?.data?.error?.message ||
    e.message;

  if (errorMessage) {
    message.error(errorMessage);
  }
};
export default formatErrorMessage;
