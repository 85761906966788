import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  GetEnabledFeaturesDocument,
  GetEnabledFeaturesQuery,
  GetEnabledFeaturesQueryVariables,
  GetFeaturesDocument,
  GetFeaturesQuery,
  GetFeaturesQueryVariables,
} from "../../../generated/graphql";

export const usePostFeatureDataSubscription = (): {
  featureData: GetFeaturesQuery["features"] | null;
  setFeatureData: (val: GetFeaturesQuery["features"] | null) => void;
  featureDataLoading: boolean;
  enabledFeatureData: GetEnabledFeaturesQuery["enabledFeatures"] | null;
  setEnabledFeatureData: (
    val: GetEnabledFeaturesQuery["enabledFeatures"] | null
  ) => void;
  enabledFeatureDataLoading: boolean;
} => {
  const [featureData, setFeatureData] = useState<
    GetFeaturesQuery["features"] | null
  >(null);

  const [enabledFeatureData, setEnabledFeatureData] = useState<
    GetEnabledFeaturesQuery["enabledFeatures"] | null
  >(null);

  const { data: originalFeatureData, loading: getFeatureDataLoading } =
    useQuery<GetFeaturesQuery, GetFeaturesQueryVariables>(GetFeaturesDocument, {
      fetchPolicy: "cache-first",
    });

  const {
    data: originalEnabledFeatureData,
    loading: getEnabledFeatureDataLoading,
  } = useQuery<GetEnabledFeaturesQuery, GetEnabledFeaturesQueryVariables>(
    GetEnabledFeaturesDocument,
    {
      fetchPolicy: "cache-first",
    }
  );

  // const { data: featureDataFromSub } = useSubscription<
  //   PostFeatureDataSubscription,
  //   PostFeatureDataSubscriptionVariables
  // >(PostFeatureDataDocument);

  /**
   * Update feature data from subscription
   */
  // useEffect(() => {
  //   if (!featureData) {
  //     return;
  //   }

  //   setFeatureData(
  //     featureData.map((currentFeatureData) => {
  //       const updatedFeatureData = find(featureDataFromSub.postFeatureData, {
  //         id: currentFeatureData.id,
  //       });
  //       if (updatedFeatureData) {
  //         return {
  //           ...currentFeatureData,
  //           ...updatedFeatureData,
  //         };
  //       }
  //       return currentFeatureData;
  //     })
  //   );
  // }, [featureDataFromSub]);

  // /**
  //  * Update enabledFeature data from subscription
  //  */
  // useEffect(() => {
  //   if (!featureDataFromSub || !enabledFeatureData) {
  //     return;
  //   }

  //   const updatedToEnabledFeatures =
  //     featureDataFromSub?.postFeatureData?.filter(
  //       (currentFeature) => currentFeature.enabled
  //     );
  //   const updatedToDisabledFeatures =
  //     featureDataFromSub?.postFeatureData?.filter(
  //       (currentFeature) => !currentFeature.enabled
  //     );

  //   let newEnabledFeatures = cloneDeep(enabledFeatureData) || [];
  //   newEnabledFeatures = newEnabledFeatures.concat(updatedToEnabledFeatures);

  //   newEnabledFeatures = newEnabledFeatures.filter(
  //     (currentFeature) =>
  //       !updatedToDisabledFeatures.some(
  //         (disabledFeature) => disabledFeature.id === currentFeature.id
  //       )
  //   );

  //   setEnabledFeatureData(newEnabledFeatures);
  // }, [featureDataFromSub]);

  useEffect(() => {
    if (originalFeatureData?.features) {
      setFeatureData(originalFeatureData?.features);
    }
  }, [originalFeatureData]);

  useEffect(() => {
    if (originalEnabledFeatureData?.enabledFeatures) {
      setEnabledFeatureData(originalEnabledFeatureData?.enabledFeatures);
    }
  }, [originalEnabledFeatureData]);

  return {
    featureData,
    setFeatureData,
    featureDataLoading: getFeatureDataLoading,
    enabledFeatureData,
    setEnabledFeatureData,
    enabledFeatureDataLoading: getEnabledFeatureDataLoading,
  };
};
