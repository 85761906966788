import { Layout } from "antd";
import React from "react";
import useRouter from "../config/router";
import SideBar from "./SideBar";
import SpecialLayout from "./SpecialLayout";

const BaseLayout: React.FC = () => {
  const { routes } = useRouter();
  return (
    <Layout className="layout min-w-xl h-full" data-testid="base-layout">
      <SideBar routes={routes} />

      <Layout.Content className="bg-gray-100 h-full p-6 pb-0">
        <SpecialLayout />
      </Layout.Content>
    </Layout>
  );
};

export default BaseLayout;
